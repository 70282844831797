import { ProfileService } from './../../services/profile/profile.service';
import { AuthService } from './../../services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from 'src/environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-player-tennis-wallet',
  templateUrl: './player-tennis-wallet.component.html',
  styleUrls: ['./player-tennis-wallet.component.scss']
})
export class PlayerTennisWalletComponent implements OnInit {
  
  isLoading: boolean;
  userObj;
  hasTransactions: boolean;
  transactions: any;
  depositAmount: number;
  isDepositModalOpen: boolean
  isModalLoading: boolean;
  isDeposited: boolean;
  showPaypal: boolean;
  payPalConfig: IPayPalConfig;
  withdrawAmount: number;
  isWithdrawModalOpen: boolean
  isWithdrawn: boolean
  isUserDataLoaded: boolean;
  redirectTo = '';

  constructor(private firestore: AngularFirestore,
              private fireFunctions: AngularFireFunctions,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private router: Router,
              private profileService: ProfileService) { }
  
  getFormattedDate(timestamp) {
    if(timestamp) {
      let getCurrentDate = new Date(timestamp)
      let getYear = getCurrentDate.getFullYear()
      let getMonth = getCurrentDate.getMonth()
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let getDate = getCurrentDate.getDate()
      return `${getDate}/${months[getMonth]}/${getYear}`
    }
    return `Couldn't find date`
  }

  openModal(type) {
    if(type === 'deposit') {
      let depositAmount = Number(this.depositAmount);
      if(depositAmount > 0) {
        this.isDepositModalOpen = true;
        document.body.classList.add('modal-open');
      }
    } else {
      let withdrawAmount = Number(this.withdrawAmount);
      if(withdrawAmount > 0 && withdrawAmount <= this.userObj.wallet) {
        this.isWithdrawModalOpen = true;
        document.body.classList.add('modal-open');
      }
    }
  }

  closeModal() {
    this.isDepositModalOpen = false;
    this.isDeposited = false;
    this.isDepositModalOpen = false;
    this.isModalLoading = false;
    this.isDeposited = false;
    this.showPaypal = false;
    this.depositAmount = undefined;
    this.payPalConfig = undefined;
    this.isWithdrawModalOpen = false;
    this.isWithdrawn = false;
    this.withdrawAmount = undefined
    document.body.classList.remove('modal-open');
  }

  deposit() {
    console.log(`Paypal client id ${environment.paypalClientId}`)
    this.showPaypal = true;
    this.payPalConfig = {
      currency: "AUD",
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'AUTHORIZE',
        purchase_units: [{
          amount: {
            currency_code: 'AUD',
            value: this.depositAmount.toString(),
          },
        }]
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [{
          name: 'intent',
          value: 'authorize',
        }]
      },
      onApprove: async (data, actions) => {
        try {
          this.isModalLoading = true;
          this.showPaypal = false;
          let authorization = await actions.order.authorize();
          let authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;
          let addToWallet = this.fireFunctions.httpsCallable('wallet-addToWallet')
          let res = await addToWallet({
            authId: authorizationID,
            amount: this.depositAmount,
            currency: 'aud',
            uid: this.authService.user.uid
          }).toPromise()
          this.isDeposited = true;
          this.isModalLoading = false;
          this.ngOnInit();
          this.redirectTo = this.activatedRoute.snapshot.url[0].parameters.redirectTo;
        } catch(e) {
          console.log(e)
        }
      }
    };
  }

  async withdraw() {
    try {
      this.isModalLoading = true;
      let withdraw = this.fireFunctions.httpsCallable('wallet-deductFromWallet')
      let response = await withdraw({ 
        amount: this.withdrawAmount,
        currency: 'aud',
       }).toPromise()
       if(response.success) {
        this.isWithdrawn = true;
        this.isModalLoading = false;
        this.ngOnInit();
       }
    } catch(e) {
      console.log(e)
    }
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      const getUserData = async () => {
        this.userObj = (await this.profileService.getUserProfileData()).data()
        if(!this.userObj.wallet) this.userObj.wallet = 0
        this.isUserDataLoaded = true;
      };
      getUserData();
      let transactions = await this.firestore.collection('Transactions', ref => ref
                        .where('uid', '==', this.authService.user.uid)).get().toPromise()
      if(transactions.docs.length > 0) {        
        let transactionsList = transactions.docs.map(el => {
          let temptransactionObj = el.data();
          temptransactionObj.transactionId = el.id; 
          return temptransactionObj;
        })
        transactionsList.sort((a, b) => b.timestamp - a.timestamp);
        this.transactions = transactionsList; 
        this.hasTransactions = true;
      } else {
        this.hasTransactions = false;
      }
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    } finally {
      if (this.redirectTo) {
        this.closeModal();
        this.router.navigateByUrl(this.redirectTo);
      }
    }
  }
}
