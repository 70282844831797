<div class="container-fluid">
  <app-shade></app-shade>
  <app-page-heading pageHeading="Privacy Policy"></app-page-heading>
</div>
<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <p>Viictor Pty Ltd (A.C.N.638 794 760) recognises and values the protection of your
        personal information.</p>
      <p>We recognise that you have an interest in our collection and use of your personal
        information via our website, which is located at www.viictor.com (the Website). We
        have implemented this Privacy Policy in order to be open and transparent about how
        we collect, hold, and use your personal information, and under what circumstances
        we may disclose or transfer your personal information. This Privacy Policy also
        outlines your rights to gain access to, and seek corrections of, your personal
        information we hold. Finally, this Privacy Policy provides information about how you
        can approach us about your privacy-related concerns and complaints, and how we
        will deal with such communications.</p>
      <p>This Privacy Policy applies to information that Viictor collects via this Website.</p>
      <p>Please note that this Website Privacy Policy forms part of the Terms of Use
        document, which is displayed at the footer of each page of the Website.</p>
      <p></p>
      <h2>Information we collect and hold</h2>
      <p>From time to time, we may ask you to supply personal information such as your
        name, address, gender, date of birth, telephone number or e-mail address. However,
        under no circumstances will we request any information from you that may disclose
        your:</p>
      <ul>
        <li>political, religious or philosophical opinions, beliefs, associations or
          affiliations;</li>
        <li>racial or ethnic origin;</li>
        <li>membership of a trade union, or a professional or trade association; or</li>
        <li>criminal records.</li>
      </ul>
      <p>We may also conduct surveys or market research and may seek other information
        from you on a periodic basis. These surveys will provide us with information that
        allows improvement in the types and quality of services offered to you, and the
        manner in which those services are offered to you.</p>
      <h2>Personal information via the Website</h2>
      <p>Most commercial websites use ‘cookies’, which are pieces of information that
        websites send to the browser and are stored in the computer hard-drive. Cookies
        make using the Website easier by storing information about your preferences on the
        Website. This allows the Website to be tailored to you for any of your return visits.
        Cookies will not identify you personally.</p>
      <p>If you would prefer not to receive cookies, you can alter your security settings on
        your web browser to disable cookies or to warn you when cookies are being used.
        However, by disabling the cookie function in your web browser you may impede your
        ability to use parts of the Website.</p>
      <h2>Your option not to provide your personal information</h2>
      <p>Providing us with your personal information is absolutely optional, but may be
        necessary for us to provide you with our services. Whenever it is optional for you to
        provide us with non-essential personal information, we will make this clear to you.
        When you provide us with your personal information, you are consenting to our
        storage, use and disclosure of that information as outlined in this Website Privacy
        Policy.</p>
      <p>We may from time to time run competitions or offer additional benefits to you and we
        may ask you to provide us with your personal details for these purposes. Providing
        us with this information is absolutely optional to you. However, if you do not provide
        your personal information to us we may not be able to contact you or give you
        access to the additional benefits.</p>
      <p>You may opt out of these additional communications at any time. Please contact our
        Privacy Officer at <i>privacy@viictor.com</i> or via post at PO Box 529, Dianella, Western
        Australia 6059.</p>
      <h2>Use and disclosure of your personal information</h2>
      <p>When we hold your personal information it will be used for the following primary
        purposes:</p>
      <ol>
        <li>to ensure the proper functioning of the Website;</li>
        <li>to ensure the proper functioning of the Viictor business and operations; and</li>
        <li>to assist Viictor with our auditing, marketing, planning, billing, product
          development and research requirements.</li>
      </ol>
      <p>We will not use or disclose (or permit the use or disclosure of) information that could
        be used to identify you in any circumstances except:</p>
      <ul>
        <li>to ensure the proper functioning of our organisation and the Website;</li>
        <li>to communicate promotional offers and special events to you;</li>
        <li>where the law requires us, or authorises us, or a company holding data on
          our behalf, to do so; or</li>
        <li>where you have given express consent to us for a prescribed purpose.</li>
      </ul>
      <p>We will not sell, distribute, rent, licence, disclose, share or pass your personal
        information onto any third parties, other than those who are contracted to us to keep
        the information confidential whether subject to a statute or a scheme which imposes
        similar restrictions to the Australian Privacy Principles contained in the Privacy Act
        1988 (Cth), as amended, regarding the handling of personal information.</p>
      <p>Should a third party approach us with a demand to access your personal information,
        we will take reasonable steps to redirect the third party to request the information
        directly from you, wherever it is lawful and reasonable for us to do so.</p>
      <p>If we are compelled to disclose your personal information, to a third party we will take
        reasonable steps to notify you of this in advance, wherever it is lawful and
        reasonable for us to do so.</p>
      <h2>Security of personal information</h2>
      <p>In our organisation, personal information may be stored both electronically and in
        hard-copy form. We are committed to keeping your personal information secure
        regardless of the format in which we hold it and we take all reasonable steps to
        protect your information from misuse, interference, loss, and unauthorised access,
        modification or disclosure. However, you use the Website at your own risk and we
        accept no responsibility, whether we are deemed to have been negligent or not, in
        the event of a security that affects your privacy.</p>
      <p>Note that no information transmitted over the Internet can be guaranteed to be
        completely secure. However, we will endeavour to protect your personal information
        as best as possible but we cannot guarantee the security of any information that you
        transmit to us, or receive from us. The transmission and exchange of information is
        carried out at your own risk.</p>
      <h2>Accuracy and quality of personal information</h2>
      <p>We will take all such steps as are reasonable in the circumstances to ensure that:</p>
      <ol>
        <li>all information collected from you is kept accurate, up to date and complete;
          and</li>
        <li>the personal information that we use or disclose is, having regard to the
          purpose of the use or disclosure, accurate, up-to-date, complete and relevant.</li>
      </ol>
      <h2>Access to your personal information</h2>
      <p>In most cases, you have the right to access the personal information that we hold
        about you. If you wish to access your personal information, please contact our
        Privacy Officer at <i>privacy@viictor.com</i> or via post at PO Box 529, Dianella, Western
        Australia 6059.</p>
      <p>We will deal with all requests for access to personal information as quickly as
        possible. Requests for a large amount of information, or information which is not
        currently in use, may require further time before a response can be given.</p>
      <p>We may charge you a reasonable fee for access if a cost is incurred by us in order to
        retrieve your information, but in no case will we charge you a fee for your application
        for access.</p>
      <p>Whenever a fee will be applied, you will be notified of how that fee will be calculated,
        or where possible, the total amount that will be charged. You will then have the
        option to decide whether to proceed with your access request.</p>
      <p>In some cases, we will refuse to give you access to personal information we hold
      about you. This includes, but is not limited to, circumstances where denying access
      is required or authorised by or under an Australian law or a court/tribunal order or
      where giving you access would be unlawful; have an unreasonable impact on other
      people's privacy; prejudice an investigation of unlawful activity; reveal our intentions
      in relation to negotiations with you so as to prejudice those negotiations; prejudice
      enforcement related activities conducted by, or on behalf of, an enforcement body;
      reveal evaluative information generated within the Viictor business in connection with
      a commercially sensitive decision-making process.</p>
      <p>We will also refuse access where the personal information relates to existing or
        anticipated legal proceedings, and the information would not be accessible by the
        process of discovery in those proceedings. Further, we will refuse access where your
        request is frivolous or vexatious, and where we reasonably believe that: giving
        access would pose a serious threat to the life, health or safety of any individual, or to
        public health or public safety; unlawful activity, or misconduct of a serious nature, is
        being or may be engaged in against Viictor and giving access would be likely to
        prejudice the taking of appropriate action in relation to that matter.</p>
      <p>If we refuse to give you access we will provide you with reasons for our refusal,
        unless doing so would be unreasonable in the circumstances. We will also take
        reasonable steps to give you access in a way that meets your needs without giving
        rise to the reasons of our refusal. Further, we will provide details of how you may
        make a complaint about our decision.</p>
      <p>These mechanisms for accessing your personal information operate alongside, and
        do not replace, other informal or legal procedures by which you may be provided with
        access to your personal information.</p>
        <h2>Correction of your personal information</h2>
      <p>The accuracy of the personal information we have requested from you is important to
        us. Should you suspect, or become aware of, that your personal information we hold
        is inaccurate, out of date, incomplete or misleading, please contact our Privacy
        Officer at <i>privacy@viictor.com</i> or via post at PO Box 529, Dianella, Western Australia
        6059.</p>
      <p>We will deal with all requests for correction of personal information as quickly as
        possible. Requests relating to a large amount of information, or information which is
        not currently in use, may require further time before a response can be given.</p>
      <p>If we refuse to change the personal information as you request, we will provide you
        with reasons for our refusal, unless doing to would be unreasonable in the
        circumstances. We will also provide details of how you may make a complaint about
        our decision. Further, in case of our refusal, you may request that we take
        reasonable steps to associate, with the relevant information, a statement that you
        view it as inaccurate, out of date, incomplete or misleading.</p>
      <p>In the case we have corrected personal information about you, you may request that
        we take reasonable steps to give notice of the correction to any third party to which
        we have disclosed the inaccurate, out of date, incomplete or misleading personal
        information.</p>
      <p>These mechanisms for correcting your personal information operate alongside, and
        do not replace, other informal or legal procedures by which you may be provided
        correction of your personal information.</p>
      <h2>Overseas transfer of personal information</h2>
      <p>We may transfer your personal information to overseas recipients. However, we will
        seek your consent prior to disclosing your information if the overseas recipient is not
        regulated in a way that equally reflects the Australian Privacy Principles. We will take
        reasonable steps to ensure an overseas recipient does not breach the requirements
        of the Privacy Act 1988 (Cth).</p>
      <h2>Concerns and complaints about breaches</h2>
      <p>If you have concerns about how we handle your personal information, it is important
        that you notify us as soon as possible, so that we can address your concerns
        appropriately as the circumstances require. Any concern or complaint should be
        made in writing. Please send it to our Privacy Officer at <i>privacy@viictor.com</i> or via
        post at PO Box 529, Dianella, Western Australia 6059 and we will respond as soon
        as reasonably possible. Alternatively, you may contact the Office of the Australian
        Information Commissioner with your concern. Information about lodging a complaint
        is available on the Office of the Australian Information Commissioner’s website (see
        in particular: <a href="http://www.oaic.gov.au/privacy/privacy-complaints"></a>
        http://www.oaic.gov.au/privacy/privacy-complaints.</p>
      <h2>Disposal of personal information not required</h2>
      <p>If we hold personal information about you, and we do not need that information for
        any purpose for which the information may be used or disclosed, we will take
        reasonable steps to destroy or de-identify that information unless we are prevented
        from doing so by law.</p>
      <h2>Unsubscribing from our e-mail database</h2>
      <p>To unsubscribe from our e-mail database, please send us an e-mail to
        <i>privacy@viictor.com</i> with “UNSUBSCRIBE” typed into the subject line of the email.</p>
      <h2>Contacting us</h2>
      <p>If you have any questions, concerns or ideas about how we could improve our
        Privacy Policy, please contact our Privacy Officer at <i>privacy@viictor.com</i>. Where you
        provide suggestions, materials or feedback it is considered non-confidential and we
        may, at our complete discretion, use it to improve the Website, service and/or how
        we handle personal information without any obligation to compensate you regardless
        of how we use, implement, copy, modify, display, distribute and/or otherwise benefit
        from your suggestions, materials or feedback.</p>
      <h2>Amendments of this Privacy Policy</h2>
      <p>We are obligated to regularly review and update this Privacy Policy. We therefore
        reserve the right to amend this Privacy Policy at any time. Should any significant
        amendments occur, notification will be provided by publication on the Website 14
        days prior to the changes being implemented (the Notice Period) unless the
        circumstances of the amendments makes it unreasonable to provide such a Notice
        Period. Your continued use after the Notice Period has lapsed indicates your consent
        to be bound by the amended Privacy Policy.</p>
      <p>For further information about privacy in general, please refer to the Office of the
        Australian Information Commissioner’s website located at <a href="http://www.oaic.gov.au"></a>http://www.oaic.gov.au.</p>
      <p>This Privacy Policy was last updated on 12 March 2020. To download a PDF version
        of this Privacy Policy, click here. Alternatively, please contact our Privacy Officer at
        <i>privacy@viictor.com</i> or via post at PO Box 529, Dianella, Western Australia 6059 and
        we will gladly send you a copy free of charge.</p>
    </div>
  </div>
</div>