<div *ngIf="userRegistered else notRegistered" class="my-matches">
  <div *ngIf="userMatchesFound || userLosersMatchesFound">

    <div *ngIf="isKnockoutMatch">
      <ng-container *ngIf="isSingleFixtures">
        <div *ngIf="userMatchesFound">
          <h4>Rounds</h4>
          <div class="row">
            <div class="col-12 col-sm-12 col-lg-6" *ngFor="let userMatch of finalUserMatches; let i = index">
              <div class="match">
                <div class="players">
                  <div class="player" [routerLink]="userMatch.teamA.name !=='Bye' && userMatch.teamA.name !=='TBA' ? ['/tennis/player/'+userMatch.teamA.id] : []">
                    <img [src]="userMatch.teamA.image" alt="profile-img"> {{ userMatch.teamA.name }}
                    <span *ngIf="userMatch.winner === 'teamA'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                    <div class="kit">{{ userMatch.home ==='teamA' ? 'Home' : 'Away' }}</div>
                  </div>
                  <div class="vs">vs</div>
                  <div class="player" [routerLink]="userMatch.teamB.name !=='Bye' && userMatch.teamB.name !=='TBA' ? ['/tennis/player/'+userMatch.teamB.id] : []">
                    <img [src]="userMatch.teamB.image" alt="profile-img"> {{ userMatch.teamB.name }}
                    <span *ngIf="userMatch.winner === 'teamB'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                    <div class="kit">{{ userMatch.home ==='teamB' ? 'Home' : 'Away' }}</div>
                  </div>
                </div>

                <ng-container *ngIf="userMatch.teamA.name === 'Bye' || userMatch.teamA.name === 'TBA' || userMatch.teamB.name === 'Bye' || userMatch.teamB.name === 'TBA'">
                  <p></p>
                  <div class="bye-match alert alert-secondary">
                    Cannot Upload Scores
                  </div>
                </ng-container>
                <ng-container *ngIf="userMatch.teamA.name !== 'Bye' && userMatch.teamA.name !== 'TBA' && userMatch.teamB.name !== 'Bye' && userMatch.teamB.name !== 'TBA'">
                  <div class="add-score-btn" *ngIf="!userMatch.frontEndState || userMatch.frontEndState === 'incomplete' else scoreUploaded">
                    <button type="button" (click)="openScoreModal(userMatch, i+1, userMatch.fixture, 'rounds', i)">Add Score</button>
                  </div>
                  <ng-template #scoreUploaded>
                    <p></p>
                    <div class="alert alert-secondary">
                      Scores have been uploaded
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="userLosersMatchesFound">
          <h4>Consolation</h4>
          <div class="row">
            <div class="col-12 col-sm-12 col-lg-6" *ngFor="let userMatch of finalLoserUserMatches; let i = index">
              <div class="match">
                <div class="players">
                  <div class="player" [routerLink]="userMatch.teamA.name !=='Bye' && userMatch.teamA.name !=='TBA' ? ['/tennis/player/'+userMatch.teamA.id] : []">
                    <img [src]="userMatch.teamA.image" alt="profile-img"> {{ userMatch.teamA.name }}
                    <span *ngIf="userMatch.winner === 'teamA'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                    <div class="kit">{{ userMatch.home ==='teamA' ? 'Home' : 'Away' }}</div>
                  </div>
                  <div class="player" [routerLink]="userMatch.teamB.name !=='Bye' && userMatch.teamB.name !=='TBA' ? ['/tennis/player/'+userMatch.teamB.id] : []">
                    <img [src]="userMatch.teamB.image" alt="profile-img"> {{ userMatch.teamB.name }}
                    <span *ngIf="userMatch.winner === 'teamB'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                    <div class="kit">{{ userMatch.home ==='teamB' ? 'Home' : 'Away' }}</div>
                  </div>
                </div>
                <ng-container *ngIf="userMatch.teamA.name === 'Bye' || userMatch.teamA.name === 'TBA' || userMatch.teamB.name === 'Bye' || userMatch.teamB.name === 'TBA'">
                  <div class="bye-match alert alert-secondary">
                    Cannot Upload Scores
                  </div>
                </ng-container>
                <ng-container *ngIf="userMatch.teamA.name !== 'Bye' && userMatch.teamA.name !== 'TBA' && userMatch.teamB.name !== 'Bye' && userMatch.teamB.name !== 'TBA'">
                  <div class="add-score-btn"
                    *ngIf="!userMatch.frontEndState || userMatch.frontEndState === 'incomplete' else scoreUploaded">
                    <button type="button" (click)="openScoreModal(userMatch, i+1, userMatch.fixture, 'loserRounds', i)">Add
                      Score</button>
                  </div>
                  <ng-template #scoreUploaded>
                    <div class="alert alert-secondary">
                      Scores have been uploaded
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isTeamFixtures">
        <div *ngIf="userMatchesFound">
          <h4>Rounds</h4>
          <div class="row">
            <div class="col-12" *ngFor="let match of finalUserMatches; let i = index">
              <div  class="teams"
                    [ngClass]="{'incomplete': !match.frontEndState || match.frontEndState === 'incomplete',
                                'complete': match.frontEndState && match.frontEndState !== 'incomplete'}">
                <div class="match">
                  <div class="team">
                    <ng-container *ngIf="match.teamA.name !== 'Bye' && match.teamA.name !== 'TBA'">
                      <div class="team-name"> {{ match.teamA.name }}
                        <span *ngIf="match.winner === 'teamA'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                      </div>
                      <div class="player" [routerLink]="['/tennis/player/'+match.teamA.playerOne.id]">
                        <img [src]="match.teamA.playerOne.image" alt="profile-img"> {{ match.teamA.playerOne.name }}</div>
                      <div class="player" [routerLink]="['/tennis/player/'+match.teamA.playerTwo.id]">
                        <img [src]="match.teamA.playerTwo.image" alt="profile-img"> {{ match.teamA.playerTwo.name }}</div>
                      <h4 class="kit">{{ match.home === 'teamA' ? 'Home' : 'Away' }}</h4>
                    </ng-container>
                    <ng-container *ngIf="match.teamA.name === 'Bye' || match.teamA.name === 'TBA'">
                      <div class="team-name">{{ match.teamA.name }}</div>
                    </ng-container>
                  </div>
                  <div class="vs">vs</div>
                  <div class="team">
                    <ng-container *ngIf="match.teamB.name !== 'Bye' && match.teamB.name !== 'TBA'">
                      <div class="team-name"> {{ match.teamB.name }}
                        <span *ngIf="match.winner === 'teamB'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                      </div>
                      <div class="player" [routerLink]="['/tennis/player/'+match.teamB.playerOne.id]">
                        <img [src]="match.teamB.playerOne.image" alt="profile-img"> {{ match.teamB.playerOne.name }}</div>
                      <div class="player" [routerLink]="['/tennis/player/'+match.teamB.playerTwo.id]">
                        <img [src]="match.teamB.playerTwo.image" alt="profile-img"> {{ match.teamB.playerTwo.name }}</div>
                      <h4 class="kit">{{ match.home === 'teamB' ? 'Home' : 'Away' }}</h4>
                    </ng-container>
                    <ng-container *ngIf="match.teamB.name === 'Bye' || match.teamB.name === 'TBA'">
                      <div class="team-name">{{ match.teamB.name }}</div>
                    </ng-container>
                  </div>
                </div>
                <ng-container *ngIf="match.teamA.name !== 'Bye' && match.teamA.name !== 'TBA' && match.teamB.name !== 'Bye' && match.teamB.name !== 'TBA'">
                  <div class="add-score-btn" *ngIf="!match.frontEndState || match.frontEndState === 'incomplete' else scoreUploaded">
                    <button type="button" (click)="openScoreModal(match, i+1, match.fixture, 'rounds', i)">Add Score</button>
                  </div>
                  <ng-template #scoreUploaded>
                    <div class="alert alert-secondary">
                      Scores have been uploaded
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="match.teamA.name === 'Bye' || match.teamA.name === 'TBA' || match.teamB.name === 'Bye' || match.teamB.name === 'TBA'">
                  <div class="bye-match alert alert-secondary">
                    Cannot Upload Scores
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="userLosersMatchesFound">
          <h4>Consolation</h4>
          <div class="row">
            <div class="col-12" *ngFor="let match of finalLoserUserMatches; let i = index">
              <div  class="teams"
                    [ngClass]="{'incomplete': !match.frontEndState || match.frontEndState === 'incomplete',
                                'complete': match.frontEndState && match.frontEndState !== 'incomplete'}">
                <div class="match">
                  <div class="team">
                    <div class="team-name"> {{ match.teamA.name }}
                      <span *ngIf="match.winner === 'teamA'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                    </div>
                    <div class="player">{{ match.teamA.playerOne.name }}</div>
                    <div class="player">{{ match.teamA.playerTwo.name }}</div>
                    <h4 class="kit">{{ match.home === 'teamA' ? 'Home' : 'Away' }}</h4>
                  </div>
                  <div class="vs">vs</div>
                  <div class="team">
                    <div class="team-name"> {{ match?.teamB?.name }}
                      <span *ngIf="match.winner === 'teamB'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                    </div>
                    <div class="player">{{ match.teamB.playerOne.name }}</div>
                    <div class="player">{{ match.teamB.playerTwo.name }}</div>
                    <h4 class="kit">{{ match.home === 'teamB' ? 'Home' : 'Away' }}</h4>
                  </div>
                </div>
                <ng-container *ngIf="match.teamA.name !== 'Bye' && match.teamA.name !== 'TBA' && match.teamB.name !== 'Bye' && match.teamB.name !== 'TBA'">
                  <div class="add-score-btn" *ngIf="!match.frontEndState || match.frontEndState === 'incomplete' else scoreUploaded">
                    <button type="button" (click)="openScoreModal(match, i+1, match.fixture, 'rounds', i)">Add Score</button>
                  </div>
                  <ng-template #scoreUploaded>
                    <div class="alert alert-secondary">
                      Scores have been uploaded
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="match.teamA.name === 'Bye' || match.teamA.name === 'TBA' || match.teamB.name === 'Bye' || match.teamB.name === 'TBA'">
                  <div class="bye-match alert alert-secondary">
                    Cannot Upload Scores
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="isBoxMatch">
      <div *ngIf="isUserBoxFixtures" class="row">
        <div class="col-12 col-sm-12 col-lg-6" *ngFor="let userMatch of userBoxMatches; let i = index">
          <div class="match">
            <div class="players">
              <div class="player" [routerLink]="['/tennis/player/'+userMatch.teamA.id]">
                <img [src]="userMatch.teamA.image" alt="profile-img">
                {{ userMatch.teamA.name }}
                <span *ngIf="userMatch.frontEndState === 'completed' && userMatch.winner === 'teamA'">
                  <img src="../../../assets/image/trophy.webp" alt="trophy">
                </span>
                <div class="kit">{{ userMatch.home ==='teamA' ? 'Home' : 'Away' }}</div>
              </div>
              <div class="vs">vs</div>
              <div class="player" [routerLink]="['/tennis/player/'+userMatch.teamB.id]">
                <img [src]="userMatch.teamB.image" alt="profile-img">
                {{ userMatch.teamB.name }}
                <span *ngIf="userMatch.frontEndState === 'completed' && userMatch.winner === 'teamB'">
                  <img src="../../../assets/image/trophy.webp" alt="trophy">
                </span>
                <div class="kit">{{ userMatch.home ==='teamB' ? 'Home' : 'Away' }}</div>
              </div>
            </div>
            <div class="add-score-btn" *ngIf="!userMatch.frontEndState || userMatch.frontEndState === 'incomplete' else scoreUploaded">
              <button type="button" (click)="openBoxMatchScoreModal(userMatch, i)">Add Score</button>
            </div>
            <ng-template #scoreUploaded>
              <p></p>
              <div class="alert alert-secondary">
                Scores have been uploaded
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="isTeamBoxFixtures" class="row">
        <div class="col-12" *ngFor="let match of userBoxMatches; let i = index">
          <div  class="teams"
                [ngClass]="{'incomplete': !match.frontEndState || match.frontEndState === 'incomplete',
                            'complete': match.frontEndState && match.frontEndState !== 'incomplete'}">
            <div class="match">
              <div class="team">
                <div class="team-name"> {{ match.teamA.name }}
                  <span *ngIf="match.winner === 'teamA'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
                </div>
                <div class="player" [routerLink]="['/tennis/player/'+match.teamA.playerOne.id]">
                  <img [src]="match.teamA.playerOne.image" alt="profile-img"> {{ match.teamA.playerOne.name }}</div>
                <div class="player" [routerLink]="['/tennis/player/'+match.teamA.playerTwo.id]">
                  <img [src]="match.teamA.playerTwo.image" alt="profile-img"> {{ match.teamA.playerTwo.name }}</div>
                <h4 class="kit">{{ match.home === 'teamA' ? 'Home' : 'Away' }}</h4>
              </div>
              <div class="vs">vs</div>
              <div class="team">
                <div class="team-name"> {{ match.teamB.name }}
                  <span *ngIf="match.winner === 'teamB'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span></div>
                <div class="player" [routerLink]="['/tennis/player/'+match.teamB.playerOne.id]">
                  <img [src]="match.teamB.playerOne.image" alt="profile-img"> {{ match.teamB.playerOne.name }}</div>
                <div class="player" [routerLink]="['/tennis/player/'+match.teamB.playerTwo.id]">
                  <img [src]="match.teamB.playerTwo.image" alt="profile-img"> {{ match.teamB.playerTwo.name }}</div>
                <h4 class="kit">{{ match.home === 'teamB' ? 'Home' : 'Away' }}</h4>
              </div>
            </div>
            <div class="add-score-btn" *ngIf="!match.frontEndState || match.frontEndState === 'incomplete' else scoreUploaded">
              <button type="button" (click)="openBoxMatchScoreModal(match, i)">Add Score</button>
            </div>
            <ng-template #scoreUploaded>
              <div class="alert alert-secondary">
                Scores have been uploaded
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notRegistered>
  <div class="my-matches">
    You are not registered in this tournament.
  </div>
</ng-template>

<section *ngIf="isScoreModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="heading">Add Score</div>

      <div class="player" [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">
        <span>Player One</span>
        {{ playersData.teamA.name }}
        <ng-container *ngIf="matchOutcome && matchOutcome.type !== '' && matchOutcome.team !== ''">
          <span class="winner" *ngIf="matchOutcome.team === 'teamB'">
            <strong>- Winner</strong><img src="../../../assets/image/trophy.webp" alt="trophy">
          </span>
        </ng-container>
      </div>

      <div class="input-fields">
        <div class="fields-inner">
          <form [formGroup]="formGroup">
            <div class="set set-one">
              <div class="set-not-tie">
                <input type="text" formControlName="setOneA" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneA.valid, 'error': formGroup.controls.setOneA.touched && formGroup.controls.setOneA.invalid}">
                <input type="text" formControlName="setOneB" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneB.valid, 'error': formGroup.controls.setOneB.touched && formGroup.controls.setOneB.invalid}">
              </div>
              <div class="set-tie" *ngIf="isSetOneTie">
                <div class="tie-breaker">Tie Breaker</div>
                <input type="text" formControlName="setOneTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneTieA.valid, 'error': formGroup.controls.setOneTieA.touched && formGroup.controls.setOneTieA.invalid}">
                <input type="text" formControlName="setOneTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneTieB.valid, 'error': formGroup.controls.setOneTieB.touched && formGroup.controls.setOneTieB.invalid}">
              </div>
              <div class="set-label">Set One</div>
            </div>
            <div class="set set-two">
              <div class="set-not-tie">
                <input type="text" formControlName="setTwoA" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoA.valid, 'error': formGroup.controls.setTwoA.touched && formGroup.controls.setTwoA.invalid}">
                <input type="text" formControlName="setTwoB" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoB.valid, 'error': formGroup.controls.setTwoB.touched && formGroup.controls.setTwoB.invalid}">
              </div>
              <div class="set-tie" *ngIf="isSetTwoTie">
                <div class="tie-breaker">Tie Breaker</div>
                <input type="text" formControlName="setTwoTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoTieA.valid, 'error': formGroup.controls.setTwoTieA.touched && formGroup.controls.setTwoTieA.invalid}">
                <input type="text" formControlName="setTwoTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoTieB.valid, 'error': formGroup.controls.setTwoTieB.touched && formGroup.controls.setTwoTieB.invalid}">
              </div>
              <div class="set-label">Set Two</div>
            </div>
            <div *ngIf="isSetThree" class="set set-three">
              <div class="set-not-tie">
                <input type="text" formControlName="setThreeA" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeA?.valid, 'error': formGroup.controls.setThreeA?.touched && formGroup.controls.setThreeA?.invalid}">
                <input type="text" formControlName="setThreeB" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeB?.valid, 'error': formGroup.controls.setThreeB?.touched && formGroup.controls.setThreeB?.invalid}">
              </div>
              <div class="set-tie" *ngIf="isSetThreeTie">
                <div class="tie-breaker">Tie Breaker</div>
                <input type="text" formControlName="setThreeTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeTieA?.valid, 'error': formGroup.controls.setThreeTieA?.touched && formGroup.controls.setThreeTieA?.invalid}">
                <input type="text" formControlName="setThreeTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeTieB?.valid, 'error': formGroup.controls.setThreeTieB?.touched && formGroup.controls.setThreeTieB?.invalid}">
              </div>
              <div class="set-label">Set Three</div>
            </div>
          </form>
        </div>
      </div>

      <div class="player" [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">
        <span>Player Two</span>
        {{ playersData.teamB.name }}
        <ng-container *ngIf="matchOutcome && matchOutcome.type !== '' && matchOutcome.team !== ''">
          <span class="winner" *ngIf="matchOutcome.team === 'teamA'">
            <strong>- Winner</strong><img src="../../../assets/image/trophy.webp" alt="trophy">
          </span>
        </ng-container>
      </div>

      <hr />
      <div class="incomplete-match-check">
        <input
          type="checkbox"
          [(ngModel)]="isIncompleteMatch"
          (change)="checkIncompleteMatch(isIncompleteMatch)"
          id="incompleteMatchCheck"
        />
        <label for="incompleteMatchCheck">This match was incomplete</label>
      </div> 
      <div class="match-outcome-wrapper" *ngIf="isIncompleteMatch">
        <div class="match-outcome">
          <p>Match Outcome</p>
          <div *ngIf="!isFormFilled" class="outcome-wrapper walkover">
            <p>Walkover</p>
            <select #matchOutcome class="form-control" (change)="setMatchOutcome('walkover', $event)">
              <option value="">Select</option>
              <option [value]="'teamA'">{{ playersData.teamA.name }}</option>
              <option [value]="'teamB'">{{ playersData.teamB.name }}</option>
            </select>
          </div>
          <div *ngIf="isFormFilled" class="outcome-wrapper retired">
            <p>Retired</p>
            <select #matchOutcome class="form-control" (change)="setMatchOutcome('retired', $event)">
              <option value="">Select</option>
              <option [value]="'teamA'">{{ playersData.teamA.name }}</option>
              <option [value]="'teamB'">{{ playersData.teamB.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="loader-and-error" *ngIf="isUploadScoreLoading || errorUploadScore">
        <div class="loader"  *ngIf="isUploadScoreLoading && !errorUploadScore">
          <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
        </div>
        <div *ngIf="!isUploadScoreLoading && errorUploadScore">
          <div class="alert alert-danger">Could not upload the scores. Please refresh the page and try again.</div>
          <button type="button" class="btn-cancel" (click)="closeModal()">Close Modal</button>
        </div>
      </div>
      <div class="join-buttons" *ngIf="!isUploadScoreLoading && !errorUploadScore">
        <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
        <button type="button" (click)="addScore(formGroup)">Add Score</button>
      </div>
    </div>
  </div>
</section>

<section *ngIf="isPlayerRatingModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="heading">Player Rating</div>
      <p class="text">Please rate your opponent in terms of 1) pre match interactions, 2) on time or late, 3) respectful, 4) fair line
        calls, 5) sportsmanship, 6) able to play hard but still be fair.
        <br>
        You rating is crucial for us to know how the player performed. And it will be beneficial for other players to see the
        the overall performance of the player.
      </p>
      <div class="player-rating">
        <star-rating
          value={{playerRating}}
          totalstars={{totalStars}}
          checkedcolor="#6fbd22"
          uncheckedcolor="#d6d6d6"
          size="35px"
          readonly="false"
          (rate)="onRate($event)">
        </star-rating>

        <p style="text-align: center; font-weight: 500;">
          {{
            playerRating === 1 ? 'Bad' :
            playerRating === 2 ? 'Not so good' :
            playerRating === 3 ? 'Okay' :
            playerRating === 4 ? 'Good' :
            playerRating === 5 ? 'Excellent' : 'Error'
          }}
        </p>
      </div>

      <div class="join-buttons">
        <button type="button" class="btn-cancel" (click)="confirmRating()">Confirm</button>
      </div>
    </div>
  </div>
</section>
