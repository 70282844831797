<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">

  <app-player-menu></app-player-menu>

  <div class="container">
    <div class="gray-table-container">
      <div class="personal-details">
        <div class="personal-details-inner">
          <div class="img-block">
            <div class="img">
              <img *ngIf="currUserObj" [src]="currUserObj.profileImg" alt="proifle-img">
            </div>
            <input #profileImg type="file" (change)="onFileSelected($event)" accept=".png, .jpg">
            <button (click)="uploadProfileImg()" [disabled]="!img">Upload</button>
          </div>
          <div class="details">
            <div class="heading">
              Personal Details
              <button type="button" class="btn-desktop" [disabled]="profileBtnDisabled" (click)="updateProfile(formProfile)"
                [disabled]="profileBtnDisabled">Save</button>
            </div>
            <form [formGroup]="formProfile">
              <div class="fields row">
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>First Name</label>
                  <input formControlName="firstName" type="text" value="">
                  <div *ngIf="submitAttempt && formProfile.get('firstName').invalid" class="errorMessage">First Name is
                    required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Last Name</label>
                  <input formControlName="lastName" type="text" value="">
                  <div *ngIf="submitAttempt && formProfile.get('lastName').invalid" class="errorMessage">Last Name is
                    required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>E-mail Address</label>
                  <input formControlName="email" type="email" value="">
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Phone no.</label>
                  <input formControlName="phone" type="number" value="">
                  <div *ngIf="submitAttempt && formProfile.get('phone').invalid" class="errorMessage">Phone no. is required
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Date of Birth</label>
                  <input formControlName="dateOfBirth" type="text" value="">
                  <div *ngIf="submitAttempt && formProfile.get('dateOfBirth').invalid" class="errorMessage">Date Of Birth is required
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Handness</label>
                  <div class="styled-select">
                    <select class="form-control" formControlName="handness">
                      <option value="Right-Handed">Right-Handed</option>
                      <option value="Left-Handed">Left-Handed</option>
                    </select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('handness').invalid" class="errorMessage">Handness is
                    required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Gender</label>
                  <div class="styled-select">
                    <select class="form-control" formControlName="gender">
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('gender').invalid" class="errorMessage">Gender is
                    required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Weight (kg) <i>(Optional)</i></label>
                  <input formControlName="weight" type="number">
                  <div *ngIf="submitAttempt && formProfile.get('weight').invalid" class="errorMessage">Weight is
                    required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Height (cm)</label>
                  <input formControlName="height" type="number" value="">
                  <div *ngIf="submitAttempt && formProfile.get('height').invalid" class="errorMessage">Height is
                    required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 field">
                  <label>Do you use a wheelchair</label>
                  <div class="styled-select">
                    <select class="form-control" formControlName="wheelChair">
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('wheelChair').invalid" class="errorMessage">This is
                    required</div>
                </div>
                <div class="col-12 field">
                  <label>Bio</label>
                  <textarea formControlName="bio" class="form-control field" name="userbio" cols="30"
                    rows="5"></textarea>
                  <div *ngIf="submitAttempt && formProfile.get('bio').invalid" class="errorMessage">Bio is required
                  </div>
                </div>

              </div>
              <button type="button" class="btn-mobile" (click)="updateProfile(formProfile)"
                [disabled]="profileBtnDisabled">Save</button>
            </form>

            <div *ngIf="profileUpdated" class="alert alert-success">
              You have Successfully Updated your Profile
            </div>
          </div>
        </div>
      </div>
      <div class="detail-box">
        <div class="inner locations">
          <div class="heading">Locations</div>
          <ng-container *ngIf="userLocations.length > 0 else noLocations">
            <div class="location" *ngFor="let loc of userLocations; let i = index;">
              <div class="loc-heading">
                <div class="heading">
                  {{ i+1 }}. {{ loc.isPrimaryLocation ? 'Primary Location' : 'Secondary Location' }}
                </div>
                <div class="update-remove-buttons">
                  <button type="button" (click)="removeLocation(i)">Remove</button>
                  <button *ngIf="!loc.isPrimaryLocation" type="button" (click)="makePrimaryLocation(i)">Make Primary</button>
                </div>
              </div>
              <div class="agm-location">
                <div class="address">{{ loc.city + ', ' + loc.country }}</div>
                <agm-map
                  [latitude]="loc.latitude"
                  [longitude]="loc.longitude"
                  [zoom]="zoom">
                    <agm-marker
                      [latitude]="loc.latitude"
                      [longitude]="loc.longitude">
                    </agm-marker>
                </agm-map>
              </div>
            </div>
          </ng-container>

          <ng-template #noLocations>
            Currently You have no locations. You can add below.
          </ng-template>

          <div class="add-location">
            <div *ngIf="!isAddLocation && isLocationAdded" class="alert alert-success">New location successfully added</div>
            <button *ngIf="!isAddLocation" type="button" class="add-location-btn" (click)="dropLocationContainer()">Add Location +</button>

            <div *ngIf="isAddLocation" class="new-location">
              <button type="button" class="close-add-location" (click)="isAddLocation = false;">Cancel</button>
              <h3>Add new location</h3>
              <div class="row">
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <div class="dropdown">
                    <label>Select Country</label>
                    <select [(ngModel)]="country" class="form-control">
                      <option [ngValue]="'Australia'">Australia</option>
                    </select>
                  </div>
                </div>

                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <div class="dropdown">
                    <label>Select City</label>
                    <ng-select
                      [items]="cities"
                      bindLabel="city"
                      bindValue="city"
                      (change)="getLocationMap($event)"
                      placeholder="Select City">
                    </ng-select>
                  </div>
                </div>
              </div>
              <button type="button" (click)="addUserLocation()" class="add-location-btn">Save</button>
              <p></p>
              <div class="alert alert-danger" *ngIf="isLocationError">{{ isLocationError }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
