import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  formGroup: FormGroup;
  submitAttempt = false;
  loading = false;
  success = false;
  errorMessage;
  isUnderage = false;
  
  constructor(public formBuilder: FormBuilder, private authService: AuthService, public firestore: AngularFirestore, public router: Router) {
    this.formGroup = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required])],
      dateOfBirth: ['', Validators.compose([Validators.required, Validators.pattern('\\d{4}-\\d{2}-\\d{2}')])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      acceptTerms: [false, Validators.compose([Validators.requiredTrue])]
    });
  }

  async signup(formGroup) {
    this.submitAttempt = true;
    let form = formGroup.value;
    if (form.password === form.confirmPassword && formGroup.valid) {
      this.loading = true;
      let userInfoForm: any = {
        firstName: this.capitalizeName(form.firstName),
        lastName: this.capitalizeName(form.lastName),
        email: form.email.trim().toLowerCase(),
        dateOfBirth: form.dateOfBirth,
        phone: form.phone,
        isProfileActive: true,
        firstTimeLogin: true,
        openForChallenge: true,
        state: 'registered',
      }
      try {
        let userObj = await this.authService.signup(form.email, form.password)
        userObj.user.updateProfile({ displayName: form.firstName });

        if (form.parentEmail) {
          userInfoForm.parentEmail = form.parentEmail,
            userInfoForm.isTokenValid = '',
            userInfoForm.state = 'send-parent-email',
            await this.firestore.collection('Users').doc(userObj.user.uid).set(userInfoForm);
        } else {
          await this.firestore.collection('Users').doc(userObj.user.uid).set(userInfoForm);
        }

        await this.authService.user.sendEmailVerification()
        this.authService.logout();
        this.loading = false;
        this.success = true;

      } catch (e) {
        this.loading = false;
        if (e.code === "auth/email-already-in-use") {
          this.errorMessage = "The email address is already in use by another account.";
        } else {
          this.errorMessage = 'Something went wrong. Please try again later.';
        }
      }
    }
  }

  capitalizeName(name) {
    let username = name.trim().split(' ')
    let usernameArr = username.map(el => {
      return el.charAt(0).toUpperCase() + el.slice(1).toLowerCase();
    })
    return usernameArr.join(' ').trim();
  }

  facebookSignup() {
    this.authService.facebookSignup()
      .then(value => {
        this.router.navigateByUrl('tennis/home');
      })
      .catch(error => {
        console.log(error);
      });
  }

  googleSignup() {
    this.authService.googleSignup()
      .then(value => {
      })
      .catch(error => {
        console.log(error);
      });
  }

  goToHome() {
    this.router.navigateByUrl('/tennis/home');
  }

  ngOnInit() {
    // Watch changes in form
    this.formGroup.get('dateOfBirth').valueChanges.subscribe(dateOfBirth => {
      if (dateOfBirth) {
        let userAge = new Date().getFullYear() - dateOfBirth.split('-')[0];
        if (userAge < 12 && !this.formGroup.controls.parentEmail) {
          this.formGroup.addControl('parentEmail', new FormControl('', [Validators.required, Validators.email]));
          this.isUnderage = true;
        } else {
          if (this.formGroup.controls.parentEmail) {
            this.formGroup.removeControl('parentEmail');
          }
          this.isUnderage = false;
        }
      }
    })
  }

}
