<div class="statistics-tabs">
  <div class="container">
    <ul>
      <li [ngClass]="{'tab-active': this.currUrl.includes('user/teams/tournament-teams/accepted')}">
        <a [routerLink]="['/tennis/user/teams/tournament-teams/accepted']">My Teams</a>
      </li>
      <li [ngClass]="{'tab-active': this.currUrl.includes('user/teams/tournament-teams/sent')}">
        <a [routerLink]="['/tennis/user/teams/tournament-teams/sent']">Sent Invites</a>
      </li>
      <li [ngClass]="{'tab-active': this.currUrl.includes('user/teams/tournament-teams/received')}">
        <a [routerLink]="['/tennis/user/teams/tournament-teams/received']">Received Invites</a>
      </li>
    </ul>
  </div>
</div>

<router-outlet></router-outlet>
