<div class="current-matches">
  <h2 class="table-header">My Current Matches</h2>
  <div class="challenges-table" *ngIf="hasCurrentMatches; else noMatches">
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Match Type</th>
          <th>Location</th>
          <th>Curr. Round</th>
        </tr>
      </thead>
      <tbody>
        <tr [routerLink]="['/tennis/tournaments/' + currentMatch.matchId]"
          *ngFor="let currentMatch of currentMatches; let i = index">
          <td>{{ currentMatch.title }}</td>
          <td>{{ currentMatch.matchType }}</td>
          <td>{{ currentMatch.location }}</td>
          <td>{{ currentMatch.currentRound }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #noMatches>
  <div class="alert">You haven't played any matches yet.</div>
</ng-template>