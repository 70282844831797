import { ProfileService } from './../../services/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewprofileGuard implements CanActivate{

  constructor(private router: Router, private authService: AuthService, private profileService: ProfileService) {}

  async canActivate() {
    try {
      return this.profileService.getUserProfileData()
      .then(res => {
        if(res.data().firstTimeLogin === true) {
          return true;
        } else {
          this.router.navigate(['tennis/home'])
        }
      })
    } 
    catch(error) {
      console.log(error)
      this.router.navigate(['tennis/home'])
    }
  }
}
