<div class="container-fluid">
  <app-shade></app-shade>
  <app-page-heading pageHeading="Create Tournament"></app-page-heading>
</div>

<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <form [formGroup]="formGroup">
        <div class="fields row">
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>Entry Fee</label>
            <div class="with-static-value">
              <div class="static-value">$</div>
              <input formControlName="entryFee" min="20" type="number" />
            </div>
            <div
              *ngIf="submitAttempt && formGroup.get('entryFee').invalid"
              class="errorMessage"
            >
              Entry Fee is required. Minimum entry fee is $20
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>Tournament Start Date</label>
            <input
              formControlName="startDate"
              type="date"
              placeholder="YYYY-MM-DD"
            />
            <ng-container *ngIf="formGroup.get('startDate').invalid">
              <p
                class="errorMessage"
                *ngIf="
                  submitAttempt && formGroup.get('startDate').errors.required
                "
              >
                Start Date is required.
              </p>
              <p
                class="errorMessage"
                *ngIf="formGroup.get('startDate').errors.pattern"
              >
                Invalid date format. Must be YYYY-MM-DD.
              </p>
            </ng-container>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>Gender</label>
            <select class="form-control" formControlName="gender">
              <option value="">Select Gender</option>
              <option *ngIf="userObj && userObj.gender === 'male'" value="male">
                Male
              </option>
              <option
                *ngIf="userObj && userObj.gender === 'female'"
                value="female"
              >
                Female
              </option>
              <option value="mixed">Mixed</option>
            </select>
            <div
              *ngIf="submitAttempt && formGroup.get('gender').invalid"
              class="errorMessage"
            >
              Gender is required
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>Select Tier</label>
            <select
              *ngIf="userObj && userObj.tier"
              class="form-control"
              formControlName="victorPoints"
            >
              <option value="">Select Tier</option>
              <ng-container *ngFor="let tier of tierArray">
                <option
                  *ngIf="userObj.tier <= tier.value"
                  [value]="'tier-' + tier.value"
                >
                  {{ tier.name }}
                </option>
              </ng-container>
            </select>
            <div
              *ngIf="submitAttempt && formGroup.get('victorPoints').invalid"
              class="errorMessage"
            >
              Tier is required
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>City</label>
            <ng-select
              [items]="cities"
              bindLabel="name"
              bindValue="name"
              formControlName="location"
              placeholder="Select City"
            >
            </ng-select>
            <div
              *ngIf="submitAttempt && formGroup.get('location').invalid"
              class="errorMessage"
            >
              Location is required
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>Category</label>
            <select class="form-control" formControlName="category">
              <option value="">Select Category</option>
              <option value="singles">Singles</option>
              <option value="doubles">Doubles</option>
            </select>
            <div
              *ngIf="submitAttempt && formGroup.get('category').invalid"
              class="errorMessage"
            >
              Category is required
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>Wheelchair</label>
            <select
              *ngIf="userObj"
              class="form-control"
              formControlName="wheelChair"
            >
              <option [value]="userObj.wheelChair" selected>
                {{ userObj.wheelChair }}
              </option>
            </select>
            <div
              *ngIf="submitAttempt && formGroup.get('wheelChair').invalid"
              class="errorMessage"
            >
              Wheelchair is required
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 field">
            <label>Age Limit</label>
            <div class="age-limit">
              <select
                *ngIf="userObj && userObj.age"
                class="form-control"
                formControlName="ageLimit"
              >
                <option value="">Select Age</option>
                <option value="open">Open</option>

                <ng-container *ngIf="userObj.age > 15; else underAge">
                  <ng-container *ngFor="let age of overAgeArray">
                    <option
                      *ngIf="
                        age.value.toString()[0] >= userObj.age.toString()[0]
                      "
                      [value]="'o' + age.value"
                    >
                      {{ age.name }}
                    </option>
                  </ng-container>
                </ng-container>

                <ng-template #underAge>
                  <ng-container *ngFor="let age of underAgeArray">
                    <option
                      *ngIf="age.value >= userObj.age"
                      [value]="'u' + age.value"
                    >
                      {{ age.name }}
                    </option>
                  </ng-container>
                </ng-template>
              </select>
            </div>
            <div
              *ngIf="
                submitAttempt &&
                (formGroup.get('ageLimit').invalid ||
                  formGroup.get('age')?.invalid)
              "
              class="errorMessage"
            >
              Age limit is required
            </div>
          </div>
        </div>
        <hr />
        <p class="note">
          * You need to register in the tournament in order to create it.
        </p>
        <hr />
        <ng-container>
          <div
            *ngIf="errorMessage && errorMessage !== ''"
            class="alert alert-danger"
          >
            {{ errorMessage }}
          </div>
          <div *ngIf="success">
            <div class="alert alert-success">
              You have successfully created a tournament
            </div>
            <div class="alert alert-secondary">
              Redirecting to created tournament page in {{ timer }} seconds.
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="isError">
            Could not create tournament. Please refresh the page and try again.
          </div>
        </ng-container>

        <ng-container *ngIf="isLoading; else activeButton">
          <div class="loader">
            <img
              src="../../../assets/image/ajax-loader.gif"
              alt="ajax-loader"
            />
          </div>
        </ng-container>
        <ng-template #activeButton>
          <button
            *ngIf="!showPaypalButton && !success"
            type="button"
            class="btn-submit"
            (click)="checkConditionsToCreateMatch(formGroup)"
            [disabled]="buttonDisabled"
          >
            Create
          </button>
        </ng-template>

        <div class="payment-container" *ngIf="showPaypalButton">
          <div
            *ngIf="
              userObj &&
                formGroup.value &&
                userObj.wallet >= formGroup.value.entryFee;
              else insufficientBalance
            "
          >
            You have sufficient funds in your wallet to create this tournament.
            Would you like to proceed?
            <br />
            <br />
            <button
              type="button"
              class="btn-submit"
              (click)="createTournament(formGroup)"
              [disabled]="buttonDisabled"
            >
              Yes
            </button>
          </div>

          <ng-template #insufficientBalance>
            <div class="alert alert-danger">
              You have insufficient balance in your wallet. Please recharge and
              try again.
            </div>
          </ng-template>
        </div>
      </form>
    </div>
    <div class="clearfix"></div>
  </div>
</div>

<section *ngIf="isUserTeamsModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body modal-body-registered-players">
      <div *ngIf="!isJoinWithCurrTeam">
        <div
          class="registered-teams"
          *ngIf="userTeams.length > 0; else noRegisteredPlayers"
        >
          <div class="heading">Your Teams</div>
          <div class="teams-list user-teams">
            <p></p>
            <div class="alert alert-info">
              As you click on the join button, you will be charged with the
              tournament fee and an invite will be sent to the other player.
              This invite will be valid for 48 hours. If the other player
              doesn't accept this invite within 48 hours, then the system will
              refund your charged fee.
            </div>
            <ul>
              <li *ngFor="let team of userTeams">
                <div class="team-name">
                  <div class="name">{{ team.name }}</div>
                  <button
                    type="button"
                    (click)="joinWithCurrTeam(team, formGroup)"
                  >
                    Join with this team
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="isJoinWithCurrTeam">
        <div *ngIf="isModalLoading" class="loader">
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
        </div>

        <div class="invite-sent" *ngIf="isJoinInviteSent">
          <div class="alert alert-success">
            Your join invite has been sent. Once your partner confirms, your
            match will be created.
          </div>

          <button [routerLink]="['/tennis/tournaments']" (click)="closeModal()">
            Okay
          </button>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="isError">
        Could not create tournament. Please refresh the page and try again.
      </div>

      <ng-template #noRegisteredPlayers>
        <div class="alert">
          Currently you have no teams. Please make atleast one to create a
          tournament.
        </div>
      </ng-template>
    </div>
  </div>
</section>
