import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-user-created-tournaments',
  templateUrl: './user-created-tournaments.component.html',
  styleUrls: ['./user-created-tournaments.component.scss']
})
export class UserCreatedTournamentsComponent implements OnInit {

  tournaments: any[];
  cities: any[];
  page: number;

  constructor(private firestore: AngularFirestore, public router: Router) { }

  getRemainingDays(deadline, location) {
    let deadlineYear = parseInt(deadline.split('-')[0]);
    let deadlineMonth = parseInt(deadline.split('-')[1]) - 1;
    let deadlineDate = parseInt(deadline.split('-')[2]);

    let timeZone: string = this.getTimeZone(location);
    let regionTime = new Date().toLocaleString("en-US", { timeZone });
    let differenceInTime = ((new Date(deadlineYear, deadlineMonth, deadlineDate, 0, 0, 0)).getTime() - new Date(regionTime).getTime()) + 86400000;
    if(differenceInTime <= 0) return '0 Days, 0 Minutes'
    let differenceInDays = Math.floor(differenceInTime / ( 1000 * 3600 * 24 ));
    let remainingDays = differenceInDays * 86400000;
    let remainingMilliseconds = differenceInTime - remainingDays;
    let remainingSeconds = remainingMilliseconds / 1000;
    let remainingMinutes = remainingSeconds / 60;
    let remainingHours = Math.floor(remainingMinutes / 60);

    if(differenceInDays <= 0) {
      if(remainingHours <= 0) {
        return '0 Days, ' + Math.floor(remainingMinutes) + ' Minutes';
      }
      return '0 Days, ' + remainingHours + (remainingHours === 1 ? ' Hour' : ' Hours');
    } else {
      let dayOrDays: string =   differenceInDays === 1 ? 'Day' : 'Days';
      let days: string =        differenceInDays + ' ' + dayOrDays;
      let hourOrHours: string = remainingHours === 1 ? 'Hour' : 'Hours'
      let hours: string =       remainingHours + ' ' + hourOrHours;
      return `${days}, ${hours}`;
    }
  }

  getTimeZone(location) {
    try {
      let timeZone: string;
      let cityFound: boolean = this.cities.some((el, index) => {
        if(el.name === location) {
          timeZone = el.timezone;
          return true;
        }
      })
      if(cityFound)  {
        try {
          Intl.DateTimeFormat(undefined, { timeZone });
          return timeZone;
        }
        catch (e) {
          console.log('Time format not found');
          return 'Australia/Sydney'
        }
      }
      return 'Australia/Sydney'
    } catch(e) {
      console.log(e)
    }
  }

  getFormattedDate(date) {
    if(date) {
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let fullDate = date.split('-').reverse()
      fullDate[1] = months[parseInt(fullDate[1]) - 1]
      return fullDate.join('/')
    } else {
      return "No Date";
    }
  }

  getTier(victorPoints) {
    if(victorPoints <= 600) {
      return 'Tier 1 Beginners'
    } else if(victorPoints <= 1200) {
      return 'Tier 2 Intermediate'
    } else if(victorPoints <= 1800) {
      return 'Tier 3 Advanced'
    } else if(victorPoints > 1800) {
      return 'Tier 4 Pro level'
    }
  }

  ngOnInit(): void {

  }

}
