import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  transactions: any[];
  matchId: string = "";
  hasTransactions: boolean;
  isLoading: boolean;
  @ViewChild('cashflowFilter') cashflowFilterElement: ElementRef;

  constructor(private firestore: AngularFirestore) { }

  async getTransactions(filter?: string) {
    try {
      let getTransactions;
      if(filter && filter !== 'credit' && filter !== 'debit') {
        getTransactions = await this.firestore.collection('Transactions', ref => ref.where('matchId', '==', this.matchId)).get().toPromise()
      } else if(filter && filter === 'credit' || filter === 'debit') {
        getTransactions = await this.firestore.collection('Transactions', ref => ref.where('type', '==', filter)).get().toPromise()
      } else getTransactions = await this.firestore.collection('Transactions').get().toPromise()

      if(getTransactions.docs.length > 0) {
        this.transactions = await Promise.all(getTransactions.docs.map(async el => {
          let user = (await this.firestore.collection('Users').doc(el.data().uid).get().toPromise()).data()
          if(user) {
            let transaction = {
              ...el.data(),
              user: {
                firstName: user.firstName,
                lastName: user.lastName,
              }
            }
            return transaction
          }
        }))
        this.transactions = this.transactions.filter(Boolean);
        this.transactions.sort((a,b) => b.timestamp - a.timestamp)
        this.hasTransactions = true;
      } else {
        this.hasTransactions = false;
      }
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

  getFormattedDate(timestamp) {
    if(timestamp) {
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let getFullDate = new Date(timestamp)
      let getDate = getFullDate.getDate();
      let getMonth = months[getFullDate.getMonth()];
      let getYear = getFullDate.getFullYear();
      return `${getDate}/${getMonth}/${getYear}`
    } return `No Date`;
  }

  searchByMatchId() {
    this.isLoading = true;
    this.matchId = this.matchId.trim();
    if(this.matchId !== '') {
      this.getTransactions(this.matchId)
    }
  }

  applyFilter(event) {
    this.getTransactions(event.target.value)
  }

  clearFilters() {
    this.isLoading = true;
    this.matchId = "";
    let cashflowFilter = this.cashflowFilterElement.nativeElement.options
    cashflowFilter[0].selected = true;
    this.getTransactions();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getTransactions();
  }

}
