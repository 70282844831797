import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { RatingModule } from 'ng-starrating';
import { NgxPayPalModule } from 'ngx-paypal';

import { ThemeService } from './services/theme/theme.service';
import { AuthService } from './services/auth/auth.service';
import { ProfileService } from './services/profile/profile.service';

import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationModule } from './registration/registration.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { Ng5SliderModule } from 'ng5-slider';
import { OwlModule } from 'ngx-owl-carousel';
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QuillModule } from 'ngx-quill'

import { AppComponent } from './app.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ShadeComponent } from './shared/shade/shade.component';
import { PlayersComponent } from './players/players.component';
import { PageHeadingComponent } from './shared/page-heading/page-heading.component';
import { CreateChallengeComponent } from './shared/create-challenge/create-challenge.component';
import { NewsletterComponent } from './shared/newsletter/newsletter.component';
import { SponsorLogoComponent } from './shared/sponsor-logo/sponsor-logo.component';
import { PlayerMenuComponent } from './profile/player-menu/player-menu.component';
import { PlayerTennisChallengesComponent } from './profile/player-tennis-challenges/player-tennis-challenges.component';
import { PlayerTennisTournamentsComponent } from './profile/player-tennis-tournaments/player-tennis-tournaments.component';
import { PlayerTennisDashboardComponent } from './profile/player-tennis-dashboard/player-tennis-dashboard.component';
import { PlayerNotificationsComponent } from './profile/player-tennis-dashboard/player-notifications/player-notifications.component';
import { PlayerTennisWalletComponent } from './profile/player-tennis-wallet/player-tennis-wallet.component';
import { PlayerTennisAccountComponent } from './profile/player-tennis-account/player-tennis-account.component';
import { PlayerMainPageComponent } from './public-profile/player-main-page/player-main-page.component';
import { PlayerOverviewComponent } from './public-profile/player-overview/player-overview.component';
import { PlayerGamesHistoryComponent } from './public-profile/player-games-history/player-games-history.component';
import { PlayerStatisticsHistoryComponent } from './public-profile/player-statistics-history/player-statistics-history.component';
import { PlayerDetailsComponent } from './public-profile/player-details/player-details.component';
import { CreateProfileComponent } from './profile/create-profile/create-profile.component';
import { CreateTournamentComponent } from './profile/create-tournament/create-tournament.component';
import { TournamentsListComponent } from './tournaments/tournaments-list/tournaments-list.component';
import { TournamentPageComponent } from './tournaments/tournament-page/tournament-page.component';
import { FixturesComponent } from './tournaments/fixtures/fixtures.component';
import { PlayerMatchesComponent } from './tournaments/player-matches/player-matches.component';
import { VerifyScoreComponent } from './tournaments/verify-score/verify-score.component';
import { PlayerTennisChallengesCompletedComponent } from './profile/player-tennis-challenges/player-tennis-challenges-completed/player-tennis-challenges-completed.component';
import { PlayerTennisChallengesOngoingComponent } from './profile/player-tennis-challenges/player-tennis-challenges-ongoing/player-tennis-challenges-ongoing.component';
import { PlayerTennisChallengesUpcomingComponent } from './profile/player-tennis-challenges/player-tennis-challenges-upcoming/player-tennis-challenges-upcoming.component';
import { LosersFixturesComponent } from './tournaments/losers-fixtures/losers-fixtures.component';
import { ChatsComponent } from './profile/chats/chats.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { UsersComponent } from './admin-panel/users/users.component';
import { PlayerTennisTeamsComponent } from './profile/player-tennis-teams/player-tennis-teams.component';
import { PlayerTennisTeamsInvitesComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-teams-invites/player-tennis-teams-invites.component';
import { PlayerTennisTeamsAcceptedComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-teams-accepted/player-tennis-teams-accepted.component';
import { PlayerTennisTeamsSentComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-teams-sent/player-tennis-teams-sent.component';
import { PlayerTennisTournamentTeamsComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams.component';
import { PlayerTennisGeneralTeamsComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-general-teams.component';
import { PlayerTennisTournamentTeamsSentComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams-sent/player-tennis-tournament-teams-sent.component';
import { PlayerTennisTournamentTeamsAcceptedComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams-accepted/player-tennis-tournament-teams-accepted.component';
import { PlayerTennisTournamentTeamsReceivedComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams-received/player-tennis-tournament-teams-received.component';
import { RulesComponent } from './misc/rules/rules.component';
import { FaqsComponent } from './misc/rules/faqs/faqs.component';
import { BoxLeaguesComponent } from './misc/rules/box-leagues/box-leagues.component';
import { HeadToHeadChallengesComponent } from './misc/rules/head-to-head-challenges/head-to-head-challenges.component';
import { HowItWorksComponent } from './misc/about-us/how-it-works/how-it-works.component';
import { MatchEtiquetteComponent } from './misc/about-us/match-etiquette/match-etiquette.component';
import { MonthlyKnockoutComponent } from './misc/rules/monthly-knockout/monthly-knockout.component';
import { VictorRankingsComponent } from './misc/about-us/victor-rankings/victor-rankings.component';
import { RegistrationTermsComponent } from './registration/registration-terms/registration-terms.component';
import { TermsOfUseComponent } from './misc/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './misc/privacy-policy/privacy-policy.component';
import { DisputesComponent } from './admin-panel/disputes/disputes.component';
import { AboutUsComponent } from './misc/about-us/about-us.component';
import { ContactUsComponent } from './misc/contact-us/contact-us.component';
import { QueriesComponent } from './admin-panel/queries/queries.component';
import { StandingsComponent } from './tournaments/standings/standings.component';
import { UserCreatedTournamentsComponent } from './tournaments/user-created-tournaments/user-created-tournaments.component';
import { MonthlyTournamentsComponent } from './tournaments/monthly-tournaments/monthly-tournaments.component';
import { WebsiteUpdatesComponent } from './misc/website-updates/website-updates.component';
import { TransactionsComponent } from './admin-panel/transactions/transactions.component';
import { TournamentsPaginationComponent } from './tournaments/tournaments-pagination/tournaments-pagination.component';
import { EmailDashboardComponent } from './admin-panel/email-dashboard/email-dashboard.component';
import { UploadGalleryComponent } from './admin-panel/upload-gallery/upload-gallery.component';
import { CurrentMatchesComponent } from './profile/player-tennis-dashboard/current-matches/current-matches.component';
import { SingleMatchesComponent } from './profile/player-tennis-dashboard/single-matches/single-matches.component';
import { DoubleMatchesComponent } from './profile/player-tennis-dashboard/double-matches/double-matches.component';
import { CurrentFixturesComponent } from './profile/player-tennis-dashboard/current-fixtures/current-fixtures.component';
import { UploadScoresComponent } from './shared/upload-scores/upload-scores.component';
import { UploadPlayerRatingComponent } from './shared/upload-player-rating/upload-player-rating.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    HomeComponent,
    ShadeComponent,
    PlayersComponent,
    PageHeadingComponent,
    CreateChallengeComponent,
    NewsletterComponent,
    SponsorLogoComponent,
    PlayerMenuComponent,
    PlayerTennisChallengesComponent,
    PlayerTennisTournamentsComponent,
    PlayerTennisDashboardComponent,
    PlayerNotificationsComponent,
    PlayerTennisAccountComponent,
    PlayerTennisWalletComponent,
    PlayerOverviewComponent,
    PlayerGamesHistoryComponent,
    PlayerStatisticsHistoryComponent,
    PlayerMainPageComponent,
    PlayerDetailsComponent,
    CreateProfileComponent,
    CreateTournamentComponent,
    TournamentsListComponent,
    TournamentPageComponent,
    FixturesComponent,
    PlayerMatchesComponent,
    VerifyScoreComponent,
    PlayerTennisChallengesCompletedComponent,
    PlayerTennisChallengesOngoingComponent,
    PlayerTennisChallengesUpcomingComponent,
    LosersFixturesComponent,
    ChatsComponent,
    AdminPanelComponent,
    UsersComponent,
    PlayerTennisTeamsComponent,
    PlayerTennisTeamsInvitesComponent,
    PlayerTennisTeamsAcceptedComponent,
    PlayerTennisTeamsSentComponent,
    PlayerTennisTournamentTeamsComponent,
    PlayerTennisGeneralTeamsComponent,
    PlayerTennisTournamentTeamsSentComponent,
    PlayerTennisTournamentTeamsAcceptedComponent,
    PlayerTennisTournamentTeamsReceivedComponent,
    RulesComponent,
    FaqsComponent,
    BoxLeaguesComponent,
    HeadToHeadChallengesComponent,
    HowItWorksComponent,
    MatchEtiquetteComponent,
    MonthlyKnockoutComponent,
    VictorRankingsComponent,
    RegistrationTermsComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    DisputesComponent,
    AboutUsComponent,
    ContactUsComponent,
    QueriesComponent,
    StandingsComponent,
    UserCreatedTournamentsComponent,
    MonthlyTournamentsComponent,
    WebsiteUpdatesComponent,
    TransactionsComponent,
    TournamentsPaginationComponent,
    EmailDashboardComponent,
    UploadGalleryComponent,
    CurrentMatchesComponent,
    SingleMatchesComponent,
    DoubleMatchesComponent,
    CurrentFixturesComponent,
    UploadScoresComponent,
    UploadPlayerRatingComponent,
    ClickOutsideDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    AgmCoreModule.forRoot({ apiKey: environment.firebaseConfig.apiKey }),
    FormsModule,
    ReactiveFormsModule,
    RegistrationModule,
    HttpClientModule,
    Ng5SliderModule,
    OwlModule,
    InlineSVGModule,
    NgSelectModule,
    RatingModule,
    NgxPayPalModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    QuillModule.forRoot(),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 0,
      innerStrokeWidth: 0,
      outerStrokeColor: "#000",
      innerStrokeColor: "#000",
      animationDuration: 0,
    })
  ],
  providers: [ThemeService, AuthService, ProfileService],
  bootstrap: [AppComponent]
})
export class AppModule { }
