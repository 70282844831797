import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-email-dashboard',
  templateUrl: './email-dashboard.component.html',
  styleUrls: ['./email-dashboard.component.scss']
})
export class EmailDashboardComponent implements OnInit {

  formGroup;
  users: any[];
  userIds: string[];
  tierOneUserIds: string[];
  tierTwoUserIds: string[];
  tierThreeUserIds: string[];
  tierFourUserIds: string[];
  @ViewChild ('otherEmail') otherEmailElement: ElementRef;
  isLoading: boolean;
  success: boolean;
  error = { isError: false, message: '' }
  formGroupIntialValues: any;

  constructor(private firestore: AngularFirestore, private fireFunctions: AngularFireFunctions) { }

  async getUsers() {
    try {
      let users = await this.firestore.collection('Users').get().toPromise();
      this.users = users.docs.map(el => {
        return {
          id: el.id,
          email: el.data().email,
          victorPoints: el.data().victorPoints
        }
      })
      this.userIds = this.users.map(el => el.id);
      this.tierOneUserIds = this.users.filter(el => el.victorPoints <= 600).map(el => el.id);
      this.tierTwoUserIds = this.users.filter(el => el.victorPoints >= 601 && el.victorPoints <= 1200).map(el => el.id);
      this.tierThreeUserIds = this.users.filter(el => el.victorPoints >= 1201 && el.victorPoints <= 1800).map(el => el.id);
      this.tierFourUserIds = this.users.filter(el => el.victorPoints >= 1800).map(el => el.id);
    } catch(e) {
      console.log(e)
    }
  }

  initializeEmailForm() {
    this.formGroup = new FormGroup({
      players: new FormControl([]),
      emails: new FormControl([]),
      subject: new FormControl('', Validators.required),
      preheader: new FormControl('', Validators.required),
      content: new FormControl('', Validators.required),
      selectType: new FormControl(''),
      tierType: new FormControl(''),
    })
    this.formGroupIntialValues = this.formGroup.value
    this.formGroup.valueChanges.subscribe(values => {
      if(this.formGroup.controls['players'].status !== 'DISABLED') {
        if(values.players.length > 0 || values.emails.length > 0) {
          this.formGroup.controls['players'].setErrors(null);
          this.formGroup.controls['emails'].setErrors(null);
        } else {
          if(values.players.length < 1) this.formGroup.controls['players'].setErrors({ incorrect: true });
          if(values.emails.length < 1) this.formGroup.controls['players'].setErrors({ incorrect: true });
        }
      }

      if(this.formGroup.controls['players'].value.length < 1) {
        if(this.formGroup.controls['selectType'].value !== '' && this.formGroup.controls['tierType'].value !== '') {
          this.formGroup.controls['selectType'].setValue('')
          this.formGroup.controls['tierType'].setValue('')
        }
      }
    })

    this.formGroup.controls['selectType'].valueChanges.subscribe(value => {
      if(value === 'allUsers') this.formGroup.controls['players'].setValue(this.userIds);
      else this.formGroup.controls['players'].setValue([]);
    })
    this.formGroup.controls['tierType'].valueChanges.subscribe(value => {
      if(value === 'tierOne') this.formGroup.controls['players'].setValue(this.tierOneUserIds);
      else if(value === 'tierTwo') this.formGroup.controls['players'].setValue(this.tierTwoUserIds)
      else if(value === 'tierThree') this.formGroup.controls['players'].setValue(this.tierThreeUserIds)
      else if(value === 'tierFour') this.formGroup.controls['players'].setValue(this.tierFourUserIds)
      else this.formGroup.controls['players'].setValue([]);
    })
  }

  inputEmailHandler(event) {
    let email = event.target.value.trim();
    if(!email) this.otherEmailElement.nativeElement.value = '';
  }

  addEmail(event) {
    let email = '';
    if(typeof event !== 'string') email = event.target.value.trim();
    else email = event.trim();
    if(email) {
      let emails = this.formGroup.controls['emails'].value;
      emails.push(email);
      this.formGroup.controls['emails'].setValue(emails)
      this.otherEmailElement.nativeElement.value = '';
    }
  }

  removeEmail(i) {
    let emails = this.formGroup.controls['emails'].value.slice();
    emails.splice(i, 1);
    this.formGroup.controls['emails'].setValue(emails)
  }

  async sendEmail(formGroup) {
    if(formGroup.valid) {
      this.isLoading = true;
      this.error.isError = false;
      this.success = false;
      let form = { ...formGroup.value };
      if(form.players.length > 0) {
        let players = [];
        form.players.forEach(el => {
          let userObj = this.users.find(el2 => el2.id === el)
          players.push(userObj);
        })
        form.players = [ ...players ]
      }

      try {
        let sendEmail = this.fireFunctions.httpsCallable('matches-sendCustomEmails')
        let response: any = await sendEmail(form).toPromise();
        if(response.success) {
          this.success = true;
          this.error.isError = false;
        } else {
          this.success = false;
          this.error.isError = true;
          this.error.message = response.error.message;
        }
        this.isLoading = false;
        formGroup.reset(this.formGroupIntialValues)
        this.otherEmailElement.nativeElement.value = '';
      } catch(e) {
        console.log(e);
        this.success = false;
        this.error.isError = true;
        this.error.message = "Couldn't send email. Please refresh the page and try again";
        this.isLoading = false;
      }
    }
  }

  ngOnInit(): void {
    this.getUsers();
    this.initializeEmailForm();
  }

}
