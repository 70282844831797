import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-player-tennis-account',
  templateUrl: './player-tennis-account.component.html',
  styleUrls: ['./player-tennis-account.component.scss']
})
export class PlayerTennisAccountComponent implements OnInit {

  formProfile: FormGroup;
  formLocation: FormGroup;
  formChangePassword: FormGroup;
  formEditLocation: FormGroup;

  submitAttempt: boolean = false;
  submitAttemptPassword: boolean = false;
  loading: boolean = false;
  profileUpdated: boolean = false;
  currUserObj;
  currUserInfoObj;
  
  isPasswordUpdated = {
    isUpdated: false,
    msg: ''
  }

  profileBtnDisabled = true;

  userLocations = [];
  latitude: number;
  longitude: number;
  zoom: number = 15;
  address: string;
  city: string;
  country: string;
  search: string;
  isAddLocation: boolean;
  isLocationAdded: boolean;

  imgRef: AngularFireStorageReference;
  imgUploadTask;
  imgUrl;
  img;
  @ViewChild('profileImg') profileImg: ElementRef
  cities;
  isLocationError: string;

  constructor(public authService: AuthService, 
              public firestore: AngularFirestore,
              public fireStorage: AngularFireStorage,
              public fireFunctions: AngularFireFunctions,
              private http: HttpClient,) { 

    this.formProfile = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl(''),
      dateOfBirth: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      handness: new FormControl('', Validators.required),
      weight: new FormControl(''),
      height: new FormControl('', Validators.required),
      bio: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      wheelChair: new FormControl('', Validators.required),
    })
    
    this.formProfile.disable();

    this.formLocation = new FormGroup({
      location: new FormControl('', Validators.required)
    })  
    this.formChangePassword = new FormGroup({
      currPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      reTypeNewPassword: new FormControl('', Validators.required)
    })
    this.formEditLocation = new FormGroup({
      newLocation: new FormControl('', Validators.required)
    })
  }

  async onFileSelected(event) {
    this.img = event.target.files[0]
  }

  async uploadProfileImg() {
    if(this.img) {
      const id = Math.random().toString(36).substring(2);
      this.imgRef = this.fireStorage.ref('profile-pictures/' + id);
      try {
        let uploadImg = await this.imgRef.put(this.img)
        let downloadURL = await uploadImg.ref.getDownloadURL();
        this.currUserObj.profileImg = downloadURL;
        await this.firestore.collection('Users').doc(this.currUserInfoObj.uid).update({
          profileImg: downloadURL
        })
        this.profileImg.nativeElement.value = "";
        this.img = undefined;
      } catch(e) {
        this.currUserObj.profileImg = '../../../assets/image/no-img.webp';
        console.log(e)
      }
    }
  }

  updateProfile(formObj) {
    this.submitAttempt = true;
    if(formObj.valid) {
      let form = formObj.value;
      this.loading = true;
      let user = this.authService.user;
      form.weight = form.weight ? form.weight : null;
      this.firestore.collection('Users').doc(this.currUserInfoObj.uid).update(form)
      .then(res => {
        this.profileUpdated = true;
      });
    } 
  }

  onProfileChange() {
    let count = 1;
    this.formProfile.valueChanges.subscribe(value => {
      if(count > 3) {
        this.profileBtnDisabled = false;
      }
      count++;
    })
  }

  getCurrentUserData() {
    if(this.authService.authenticated()) {
      this.authService.authState.subscribe(user => {
        if(user) {
          this.currUserInfoObj = user;
          this.firestore.collection('Users').doc(this.currUserInfoObj.uid).get().toPromise()
          .then(res => {
            this.currUserObj = res.data();
            if(!this.currUserObj.profileImg) {
              this.currUserObj.profileImg = '../../../assets/image/no-img.webp';
            } 

            this.setFormValues(this.currUserObj);
            if(res.data().location && res.data().location.length > 0) {
              this.userLocations = res.data().location;
            }
          })
        }
      })
    }
  }

  setFormValues(user) {
    let getEmail = this.authService.user.email;
    this.formProfile.setValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: getEmail,
      dateOfBirth: user.dateOfBirth,
      phone: user.phone,
      handness: user.handness,
      weight: user.weight,
      height: user.height,
      bio: user.bio,
      gender: user.gender,
      wheelChair: user.wheelChair,
    })
    this.formProfile.enable()
    this.formProfile.controls.email.disable();
    this.formProfile.controls.dateOfBirth.disable();

  }

  changePassword(passwordObj) {
    let currPassword = passwordObj.value.currPassword; 
    let newPassword = passwordObj.value.newPassword;
    let reTypeNewPassword = passwordObj.value.reTypeNewPassword;

    this.submitAttemptPassword = true;

    if(this.formChangePassword.valid && this.matchPassword(newPassword, reTypeNewPassword)) {

      if(this.authService.authenticated()) {
        this.authService.changePassword(this.authService.user.email, currPassword)
        .then(res => {
          return this.authService.user.updatePassword(newPassword)    
        })
        .then(res => {
          this.isPasswordUpdated.isUpdated = true,
          this.isPasswordUpdated.msg = 'Your password has been successfully updated.' 
        })
        .catch(error => { 
          console.log(error)
          this.isPasswordUpdated.isUpdated = false;
          
          if(error.code === 'auth/wrong-password') { 
            this.isPasswordUpdated.msg = 'Your current password is invalid. Please try again'
          } else if(error.code === 'auth/too-many-requests') {
            this.isPasswordUpdated.msg = 'Too many unsuccessful login attempts. Please try again later.'
          } else {
            this.isPasswordUpdated.msg = 'Something went wrong. Please try again later.'
          }
        })  
      }
    } 
  }

  matchPassword(newPassword, reTypeNewPassword) {
    if(newPassword == reTypeNewPassword) {
      return true
    }
    return false;
  }
  
  dropLocationContainer() {
    this.isAddLocation = true;
  }

  getLocationMap(cityObj) {
    if(cityObj) {
      this.city = cityObj.city;
      this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.city}+australia&key=${environment.firebaseConfig.apiKey}`, {}).toPromise()
      .then((res: any) => {
        this.latitude = res.results[0].geometry.location.lat;
        this.longitude = res.results[0].geometry.location.lng;
        this.address = res.results[0].formatted_address.split(',').slice(0, 3).join(',');
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      this.isLocationError = 'Please select a location';      
    }
  }

  async addUserLocation() {
    this.isLocationError = undefined;
    if(this.country && this.city) {
      let isLocationExists: boolean = this.userLocations.some(el => {
        if(el.city === this.city) {
          return true;
        }
      })
      if(!isLocationExists) {
        let locationObj = {
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.address,
          isPrimaryLocation: this.userLocations.length > 0 ? false : true,
          city: this.city,
          country: this.country,
        }
        this.userLocations.push(locationObj);
        this.currUserObj.location = this.userLocations;
        try {
          let addLocation = await this.firestore.collection('Users').doc(this.authService.user.uid).update({
            location: this.userLocations
          })
          this.isAddLocation = false;
          this.isLocationAdded = true;
        } catch(e) {
          this.userLocations.pop();
          console.log(e)
          this.isLocationError = 'Could not add location. Please refresh the page and try again';
        }
      } else {
        this.isLocationError = 'Cannot add same location more than once';
      }
    } else {
      this.isLocationError = 'Please select a location';
    }
  }

  async removeLocation(index) {
    let previousLocationState = this.userLocations; 
    let locationsCopy = this.userLocations.slice();
    let primaryLocationFound: boolean = true;

    locationsCopy.splice(index, 1);
    locationsCopy.some(el => {
      if(el.isPrimaryLocation) {
        primaryLocationFound = true;
        return true;
      } else {
        primaryLocationFound = false;        
      }
    })
    if(!primaryLocationFound) {
      locationsCopy[0].isPrimaryLocation = true;
    }
    this.userLocations = locationsCopy;

    try {
      let removeLocation = await this.firestore.collection('Users').doc(this.authService.user.uid).update({
        location: this.userLocations
      })
    } catch(e) {
      console.log(e)
      this.userLocations = previousLocationState;
    }
  }

  async makePrimaryLocation(index) {
    let previousLocationState = this.userLocations; 
    this.userLocations.forEach(el => {
      delete el.isPrimaryLocation
    }) 

    this.userLocations[index].isPrimaryLocation = true;
    let primaryLocation = this.userLocations[index];
    this.userLocations.splice(index, 1);
    this.userLocations.unshift(primaryLocation)

    try {
      let makePrimaryLocation = await this.firestore.collection('Users').doc(this.authService.user.uid).update({
        location: this.userLocations
      })
    } catch(e) {
      console.log(e)
      this.userLocations = previousLocationState;
    }
  }

  async ngOnInit() {
    this.getCurrentUserData();
    this.onProfileChange();

    try {
    } catch(e) {
      console.log(e)
    }

    try {
      let citiesFunction = this.fireFunctions.httpsCallable('cities-getCities')
      this.cities = await citiesFunction({}).toPromise();
      this.country = 'Australia'
    } catch(e) {
      console.log(e)
    }
  }
}