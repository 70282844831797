<div class="gray-table-container">
  <div class="tournaments">
    <div class="tournament" *ngFor="let tournament of tournaments[page]">
      <div class="inner">
        <div class="block-1">
          <div  class="state"
                [ngClass]="{
                  'registration-open': tournament.data().frontEndState === 'registration-open',
                  'registration-closed': !tournament.data().frontEndState || tournament.data().frontEndState === 'registration-closed',
                  'completed': tournament.data().frontEndState === 'completed'}">

            <ng-container *ngIf="tournament.data().frontEndState">
              <div *ngIf="tournament.data().frontEndState === 'registration-open'">
                Reg<span class="dot">.</span><span>istration</span> Open</div>

              <div *ngIf="tournament.data().frontEndState === 'registration-closed'">
                In Progress
              </div>

              <div *ngIf="tournament.data().frontEndState === 'completed'">Completed</div>
            </ng-container>
          </div>
          <div class="entry-fee">Entry Fee: <strong>${{tournament.data().entryFee}}</strong></div>
          <div class="utr-rating">Tier: <strong>{{getTier(tournament.data().victorPoints)}}</strong></div>
          <div class="gender">Gender: <strong>{{tournament.data().gender}}</strong></div>
          <div class="wheelchair">Wheelchair: <strong>{{tournament.data().wheelChair}}</strong></div>
          <div class="age-limit">Age Limit:
            <strong *ngIf="tournament.data().ageLimit && tournament.data().ageLimit !== 'open'">
              {{ tournament.data().ageLimit[0] === 'u' ? 'Under' : 'Over' }}
              {{ tournament.data().ageLimit.slice(1) }}
            </strong>
            <strong *ngIf="!tournament.data().ageLimit || tournament.data().ageLimit === 'open'">Open</strong>
          </div>
          <div *ngIf="tournament.data().frontEndState === 'registration-open'" class="days-left">Time Left<span> To Register</span>:
              <strong>{{getRemainingDays(tournament.data().deadline, tournament.data().location)}}</strong>
          </div>
          <div *ngIf="tournament.data().frontEndState !== 'registration-open'" class="location">Registered Players: <strong>{{tournament.data().players ? tournament.data().players.length : 0}}</strong></div>
        </div>
        <div class="block-2">
          <div class="title"><span></span> Title: <strong>{{tournament.data().title}}</strong></div>
          <div class="start-date"><span>Reg. </span> Deadline: <strong>{{getFormattedDate(tournament.data().deadline)}}</strong></div>
          <div class="location"><span></span> Location: <strong>{{tournament.data().location}}</strong></div>
          <div class="category">Category: <strong>{{tournament.data().category}}</strong></div>
          <div *ngIf="tournament.data().frontEndState === 'registration-open'" class="location">Registered Players: <strong>{{tournament.data().players ? tournament.data().players.length : 0}}</strong></div>
          <div class="btn-join"><button type="button" [routerLink]="['/tennis/tournaments/'+tournament.id]">View Details</button></div>
        </div>
      </div>
    </div>
  </div>
</div>
