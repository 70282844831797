import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  currUserObj;

  constructor(public authService: AuthService, public firestore: AngularFirestore) { }

  getUserProfileData() {
    return this.authService.authState.pipe(first()).toPromise()
    .then(user => {
      if(user) {
        return this.firestore.collection('Users').doc(user.uid).get().toPromise()
      }
    })
  }  
}
