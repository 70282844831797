import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-statistics-history',
  templateUrl: './player-statistics-history.component.html',
  styleUrls: ['./player-statistics-history.component.scss']
})
export class PlayerStatisticsHistoryComponent implements OnInit {

  circleRadius: number = 42;
  circleFontSize: number = 32;
  circlePadding: number = -5;
  circleInnerStroke: number = 5;
  circleOuterStroke: number = 5;

  constructor() { }

  onResize(event) {
    if(window.innerWidth < 576) {
      this.circleRadius = 26;
      this.circleFontSize = 18;
      this.circlePadding = 5;
      this.circleInnerStroke = 0;
      this.circleOuterStroke = 2; 
    } else if(window.innerWidth < 768) {
      this.circleRadius = 30;
      this.circleFontSize = 22;
      this.circlePadding = 2;
      this.circleInnerStroke = 0;
      this.circleOuterStroke = 3;
    } else {
      this.circleRadius = 42;
      this.circleFontSize = 32;
      this.circlePadding = -5;
      this.circleInnerStroke = 5;
      this.circleOuterStroke = 5;
    }
  }

  ngOnInit() {
    if(window.innerWidth < 768) {
      this.onResize(12345)
    }
  }

}
