<section class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="heading">Player Rating</div>
      <p class="text">Please rate your opponent in terms of 1) pre match interactions, 2) on time or late, 3) respectful, 4) fair line
        calls, 5) sportsmanship, 6) able to play hard but still be fair.
        <br>
        You rating is crucial for us to know how the player performed. And it will be beneficial for other players to see the
        the overall performance of the player.
      </p>
      <div class="player-rating">
        <star-rating
          value={{playerRating}}
          totalstars={{totalStars}}
          checkedcolor="#6fbd22"
          uncheckedcolor="#d6d6d6"
          size="35px"
          readonly="false"
          (rate)="onRate($event)">
        </star-rating>

        <p style="text-align: center; font-weight: 500;">
          {{
            playerRating === 1 ? 'Bad' :
            playerRating === 2 ? 'Not so good' :
            playerRating === 3 ? 'Okay' :
            playerRating === 4 ? 'Good' :
            playerRating === 5 ? 'Excellent' : 'Error'
          }}
        </p>
      </div>

      <div class="join-buttons">
        <button type="button" class="btn-cancel" (click)="confirmRating()">Confirm</button>
      </div>
    </div>
  </div>
</section>
