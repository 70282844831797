<div class="loader" *ngIf="isLoading">
  <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
</div>
<div *ngIf="areRoundsCreated">
  <div *ngIf="isKnockoutMatch">
    <div class="nav-tabs" *ngIf="isNavigationCreated">
      <div class="tab" *ngFor="let nav of navigationArray; let i = index" [ngClass]="{'active': k === i}"
        (click)="navigateDraws(i)">
        {{ nav.round }}
      </div>
    </div>

    <div class="fixtures">
      <div class="col-headings" *ngIf="isNavigationCreated">
        <div class="heading" *ngIf="navigationArray[k+0]">
          <div>{{ navigationArray[k+0].round }}</div>
          <div class="prize">{{ navigationArray[k+0].prize !== 0 ? "$" + navigationArray[k+0].prize.toFixed(2) : "" }}</div>
        </div>
        <div class="heading" *ngIf="navigationArray[k+1]">
          <div>{{ navigationArray[k+1].round }}</div>
          <div class="prize">{{ navigationArray[k+1].prize !== 0 ? "$" + navigationArray[k+1].prize.toFixed(2) : "" }}</div>
        </div>
        <div class="heading" *ngIf="navigationArray[k+2]">
          <div>{{ navigationArray[k+2].round }}</div>
          <div class="prize">{{ navigationArray[k+2].prize !== 0 ? "$" + navigationArray[k+2].prize.toFixed(2) : "" }}</div>
        </div>
      </div>

      <div class="matches">
        <div class="col-33 col-one">
          <div class="player-wrapper" [ngClass]="{'winner': player.winner}" *ngFor="let player of roundsData[k+0]; let i = index">
            <div class="player">
              <img [src]="player.image" alt="no-img">
              <div class="seed" *ngIf="player.seed">({{ player.seed }})</div>
                <div
                  class="name"
                  [routerLink]="tournamentData.category === 'singles' && player.name !== 'Bye' && player.name !== 'TBA' ? ['/tennis/player/'+player.id] : []">
                    {{ 
                      tournamentData.category === 'singles' ?
                        player.name.split(' ')[0] + ' ' + (player.name !== 'Bye' && player.name !== 'TBA' ? player.name.split(' ')[1][0] + '.' : '' | uppercase)
                      : player.name
                    }}
                </div>
                <div 
                  class="outcome"
                  [ngClass]="{'walkover': player.outcome === 'walkover', 'retired': player.outcome === 'retired'}"
                  *ngIf="player.outcome">
                    {{ player.outcome === 'retired' ? ' (Ret.)' : 'Walkover' }}
                </div>
                <div class="score" *ngIf="player.mark === 'completed' && !player.dontShowScore">
                  <div>
                    {{ player.score.setOne }}
                    <sup *ngIf="player.score.setOneTie !== undefined">{{ player.score.setOneTie }}</sup>
                  </div>
                  <div>
                    {{ player.score.setTwo }}
                    <sup *ngIf="player.score.setTwoTie !== undefined">{{ player.score.setTwoTie }}</sup>
                  </div>
                  <div *ngIf="player.score.setThree !== undefined">
                    {{ player.score.setThree }}
                    <sup *ngIf="player.score.setThreeTie !== undefined">{{ player.score.setThreeTie }}</sup>
                  </div>
                </div>
            </div>

            <div *ngIf="!(i % 2)">
              <div class="space"></div>
            </div>

            <div *ngIf="i % 2">
              <div class="space"></div>
              <div class="space"></div>
            </div>
          </div>
        </div>

        <div class="col-33 col-two" *ngIf="roundsData[k+1]">
          <div class="player-wrapper" [ngClass]="{'winner': player.winner}" *ngFor="let player of roundsData[k+1]; let i = index">
            <div *ngIf="i == 0">
              <div class="space"></div>
            </div>
            <div class="player">
              <img [src]="player.image" alt="no-img">
                <div
                  class="name"
                  [routerLink]="tournamentData.category === 'singles' && player.name !== 'Bye' && player.name !== 'TBA' ? ['/tennis/player/'+player.id] : []">
                    {{ 
                      tournamentData.category === 'singles' ?
                        player.name.split(' ')[0] + ' ' + (player.name !== 'Bye' && player.name !== 'TBA' ? player.name.split(' ')[1][0] + '.' : '' | uppercase)
                      : player.name
                    }}
                </div>
                <div 
                  class="outcome"
                  [ngClass]="{'walkover': player.outcome === 'walkover', 'retired': player.outcome === 'retired'}"
                  *ngIf="player.outcome">
                    {{ player.outcome === 'retired' ? ' (Ret.)' : 'Walkover' }}
                </div>
                <div class="score" *ngIf="player.mark === 'completed' && !player.dontShowScore">
                  <div>
                    {{ player.score.setOne }}
                    <sup *ngIf="player.score.setOneTie !== undefined">{{ player.score.setOneTie }}</sup>
                  </div>
                  <div>
                    {{ player.score.setTwo }}
                    <sup *ngIf="player.score.setTwoTie !== undefined">{{ player.score.setTwoTie }}</sup>
                  </div>
                  <div *ngIf="player.score.setThree !== undefined">
                    {{ player.score.setThree }}
                    <sup *ngIf="player.score.setThreeTie !== undefined">{{ player.score.setThreeTie }}</sup>
                  </div>
                </div>
            </div>

            <div *ngIf="!(i % 2)">
              <div class="space-combined">
              </div>
            </div>

            <div *ngIf="i % 2">
              <div class="space-combined"></div>
            </div>
          </div>
        </div>

        <div class="col-33 col-three" *ngIf="roundsData[k+2]">
          <div class="player-wrapper" [ngClass]="{'winner': player.winner}" *ngFor="let player of roundsData[k+2]; let i = index">
            <div *ngIf="i == 0">
              <div class="space"></div>
              <div class="space"></div>
              <div class="space"></div>
              <div class="space"></div>
              <div class="space"></div>
            </div>
            <div class="player">
              <img [src]="player.image" alt="no-img">
              <div
                class="name"
                [routerLink]="tournamentData.category === 'singles' && player.name !== 'Bye' && player.name !== 'TBA' ? ['/tennis/player/'+player.id] : []">
                  {{ 
                    tournamentData.category === 'singles' ?
                      player.name.split(' ')[0] + ' ' + (player.name !== 'Bye' && player.name !== 'TBA' ? player.name.split(' ')[1][0] + '.' : '' | uppercase)
                    : player.name
                  }}
              </div>
              <div 
                class="outcome"
                [ngClass]="{'walkover': player.outcome === 'walkover', 'retired': player.outcome === 'retired'}"
                *ngIf="player.outcome">
                  {{ player.outcome === 'retired' ? ' (Ret.)' : 'Walkover' }}
              </div>
              <div class="score" *ngIf="player.mark === 'completed' && !player.dontShowScore">
                <div>
                  {{ player.score.setOne }}
                  <sup *ngIf="player.score.setOneTie !== undefined">{{ player.score.setOneTie }}</sup>
                </div>
                <div>
                  {{ player.score.setTwo }}
                  <sup *ngIf="player.score.setTwoTie !== undefined">{{ player.score.setTwoTie }}</sup>
                </div>
                <div *ngIf="player.score.setThree !== undefined">
                  {{ player.score.setThree }}
                  <sup *ngIf="player.score.setThreeTie !== undefined">{{ player.score.setThreeTie }}</sup>
                </div>
              </div>
            </div>

            <div *ngIf="!(i % 2)">
              <div class="space-combined">
              </div>
            </div>

            <div *ngIf="i % 2">
              <div class="space-combined"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isBoxMatch">
    <ng-container *ngIf="isUserBoxFixtures">
      <div class="row my-matches">
        <div class="col-12 col-lg-6" *ngFor="let fixture of boxFixtures; let i = index">
          <div class="match">
            <div class="players">
              <div class="player" [routerLink]="['/tennis/player/'+fixture.teamA.id]">
                <img [src]="fixture.teamA.image" alt="profile-img">
                {{ fixture.teamA.name }} {{ fixture.home === 'teamA' ? '(H)' : '(A)' }}
                <span *ngIf="fixture.frontEndState === 'completed'">
                  <span class="match-outcome" *ngIf="fixture.outcome">
                    <span *ngIf="fixture.outcome.newMatchOutcome && fixture.outcome.newMatchOutcome.team === 'teamA'">
                      ({{ fixture.outcome.newMatchOutcome.type }})</span>
                    <span *ngIf="!fixture.outcome.newMatchOutcome && fixture.outcome.matchOutcome.team === 'teamA'">
                      ({{ fixture.outcome.matchOutcome.type }})</span>
                  </span>

                  <span *ngIf="fixture.winner === 'teamA'">
                    <img src="../../../assets/image/trophy.webp" alt="trophy">
                  </span>
                </span>
                <div class="scores" *ngIf="fixture.frontEndState === 'completed'">
                  <ng-container *ngIf="!fixture.teamA.newScore">
                    <div>
                      {{ fixture.teamA.matchScore.setOne }}
                      <sup *ngIf="fixture.teamA.matchScore.setOneTie">{{ fixture.teamA.matchScore.setOneTie }}</sup>
                    </div>
                    <div>
                      {{ fixture.teamA.matchScore.setTwo }}
                      <sup *ngIf="fixture.teamA.matchScore.setTwoTie">{{ fixture.teamA.matchScore.setTwoTie }}</sup>
                    </div>
                    <div *ngIf="fixture.teamA.matchScore.setThree">
                      {{ fixture.teamA.matchScore.setThree }}
                      <sup *ngIf="fixture.teamA.matchScore.setThreeTie">{{ fixture.teamA.matchScore.setThreeTie }}</sup>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="fixture.teamA.newScore">
                    <div>
                      {{ fixture.teamA.newScore.setOne }}
                      <sup *ngIf="fixture.teamA.newScore.setOneTie">{{ fixture.teamA.newScore.setOneTie }}</sup>
                    </div>
                    <div>
                      {{ fixture.teamA.newScore.setTwo }}
                      <sup *ngIf="fixture.teamA.newScore.setTwoTie">{{ fixture.teamA.newScore.setTwoTie }}</sup>
                    </div>
                    <div *ngIf="fixture.teamA.newScore.setThree">
                      {{ fixture.teamA.newScore.setThree }}
                      <sup *ngIf="fixture.teamA.newScore.setThreeTie">{{ fixture.teamA.newScore.setThreeTie }}</sup>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="vs">vs</div>
              <div class="player" [routerLink]="['/tennis/player/'+fixture.teamB.id]">
                <img [src]="fixture.teamB.image" alt="profile-img">
                {{ fixture.teamB.name }} {{ fixture.home === 'teamB' ? '(H)' : '(A)' }}
                <span *ngIf="fixture.frontEndState === 'completed'">
                  <span class="match-outcome" *ngIf="fixture.outcome">
                    <span *ngIf="fixture.outcome.newMatchOutcome && fixture.outcome.newMatchOutcome.team === 'teamB'">
                      ({{ fixture.outcome.newMatchOutcome.type }})</span>
                    <span *ngIf="!fixture.outcome.newMatchOutcome && fixture.outcome.matchOutcome.team === 'teamB'">
                      ({{ fixture.outcome.matchOutcome.type }})</span>
                  </span>

                  <span *ngIf="fixture.winner === 'teamB'">
                    <img src="../../../assets/image/trophy.webp" alt="trophy">
                  </span>
                </span>
                <div class="scores" *ngIf="fixture.frontEndState === 'completed'">
                  <ng-container *ngIf="!fixture.teamA.newScore">
                    <div>
                      {{ fixture.teamB.matchScore.setOne }}
                      <sup *ngIf="fixture.teamA.matchScore.setOneTie">{{ fixture.teamB.matchScore.setOneTie }}</sup>
                    </div>
                    <div>
                      {{ fixture.teamB.matchScore.setTwo }}
                      <sup *ngIf="fixture.teamA.matchScore.setTwoTie">{{ fixture.teamB.matchScore.setTwoTie }}</sup>
                    </div>
                    <div *ngIf="fixture.teamA.matchScore.setThree">
                      {{ fixture.teamB.matchScore.setThree }}
                      <sup *ngIf="fixture.teamA.matchScore.setThreeTie">{{ fixture.teamB.matchScore.setThreeTie }}</sup>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="fixture.teamA.newScore">
                    <div>
                      {{ fixture.teamB.newScore.setOne }}
                      <sup *ngIf="fixture.teamA.newScore.setOneTie">{{ fixture.teamB.newScore.setOneTie }}</sup>
                    </div>
                    <div>
                      {{ fixture.teamB.newScore.setTwo }}
                      <sup *ngIf="fixture.teamA.newScore.setTwoTie">{{ fixture.teamB.newScore.setTwoTie }}</sup>
                    </div>
                    <div *ngIf="fixture.teamA.newScore.setThree">
                      {{ fixture.teamB.newScore.setThree }}
                      <sup *ngIf="fixture.teamA.newScore.setThreeTie">{{ fixture.teamB.newScore.setThreeTie }}</sup>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isTeamsFixtures">
      <div class="row my-matches teams">
        <div class="col-12" *ngFor="let team of teams; let i = index">
          <div
            class="match"
            [ngClass]="{'complete': team.frontEndState && team.frontEndState === 'completed'}">
            <div class="team">
              <div class="team-name"> {{ team.teamA.teamName }}
                <span *ngIf="team.winner === 'teamA'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
              </div>
              <div class="player" [routerLink]="['/tennis/player/'+team.teamA.playerOne.id]">
                <img [src]="team.teamA.playerOne.image" alt="profile-img"> {{ team.teamA.playerOne.name }}</div>
              <div class="player" [routerLink]="['/tennis/player/'+team.teamB.playerOne.id]">
                <img [src]="team.teamA.playerTwo.image" alt="profile-img"> {{ team.teamA.playerTwo.name }}</div>
              <h4 class="kit">{{ team.home === 'teamA' ? 'Home' : 'Away' }}</h4>
              <div class="scores" *ngIf="team.frontEndState === 'completed'">
                <ng-container *ngIf="!team.teamA.newScore">
                  <div>
                    {{ team.teamA.matchScore.setOne }}
                    <sup *ngIf="team.teamA.matchScore.setOneTie">{{ team.teamA.matchScore.setOneTie }}</sup>
                  </div>
                  <div>
                    {{ team.teamA.matchScore.setTwo }}
                    <sup *ngIf="team.teamA.matchScore.setTwoTie">{{ team.teamA.matchScore.setTwoTie }}</sup>
                  </div>
                  <div *ngIf="team.teamA.matchScore.setThree">
                    {{ team.teamA.matchScore.setThree }}
                    <sup *ngIf="team.teamA.matchScore.setThreeTie">{{ team.teamA.matchScore.setThreeTie }}</sup>
                  </div>
                </ng-container>
                <ng-container *ngIf="team.teamA.newScore">
                  <div>
                    {{ team.teamA.newScore.setOne }}
                    <sup *ngIf="team.teamA.newScore.setOneTie">{{ team.teamA.newScore.setOneTie }}</sup>
                  </div>
                  <div>
                    {{ team.teamA.newScore.setTwo }}
                    <sup *ngIf="team.teamA.newScore.setTwoTie">{{ team.teamA.newScore.setTwoTie }}</sup>
                  </div>
                  <div *ngIf="team.teamA.newScore.setThree">
                    {{ team.teamA.newScore.setThree }}
                    <sup *ngIf="team.teamA.newScore.setThreeTie">{{ team.teamA.newScore.setThreeTie }}</sup>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="vs">vs</div>
            <div class="team">
              <div class="team-name"> {{ team.teamB.teamName }}
                <span *ngIf="team.winner === 'teamB'"><img src="../../../assets/image/trophy.webp" alt="trophy"></span>
              </div>
              <div class="player" [routerLink]="['/tennis/player/'+team.teamB.playerOne.id]">
                <img [src]="team.teamB.playerOne.image" alt="profile-img"> {{ team.teamB.playerOne.name }}</div>
              <div class="player" [routerLink]="['/tennis/player/'+team.teamB.playerTwo.id]">
                <img [src]="team.teamB.playerTwo.image" alt="profile-img"> {{ team.teamB.playerTwo.name }}</div>
              <h4 class="kit">{{ team.home === 'teamB' ? 'Home' : 'Away' }}</h4>
              <div class="scores" *ngIf="team.frontEndState === 'completed'">
                <ng-container *ngIf="!team.teamB.newScore">
                  <div>
                    {{ team.teamB.matchScore.setOne }}
                    <sup *ngIf="team.teamB.matchScore.setOneTie">{{ team.teamB.matchScore.setOneTie }}</sup>
                  </div>
                  <div>
                    {{ team.teamB.matchScore.setTwo }}
                    <sup *ngIf="team.teamB.matchScore.setTwoTie">{{ team.teamB.matchScore.setTwoTie }}</sup>
                  </div>
                  <div *ngIf="team.teamB.matchScore.setThree">
                    {{ team.teamB.matchScore.setThree }}
                    <sup *ngIf="team.teamB.matchScore.setThreeTie">{{ team.teamB.matchScore.setThreeTie }}</sup>
                  </div>
                </ng-container>
                <ng-container *ngIf="team.teamB.newScore">
                  <div>
                    {{ team.teamB.newScore.setOne }}
                    <sup *ngIf="team.teamB.newScore.setOneTie">{{ team.teamB.newScore.setOneTie }}</sup>
                  </div>
                  <div>
                    {{ team.teamB.newScore.setTwo }}
                    <sup *ngIf="team.teamB.newScore.setTwoTie">{{ team.teamB.newScore.setTwoTie }}</sup>
                  </div>
                  <div *ngIf="team.teamB.newScore.setThree">
                    {{ team.teamB.newScore.setThree }}
                    <sup *ngIf="team.teamB.newScore.setThreeTie">{{ team.teamB.newScore.setThreeTie }}</sup>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>
