import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-player-tennis-tournament-teams-accepted',
  templateUrl: './player-tennis-tournament-teams-accepted.component.html',
  styleUrls: ['./player-tennis-tournament-teams-accepted.component.scss']
})
export class PlayerTennisTournamentTeamsAcceptedComponent implements OnInit {

  isLoading: boolean = true;
  invites = [];
  requests = [];
  hasInvites: boolean;
  isAcceptModalOpen: boolean;
  isRejectModalOpen: boolean;
  currRequest;
  isModalLoading: boolean;


  constructor(private firestore: AngularFirestore,
              private authService: AuthService,
              private router: Router) { }

  async gatherData(invites) {
    try {
      let requests = await Promise.all(invites.map(async el => {
        let requestObj: any = {}
        let team = await this.gatherTeamData(el.teamId)
        let match = await this.gatherMatchData(el.matchId)
        requestObj.team = team;
        requestObj.match = match;
        requestObj.inviteId = el.inviteId
        return requestObj
      }))
      this.requests = requests.slice();
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

  async gatherTeamData(teamId) {
    try {
      let team = await this.firestore.collection('Teams').doc(teamId).get().toPromise();
      let teamObj: any = {}
      teamObj.name = team.data().name 
      teamObj.id = team.id
      return teamObj
    } catch(e) {
      console.log(e)
    }
  }

  async gatherMatchData(matchId) {
    try {
      let match = await this.firestore.collection('Matches').doc(matchId).get().toPromise();
      let matchObj: any = {}
      matchObj.name = match.data().title 
      matchObj.id = match.id
      return matchObj
    } catch(e) {
      console.log(e)
    }
  }

  openModal(request, modalType) {
    this.currRequest = request;
    if(modalType === 'reject') {
      document.body.classList.add('modal-open');
      this.isRejectModalOpen = true;
    }
  }

  async reject(request) {
    try {
      let deleteDoc = await this.firestore.collection('MatchTeamRequests').doc(request.inviteId).delete();
      this.closeModal();
      this.isLoading = true;
      this.ngOnInit();
    } catch(e) {
      console.log(e)
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');    
    this.isAcceptModalOpen = false;    
    this.isRejectModalOpen = false;
  }

  async ngOnInit() {
    try {
      let teams = await this.firestore.collection('MatchTeamRequests', ref => ref
                  .where('state', '==', 'accepted')).get().toPromise()
      if(teams.docs.length > 0) {
        let userTeams = teams.docs.filter(team => { 
          if(team.data().receiverId === this.authService.user.uid || team.data().senderId === this.authService.user.uid) {
            return team
          }
        })
        if(userTeams.length > 0) {
          this.invites = userTeams.slice()
          let invites = [];
          invites = this.invites.map(el => {
            let tempInviteObj = el.data();
            tempInviteObj.inviteId = el.id; 
            return tempInviteObj;
          })
          this.gatherData(invites);
          this.hasInvites = true;
        } else {
          this.isLoading = false;
          this.hasInvites = false;
        }
      } else {
        this.isLoading = false;
        this.hasInvites = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

}
