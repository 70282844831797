import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'

@Component({
  selector: 'app-losers-fixtures',
  templateUrl: './losers-fixtures.component.html',
  styleUrls: ['./losers-fixtures.component.scss']
})
export class LosersFixturesComponent implements OnInit {

  k: number = 0;
  @Input() tournament;
  tournamentData;
  tournamentRounds = [];
  areRoundsLoaded: boolean;
  roundsRawData = [];
  roundsDataLoaded: boolean;
  roundsData;
  numberOfRounds: number;
  areRoundsCreated: boolean;
  isNavigationCreated: boolean;
  navigationArray;
  isLoading: boolean;
  registeredPlayers: any[]

  constructor(private firestore: AngularFirestore) { }

  async getRegisteredPlayers() {
    let players = this.tournamentData.players;
    if(players) {
      try {
        if(this.tournamentData.category === 'singles') {
          this.registeredPlayers = await Promise.all(players.map(async user => await this.gatherData(user.id)));
        } else {
          this.registeredPlayers = await Promise.all(players.map(async team => await this.gatherKnockoutTeamData(team.id)));
        }
      } catch(e) {
        console.log(e)
      }
    }
  }

  getRounds() {
    this.tournament.ref.collection('loserRounds').get()
    .then(res => {
      if(res.docs.length > 0) {
        this.tournamentRounds = res.docs;
        this.areRoundsLoaded = true;
        this.serparateRounds(this.tournamentRounds);
      } else {
        this.areRoundsLoaded = false;
        this.isLoading = false;
      }
    })
    .catch(error =>{
      console.log(error)
    })
  }

  serparateRounds(rounds) {
    if(this.areRoundsLoaded) {
      let promises = [];

      rounds.forEach((el, index) => {
        let rounds = []
        rounds.length = el.data().fixtureLength
        rounds.fill(undefined)
        promises.push(el.ref.collection('fixtures').get()
        .then(res => {
          res.docs.forEach((el2, index2) => {
            let fixtureId = Number(el2.id.match(/\d+/g)[0]);
            rounds[fixtureId - 1] = el2;
          })
          this.roundsRawData.push(rounds);
        })
        .catch(error => {
          console.log(error)
        }));
      });

      Promise.all(promises)
      .then(() => {
        this.roundsDataLoaded = true;
        this.createRounds(this.roundsRawData);
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  createRounds(rawData) {
    let rawRounds = rawData.slice();
    let rounds = [];
    rawRounds.forEach((el) => {
      let tempArr = el;
      tempArr.forEach((el, index, array) => {
        if(el?.data()) {
          array[index] = el.data(); 
        }
      })
      rounds.push(tempArr);
    });
    this.createSeparateRounds(rounds);
  }

  async createSeparateRounds(rawRounds) {
    let rounds = [];
    rawRounds.forEach(el1 => {
      let round = [];
      el1.forEach((el2) => {
        if(el2) {
          if(el2.frontEndState === 'completed') {
            el2.teamA.mark = 'completed';
            el2.teamB.mark = 'completed';
            if(el2.outcome && el2.outcome.matchOutcome) {
              let outcome  = el2.outcome.newMatchOutcome ? el2.outcome.newMatchOutcome : el2.outcome.matchOutcome
              if(outcome.type === 'walkover') {
                el2.teamA.dontShowScore = true;
                el2.teamB.dontShowScore = true;              
              }
              if(outcome.team === 'teamA') el2.teamA.outcome = outcome.type
              else el2.teamB.outcome = outcome.type
            }
          }
          round.push(el2.teamA)
          round.push(el2.teamB)
        } else round.push(undefined, undefined)
      })
      rounds.push(round)
    })
    this.numberOfRounds = rounds.length;
    await this.gatherUserData(rounds)
    this.createRoundNavigation(rounds)
  }

  async gatherUserData(rounds) {
    try {
      let roundUsers = [];
      for(let i = 0; i < rounds.length; i++) {
        let round = rounds[i];
        let users = [];
        for (let j = 0; j < round.length; j++) {
          if(round[j]) {
            let userData: any = { ...this.registeredPlayers.find(el => el.id === round[j].id) };
            if(!userData || Object.keys(userData).length < 1) {
              if(this.tournamentData.category === 'singles') userData = await this.gatherData('')
              else if(this.tournamentData.category === 'doubles') userData = await this.gatherKnockoutTeamData('') 
            }

            if(round[j].mark === 'completed') {
              userData.mark = 'completed';
              userData.score = round[j].newScore ?? round[j].matchScore
            }
            if(i === 0 && round[j].seed) userData.seed = round[j].seed;
            if(round[j].outcome) userData.outcome = round[j].outcome;
            if(round[j].dontShowScore) userData.dontShowScore = round[j].dontShowScore;

            users.push(userData);
          } else users.push(undefined);
        }
        roundUsers.push(users);
      }
      roundUsers.forEach(el => {
        el.forEach(async (el2, index, array) => {
          if(!el2) array[index] = { name: 'TBA', image: '../../../assets/image/no-img.webp' };
        })
      })
      this.roundsData = roundUsers;
      this.areRoundsCreated = true;
      this.isLoading = false;
    } catch(e) {
      console.log(e);
    }
  }

  async gatherData(userId) {
    let userObj: any = {};
    if(userId !== '') {
      let user = await this.firestore.collection('Users').doc(userId).get().toPromise();
      userObj = {
        id: userId,
        email: user.data().email,
        name: user.data().firstName + " " + user.data().lastName,
        victorPoints: user.data().victorPoints,
        image: user.data().profileImg ? user.data().profileImg : '../../../assets/image/no-img.webp'
      };
      return userObj;
    } else {
      userObj = {
        id: "userId",
        email: "email",
        name: "Bye",
        victorPoints: "victorPoints",
        image: '../../../assets/image/no-img.webp'
      };
      return userObj
    }
  }

  async gatherKnockoutTeamData(teamId) {
    let teamObj: any = {};
    try {
      if(teamId !== '') {
        let team = await this.firestore.collection('Teams').doc(teamId).get().toPromise();
        teamObj = team.data();
        teamObj.id = team.id;
        teamObj.image = '../../../assets/image/no-img.webp'
      } else {
        teamObj.name = 'Bye';
        teamObj.image = '../../../assets/image/no-img.webp'
      }
      return teamObj
    } catch(e) {
      console.log(e)
    }
  }

  async createRoundNavigation(rounds) {
    const addCompulsoryRounds = (roundArr) => {
      roundArr.push({ round: 'Semifinals', prize: 0});
      roundArr.push({ round: 'Finals', prize: 0});
      roundArr.push({ round: 'Winner', prize: 0});
    }

    let roundsArray = [];
    if(rounds[0].length <= 4) {
      addCompulsoryRounds(roundsArray)
    } else {
      for(let i = 0; i < rounds.length; i++) {
        if(rounds[i].length >= 8) {
          roundsArray.push({ round: 'Quarterfinals', prize: 0});
        }
        if(rounds[i].length >= 16) {
          let roundName = 'Round of ' + rounds[i].length;
          roundsArray.push({ round: roundName, prize: 0})
        }
      }
      addCompulsoryRounds(roundsArray)
    }

    if(this.tournamentData.frontEndState === 'completed' && this.tournamentData.loserRoundsWinner) {
      try {
        let user =  this.tournamentData.category === 'singles' ?
                      await this.gatherData(this.tournamentData.loserRoundsWinner.id)
                    : await this.gatherKnockoutTeamData(this.tournamentData.loserRoundsWinner.id)
        user.winner = true;
        this.roundsData.push([user])
      } catch(e) {
        console.log(e)
      }
    }

    this.navigationArray = roundsArray;
    this.isNavigationCreated = true;
  }

  navigateDraws(index) {
    this.k = index;
  }

  ngOnInit(): void {
    this.tournamentData = this.tournament.data();
    this.isLoading = true;
    this.getRegisteredPlayers();
    this.getRounds();
  }

}
