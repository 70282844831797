import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/internal/operators';
import { AuthService } from './../../services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

  isLoading: boolean = true;
  users = [];
  isChatFound: boolean;
  isChatLoading: boolean;
  chat = [];
  isChatLoaded: boolean;
  currChatId: string;
  chatForm: FormGroup;
  isSendMessageLoading: boolean;
  isMessageSent: boolean;
  isMessageError: boolean;
  isSendMessageActive: boolean = true;
  $chats: Subscription;
  $messages: Subscription;

  constructor(private firestore: AngularFirestore, 
              private authService: AuthService,
              private formBuilder: FormBuilder) { }

  getChats() {
    this.$chats = this.firestore.collection('Chats', ref => ref
    .where('members', 'array-contains', this.authService.user.uid)
    .orderBy('updatedAt', 'desc')).snapshotChanges().subscribe(res => {
      let userChats = [];
      if(res.length > 0) {
        userChats = res.map(el => {
          return el.payload.doc
        })
        this.assembleData(userChats)
      } else {
        this.isChatFound = false;
        this.isLoading = false;
      }
    });
  }

  assembleData(chats) {
    if(chats.length > 0) {
      let promises = []
      chats.forEach(chat => {
        let otherUserId = chat.data().members[0] !== this.authService.user.uid ? chat.data().members[0] : chat.data().members[1] 
        promises.push(this.getUserData(chat, this.authService.user.uid, otherUserId))
      })

      Promise.all(promises)
      .then(res => {
        this.users = [...res];
        this.isChatFound = true;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      })
    }
  }

  async getUserData(chatDoc, userOneId, userTwoId) {
    try {
      let messageObj: any = {}
      let userOne = userOneId;
      let userTwo = await this.firestore.collection('Users').doc(userTwoId).get().toPromise();
 
      messageObj.chatId = chatDoc.id;
      messageObj.userOne = {
        id: userOne,
        email: this.authService.user.email,
      };
      messageObj.userTwo = {
        id: userTwo.id,
        name: userTwo.data().firstName + " " + userTwo.data().lastName,
        email: userTwo.data().email,
        image: userTwo.data().profileImg ? userTwo.data().profileImg : '../../../assets/image/no-img.webp'
      }
      messageObj.isRead = chatDoc.data().isRead
      return messageObj;
    } catch(e) {
      console.log(e)
    }
  }

  openChat(chatId) {
    this.isChatLoading = true;

    this.$messages = this.firestore.collection('Chats').doc(chatId)
                    .collection('messages', ref => ref.orderBy('timestamp', 'asc'))
      .snapshotChanges().subscribe(async res => {
      
      let messagesDocs = []
      messagesDocs = res.map(el => {
        return el.payload.doc
      })

      if(messagesDocs.length > 0) {
        let chat = []
        chat = messagesDocs.map(el => {
          return el.data()
        })
        this.chat = chat;
        this.isChatLoaded = true;
        this.currChatId = chatId
        this.isChatLoading = false;
        await this.firestore.collection('Chats').doc(this.currChatId).update({ isRead: true });
      }
    })
  }

  async sendMessage(form) {
    try {
      if(form.valid) {
        this.isSendMessageActive = false;
        let messageObj = {
          content: form.value.message,
          timestamp: Date.now(),
          id: this.authService.user.uid,
        }
        
        await this.firestore.collection('Chats').doc(this.currChatId).collection('messages').add(messageObj)
        await this.firestore.collection('Chats').doc(this.currChatId).update({ updatedAt: Date.now(), isRead: false });
        this.isMessageSent = true;
        this.isSendMessageActive = true;
        this.chatForm.reset()
      }
    } catch(e) {
      console.log(e) 
      this.isMessageError = true;
      this.isSendMessageLoading = false;
    }
  }

  async ngOnInit() {
    try {
      let user = await this.authService.authState.pipe(first()).toPromise()
      if(user) {
        this.getChats();
      }
    } catch(e) {
      console.log(e)
    }

    this.chatForm = this.formBuilder.group({
      message: new FormControl('', Validators.required)
    })
  }

  ngDestroy() {
    if(this.$chats) {
      this.$chats.unsubscribe();
    }
    if(this.$messages ) {
      this.$messages.unsubscribe();
    }
  }
}
