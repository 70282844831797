<div class="container-white">
  <div class="container">
    <div class="gray-table-container">

      <ng-container *ngIf="isLoggedIn !== false; else notLoggedIn">
        
        <div *ngIf="isLoading" class="loader">
          <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
        </div>
  
        <ng-container *ngIf="isTokenVerified">
          <div *ngIf="isVerifyScore">
            <div class="verify" *ngIf="fixtureState === 'score-uploaded'">
              <div *ngIf="isDataLoaded">
                <p *ngIf="matchType === 'knockout'">
                  <strong> {{ usersData[fixtureData.scoreBy].firstName }} {{ usersData[fixtureData.scoreBy].lastName }} </strong>
                  has uploaded these scores of your match in<strong> {{ roundData.roundName }} </strong> <strong> {{ tournamentData.title }} </strong> 
                </p>
                <p *ngIf="matchType === 'box'">
                  <strong> {{ usersData[fixtureData.scoreBy].firstName }} {{ usersData[fixtureData.scoreBy].lastName }} </strong>
                  has uploaded these scores of your match in<strong> {{ tournamentData.title }} </strong> 
                </p>
                <p *ngIf="matchType === 'one-on-one'">
                  <strong> {{ usersData[fixtureData.scoreBy].firstName }} {{ usersData[fixtureData.scoreBy].lastName }} </strong>
                  has uploaded these scores of your match in<strong> One On One.</strong>
                </p>

                <ng-container *ngIf="!(fixtureData.outcome?.matchOutcome?.type === 'walkover') || deniedScores">
                  <div  class="player"
                        [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">      
                    {{ usersData.teamA.firstName }} {{ usersData.teamA.lastName }}
                    <ng-container *ngIf="matchOutcome && matchOutcome.type !== '' && matchOutcome.team !== ''">
                      <span class="winner">{{ matchOutcome.team === 'teamB' ? '- Winner' : '' }}</span>
                    </ng-container>
                  </div>
    
                  <div class="input-fields-scores">
                    <div class="fields-scores-inner">
                      <form [formGroup]="formGroup"> 
                        <div class="set set-one">
                          <div class="set-not-tie">
                            <input type="text" formControlName="setOneA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneA.valid, 'error': formGroup.controls.setOneA.touched && formGroup.controls.setOneA.invalid}">
                            <input type="text" formControlName="setOneB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneB.valid, 'error': formGroup.controls.setOneB.touched && formGroup.controls.setOneB.invalid}">
                          </div>
                          <div class="set-tie" *ngIf="isSetOneTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input type="text" formControlName="setOneTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneTieA.valid, 'error': formGroup.controls.setOneTieA.touched && formGroup.controls.setOneTieA.invalid}">
                            <input type="text" formControlName="setOneTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneTieB.valid, 'error': formGroup.controls.setOneTieB.touched && formGroup.controls.setOneTieB.invalid}">
                          </div>
                          <div class="set-label">Set One</div>
                        </div>
                        <div class="set set-two">
                          <div class="set-not-tie">
                            <input type="text" formControlName="setTwoA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoA.valid, 'error': formGroup.controls.setTwoA.touched && formGroup.controls.setTwoA.invalid}">
                            <input type="text" formControlName="setTwoB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoB.valid, 'error': formGroup.controls.setTwoB.touched && formGroup.controls.setTwoB.invalid}">
                          </div>
                          <div class="set-tie" *ngIf="isSetTwoTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input type="text" formControlName="setTwoTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoTieA.valid, 'error': formGroup.controls.setTwoTieA.touched && formGroup.controls.setTwoTieA.invalid}">
                            <input type="text" formControlName="setTwoTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoTieB.valid, 'error': formGroup.controls.setTwoTieB.touched && formGroup.controls.setTwoTieB.invalid}">
                          </div>
                          <div class="set-label">Set Two</div>
                        </div>
                        <div *ngIf="isSetThree" class="set set-three">
                          <div class="set-not-tie">
                            <input type="text" formControlName="setThreeA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeA?.valid, 'error': formGroup.controls.setThreeA?.touched && formGroup.controls.setThreeA?.invalid}">
                            <input type="text" formControlName="setThreeB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeB?.valid, 'error': formGroup.controls.setThreeB?.touched && formGroup.controls.setThreeB?.invalid}">
                          </div>
                          <div class="set-tie" *ngIf="isSetThreeTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input type="text" [formControl]="formGroup.controls['setThreeTieA']" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeTieA?.valid, 'error': formGroup.controls.setThreeTieA?.touched && formGroup.controls.setThreeTieA?.invalid}">
                            <input type="text" [formControl]="formGroup.controls['setThreeTieB']" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeTieB?.valid, 'error': formGroup.controls.setThreeTieB?.touched && formGroup.controls.setThreeTieB?.invalid}">
                          </div>
                          <div class="set-label">Set Three</div>
                        </div>
                      </form>
                    </div>
                  </div>
    
                  <div  class="player"
                        [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">
                      {{ usersData.teamB.firstName }} {{ usersData.teamB.lastName }}
                      <ng-container *ngIf="matchOutcome && matchOutcome.type !== '' && matchOutcome.team !== ''">
                        <span class="winner">{{ matchOutcome.team === 'teamA' ? '- Winner' : '' }}</span>
                      </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="deniedScores">
                  <hr />
                  <div class="incomplete-match-check">
                    <input
                      type="checkbox"
                      [(ngModel)]="isIncompleteMatch"
                      (change)="checkIncompleteMatch(isIncompleteMatch)"
                      id="incompleteMatchCheck"
                    />
                    <label for="incompleteMatchCheck">This match was incomplete</label>
                  </div> 
                  <div class="match-outcome-wrapper" *ngIf="isIncompleteMatch">
                    <div class="match-outcome">
                      <div *ngIf="!isFormFilled" class="outcome-wrapper walkover">
                        <p>Walkover</p>
                        <select #matchOutcome class="form-control" (change)="setMatchOutcome('walkover', $event)">
                          <option value="">Select</option>
                          <option [value]="'teamA'">{{ usersData.teamA.firstName }}</option>
                          <option [value]="'teamB'">{{ usersData.teamB.firstName }}</option>
                        </select>
                      </div>
                      <div *ngIf="isFormFilled" class="outcome-wrapper retired">
                        <p>Retired</p>
                        <select #matchOutcome class="form-control" (change)="setMatchOutcome('retired', $event)">
                          <option value="">Select</option>
                          <option [value]="'teamA'">{{ usersData.teamA.firstName }}</option>
                          <option [value]="'teamB'">{{ usersData.teamB.firstName }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </ng-container> 

                <div class="match-outcome-text" *ngIf="fixtureData.outcome?.matchOutcome">
                  Also, <strong>{{ usersData[fixtureData.scoreBy].firstName }}</strong> says that you
                  <span *ngIf="fixtureData.outcome.matchOutcome.type === 'retired'">
                    were <strong>retired</strong> during the match
                  </span>
                  <span *ngIf="fixtureData.outcome.matchOutcome.type === 'walkover'">did not show up.</span>
                  And so <strong>{{ usersData[fixtureData.scoreBy].firstName }}</strong> wins this match
                </div>
                
                <ng-container *ngIf="!isScoreUploadLoading">
                  <div *ngIf="isAskUser">
                    <p>Do you agree with these scores? </p>
                    <div class="join-buttons">
                      <button type="button" (click)="confirmScores()">Yes</button>
                      <button type="button" (click)="denyScores()">No</button>
                    </div>
                  </div>
                  <div *ngIf="isUserAgreed">
                    <div class="alert alert-success">Your scores have been confirmed and your match is completed. Thank you for playing.</div>

                    <div *ngIf="isPlayerRating">
                      <ng-container *ngTemplateOutlet="playerRatingElement"></ng-container>
                    </div>

                    <div *ngIf="!isPlayerRating" class="go-to-profile">
                      <button class="go-to-home" type="button" (click)="goToTournament()">Go To Tournament</button>
                    </div>
                  </div>

                  <div *ngIf="deniedScores">
                    <p>
                      You can edit the scores now. Once you edit and confirm, an email will be sent to
                      <strong> {{ usersData[fixtureData.scoreBy].firstName }} </strong> to confirm again.
                    </p>
                    <div class="join-buttons">
                      <button type="button" (click)="addScore(formGroup)">Confirm</button>
                      <button type="button" (click)="goToPreviousScores()">Go Back</button>
                    </div>
                  </div>
  
                  <div *ngIf="isNewScoreUploaded">
                    <div class="alert alert-success">You have successfully uploaded new scores</div>
                    <p>
                      An email has been sent to
                      <strong> {{ usersData[fixtureData.scoreBy].firstName }} {{ usersData[fixtureData.scoreBy].lastName }} </strong>
                      to confirm again. If <strong> {{ usersData[fixtureData.scoreBy].firstName }} </strong>denies these scores
                      then this issue will be resolved by the admin.
                    </p>
                    <div *ngIf="isPlayerRating">
                      <ng-container *ngTemplateOutlet="playerRatingElement"></ng-container>
                    </div>
                    <div *ngIf="!isPlayerRating" class="go-to-profile">
                      <button class="go-to-home" type="button" (click)="goToTournament()">Go To Tournament</button>
                    </div>
                  </div>

                </ng-container>
                
                <ng-container *ngIf="isScoreUploadLoading">
                  <div class="loader">
                    <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
                  </div>
                </ng-container>
                
              </div>
            </div>
  
            <div class="verify" *ngIf="fixtureState === 'disputed-pending'">
              <div *ngIf="isDataLoaded">
                <p *ngIf="matchType === 'knockout'">
                  <strong> {{ usersData[fixtureData.scoreBy].firstName }} {{ usersData[fixtureData.scoreBy].lastName }} </strong>
                  has denied your scores and uploaded new scores of your match in<strong> {{ roundData.roundName }} </strong> <strong> {{ tournamentData.title }} </strong> 
                </p>
                <p *ngIf="matchType === 'box'">
                  <strong> {{ usersData[fixtureData.scoreBy].firstName }} {{ usersData[fixtureData.scoreBy].lastName }} </strong>
                  has denied your scores and uploaded these scores again of your match in<strong> {{ tournamentData.title }} </strong> 
                </p>
                <p *ngIf="matchType === 'one-on-one'">
                  <strong> {{ usersData[fixtureData.scoreBy].firstName }} {{ usersData[fixtureData.scoreBy].lastName }} </strong>
                  has denied your scores and uploaded these scores again of your match in<strong> One On One. </strong>
                </p>
  
                
                <ng-container *ngIf="!(fixtureData.outcome?.newMatchOutcome?.type === 'walkover')">
                  <div  class="player"
                        [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">      
                        {{ usersData.teamA.firstName }} {{ usersData.teamA.lastName }}
                  </div>
    
                  <div class="input-fields-scores">
                    <div class="fields-scores-inner">
                      <form [formGroup]="formGroup"> 
                        <div class="set set-one">
                          <div class="set-not-tie">
                            <input type="text" formControlName="setOneA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneA.valid, 'error': formGroup.controls.setOneA.touched && formGroup.controls.setOneA.invalid}">
                            <input type="text" formControlName="setOneB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneB.valid, 'error': formGroup.controls.setOneB.touched && formGroup.controls.setOneB.invalid}">
                          </div>
                          <div class="set-tie" *ngIf="isSetOneTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input type="text" formControlName="setOneTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneTieA.valid, 'error': formGroup.controls.setOneTieA.touched && formGroup.controls.setOneTieA.invalid}">
                            <input type="text" formControlName="setOneTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneTieB.valid, 'error': formGroup.controls.setOneTieB.touched && formGroup.controls.setOneTieB.invalid}">
                          </div>
                          <div class="set-label">Set One</div>
                        </div>
                        <div class="set set-two">
                          <div class="set-not-tie">
                            <input type="text" formControlName="setTwoA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoA.valid, 'error': formGroup.controls.setTwoA.touched && formGroup.controls.setTwoA.invalid}">
                            <input type="text" formControlName="setTwoB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoB.valid, 'error': formGroup.controls.setTwoB.touched && formGroup.controls.setTwoB.invalid}">
                          </div>
                          <div class="set-tie" *ngIf="isSetTwoTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input type="text" formControlName="setTwoTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoTieA.valid, 'error': formGroup.controls.setTwoTieA.touched && formGroup.controls.setTwoTieA.invalid}">
                            <input type="text" formControlName="setTwoTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoTieB.valid, 'error': formGroup.controls.setTwoTieB.touched && formGroup.controls.setTwoTieB.invalid}">
                          </div>
                          <div class="set-label">Set Two</div>
                        </div>
                        <div *ngIf="isSetThree" class="set set-three">
                          <div class="set-not-tie">
                            <input type="text" formControlName="setThreeA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeA?.valid, 'error': formGroup.controls.setThreeA?.touched && formGroup.controls.setThreeA?.invalid}">
                            <input type="text" formControlName="setThreeB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeB?.valid, 'error': formGroup.controls.setThreeB?.touched && formGroup.controls.setThreeB?.invalid}">
                          </div>
                          <div class="set-tie" *ngIf="isSetThreeTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input type="text" formControlName="setThreeTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeTieA?.valid, 'error': formGroup.controls.setThreeTieA?.touched && formGroup.controls.setThreeTieA?.invalid}">
                            <input type="text" formControlName="setThreeTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeTieB?.valid, 'error': formGroup.controls.setThreeTieB?.touched && formGroup.controls.setThreeTieB?.invalid}">
                          </div>
                          <div class="set-label">Set Three</div>
                        </div>
                      </form>
                    </div>
                  </div>
  
                  <div  class="player"
                        [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">
                        {{ usersData.teamB.firstName }} {{ usersData.teamB.lastName }}
                  </div>
                </ng-container>

                <div class="match-outcome-text" *ngIf="fixtureData.outcome?.newMatchOutcome">
                  Also, <strong>{{ usersData[fixtureData.scoreBy].firstName }}</strong> says that you
                  <span *ngIf="fixtureData.outcome.newMatchOutcome.type === 'retired'">
                    were <strong>retired</strong> during the match.
                  </span>
                  <span *ngIf="fixtureData.outcome.newMatchOutcome.type === 'walkover'">did not show up.</span>
                  And so <strong>{{ usersData[fixtureData.scoreBy].firstName }}</strong> wins this match.
                </div>
                
                <ng-container *ngIf="isScoreUploadLoading">
                  <div class="loader">
                    <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
                  </div>
                </ng-container>

                <ng-container *ngIf="!isScoreUploadLoading">
                  <div *ngIf="isAskUser">
                    <p> Do you agree with these scores? If not, then this issue will be handled by the admin.</p>
                    <div class="join-buttons">
                      <button type="button" (click)="agree()">Yes</button>
                      <button type="button" (click)="notAgree()">No</button>
                    </div>
                  </div>

                  <div *ngIf="isUserAgreed">
                    <p>Your scores have been confirmed and your match is completed. Thank you for playing.</p>
                    <div class="go-to-profile">
                      <button class="go-to-home" type="button" (click)="goToTournament()">Go To Tournament</button>
                    </div>
                  </div>

                  <div *ngIf="deniedScores">
                    <div>
                      Your match is completed and you both have disagreed with the scores. This issue will now be handled by the admin.
                    </div>
                    <p></p>
                    <p>Thank you playing.</p>
                    <div class="go-to-profile">
                      <button class="go-to-home" type="button" (click)="goToTournament()">Go To Tournament</button>
                    </div>
                  </div>
                </ng-container>
  
              </div>
            </div>
  
            <div class="verify" *ngIf="fixtureState === 'disputed'">
              <p>Your scores have been uploaded and you both have disagreed with the scores. This issue will now be handled by the admin.</p>
              <div class="go-to-profile">
                <button class="go-to-home" type="button" (click)="goToTournament()">Go To Tournament</button>
              </div>
            </div>

            <div class="verify" *ngIf="fixtureState === 'completed'">
              <div class="alert alert-success">
                Your match has been completed. Thank you for playing.
              </div>
              <div class="go-to-profile">
                <button class="go-to-home" type="button" (click)="goToTournament()">Go To Tournament</button>
              </div>
            </div>
          </div>

        </ng-container>
  
        <ng-container *ngIf="isTokenVerified === false">
          <div style="padding: 30px;">
            Invalid URL
          </div>
        </ng-container>
      </ng-container>

      <ng-template #notLoggedIn>
        <div class="not-logged-in" style="padding: 30px">
          You are not logged in.
          <a (click)="router.navigate(['tennis/users/login', {redirectTo: this.router.url}])">
            Click here to login and view this page</a> 
        </div>
      </ng-template>
      
    </div>
  </div>
</div>

<ng-template #playerRatingElement>
  <p><strong>One Last Step</strong></p>
  <p class="text">Please rate your opponent in terms of </p>
  <ul>
    <li>Pre-Match interactions</li>
    <li>On time or late</li>
    <li>Respectful</li>
    <li>Fair line calls</li>
    <li>Sportsmanship</li>
    <li>Able to play hard but still be fair</li>
  </ul>
    
  <p>
    You rating is crucial for us to know how the player performed. And it will be beneficial for other players to see the
    the overall performance of the player.
  </p>
  <div class="player-rating" style="text-align: center;">
    <star-rating 
      value={{playerRating}}
      totalstars={{totalStars}}
      checkedcolor="#6fbd22"
      uncheckedcolor="#d6d6d6"
      size="35px"
      readonly="false"
      (rate)="onRate($event)">
    </star-rating>

    <p style="font-weight: 500;"> 
      {{
        playerRating === 1 ? 'Bad' :
        playerRating === 2 ? 'Not so good' :
        playerRating === 3 ? 'Okay' :
        playerRating === 4 ? 'Good' :
        playerRating === 5 ? 'Excellent' : 'Error'
      }}
    </p>
  </div>

  <div class="join-buttons">
    <button type="button" class="btn-cancel" (click)="confirmRating()">Confirm</button>
  </div>
</ng-template>
