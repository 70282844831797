import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-tennis-challenges-ongoing',
  templateUrl: './player-tennis-challenges-ongoing.component.html',
  styleUrls: ['./player-tennis-challenges-ongoing.component.scss']
})
export class PlayerTennisChallengesOngoingComponent implements OnInit {

  hasMatches: boolean;
  rawMatches = [];
  matches = [];
  isLoading: boolean = true;
  currMatch: any;
  isRejectModalOpen: boolean;
  isModalLoading: boolean;

  constructor(private firestore: AngularFirestore, private authService: AuthService) { }

  gatherData(matches) {
    let promises = [];
    matches.forEach(el => {
      let tempUserObj;
      tempUserObj = this.gatherUserData(el.receiverId, el.requestId);
      promises.push(tempUserObj)
    })
    
    Promise.all(promises)
    .then((res) => {
      this.matches = res.slice();
      this.isLoading = false;
    })
    .catch(error => {
      console.log(error)
    })
  }

  gatherUserData(userId, requestId) {
    return this.firestore.collection('Users').doc(userId).get().toPromise()
    .then(res => {
      let userObj: any = {};
      userObj.firstName = res.data().firstName;
      userObj.lastName = res.data().lastName;
      userObj.id = userId;
      userObj.requestId = requestId;
      return userObj;
    })
    .catch(error => {
      console.log(error)        
    })
  }

  openModal(match, modalType) {
    this.currMatch = match;

    if(modalType === 'reject') {
      document.body.classList.add('modal-open');
      this.isRejectModalOpen = true;
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isRejectModalOpen = false;
  }
    
  reject(requestId) {
    this.isModalLoading = true;
    this.firestore.collection('ChallengeRequests').doc(requestId).delete()
    .then(res => {
      this.closeModal();
      this.isModalLoading = false;
      this.isLoading = true;
      this.ngOnInit()
    })
    .catch(error => {
      console.log(error)
    })
  }
  
  ngOnInit() {
    this.firestore.collection('ChallengeRequests', ref => ref
    .where('senderId', '==', this.authService.user.uid)
    .where('state', '==', 'pending')).get().toPromise()
    .then(res => {
      if(res.docs.length > 0) {
        this.rawMatches = this.rawMatches.concat(res.docs)
        this.hasMatches = true;
        let matches = [];
        matches = this.rawMatches.map(el => {
          let matchObj: any  = {};
          matchObj = el.data();
          matchObj.requestId = el.id;
          return matchObj;
        })

        this.gatherData(matches);
      } else {
        this.isLoading = false;
        this.hasMatches = false;
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

}
