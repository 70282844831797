<div class="current-fixtures">
  <h2 class="table-header">My Pending Fixtures</h2>

  <div class="challenges-table" *ngIf="currentFixtures.length > 0; else noMatches">
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Match Type</th>
          <!-- <th>Category</th> -->
          <!-- <th>Opponent</th> -->
          <th>Curr. Round</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let currentFixture of currentFixtures; let i = index">
          <td>{{ currentFixture.matchTitle }}</td>
          <td>{{ currentFixture.matchType }}</td>
          <!-- <td>{{ currentFixture.category }}</td> -->
          <!-- <td>{{ currentFixture.fixture.opponent.name }}</td> -->
          <td>{{ currentFixture.currentRound ? currentFixture.currentRound : '-' }}</td>
          <td>
            <button 
              *ngIf="currentFixture.frontEndState !== 'score-uploaded'"
              (click)="openScoreModal(currentFixture)">Add Score</button>
            <span
              *ngIf="currentFixture.frontEndState === 'score-uploaded'"
              class="score-uploaded">Score uploaded</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #noMatches>
  <div class="alert">You don't have any current fixtures</div>
</ng-template>

<app-upload-scores 
  [userObj]="userObj"
  [isScoreModalOpen]="isScoreModalOpen"
  [fixtureData]="selectedFixture"
  (isScoreModalOpenParent)="isScoreModalOpen = false"
  (scoreUploaded)="currentFixtures[selectedFixture.id].frontEndState = 'score-uploaded'"
></app-upload-scores>
