<div class="container-fluid">
  <app-shade></app-shade>
  <app-page-heading pageHeading="Players"></app-page-heading>
</div>
<div class="container-white">
  <div class="container">
    <div class="clearfix"></div>
    <div class="gray-table-container">
      <div class="top-players">
        <div class="note">Note: You can click on the player to view profile</div>
        <hr>
        <div class="filters">
          <h4>Filters</h4>
          <form [formGroup]="filterForm">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3">
                <label>Gender</label>
                <select class="form-control" formControlName="gender" (change)="filterByGender(filterForm.value.gender)">
                  <option value="">All</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <label>Wheelchair</label>
                <select class="form-control" formControlName="wheelChair" (change)="filterByWheelChair(filterForm.value.wheelChair)">
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <label>Tier</label>
                <select class="form-control" formControlName="tier" (change)="filterByTier(filterForm.value.tier)">
                  <option value="">All</option>
                  <option value="600">Tier 1 Beginners</option>
                  <option value="1200">Tier 2 Intermediate</option>
                  <option value="1800">Tier 3 Advanced</option>
                  <option value="3500">Tier 4 Pro level</option>
                </select>
              </div>
            </div>
          </form>
        </div>

        <div class="top-players-inner">
          <div class="heading">Players</div>
          <div class="players-table">
            <div class="header">
              <div class="s-no">S.No</div>
              <div class="name">Player</div>
              <div class="victor-points">Viictor Points</div>
              <div class="wins">Wins</div>
              <div class="earnings">Earnings</div>
              <div class="challenge">Available For Challenge</div>
            </div>
            <div class="players" [ngStyle]="{'display': !isLoading && isDataLoaded ? 'block' : 'none'}">
              <div class="player" (click)="router.navigate(['/tennis/player/', player.userId])" *ngFor="let player of players$ | async; let playerss; let i = index;">
                <div class="s-no">{{ i + 1 }}</div>
                <div class="name">{{ player.name }}</div>
                <div class="victor-points">{{ player.victorPoints }}</div>
                <div class="wins">{{ player.wins }}</div>
                <div class="earnings">${{ player.earnings }}</div>
                <div class="challenge">{{ player.availableForChallenge ? 'Yes' : 'No '}}</div>
              </div>
              <div class="alert no-result" *ngIf="!isPlayersFound">No results, try clearing filters</div>
            </div>

            <div *ngIf="isLoading" class="loader">
              <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
            </div>
            <div *ngIf="isError" class="error">
              <div class="alert alert-danger">Could not load the data. Please refresh the page</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-newsletter></app-newsletter>
</div>
