import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-challenge',
  templateUrl: './create-challenge.component.html',
  styleUrls: ['./create-challenge.component.scss']
})
export class CreateChallengeComponent implements OnInit {

  displayNone: boolean = false; 

  constructor(private router: Router) { }

  goToCreateTournament() {
    this.router.navigate(['tennis/user/create-tournament'])
  }

  ngOnInit() {
    if(this.router.url === '/tennis/user/create-tournament') {
      this.displayNone = true;  
    }
  }

}
