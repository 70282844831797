<div class="matches">
  <div *ngIf="isLoading" class="loader">
    <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
  </div>
  <div *ngIf="!isLoading">
    <div *ngIf="hasMatches else noMatches" class="matches-inner">
      <div class="match" *ngFor="let match of matches; let i = index">
        <div class="block-1">
          <div class="name"><label>Name:</label> 
            <a [routerLink]="['/tennis/player/', match.teamA.id !== authService.user.uid ? match.teamA.id : match.teamB.id]">
              {{ match.teamA.id !== authService.user.uid ? match.teamA.firstName +' '+ match.teamA.lastName : match.teamB.firstName +' '+ match.teamB.lastName}}
            </a> (Click to view profile)
          </div>
          <div class="location"><label>Location:</label> Perth (<span class="view-map">View on map</span>)</div>
        </div>
        <div class="block-2">
          <a class="button" [routerLink]="['/tennis/tournaments/', match.teamA.matchId]">See Details</a>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noMatches>
    <div class="alert">
      Currently you have no accepted matches.
    </div>
  </ng-template>
</div>