import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-double-matches',
  templateUrl: './double-matches.component.html',
  styleUrls: ['./double-matches.component.scss']
})
export class DoubleMatchesComponent implements OnInit {

  
  @Input() userObj;
  tournaments: any[];
  hasDoubleMatches: boolean;
  
  constructor(private router: Router,
    private firestore: AngularFirestore) { }

  async getDoublesMatches() {
    try {
      let teams = await this.firestore.collection('Teams', ref => ref.
                  where('members', 'array-contains', { email: this.userObj.email, id: this.userObj.id }))
                  .get().toPromise()
      if(teams.docs.length > 0) {
        let teamsData = await Promise.all(teams.docs.map(async el => {
          let teamHistory = await el.ref.collection('history').get()
          if(teamHistory.docs.length > 0) {
            let allMatches: any[] = await Promise.all(teamHistory.docs.map(async el2 => {
              let matchId = el2.data().matchRef.split('/')[2];
              let match = await this.firestore.collection('Matches').doc(matchId)
                          .get().toPromise()
              if(match.data().category === 'doubles') {
                let fixtureData;
                if(match.data().matchType === 'one-on-one' || match.data().matchType === 'box') {
                  let fixture = el2.data().matchRef.split('/')[4];
                  fixtureData = await this.firestore.collection('Matches').doc(matchId)
                                    .collection('fixtures').doc(fixture).get().toPromise()
                } else if(match.data().matchType === 'knockout') {
                  let roundType = el2.data().matchRef.split('/')[3]
                  let round = el2.data().matchRef.split('/')[4]
                  let fixture = el2.data().matchRef.split('/')[6];
                  fixtureData = await this.firestore.collection('Matches').doc(matchId).collection(roundType)
                                    .doc(round).collection('fixtures').doc(fixture).get().toPromise()
                }
                return {
                  teamId: el.id,
                  fixtureData: fixtureData.data(),
                  title: match.data().matchType === 'one-on-one' ? 'One On One' : match.data().title,
                  location: match.data().location ? match.data().location : 'No Location',
                  prize: match.data().entryFee ? match.data().entryFee * match.data().players.length * 0.85 : 'No Prize',
                  matchId: matchId
                }
              }
            }))
            return allMatches.filter(Boolean)[0];
          }
        }))
        if(teamsData.filter(Boolean).length > 0) {
          this.tournaments = teamsData.filter(Boolean)
          this.hasDoubleMatches = true;
        } else {
          this.hasDoubleMatches = false;
        }
      } else {
        this.hasDoubleMatches = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

  ngOnInit(): void {
    this.getDoublesMatches();
  }

}
