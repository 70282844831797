<app-registration-template>
  <div class="form">
    <div class="form-header">Sign Up</div>
    <div *ngIf="!success" class="form-body">
      <form [formGroup]="formGroup">
        <div class="input-fields">
          <label>First Name</label>
          <input class="form-control" formControlName="firstName" type="text" placeholder="Name" [ngClass]="{'valid': formGroup.controls.firstName.valid, 'error': formGroup.controls.firstName.invalid && (formGroup.controls.firstName.touched || submitAttempt)}">
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.firstName.invalid">Name is required.</p>
          <label>Surname</label>
          <input class="form-control" formControlName="lastName" type="text" placeholder="Surname" [ngClass]="{'valid': formGroup.controls.lastName.valid, 'error': formGroup.controls.lastName.invalid && (formGroup.controls.lastName.touched || submitAttempt)}">
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.lastName.invalid">Surname is required.</p>
          <label>Email</label>
          <input class="form-control" formControlName="email" type="email" placeholder="E-mail" [ngClass]="{'valid': formGroup.controls.email.valid, 'error': formGroup.controls.email.invalid && (formGroup.controls.email.touched || submitAttempt)}">
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.email.invalid && formGroup.controls.email.value === ''">E-mail is required.</p>
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.email.invalid && formGroup.controls.email.value !== ''">Invalid e-mail format.</p>

          <label>Date Of Birth</label>
          <input formControlName="dateOfBirth" type="date" class="date form-control" placeholder="YYYY-MM-DD" [ngClass]="{'valid': formGroup.controls.dateOfBirth.valid, 'error': formGroup.controls.dateOfBirth.invalid && (formGroup.controls.dateOfBirth.touched || submitAttempt)}"> 
          <ng-container *ngIf="formGroup.controls.dateOfBirth.invalid">
            <p class="error-message" *ngIf="submitAttempt && formGroup.controls.dateOfBirth.errors.required">Date of birth is required.</p>
            <p class="error-message" *ngIf="submitAttempt && formGroup.controls.dateOfBirth.errors.pattern">Invalid date format. Must be YYYY-MM-DD.</p>
          </ng-container>

          <div *ngIf="isUnderage">
            <label>Parent's Email</label>
            <input formControlName="parentEmail" type="email" class="form-control" placeholder="Parent's Email" [ngClass]="{'valid': formGroup.controls.parentEmail.valid, 'error': formGroup.controls.parentEmail.invalid && (formGroup.controls.parentEmail.touched || submitAttempt)}"> 
            <p class="error-message" *ngIf="submitAttempt && formGroup.controls.parentEmail.invalid && formGroup.controls.parentEmail.value === ''">Parent's email is required.</p>
            <p class="error-message" *ngIf="submitAttempt && formGroup.controls.parentEmail.invalid && formGroup.controls.parentEmail.value !== ''">Invalid e-mail format.</p>
          </div>

          <label>Mobile Number</label>
          <input class="form-control" formControlName="phone" type="number" placeholder="Mobile" [ngClass]="{'valid': formGroup.controls.phone.valid, 'error': formGroup.controls.phone.invalid && (formGroup.controls.phone.touched || submitAttempt)}">
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.phone.invalid">Mobile is required.</p>
          <label>Password</label>
          <input class="form-control" formControlName="password" type="password" placeholder="Password" [ngClass]="{'valid': formGroup.controls.password.valid, 'error': formGroup.controls.password.invalid && (formGroup.controls.password.touched || submitAttempt), 'error-password': formGroup.controls.confirmPassword.value !== formGroup.controls.password.value && submitAttempt}">
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.password.invalid && formGroup.controls.password.value === ''">Password is required.</p>
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.password.invalid && formGroup.controls.password.value !== ''">Password should be atleast 6 characters.</p>
          <label>Confirm Password</label>
          <input class="form-control" formControlName="confirmPassword" type="password" placeholder="Confirm Password" [ngClass]="{'valid': formGroup.controls.confirmPassword.valid, 'error': formGroup.controls.confirmPassword.invalid && (formGroup.controls.confirmPassword.touched || submitAttempt), 'error-password': formGroup.controls.confirmPassword.value !== formGroup.controls.password.value && submitAttempt}">
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.confirmPassword.invalid">Confirm Password is required.</p>
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.confirmPassword.valid && formGroup.controls.confirmPassword.value !== formGroup.controls.password.value">Passwords do not match.</p>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="acceptTerms" id="defaultCheck1">
          <label class="form-check-label" for="defaultCheck1">
            I confirm I have read, understood and accept <a [routerLink]="['/registration-terms']">registration terms</a>
          </label>
          
          <div *ngIf="loading" class="loader-gif">
            <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
          </div>
        </div>

        <p *ngIf="errorMessage" class="error-message backend"> {{ errorMessage }} </p>
        <p class="error-message backend" *ngIf="submitAttempt && formGroup.controls.acceptTerms.invalid">You need to accept the terms before proceeding.</p>

        <button *ngIf="!loading" class="btn btn-submit" (click)="signup(formGroup)">Create</button>
      </form>
    </div>
    <div *ngIf="success" class="form-body success">
      <p>Thanks for Registering. Your Account has been created. 
        A verification email has been sent to your email. 
        Please click on the link in the email to verify your account.
      </p>
      <p *ngIf="isUnderage">
        Since you're under 12 years of age, an approval email has also been sent to your parent or guardian.
        You may ask them to check their email and grant their approval.
      </p>
      <button class="btn btn-submit" (click)="goToHome()">Go TO Home Page</button>
    </div>
  </div>  
</app-registration-template>