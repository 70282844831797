<div class="matches">
  <div *ngIf="isLoading" class="loader">
    <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
  </div>
  <div *ngIf="!isLoading">
    <div *ngIf="hasTeams else noMatches" class="matches-inner">
      <div class="match" *ngFor="let team of teams; let i = index">
        <div class="block-1">
          <div class="name"><label>Name:</label> 
              {{ team.name }}
          </div>
          <div class="location"><label>Location:</label> Perth (<span class="view-map">View on map</span>)</div>
        </div>
        <div class="block-2">
          <button type="button" class="button-reject" (click)="openModal(team, 'reject')">Disband Team</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noMatches>
    <div class="alert">
      Currently you have no teams.
    </div>
  </ng-template>

  <section *ngIf="isRejectModalOpen" class="modal">
    <div class="modal-background">
      <div class="modal-body">
        <p>Would you like to disband your team: <strong>{{ currTeam.name }}</strong>? </p>
        <div *ngIf="isModalLoading" class="loader">
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
        </div>
        <div *ngIf="!isModalLoading"class="join-buttons">
          <button type="button" (click)="reject(currTeam)">Yes</button>
          <button type="button" (click)="closeModal()">No</button>
        </div>
      </div>
    </div>
  </section>
</div>