import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { first } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-player-notifications',
  templateUrl: './player-notifications.component.html',
  styleUrls: ['./player-notifications.component.scss']
})
export class PlayerNotificationsComponent implements OnInit {
  isNotifications: boolean;
  notifications: any[];
  @Input() userObj;

  constructor(public router: Router,
              private firestore: AngularFirestore,) { }

  getNotificationTime(timestamp) {
    let monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    let notifyTimestamp = timestamp;
    let currTimestamp = Date.now();
    let differenceInMs = currTimestamp - notifyTimestamp;
    let resultDate = Math.ceil(differenceInMs/86400000); // Milliseconds in a day: 86400000
    let notifyDate: any = new Date(timestamp)
    let currDateTime: any = new Date(Date.now());
    
    if(resultDate <= 1) {
      if(notifyDate.getDate() === currDateTime.getDate()) {
        let notifyHoursDifference = 24 - notifyDate.getHours();
        let currHoursDifference = 24 - currDateTime.getHours();
        let hoursDifference = Math.abs(notifyHoursDifference - currHoursDifference);

        if(hoursDifference <= 1) {
          if(notifyDate.getHours() === currDateTime.getHours()) {
            return `${Math.abs(notifyDate.getMinutes() - currDateTime.getMinutes())} minute(s) ago`
          } else {
            return `${Math.abs(60 - Math.abs((notifyDate.getMinutes() - currDateTime.getMinutes())))} minute(s) ago`
          }
        } else {
          return `${hoursDifference} hours ago`
        }
      } else {
        return `${notifyDate.getDate()} ${monthList[notifyDate.getMonth()]}`        
      }
    } else {
      return `${notifyDate.getDate()} ${monthList[notifyDate.getMonth()]}`
    }
  }

  goToNotificationLink(redirectLink, index) {
    if(redirectLink) {
      if(redirectLink !== '') {
        console.log(redirectLink)
        this.router.navigateByUrl(redirectLink)
        .then(res => {
          this.notifications[index].isRead = true;
          return  this.firestore.collection('Users').doc(this.userObj.user.uid)
                  .collection('notifications').doc(this.notifications[index].id).update({
                    isRead: true,
                  })
        })
        .then(res => {
          console.log("isRead Updated")
        })
        .catch(error => {
          console.log(error)
        })
      }
    } else {
      this.notifications[index].isRead = true;
      this.firestore.collection('Users').doc(this.userObj.user.uid)
      .collection('notifications').doc(this.notifications[index].id).update({
        isRead: true,
      })
      .then(res => { console.log("isRead Updated") })
      .catch(error => console.log(error))
    } 
  }

  async ngOnInit() {
    try {
      let notificatonData = await this.firestore.collection('Users').doc(this.userObj.id)
                            .collection('notifications', ref => ref.orderBy('timestamp', 'desc'))
                            .get().toPromise()
      if(notificatonData.docs.length > 0) {
        this.isNotifications = true;
        let notifications = []
        notifications = notificatonData.docs.map(el => {
          let notification: any = {}
          notification = el.data()
          notification.id = el.id 
          return notification
        })
        this.notifications = notifications;
      } else {
        this.isNotifications = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

}
