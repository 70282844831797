<div class="users">
  <div class="users-inner">
    <h3 class="top-heading">Disputes</h3>
    <div class="loader" *ngIf="isLoading">
      <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
    </div>
    <div class="disputes-list" *ngIf="isDisputesFound && !isLoading; else noDisputes">
      <ng-container *ngIf="!currDispute">
        <h4>Unresolved</h4>
        <ul>
          <ng-container *ngIf="disputesDisputed.length > 0; else noDisputes">
            <li *ngFor="let dispute of disputesDisputed; let i = index;">
              <div class="s-no">{{ i+1 }}</div>
              <div class="content">
                <div class="details">  
                  <div class="name">Match Title:<br>{{ dispute.matchTitle }}</div>        
                  <div class="status">Status:<br>{{ dispute.state }}</div>        
                  <div class="date">Date:<br>{{ getFormattedDate(dispute.timestamp) }}</div>
                </div>
                <div class="button">
                  <button (click)="viewDispute(dispute)">View Details</button>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
        <hr>
        <h4>Resolved</h4>
        <ul>
          <ng-container *ngIf="disputesResolved.length > 0; else noDisputes">
            <li *ngFor="let dispute of disputesResolved; let i = index;">
              <div class="s-no">{{ i+1 }}</div>
              <div class="content">
                <div class="details">
                  <div class="name">Match Title:<br>{{ dispute.matchTitle }}</div>        
                  <div class="status">Status:<br>{{ dispute.state }}</div>        
                  <div class="date">Date:<br>{{ getFormattedDate(dispute.timestamp) }}</div>
                </div>
                <div class="button">
                  <button (click)="viewDispute(dispute)">View Details</button>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <div *ngIf="currDispute" class="curr-dispute">
        <div class="go-back">
          <button (click)="currDispute = undefined; ngOnInit()">Go Back</button>
        </div>
        <hr>
        <div class="content">
          <div class="name">Match Title:<br><a [routerLink]="['/tennis/tournaments/'+currDispute.matchId]">{{ currDispute.matchTitle }}</a></div>        
          <div class="status">Status:<br>{{ currDispute.state }}</div>        
          <div class="date">Date:<br>{{ getFormattedDate(currDispute.timestamp) }}</div>
        </div>
        <hr>
        <div class="scores">
          <div  class="player"
                *ngIf="currDispute.category === 'singles'"
                [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie, 'singles': currDispute.category === 'singles'}"
                [routerLink]="['/tennis/player/'+currDispute.fixtureData.teamA.id]">
                {{ currDispute.fixtureData.teamA.name }}
          </div>
          <div  class="player"
          *ngIf="currDispute.category === 'doubles'"
                [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie, 'doubles': currDispute.category === 'doubles'}"
                >
                {{ currDispute.fixtureData.teamA.name }}
                <div [routerLink]="['/tennis/player/'+currDispute.fixtureData.teamA.playerOne.id]">{{currDispute.fixtureData.teamA.playerOne.name}}</div>
                <div [routerLink]="['/tennis/player/'+currDispute.fixtureData.teamA.playerTwo.id]">{{currDispute.fixtureData.teamA.playerTwo.name}}</div>
          </div>

          <div class="input-fields-scores">
            <div class="fields-scores-inner">
              <form [formGroup]="formGroup"> 
                <div class="set set-one">
                  <div class="set-not-tie">
                    <input type="text" formControlName="setOneA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneA.valid, 'error': formGroup.controls.setOneA.touched && formGroup.controls.setOneA.invalid}">
                    <input type="text" formControlName="setOneB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneB.valid, 'error': formGroup.controls.setOneB.touched && formGroup.controls.setOneB.invalid}">
                  </div>
                  <div class="set-tie" *ngIf="isSetOneTie">
                    <div class="tie-breaker">Tie Breaker</div>
                    <input type="text" formControlName="setOneTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneTieA.valid, 'error': formGroup.controls.setOneTieA.touched && formGroup.controls.setOneTieA.invalid}">
                    <input type="text" formControlName="setOneTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setOneTieB.valid, 'error': formGroup.controls.setOneTieB.touched && formGroup.controls.setOneTieB.invalid}">
                  </div>
                  <div class="set-label">Set One</div>
                </div>
                <div class="set set-two">
                  <div class="set-not-tie">
                    <input type="text" formControlName="setTwoA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoA.valid, 'error': formGroup.controls.setTwoA.touched && formGroup.controls.setTwoA.invalid}">
                    <input type="text" formControlName="setTwoB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoB.valid, 'error': formGroup.controls.setTwoB.touched && formGroup.controls.setTwoB.invalid}">
                  </div>
                  <div class="set-tie" *ngIf="isSetTwoTie">
                    <div class="tie-breaker">Tie Breaker</div>
                    <input type="text" formControlName="setTwoTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoTieA.valid, 'error': formGroup.controls.setTwoTieA.touched && formGroup.controls.setTwoTieA.invalid}">
                    <input type="text" formControlName="setTwoTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setTwoTieB.valid, 'error': formGroup.controls.setTwoTieB.touched && formGroup.controls.setTwoTieB.invalid}">
                  </div>
                  <div class="set-label">Set Two</div>
                </div>
                <div *ngIf="isSetThree" class="set set-three">
                  <div class="set-not-tie">
                    <input type="text" formControlName="setThreeA" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeA?.valid, 'error': formGroup.controls.setThreeA?.touched && formGroup.controls.setThreeA?.invalid}">
                    <input type="text" formControlName="setThreeB" pattern="[0-7]" maxlength="1" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeB?.valid, 'error': formGroup.controls.setThreeB?.touched && formGroup.controls.setThreeB?.invalid}">
                  </div>
                  <div class="set-tie" *ngIf="isSetThreeTie">
                    <div class="tie-breaker">Tie Breaker</div>
                    <input type="text" [formControl]="formGroup.controls['setThreeTieA']" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeTieA?.valid, 'error': formGroup.controls.setThreeTieA?.touched && formGroup.controls.setThreeTieA?.invalid}">
                    <input type="text" [formControl]="formGroup.controls['setThreeTieB']" pattern="[0-99]{1,2}" maxlength="2" placeholder="0" [ngClass]="{'valid': formGroup.controls.setThreeTieB?.valid, 'error': formGroup.controls.setThreeTieB?.touched && formGroup.controls.setThreeTieB?.invalid}">
                  </div>
                  <div class="set-label">Set Three</div>
                </div>
              </form>
            </div>
          </div>

          <div  class="player"
                *ngIf="currDispute.category === 'singles'"
                [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie, 'singles': currDispute.category === 'singles'}"
                [routerLink]="['/tennis/player/'+currDispute.fixtureData.teamB.id]">
                {{ currDispute.fixtureData.teamB.name }}
          </div>
          <div  class="player"
                *ngIf="currDispute.category === 'doubles'"
                [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie, 'doubles': currDispute.category === 'doubles'}"
                >
                {{ currDispute.fixtureData.teamB.name }}
                <div [routerLink]="['/tennis/player/'+currDispute.fixtureData.teamB.playerOne.id]">{{currDispute.fixtureData.teamB.playerOne.name}}</div>
                <div [routerLink]="['/tennis/player/'+currDispute.fixtureData.teamB.playerTwo.id]">{{currDispute.fixtureData.teamB.playerTwo.name}}</div>
          </div>

          <ng-container *ngIf="currDispute.state === 'disputed'">
            <ng-container *ngIf="!isEditScores">
              <div class="join-buttons">
                <button (click)="confirmScores(currDispute)">Confirm these scores</button>
                <button (click)="editScores(currDispute)">Edit these scores</button>
              </div>
            </ng-container>

            <ng-container *ngIf="isEditScores">
              <div class="join-buttons">
                <button (click)="uploadNewScores(formGroup, currDispute)">Upload new scores</button>
                <button (click)="previousScores()">Go back</button>
              </div>              
            </ng-container>
          </ng-container>

          <ng-container *ngIf="currDispute.state === 'resolved'">
            <div class="alert alert-success">This dispute has been resolved</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDisputes>
  <ng-container *ngIf="!isLoading">
    <div class="alert">No disputes found</div>
  </ng-container>
</ng-template>
