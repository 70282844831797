import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ProfileService } from 'src/app/services/profile/profile.service';
import {
  tierArray,
  underAgeArray,
  overAgeArray,
} from './create-tournament-data';

@Component({
  selector: 'app-create-tournament',
  templateUrl: './create-tournament.component.html',
  styleUrls: ['./create-tournament.component.scss'],
})
export class CreateTournamentComponent implements OnInit {
  formGroup: FormGroup;
  buttonDisabled: boolean;
  submitAttempt: boolean;
  errorMessage: string = '';
  success;
  isLoading: boolean;
  isError: boolean;
  timer: number = 5;
  cities: any;
  showPaypalButton: boolean;

  isUserTeamsModalOpen: boolean;
  userTeams: any[] = [];
  isJoinWithCurrTeam: boolean;
  currJoiningTeam;
  isJoinInviteSent: boolean;
  isModalLoading: boolean;
  userObj: any;
  tierArray: any[] = tierArray;
  overAgeArray: any[] = overAgeArray;
  underAgeArray: any[] = underAgeArray;

  constructor(
    private firestore: AngularFirestore,
    public fireFunctions: AngularFireFunctions,
    private router: Router,
    public authService: AuthService,
    private profileService: ProfileService
  ) {}

  initializeForm() {
    this.formGroup = new FormGroup({
      entryFee: new FormControl(20, [Validators.required, Validators.min(20)]),
      deadline: new FormControl({ value: '' }),
      location: new FormControl(null, Validators.required),
      startDate: new FormControl('', [
        Validators.required,
        Validators.pattern('\\d{4}-\\d{2}-\\d{2}'),
      ]),
      category: new FormControl('', Validators.required),
      victorPoints: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      wheelChair: new FormControl('', Validators.required),
      ageLimit: new FormControl('', Validators.required),
    });

    this.formGroup.controls.wheelChair.disable();
  }

  checkConditionsToCreateMatch(formObj) {
    this.submitAttempt = true;
    if (formObj.valid) {
      this.showPaypalButton = true;
    }
  }

  async createTournament(formObj) {
    let boxMatchTitle = '';

    this.isLoading = true;
    if (formObj.value.victorPoints === 'tier-1') {
      formObj.value.victorPoints = 600;
    } else if (formObj.value.victorPoints === 'tier-2') {
      formObj.value.victorPoints = 1200;
    } else if (formObj.value.victorPoints === 'tier-3') {
      formObj.value.victorPoints = 1800;
    } else if (formObj.value.victorPoints === 'tier-4') {
      formObj.value.victorPoints = 3000;
    }
    try {
      let matchData = await this.firestore
        .collection('MatchesData', (ref) => ref.where('matchType', '==', 'box'))
        .get()
        .toPromise();
      if (matchData.docs.length > 0) {
        boxMatchTitle = `Box Match ${
          matchData.docs[0].data().boxMatchCount + 1
        }`;
      } else {
        boxMatchTitle = `Box Match 1`;
      }

      // Adding necessary values in the Match Object
      formObj.value = {
        ...formObj.value,
        title: boxMatchTitle,
        frontEndState: 'registration-open',
        matchType: 'box',
        createdBy: 'user',
        maxPlayers: 5,
        deadline: formObj.value.startDate,
        wheelChair: this.userObj.wheelChair,
      };

      if (formObj.value.category === 'singles') {
        this.singlesMatchHandler(formObj);
      } else {
        document.body.classList.add('modal-open');
        this.isUserTeamsModalOpen = true;
      }
    } catch (e) {
      this.formGroup.controls.deadline.disable();
      this.isLoading = false;
      this.isError = true;
      console.log(e);
    }
  }

  async singlesMatchHandler(formObj) {
    let response = await this.createTransactionforSinglesMatch(formObj);
    if (response.success) {
      this.createSinglesMatch(formObj, response);
    } else {
      this.isError = true;
    }
  }

  async createTransactionforSinglesMatch(formObj) {
    try {
      // Calling backend API for transaction
      let transaction = this.fireFunctions.httpsCallable(
        'payments-processPayment'
      );
      let postObj = {
        matchId: null,
        amount: formObj.value.entryFee,
        currency: 'aud',
        uid: this.authService.user.uid,
      };

      let response: {
        success: boolean;
        transactionId: string;
      } = await transaction(postObj).toPromise();
      return response;
    } catch (e) {
      console.log(e);
      this.isError = true;
    }
  }

  async createSinglesMatch(formObj, response) {
    try {
      // Creating Match
      let match = await this.firestore.collection('Matches').add(formObj.value);

      // Getting Total Match Count
      let matchData = await this.firestore
        .collection('MatchesData', (ref) => ref.where('matchType', '==', 'box'))
        .get()
        .toPromise();
      let boxMatchCount = matchData.docs[0].data().boxMatchCount + 1;

      // Updating Match Count
      await this.firestore
        .collection('MatchesData')
        .doc(matchData.docs[0].id)
        .update({ boxMatchCount });

      // Registering User into the match
      await this.firestore
        .collection('Matches')
        .doc(match.id)
        .update({
          players: [
            {
              id: this.authService.user.uid,
              email: this.authService.user.email,
            },
          ],
        });

      // Updating Transaction Object with MatchId
      await this.firestore
        .collection('Transactions')
        .doc(response.transactionId)
        .update({ matchId: match.id });

      // Showing Success
      this.formGroup.controls.deadline.disable();
      this.isLoading = false;
      this.success = true;
      this.showPaypalButton = false;
      setTimeout(() => {
        this.router.navigate([`/tennis/tournaments/${match.id}`]);
      }, parseInt('' + this.timer + '000'));
      setInterval(() => {
        this.timer--;
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  }

  async joinWithCurrTeam(team, formObj) {
    this.isModalLoading = true;
    this.currJoiningTeam = team;
    this.isJoinWithCurrTeam = true;

    // Getting Receiver Id
    let receiverId: string = this.currJoiningTeam.members.find(
      (el: { email: string; id: string }) => el.id !== this.authService.user.uid
    ).id;

    // Creating a new match and getting matchId in return
    let matchId = await this.createDoublesMatch(formObj);

    // Making Transaction for Creating the Match
    let response = await this.createTransactionforDoublesMatch(
      formObj,
      matchId,
      receiverId
    );

    // Notifying User About Successful Match Creation Or About Any Error
    this.isModalLoading = false;
    if (response.success) this.isJoinInviteSent = true;
    else this.isError = true;
  }

  async createTransactionforDoublesMatch(formObj, matchId, receiverId) {
    try {
      let transaction = this.fireFunctions.httpsCallable(
        'matchTeamRequests-createRequest'
      );
      let postObj = {
        matchId: matchId,
        amount: formObj.value.entryFee,
        currency: 'aud',
        receiverId,
        teamId: this.currJoiningTeam.teamId,
      };

      let response = await transaction(postObj).toPromise();
      return response;
    } catch (e) {
      console.log(e);
      this.isError = true;
    }
  }

  async createDoublesMatch(formObj) {
    try {
      let match = await this.firestore
        .collection('Matches')
        .add({ ...formObj.value, deleted: true });
      let matchData = await this.firestore
        .collection('MatchesData', (ref) => ref.where('matchType', '==', 'box'))
        .get()
        .toPromise();
      let boxMatchCount = matchData.docs[0].data().boxMatchCount + 1;
      await this.firestore
        .collection('MatchesData')
        .doc(matchData.docs[0].id)
        .update({ boxMatchCount });
      return match.id;
    } catch (e) {
      console.log(e);
      this.isError = true;
    }
  }

  async getCities() {
    try {
      let cities: any = await this.firestore
        .collection('Cities')
        .get()
        .toPromise();
      let citiesData = cities.docs.map((el) => el.data());
      this.cities = citiesData;
    } catch (e) {
      console.log(e);
    }
  }

  async fetchUserTeams() {
    try {
      let rawTeams = await this.firestore.collection('Teams').get().toPromise();
      let filteredTeams = rawTeams.docs.filter((team) => {
        let teamData = team.data();
        return (
          teamData.members &&
          (teamData.members[0].id === this.authService.user.uid ||
            teamData.members[1].id === this.authService.user.uid)
        );
      });
      if (filteredTeams.length > 0) {
        let userTeams: any = filteredTeams.slice();
        userTeams = userTeams.map((el) => {
          let teamObj: any = {};
          teamObj = el.data();
          teamObj.teamId = el.id;
          return teamObj;
        });
        userTeams = userTeams.filter(Boolean);
        this.userTeams = userTeams.slice();
      }
    } catch (e) {
      console.log(e);
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isUserTeamsModalOpen = false;
    this.isJoinWithCurrTeam = false;
    this.isLoading = false;
  }

  async getUserData() {
    try {
      this.userObj = (await this.profileService.getUserProfileData()).data();
      // Setting User Tier
      let tier =
        this.userObj.victorPoints > 1800
          ? 4
          : this.userObj.victorPoints > 1200
          ? 3
          : this.userObj.victorPoints > 600
          ? 2
          : 1;
      this.userObj.tier = tier;

      // Calculating User Age
      let playerAge: number =
        new Date().getFullYear() -
        parseInt(this.userObj.dateOfBirth.split('-')[0]);
      this.userObj.age = playerAge;

      // Setting Wheelchair Value
      this.formGroup.controls.wheelChair.setValue(this.userObj.wheelChair);
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    this.initializeForm();
    this.getCities();
    this.fetchUserTeams();
    this.getUserData();
  }
}
