import { AuthService } from './../services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  players: any[];
  isPlayersLoaded: boolean;
  gallery: any[];
  isGalleryLoaded: boolean;
  isModalOpen: boolean;
  modalImage: { url: string, caption: string };
  slideOptions = { 
    dots: true,
    navigation: true,
    items: 1
  };

  constructor(public router: Router,
              private firestore: AngularFirestore,
              public authService: AuthService) { }

  async getPlayers() {
    try {
      let players = await this.firestore.collection('Users', ref => ref
                          .orderBy('victorPoints', 'desc').limit(20)).get().toPromise()
      this.players = await Promise.all(players.docs.map(async el => {
        if(el.data().state === 'registered' && !el.data().deleted) {
          let wins = [];
          let scores = await el.ref.collection('history').get()
          if(scores.docs.length > 0) {
            wins = await Promise.all(scores.docs.map(async el2 => {
              let matchUrl = el2.data().matchRef.split('/')[2];
              let match = await this.firestore.collection('Matches').doc(matchUrl).get().toPromise()
              if(match.exists) {
                if(match.data().matchType === 'one-on-one' || match.data().matchType === 'box') {
                  let fixture = el2.data().matchRef.split('/')[4];
                  if(!fixture) return false;
                  let fixtureData = (await this.firestore.collection('Matches').doc(matchUrl)
                                    .collection('fixtures').doc(fixture).get().toPromise()).data()
                  if(fixtureData && fixtureData.winner) {
                    if(fixtureData[fixtureData.winner].id && fixtureData[fixtureData.winner].id === el.id){
                      return el2
                    }
                  }
                } else if(match.data().matchType === 'knockout') {
                  let roundType = el2.data().matchRef.split('/')[3];
                  let round = el2.data().matchRef.split('/')[4];
                  let fixture = el2.data().matchRef.split('/')[6];
                  if(!roundType || !round || !fixture) return false;
                  let fixtureData = (await this.firestore.collection('Matches').doc(matchUrl)
                                    .collection(roundType).doc(round).collection('fixtures')
                                    .doc(fixture).get().toPromise()).data()
                  if(fixtureData && fixtureData.winner && fixtureData[fixtureData.winner].id) {
                    if(fixtureData[fixtureData.winner].id === el.id){
                      return el2
                    }
                  }
                }
              }
            }))
          }
          wins = wins.filter(Boolean)
          let victorPoints = parseFloat(el.data().victorPoints).toFixed(2)
          return {
            userId: el.id,
            name: el.data().firstName + " " + el.data().lastName,
            victorPoints,
            earnings: el.data().earnings ? el.data().earnings : 0,
            wins: wins.length
          }
        }
      }))
      this.players = this.players.filter(Boolean)
      this.isPlayersLoaded = true;
    } catch(e) {
      console.log(e)
    }
  }

  async getGallery() {
    try {
      let gallery = await this.firestore.collection('Gallery').get().toPromise();
      if(gallery.docs.length > 0) {
        this.gallery = gallery.docs.map(el => { 
          return { url: el.data().imageUrl, caption: el.data().caption, timestamp: el.data().timestamp }
        })

        const paginateGallery = (gallery) => {
          let paginationLength = gallery.length / 5;
          let count = 0;
          let paginatedGallery = [];
          for(let i = 0; i < paginationLength; i++) {
            let batch = gallery.slice(count, count + 5)
            paginatedGallery.push(batch)
            count += 5;
          }
          return paginatedGallery;
        }
        this.gallery = paginateGallery(this.gallery)
        this.isGalleryLoaded = true
      }
    } catch(e) {
      console.log(e)
    }
  }

  openModal(event) {
    let imgSource = event.target.src;
    let imgCaption = event.target.alt;
    this.modalImage = { url: imgSource, caption: imgCaption };
    this.isModalOpen = true;
    document.body.classList.add('modal-open');
  }
  closeModal()  {
    document.body.classList.remove('modal-open');
    this.isModalOpen = false;
  }

  ngOnInit() {
    this.getPlayers();
    this.getGallery();
  }

}
