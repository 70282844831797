import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-upload-gallery',
  templateUrl: './upload-gallery.component.html',
  styleUrls: ['./upload-gallery.component.scss']
})
export class UploadGalleryComponent implements OnInit {
  
  isValid: boolean = false;
  isError = { error: false, msg: ''};
  success: boolean;
  img: any;
  hasGallery: boolean
  gallery: any[];
  caption: string;
  isLoading: boolean;
  @ViewChild('uploadInput') uploadInput: ElementRef
  @ViewChild('previewImage') previewImage: ElementRef
  
  constructor(private fireStorage: AngularFireStorage, private firestore: AngularFirestore) { }

  imgHandler(event) {
    let img = event.target.files[0];
    if(img.type === "image/png" || img.type === "image/jpeg") {
      if(img.size < 5000000) {
        const reader = new FileReader();
        reader.onload = e => this.previewImage.nativeElement.src = reader.result;
        reader.readAsDataURL(img)
        this.img = img;
        this.isValid = true
      } else this.isError = { error: true, msg: "Images over 5 mbs are not allowed" }
    } else this.isError = { error: true, msg: "Only .jpeg and .png images are allowed" }
  }

  async uploadImage() {
    this.isValid = false;
    this.isError.error = false;
    this.success = false;
    const id = Math.random().toString(36).substring(2);
    const imgRef = this.fireStorage.ref('gallery/' + id);
    try {
      let uploadImg = await imgRef.put(this.img)
      let downloadURL = await uploadImg.ref.getDownloadURL();
      let imageObj = { imageUrl: downloadURL, timestamp: Date.now() }  
      Object.assign(imageObj, this.caption ? { caption: this.caption } : null)
      await this.firestore.collection('Gallery').add(imageObj)
      this.ngOnInit();
      this.uploadInput.nativeElement.value = "";
      this.previewImage.nativeElement.src = '../../../assets/image/no-img.png';
      this.caption = '';
      this.isValid = false;
      this.success = true;
    } catch(e) {
      this.previewImage.nativeElement.src = '../../../assets/image/no-img.png';
      this.success = false;
      this.isError = { error: true, msg: "Could not upload image, pleaes try again later" }
      console.log(e)
    }
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      let gallery = await this.firestore.collection('Gallery').get().toPromise()
      if(gallery.docs.length > 0) {
        let images = gallery.docs.map(el => {
          return { url: el.data().imageUrl, caption: el.data().caption, timestamp: el.data().timestamp }
        })
        this.gallery = images.sort((a, b) => b.timestamp - a.timestamp);
        this.hasGallery = true;
      } else {
        this.hasGallery = false;
      }
    } catch(e) {
      console.log(e)
    }
    this.isLoading = false;
  }

}
