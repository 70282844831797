import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tournaments-pagination',
  templateUrl: './tournaments-pagination.component.html',
  styleUrls: ['./tournaments-pagination.component.scss']
})
export class TournamentsPaginationComponent implements OnInit {

  @Input() currentPage;
  @Input() pagesLength;
  pagesArray: any[];
  pagination: boolean;

  constructor(public router: Router) { }

  goToPage(page) {
    this.router.navigate([], {
      queryParams: { page },
      queryParamsHandling: 'merge',
    }).then(() => {
      this.pagesArray[page].class = "active"
      this.pagesArray.forEach((el, index) => {
        if(index !== page) this.pagesArray[index].class = 'hide'
        else this.pagesArray[index].class = 'active'
      })
      this.assignClass(this.pagesArray)
    });
  }

  assignClass(pagesArray) {
    let activeIndex = pagesArray.findIndex(el => el.class === 'active')
    // number of pagination items according to window width
    let items = window.innerWidth > 767 ? 5 : 4
    for(let i = (activeIndex + 1); i < (activeIndex + items); i++) {
      if(pagesArray[i]) pagesArray[i].class = 'show'
    }
    for(let i = (activeIndex - 1); i > (activeIndex - items); i--) {
      if(pagesArray[i]) pagesArray[i].class = 'show'
    }
  }
  
  ngOnInit(): void {
    if(this.pagesLength > 1) {
      this.pagesArray = Array(this.pagesLength).fill(0).map((num, index) => { 
        return { page: index, class: index === this.currentPage ? 'active' : 'hide'}
      })
      this.assignClass(this.pagesArray);
      this.pagination = true
    } else this.pagination = false
  }

}
