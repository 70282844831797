<div class="container-fluid">
  <app-shade></app-shade>
  <app-page-heading pageHeading="Terms Of Use"></app-page-heading>
</div>
<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <p>Welcome to the Viictor website (the Website), which is operated by Viictor Pty Ltd (A.C.N.638 794 760) (we, us, our, or Viictor).
      </p>
      <p>Your access to the Website is conditional upon your acceptance and compliance with the terms, conditions, notices and disclaimers contained in this document (collectively known as Terms of Use).  Your use of, and/or access to, the Website constitutes your agreement to the Terms of Use.</p>
      <p>We reserve the right to amend the Terms of Use at any time.</p>
      <h2>Our services</h2>
      <p>We cannot be responsible for any delays or interruptions to the Website. We will use commercially reasonable efforts to minimise delays and interruptions. However, we cannot warrant that the Website will be available at all times or at any given time.</p>
      <p>We may at any time and without notice to you, discontinue the Website in whole or in part. However, we cannot be responsible for any loss, cost, damage or liability that may result from our discontinuance of the Website.
      </p>
      <h2>Prohibited conduct</h2>
      <p>In relation to the Website, you must not:</p>
      <ul>
        <li>use the Website for any activities, sales or post or transmit via the Website, any information or materials which breach any laws or regulations, infringe a third party’s rights, or are contrary to any relevant standard or codes;
        </li>
        <li>use the Website to post or transmit any material which interferes with other users or defames, harasses, threatens, menaces, offends or restricts any person or which inhibits any user from using the Website or the Internet;
        </li>
        <li>use the Website to send unsolicited email messages;</li>
        <li>in any way tamper with, hinder or modify the Website;</li>
        <li>knowingly transmit any viruses or other disabling features to the Website or via the Website; or</li>
        <li>attempt any of the above acts or facilitate or assist another person to do any of the above acts.</li>
      </ul>
      <h2>Intellectual Property</h2>
      <p>The material on the Website, including the software, design, text, images and graphics comprised in the Website and the selection and layout of the Website are owned or under licence by Viictor and protected by Australian and international laws.</p>
      <p>Your use of the Website does not grant you a licence or act as a right of use of any of the trade marks or logos, whether registered or unregistered, that are displayed on the Website without the express written permission of the trade mark owner.
      </p>
      <p>We own the copyright, which subsists in all creative and literary works that are displayed on the Website.
      </p>
      <p>You may view the Website and its contents using your web browser. In visiting the Website, you may make a temporary copy of the Website by means of the usual operation of your web browser only.</p>
      <p>You must not:</p>
      <ul>
        <li>reproduce or use any of the material on the Website for commercial purposes, including sale;</li>
        <li>in any way modify the material on the Website; or</li>
        <li>cause any of the material on the Website to be framed or embedded in another website.</li>
      </ul>
      <p>In summary, you must not modify, copy, reproduce, republish, frame, upload to a third party, post, transmit or distribute the content of the Website in any way except as expressly provided for by us or expressly authorised in writing by us.
      </p>
      <p>In the event that you do any of the above acts, we will not hesitate to enforce our intellectual property rights against you.</p>
      <h2>Third party links</h2>
      <p>The Website may contain hyperlinks and other pointers to websites operated by third parties (Linked Websites). We do not control Linked Websites and are therefore not responsible for the content of any Linked Website or any hyperlink contained in a Linked Website.  We provide the hyperlinks for your convenience only and do not indicate, expressly or implicitly, any endorsement, sponsorship or approval by us of a Linked Website or the products or services offered at Linked Websites. You visit Linked Websites entirely at your own risk.
      </p>
      <p>We do not provide any warranty or take any responsibility for any aspect of Linked Websites or their content. You should make your own investigations with respect to the suitability of goods and/or services offered to you via a Linked Website.</p>
      <h2>Indemnity</h2>
      <p>By using the Website, you agree to indemnify us from and against all actions, claims, suits, demands, damages, liabilities, costs or expenses (whether in tort or in contract including and without limitation, negligence) arising out of or in any way connected to the use of the Website by you.
      </p>
      <h2>Disclaimer</h2>
      <p>Some legislation such as the Australian Competition and Consumer Act 2010 (Cth) and other similar consumer protection laws and regulations in other countries may confer you with rights and remedies relating to the provision of goods or services to you by us via the Website which cannot be excluded, restricted or modified (your Statutory Rights).  We exclude all conditions and warranties implied by custom, law or statute except for your Statutory Rights. 
      </p>
      <p>Except for your Statutory Rights and with respect to the Website:</p>
      <ul>
        <li>all material on the Website is provided to you without warranties of any kind, either express or implied;</li>
        <li>we expressly disclaim all warranties of any kind including but not limited to implied warranties of merchantability and fitness for a particular purpose;</li>
        <li>we do not warrant that the functions contained in any material on the Website or your access to the Website will be uninterrupted or error free, that any defects will be corrected or that the Website or the server which stores and transmits material to you are free of viruses or any other harmful components; and</li>
        <li>we do not warrant or make any representation regarding your access to, or the results of your access to, the Website including its correctness, accuracy, timeliness, completeness, reliability or otherwise.
        </li>
      </ul>
      <p>To the extent permitted by law, including but not limited to any act or omission on tour part, we will not be liable for any loss, damage, costs or expense whether direct, indirect, incidental, special and/or consequential, including loss of profits, suffered by you or claims made against you which result from any use or access of, or any inability to use or access, the Website. 
      </p>
      <p>You expressly acknowledge that we do not exert control over users of the Internet and we are not liable for damage suffered by you, either directly or indirectly, as a result of your access to the Website.
      </p>
      <h2>Limitation of liability</h2>
      <p>To the extent permitted by law, our liability for breach of any implied warranty or condition, which cannot be excluded by the Terms of Use, is limited, at our option, to one or more of the following:</p>
      <p>In the case of services supplied or offered by us:</p>
      <ul>
        <li>the resupply of the services; or</li>
        <li>the payment of the cost of having the services resupplied.</li>
      </ul>
      <p>In the case of goods supplied or offered by us:</p>
      <ul>
        <li>the replacement of the goods or the supply of equivalent goods;</li>
        <li>the repair of such goods;</li>
        <li>the payment of the costs of replacing the goods or acquiring equivalent goods; or
        </li>
        <li>the payment of the costs of having the goods repaired.</li>
      </ul>
      <h2>Privacy</h2>
      <p>By agreeing to and accepting the Terms of Use, you also agree to our <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</p>
      <h2>Termination</h2>
      <p>The Terms of Use are effective until terminated by us, which we may do at any time and without notice to you.  In the event of termination, all restrictions imposed on you by the Terms of Use and limitations of liability set out in the Terms of Use will survive.
      </p>
      <h2>Miscellaneous</h2>
      <p>We rely upon your continued observance of the Terms of Use.  If we suffer loss or damage or incur any costs associated with any breach by you of the Terms of Use or any associated legal obligation, you agree to indemnify us for those losses, damages and costs.</p>
      <p>We do not make any claims that the information is appropriate or may be downloaded in all areas, countries or jurisdictions. Access to the information contained in the Website may not be legal by certain persons or in certain countries.  If you access the Website, you do so at your own risk and you are responsible for compliance with the laws of your jurisdiction.
      </p>
      <p>If any provision of the Terms of Use is found to be invalid or unenforceable by a Court of Law, such invalidity or unenforceability will not affect the remainder of the document, which will continue in full force and effect.
      </p>
      <p>All rights not expressly granted in the Terms of Use are reserved.</p>
      <p>If we do not act in relation to a breach of the Terms of Use by you, this does not waive our rights to act with respect to subsequent or similar breaches of the Terms of Use by you.</p>
      <h2>Applicable Law</h2>
      <p>The Terms of Use are governed by and construed in accordance with the laws of the State of Western Australia. You irrevocably and unconditionally submit to the non-exclusive jurisdiction of the Courts of Western Australia and Courts of Appeal from them for determining any dispute concerning the Terms of Use.
      </p>
      <h2>Contact us</h2>
      <p>Please email us if you have any questions relating to these Terms of Use.</p>
      <p><i>Terms of Use last updated on 12 March 2020.</i></p>
    </div>
  </div>
</div>