import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-website-updates',
  templateUrl: './website-updates.component.html',
  styleUrls: ['./website-updates.component.scss']
})
export class WebsiteUpdatesComponent implements OnInit {

  isLoading: boolean = true;
  hasUpdates: boolean;
  updates: any[];
  constructor(private firestore: AngularFirestore) { }

  async getUpdates() {
    try {
      let websiteUpdates = await this.firestore.collection('WebsiteUpdates').get().toPromise();
      if(websiteUpdates.docs.length > 0) {
        this.hasUpdates = true;
        this.updates = websiteUpdates.docs.map(el => el.data());
      } else {
        this.hasUpdates = false;
      }
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

  getFormattedDate(timestamp) {
    if(timestamp) {
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let getFullDate = new Date(timestamp)
      let getDate = getFullDate.getDate();
      let getMonth = months[getFullDate.getMonth()];
      let getYear = getFullDate.getFullYear();
      return `${getDate}/${getMonth}/${getYear}`
    } return `No Date`;
  }

  ngOnInit(): void {
    this.getUpdates();
  }

}
