<div class="doubles-matches">
  <h2 class="table-header">My Double Matches</h2>
  <div class="challenges-table" *ngIf="hasDoubleMatches; else noMatches">
    <div class="header">
      <div class="title">Title</div>
      <div class="victor-points">Location</div>
      <div class="prize">Prize</div>
      <div class="status">Status</div>
    </div>
    <div class="challenges">
      <div class="challenge" [routerLink]="['/tennis/tournaments/'+challenge.matchId]"
        *ngFor="let challenge of tournaments; let i = index">
        <div class="title">{{ challenge.title }}</div>
        <div class="victor-points">{{ challenge.location }}</div>
        <div class="prize">${{ challenge.prize }}</div>
        <div class="status">
          {{ challenge.fixtureData[challenge.fixtureData.winner].id === challenge.teamId ? "Won" : "Lost" }}
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #noMatches>
  <div class="alert">You haven't played any matches yet.</div>
</ng-template>
