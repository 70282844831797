<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <div *ngIf="invalidUrl" class="alert">Invalid Url</div>
      <div *ngIf="isTournamentLoading" class="loader">
        <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader" />
      </div>
      <ng-container *ngIf="!isTournamentLoading">
        <div class="option-dropdown">
          <div
            appClickOutside
            (clickOutside)="showOptionDropdown = false"
            class="option-icon"
            (click)="toggleDropdown()"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div
            class="dropdown"
            [ngStyle]="{ display: showOptionDropdown ? 'block' : 'none' }"
          >
            <ul class="dropdown-wrapper">
              <li
                *ngIf="isUserRegistered && !registrationClosed"
                (click)="openWithdrawModal()"
              >
                Withdraw from tournament
              </li>
              <li (click)="copyUrl($event)">Copy match url</li>
              <li *ngIf="userObj?.isAdmin" (click)="downloadCSV()">
                Download Data
              </li>
            </ul>
          </div>
        </div>
        <div class="knockout-match-wrapper" *ngIf="isKnockoutMatch">
          <div *ngIf="isDataLoaded" class="fixture-statistics">
            <div class="heading">
              {{ tournamentData.title }}
              <button
                *ngIf="isButtonActive"
                type="button"
                class="btn-desktop"
                (click)="checkConditionsToJoin()"
              >
                Join
              </button>
            </div>
            <div class="stats row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Tier</label>
                  <div class="value">{{ tournamentData.tier }}</div>
                </div>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                *ngIf="registrationClosed"
              >
                <div class="stat">
                  <label>Total Winning Prize</label>
                  <div class="value">${{ this.tournamentData.prize }}</div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Joining Fee</label>
                  <div class="value">${{ tournamentData.entryFee }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Registration Deadline</label>
                  <div class="value">
                    {{ getFormattedDate(tournamentData.deadline) }}
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Category</label>
                  <div class="value">{{ tournamentData.category }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Gender</label>
                  <div class="value">{{ tournamentData.gender }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Wheelchair</label>
                  <div class="value">{{ tournamentData.wheelChair }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Age Limit</label>
                  <div
                    class="value"
                    *ngIf="
                      tournamentData.ageLimit &&
                      tournamentData.ageLimit !== 'open'
                    "
                  >
                    {{ tournamentData.ageLimit[0] === 'o' ? 'Over' : 'Under' }}
                    {{ tournamentData.ageLimit.slice(1) }}
                  </div>
                  <div
                    class="value"
                    *ngIf="
                      !tournamentData.ageLimit ||
                      (tournamentData.ageLimit &&
                        tournamentData.ageLimit === 'open')
                    "
                  >
                    Open
                  </div>
                </div>
              </div>
            </div>

            <button
              *ngIf="isButtonActive"
              type="button"
              class="btn-mobile"
              (click)="checkConditionsToJoin()"
            >
              Join
            </button>
          </div>

          <div *ngIf="isDataLoaded" class="location-players">
            <div class="registered-players">
              <div class="text">Registered Players</div>
              <button type="button" (click)="openRegisteredPlayersModal()">
                Click to view
              </button>
            </div>
            <div class="location">
              <div class="text">{{ tournamentData.location }}</div>
              <button type="button" (click)="openMapModal()">
                Show On Map
              </button>
            </div>
          </div>

          <div class="alert-msgs">
            <div *ngIf="errorMessage" class="alert alert-danger">
              {{ errorMessage }}.
              <span
                *ngIf="
                  errorMessage === 'You need to login to join this tournament'
                "
                style="cursor: pointer"
                (click)="
                  router.navigate([
                    'tennis/users/login',
                    { redirectTo: this.router.url }
                  ])
                "
                >Click here to login</span
              >
            </div>
            <div *ngIf="successMessage" class="alert alert-success">
              {{ successMessage }}
            </div>
            <div *ngIf="isRegistrationFull" class="alert alert-danger">
              {{ isRegistrationFull }}
            </div>
            <div *ngIf="isGenderError" class="alert alert-danger">
              {{ isGenderError }}
            </div>
            <div *ngIf="isWheelchairError" class="alert alert-danger">
              {{ isWheelchairError }}
            </div>
            <div *ngIf="isJoinInviteSent" class="alert alert-success">
              You have already sent an invite for this tournament
            </div>
            <div *ngIf="isUserTeamRegistered" class="alert alert-success">
              Your team is registered in this tournament
            </div>
            <div *ngIf="isAgeError" class="alert alert-danger">
              {{ isAgeError }}
            </div>
          </div>

          <div *ngIf="registrationClosed; else noFixtures">
            <div *ngIf="isDataLoaded">
              <div class="navigate-components">
                <div class="inner" (click)="navigateFixturestoMatches($event)">
                  <div [ngClass]="{ active: showFixtures }">Main Draw</div>
                  <div [ngClass]="{ active: showLosersFixtures }">
                    Consolation
                  </div>
                  <div [ngClass]="{ active: showMyMatches }">My Matches</div>
                </div>
              </div>

              <app-fixtures
                [ngStyle]="{ display: showFixtures ? 'block' : 'none' }"
                [tournament]="tournamentDoc"
              ></app-fixtures>
              <app-losers-fixtures
                [ngStyle]="{ display: showLosersFixtures ? 'block' : 'none' }"
                [tournament]="tournamentDoc"
              ></app-losers-fixtures>
              <app-player-matches
                [ngStyle]="{ display: showMyMatches ? 'block' : 'none' }"
                [tournament]="tournamentDoc"
              ></app-player-matches>
            </div>
          </div>

          <section
            *ngIf="
              tournamentData.category === 'singles' && isJoinTournamentModalOpen
            "
            class="modal"
          >
            <div class="modal-background">
              <div class="modal-body">
                <ng-container *ngIf="userObj.wallet < tournamentData.entryFee">
                  <div class="alert alert-danger">
                    You have insufficient balance in your account. Please
                    recharge and try again.
                  </div>
                  <div class="join-buttons">
                    <button
                      type="button"
                      [routerLink]="['/tennis/user/wallet', { redirectTo: router.url }]"
                      (click)="closeModal()"
                    >
                      Okay
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="userObj.wallet >= tournamentData.entryFee">
                  <p>
                    This tournament requires you to pay
                    <strong>${{ tournamentData.entryFee }}</strong
                    >. Would you like to proceed?
                  </p>

                  <div *ngIf="!isModalLoading">
                    <div class="join-buttons">
                      <button type="button" (click)="joinSinglesTournament()">
                        Yes
                      </button>
                      <button
                        type="button"
                        (click)="closeJoinTournamentModal()"
                      >
                        No
                      </button>
                    </div>
                  </div>
                  <div *ngIf="isModalLoading">
                    <div class="loader">
                      <img
                        src="../../../assets/image/ajax-loader.gif"
                        alt="ajax-loader"
                      />
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </section>

          <section *ngIf="isRegisteredPlayersModalOpen" class="modal">
            <div class="modal-background">
              <div class="modal-body modal-body-registered-players">
                <div
                  class="close-modal"
                  (click)="closeRegisteredPlayersModal()"
                >
                  x
                </div>
                <ng-container *ngIf="tournamentData.category === 'singles'">
                  <div
                    *ngIf="
                      registeredPlayers.length > 0;
                      else noRegisteredPlayers
                    "
                  >
                    <div class="heading">Registered Players</div>

                    <div class="players-list">
                      <ul>
                        <li *ngFor="let player of registeredPlayers">
                          {{ player.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="tournamentData.category === 'doubles'">
                  <div
                    class="registered-teams"
                    *ngIf="registeredTeams.length > 0; else noRegisteredPlayers"
                  >
                    <div class="heading">Registered Teams</div>

                    <div class="teams-list">
                      <ul>
                        <li *ngFor="let team of registeredTeams">
                          <div class="team-name">{{ team.teamName }}</div>
                          <div class="player">1. {{ team.playerOne.name }}</div>
                          <div class="player">2. {{ team.playerTwo.name }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noRegisteredPlayers>
                  <div class="text">
                    Currently there are no registered players in this
                    tournament.
                  </div>
                </ng-template>
              </div>
            </div>
          </section>

          <section
            *ngIf="
              tournamentData.category === 'doubles' && isUserTeamsModalOpen
            "
            class="modal"
          >
            <div class="modal-background">
              <div class="modal-body modal-body-registered-players">
                <div class="close-modal" (click)="closeUserTeamsModal()">x</div>
                <ng-container *ngIf="userObj.wallet < tournamentData.entryFee">
                  <div class="alert alert-danger">
                    You have insufficient balance in your account. Please
                    recharge and try again.
                  </div>
                  <div class="join-buttons">
                    <button
                      type="button"
                      [routerLink]="['/tennis/user/wallet', { redirectTo: router.url }]"
                      (click)="closeModal()"
                    >
                      Okay
                    </button>
                  </div>
                </ng-container>

                <ng-container *ngIf="userObj.wallet >= tournamentData.entryFee">
                  <div *ngIf="!isJoinWithCurrTeam">
                    <div
                      class="registered-teams"
                      *ngIf="userTeams.length > 0; else noRegisteredPlayers"
                    >
                      <div class="heading">Your Teams</div>
                      <p></p>
                      <div class="alert alert-info">
                        As you click on the join button, you will be charged
                        with the tournament fee and an invite will be sent to
                        the other player. This invite will be valid for 48
                        hours. If the other player doesn't accept this invite
                        within 48 hours, then the system will refund your
                        charged fee.
                      </div>
                      <div class="teams-list user-teams">
                        <ul>
                          <li *ngFor="let team of userTeams">
                            <div class="team-name">
                              <div class="name">{{ team.name }}</div>
                              <button
                                type="button"
                                (click)="joinWithCurrTeam(team)"
                              >
                                Join with this team
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="search-box">
                        <div class="alert alert-info">
                          Want to invite someone new? Use the search box below to find their profile and send them an invite.
                        </div>
                        <div class="field">
                          <input type="text" class="form-control" [formControl]="searchUser" placeholder="Enter player's name">
                          <!-- <div class="errorMessage" *ngIf="submitAttempt && formGroup.get('name').invalid">Full name is required</div> -->
                        </div>
                        <div class="users-list">
                          <div class="user-box">
                            <img src="../../../assets/image/profile.jpeg" class="profile-image">
                            <div class="name">Talha Khatri</div>
                            <button
                              type="button"
                              (click)="joinWithCurrTeam(team)"
                            >
                              Invite
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isJoinWithCurrTeam">
                    <div *ngIf="isModalLoading" class="loader">
                      <img
                        src="../../../assets/image/ajax-loader.gif"
                        alt="ajax-loader"
                      />
                    </div>

                    <div *ngIf="isJoinInviteSent" class="alert alert-success">
                      Your join invite has been sent. Once your partner
                      confirms, your team will be enrolled in the tournament.
                    </div>
                  </div>
                </ng-container>

                <ng-template #noRegisteredPlayers>
                  <div class="text">
                    Currently you have no teams registered or your teams don't
                    match with this tournament's criteria.
                  </div>
                </ng-template>
              </div>
            </div>
          </section>
        </div>

        <div class="box-match-wrapper" *ngIf="isBoxMatch">
          <div class="fixture-statistics">
            <div class="heading">
              {{ tournamentData.title }}
              <div class="available-spots">
                <ng-container *ngIf="tournamentData.category === 'singles'">
                  <label>Registered Players</label>
                  <div class="content">
                    <div class="available">
                      {{ registeredPlayers ? registeredPlayers.length : 0 }}
                    </div>
                    <div class="available-spots-inner">
                      <div
                        [ngStyle]="{
                          flex:
                            (tournamentData.maxPlayers -
                              (tournamentData.maxPlayers -
                                registeredPlayers.length)) /
                            tournamentData.maxPlayers
                        }"
                      ></div>
                    </div>
                    <div class="total">{{ tournamentData.maxPlayers }}</div>
                  </div>
                </ng-container>
                <ng-container *ngIf="tournamentData.category === 'doubles'">
                  <label>Registered Teams</label>
                  <div class="content">
                    <div class="available">
                      {{ registeredTeams ? registeredTeams.length : 0 }}
                    </div>
                    <div class="available-spots-inner">
                      <div
                        [ngStyle]="{
                          flex:
                            (tournamentData.maxPlayers -
                              (tournamentData.maxPlayers -
                                registeredTeams.length)) /
                            tournamentData.maxPlayers
                        }"
                      ></div>
                    </div>
                    <div class="total">{{ tournamentData.maxPlayers }}</div>
                  </div>
                </ng-container>
              </div>
              <button
                *ngIf="isButtonActive"
                type="button"
                class="btn-desktop"
                (click)="checkConditionsToJoin()"
              >
                Join
              </button>
            </div>
            <div class="stats row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Tier</label>
                  <div class="value">{{ tournamentData.tier }}</div>
                </div>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                *ngIf="registrationClosed"
              >
                <div class="stat">
                  <label>Total Winning Prize</label>
                  <div class="value">${{ this.tournamentData.prize }}</div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Joining Fee</label>
                  <div class="value">${{ tournamentData.entryFee }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Registration Deadline</label>
                  <div class="value">
                    {{ getFormattedDate(tournamentData.deadline) }}
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Category</label>
                  <div class="value">{{ tournamentData.category }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Gender</label>
                  <div class="value">{{ tournamentData.gender }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Wheelchair</label>
                  <div class="value">{{ tournamentData.wheelChair }}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="stat">
                  <label>Age Limit</label>
                  <div
                    class="value"
                    *ngIf="
                      tournamentData.ageLimit &&
                      tournamentData.ageLimit !== 'open'
                    "
                  >
                    {{ tournamentData.ageLimit[0] === 'o' ? 'Over' : 'Under' }}
                    {{ tournamentData.ageLimit.slice(1) }}
                  </div>
                  <div
                    class="value"
                    *ngIf="
                      !tournamentData.ageLimit ||
                      (tournamentData.ageLimit &&
                        tournamentData.ageLimit === 'open')
                    "
                  >
                    Open
                  </div>
                </div>
              </div>
            </div>

            <button
              *ngIf="isButtonActive"
              type="button"
              class="btn-mobile"
              (click)="checkConditionsToJoin()"
            >
              Join
            </button>
          </div>

          <div *ngIf="isDataLoaded" class="location-players">
            <div class="registered-players">
              <div class="text">
                {{
                  tournamentData.category === 'singles'
                    ? 'Registered Players'
                    : 'Registered Teams'
                }}
              </div>
              <button type="button" (click)="openRegisteredPlayersModal()">
                Click to view
              </button>
            </div>
            <div class="location">
              <div class="text">{{ tournamentData.location }}</div>
              <button type="button" (click)="openMapModal()">
                Show On Map
              </button>
            </div>
          </div>

          <div class="alert-msgs">
            <div *ngIf="errorMessage" class="alert alert-danger">
              {{ errorMessage }}.
              <span
                *ngIf="
                  errorMessage === 'You need to login to join this tournament'
                "
                style="cursor: pointer"
                (click)="
                  router.navigate([
                    'tennis/users/login',
                    { redirectTo: this.router.url }
                  ])
                "
                >Click here to login</span
              >
            </div>
            <div *ngIf="successMessage" class="alert alert-success">
              {{ successMessage }}
            </div>
            <div *ngIf="isRegistrationFull" class="alert alert-danger">
              {{ isRegistrationFull }}
            </div>
            <div *ngIf="isGenderError" class="alert alert-danger">
              {{ isGenderError }}
            </div>
            <div *ngIf="isWheelchairError" class="alert alert-danger">
              {{ isWheelchairError }}
            </div>
            <div *ngIf="isJoinInviteSent" class="alert alert-success">
              You have already sent an invite for this tournament
            </div>
            <div *ngIf="isUserTeamRegistered" class="alert alert-success">
              Your team is registered in this tournament
            </div>
            <div *ngIf="isAgeError" class="alert alert-danger">
              {{ isAgeError }}
            </div>
          </div>

          <div *ngIf="registrationClosed; else noFixtures">
            <div *ngIf="isDataLoaded">
              <div class="navigate-components">
                <div class="inner" (click)="navigateFixturestoMatches($event)">
                  <div [ngClass]="{ active: showFixtures }">Main Draw</div>
                  <div [ngClass]="{ active: showMyMatches }">My Matches</div>
                  <div [ngClass]="{ active: showStandings }">Standings</div>
                </div>
              </div>

              <app-fixtures
                [ngStyle]="{ display: showFixtures ? 'block' : 'none' }"
                [tournament]="tournamentDoc"
              ></app-fixtures>
              <app-player-matches
                [ngStyle]="{ display: showMyMatches ? 'block' : 'none' }"
                [tournament]="tournamentDoc"
              ></app-player-matches>
              <app-standings
                [ngStyle]="{ display: showStandings ? 'block' : 'none' }"
                [tournament]="tournamentDoc"
              ></app-standings>
            </div>
          </div>

          <section
            *ngIf="
              tournamentData.category === 'singles' && isJoinTournamentModalOpen
            "
            class="modal"
          >
            <div class="modal-background">
              <div class="modal-body">
                <ng-container *ngIf="userObj.wallet < tournamentData.entryFee">
                  <div class="alert alert-danger">
                    You have insufficient balance in your account. Please
                    recharge and try again.
                  </div>
                  <div class="join-buttons">
                    <button
                      type="button"
                      [routerLink]="['/tennis/user/wallet', { redirectTo: router.url }]"
                      (click)="closeModal()"
                    >
                      Okay
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="userObj.wallet >= tournamentData.entryFee">
                  <p>
                    This tournament requires you to pay
                    <strong>${{ tournamentData.entryFee }}</strong
                    >. Would you like to proceed?
                  </p>

                  <div *ngIf="!isModalLoading">
                    <div class="join-buttons">
                      <button type="button" (click)="joinSinglesTournament()">
                        Yes
                      </button>
                      <button
                        type="button"
                        (click)="closeJoinTournamentModal()"
                      >
                        No
                      </button>
                    </div>
                  </div>
                  <div *ngIf="isModalLoading">
                    <div class="loader">
                      <img
                        src="../../../assets/image/ajax-loader.gif"
                        alt="ajax-loader"
                      />
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </section>

          <section *ngIf="isRegisteredPlayersModalOpen" class="modal">
            <div class="modal-background">
              <div class="modal-body modal-body-registered-players">
                <div
                  class="close-modal"
                  (click)="closeRegisteredPlayersModal()"
                >
                  x
                </div>
                <ng-container *ngIf="tournamentData.category === 'singles'">
                  <div
                    *ngIf="
                      registeredPlayers.length > 0;
                      else noRegisteredPlayers
                    "
                  >
                    <div class="heading">Registered Players</div>

                    <div class="players-list">
                      <ul>
                        <li *ngFor="let player of registeredPlayers">
                          {{ player.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="tournamentData.category === 'doubles'">
                  <div
                    class="registered-teams"
                    *ngIf="registeredTeams.length > 0; else noRegisteredPlayers"
                  >
                    <div class="heading">Registered Teams</div>

                    <div class="teams-list">
                      <ul>
                        <li *ngFor="let team of registeredTeams">
                          <div class="team-name">{{ team.teamName }}</div>
                          <div class="player">1. {{ team.playerOne.name }}</div>
                          <div class="player">2. {{ team.playerTwo.name }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noRegisteredPlayers>
                  <div class="text">
                    Currently there are no registered players in this
                    tournament.
                  </div>
                </ng-template>
              </div>
            </div>
          </section>

          <section
            *ngIf="
              tournamentData.category === 'doubles' && isUserTeamsModalOpen
            "
            class="modal"
          >
            <div class="modal-background">
              <div class="modal-body modal-body-registered-players">
                <div class="close-modal" (click)="closeUserTeamsModal()">x</div>
                <ng-container *ngIf="userObj.wallet < tournamentData.entryFee">
                  <div class="alert alert-danger">
                    You have insufficient balance in your account. Please
                    recharge and try again.
                  </div>
                  <div class="join-buttons">
                    <button
                      type="button"
                      [routerLink]="['/tennis/user/wallet', { redirectTo: router.url }]"
                      (click)="closeModal()"
                    >
                      Okay
                    </button>
                  </div>
                </ng-container>

                <ng-container *ngIf="userObj.wallet >= tournamentData.entryFee">
                  <div *ngIf="!isJoinWithCurrTeam">
                    <div
                      class="registered-teams"
                      *ngIf="userTeams.length > 0; else noRegisteredPlayers"
                    >
                      <div class="heading">Your Teams</div>
                      <p></p>
                      <div class="alert alert-info">
                        As you click on the join button, you will be charged
                        with the tournament fee and an invite will be sent to
                        the other player. This invite will be valid for 48
                        hours. If the other player doesn't accept this invite
                        within 48 hours, then the system will refund your
                        charged fee.
                      </div>
                      <div class="teams-list user-teams">
                        <ul>
                          <li *ngFor="let team of userTeams">
                            <div class="team-name">
                              <div class="name">{{ team.name }}</div>
                              <button
                                type="button"
                                (click)="joinWithCurrTeam(team)"
                              >
                                Join with this team
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="field">
                        <label>Full Name</label>
                        <input type="text" class="form-control" >
                        <!-- <div class="errorMessage" *ngIf="submitAttempt && formGroup.get('name').invalid">Full name is required</div> -->
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isJoinWithCurrTeam">
                    <div *ngIf="isModalLoading" class="loader">
                      <img
                        src="../../../assets/image/ajax-loader.gif"
                        alt="ajax-loader"
                      />
                    </div>

                    <div *ngIf="isJoinInviteSent" class="alert alert-success">
                      Your join invite has been sent. Once your partner
                      confirms, your team will be registered in the tournament.
                    </div>
                  </div>
                </ng-container>

                <ng-template #noRegisteredPlayers>
                  <div class="text">
                    Currently you have no teams registered or your teams don't
                    match with this tournament's criteria.
                  </div>
                </ng-template>
              </div>
            </div>
          </section>
        </div>

        <div class="one-on-one-match-wrapper" *ngIf="isOneOnOneMatch">
          <div *ngIf="isOneOnOneMatchLoaded">
            <ng-container *ngIf="tournamentData.category === 'singles'">
              <div class="players">
                <div class="player player-one">
                  <div class="name">
                    <span>Name:</span> {{ oneOnOnePlayers.teamA.firstName }}
                    {{ oneOnOnePlayers.teamA.lastName }}
                  </div>
                  <div class="points">
                    <span>Viictor Points:</span>
                    {{ oneOnOnePlayers.teamA.victorPoints }}
                  </div>
                  <div class="kit">
                    {{ oneOnOnePlayers.home === 'teamA' ? 'Home' : 'Away' }}
                  </div>
                </div>
                <div class="vs">VS</div>
                <div class="player player-two">
                  <div class="name">
                    <span>Name:</span> {{ oneOnOnePlayers.teamB.firstName }}
                    {{ oneOnOnePlayers.teamB.lastName }}
                  </div>
                  <div class="points">
                    <span>Viictor Points:</span>
                    {{ oneOnOnePlayers.teamB.victorPoints }}
                  </div>
                  <div class="kit">
                    {{ oneOnOnePlayers.home === 'teamB' ? 'Home' : 'Away' }}
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="tournamentData.category === 'doubles'">
              <div class="players">
                <div class="player player-one">
                  <div class="name">
                    <span>Name:</span> {{ oneOnOnePlayers.teamA.name }}
                  </div>
                  <div class="points">
                    <span>Viictor Points:</span>
                    {{ oneOnOnePlayers.teamA.victorPoints }}
                  </div>
                  <div class="kit">
                    {{ oneOnOnePlayers.home === 'teamA' ? 'Home' : 'Away' }}
                  </div>
                </div>
                <div class="vs">VS</div>
                <div class="player player-two">
                  <div class="name">
                    <span>Name:</span> {{ oneOnOnePlayers.teamB.name }}
                  </div>
                  <div class="points">
                    <span>Viictor Points:</span>
                    {{ oneOnOnePlayers.teamB.victorPoints }}
                  </div>
                  <div class="kit">
                    {{ oneOnOnePlayers.home === 'teamB' ? 'Home' : 'Away' }}
                  </div>
                </div>
              </div>
            </ng-container>

            <div
              *ngIf="
                isUserRegistered || isUserTeamRegistered;
                else userNotExists
              "
            >
              <div
                class="scores"
                *ngIf="oneOnOneFixtureData.frontEndState; else noScores"
              >
                <div
                  class="score-uploaded alert-secondary"
                  *ngIf="
                    oneOnOneFixtureData.frontEndState === 'score-uploaded' ||
                    oneOnOneFixtureData.frontEndState === 'disputed-pending' ||
                    oneOnOneFixtureData.frontEndState === 'disputed'
                  "
                >
                  Scores have been uploaded
                </div>
                <div
                  class="completed"
                  *ngIf="oneOnOneFixtureData.frontEndState === 'completed'"
                >
                  <ng-container
                    *ngTemplateOutlet="matchCompleted"
                  ></ng-container>
                </div>
                <div
                  class="no-scores"
                  *ngIf="
                    !oneOnOneFixtureData.frontEndState &&
                    oneOnOneFixtureData.frontEndState !== 'score-uploaded' &&
                    oneOnOneFixtureData.frontEndState !== 'disputed-pending' &&
                    oneOnOneFixtureData.frontEndState !== 'disputed' &&
                    oneOnOneFixtureData.frontEndState !== 'completed'
                  "
                >
                  Scores are not uploaded
                  <button type="button" (click)="openScoreModal()">
                    Add Score
                  </button>
                </div>
              </div>
              <ng-template #noScores>
                <div class="no-scores">
                  Scores are not uploaded
                  <button type="button" (click)="openScoreModal()">
                    Add Score
                  </button>
                </div>
              </ng-template>
            </div>

            <ng-template #userNotExists>
              <div
                class="completed"
                *ngIf="oneOnOneFixtureData.frontEndState === 'completed'"
              >
                <ng-container *ngTemplateOutlet="matchCompleted"></ng-container>
              </div>
              <div
                class="score-uploaded alert"
                *ngIf="oneOnOneFixtureData.frontEndState !== 'completed'"
              >
                Scores are not uploaded yet. Stay Tuned
              </div>
            </ng-template>

            <section *ngIf="isScoreModalOpen" class="modal">
              <div class="modal-background">
                <div class="modal-body">
                  <div class="heading">Add Score</div>

                  <div
                    class="player"
                    *ngIf="tournamentData.category === 'singles'"
                    [ngClass]="{
                      'add-tie-breaker':
                        isSetOneTie || isSetTwoTie || isSetThreeTie
                    }"
                  >
                    {{ oneOnOnePlayers.teamA.firstName }}
                    {{ oneOnOnePlayers.teamA.lastName }}
                  </div>
                  <div
                    class="player"
                    *ngIf="tournamentData.category === 'doubles'"
                    [ngClass]="{
                      'add-tie-breaker':
                        isSetOneTie || isSetTwoTie || isSetThreeTie
                    }"
                  >
                    {{ oneOnOnePlayers.teamA.name }}
                  </div>

                  <div class="input-fields">
                    <div class="fields-inner">
                      <form [formGroup]="formGroup">
                        <div class="set set-one">
                          <div class="set-not-tie">
                            <input
                              type="text"
                              formControlName="setOneA"
                              pattern="[0-7]"
                              maxlength="1"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setOneA.valid,
                                error:
                                  formGroup.controls.setOneA.touched &&
                                  formGroup.controls.setOneA.invalid
                              }"
                            />
                            <input
                              type="text"
                              formControlName="setOneB"
                              pattern="[0-7]"
                              maxlength="1"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setOneB.valid,
                                error:
                                  formGroup.controls.setOneB.touched &&
                                  formGroup.controls.setOneB.invalid
                              }"
                            />
                          </div>
                          <div class="set-tie" *ngIf="isSetOneTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input
                              type="text"
                              formControlName="setOneTieA"
                              pattern="[0-99]{1,2}"
                              maxlength="2"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setOneTieA.valid,
                                error:
                                  formGroup.controls.setOneTieA.touched &&
                                  formGroup.controls.setOneTieA.invalid
                              }"
                            />
                            <input
                              type="text"
                              formControlName="setOneTieB"
                              pattern="[0-99]{1,2}"
                              maxlength="2"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setOneTieB.valid,
                                error:
                                  formGroup.controls.setOneTieB.touched &&
                                  formGroup.controls.setOneTieB.invalid
                              }"
                            />
                          </div>
                          <div class="set-label">Set One</div>
                        </div>
                        <div class="set set-two">
                          <div class="set-not-tie">
                            <input
                              type="text"
                              formControlName="setTwoA"
                              pattern="[0-7]"
                              maxlength="1"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setTwoA.valid,
                                error:
                                  formGroup.controls.setTwoA.touched &&
                                  formGroup.controls.setTwoA.invalid
                              }"
                            />
                            <input
                              type="text"
                              formControlName="setTwoB"
                              pattern="[0-7]"
                              maxlength="1"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setTwoB.valid,
                                error:
                                  formGroup.controls.setTwoB.touched &&
                                  formGroup.controls.setTwoB.invalid
                              }"
                            />
                          </div>
                          <div class="set-tie" *ngIf="isSetTwoTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input
                              type="text"
                              formControlName="setTwoTieA"
                              pattern="[0-99]{1,2}"
                              maxlength="2"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setTwoTieA.valid,
                                error:
                                  formGroup.controls.setTwoTieA.touched &&
                                  formGroup.controls.setTwoTieA.invalid
                              }"
                            />
                            <input
                              type="text"
                              formControlName="setTwoTieB"
                              pattern="[0-99]{1,2}"
                              maxlength="2"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setTwoTieB.valid,
                                error:
                                  formGroup.controls.setTwoTieB.touched &&
                                  formGroup.controls.setTwoTieB.invalid
                              }"
                            />
                          </div>
                          <div class="set-label">Set Two</div>
                        </div>
                        <div *ngIf="isSetThree" class="set set-three">
                          <div class="set-not-tie">
                            <input
                              type="text"
                              formControlName="setThreeA"
                              pattern="[0-7]"
                              maxlength="1"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setThreeA?.valid,
                                error:
                                  formGroup.controls.setThreeA?.touched &&
                                  formGroup.controls.setThreeA?.invalid
                              }"
                            />
                            <input
                              type="text"
                              formControlName="setThreeB"
                              pattern="[0-7]"
                              maxlength="1"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setThreeB?.valid,
                                error:
                                  formGroup.controls.setThreeB?.touched &&
                                  formGroup.controls.setThreeB?.invalid
                              }"
                            />
                          </div>
                          <div class="set-tie" *ngIf="isSetThreeTie">
                            <div class="tie-breaker">Tie Breaker</div>
                            <input
                              type="text"
                              formControlName="setThreeTieA"
                              pattern="[0-99]{1,2}"
                              maxlength="2"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setThreeTieA?.valid,
                                error:
                                  formGroup.controls.setThreeTieA?.touched &&
                                  formGroup.controls.setThreeTieA?.invalid
                              }"
                            />
                            <input
                              type="text"
                              formControlName="setThreeTieB"
                              pattern="[0-99]{1,2}"
                              maxlength="2"
                              placeholder="0"
                              [ngClass]="{
                                valid: formGroup.controls.setThreeTieB?.valid,
                                error:
                                  formGroup.controls.setThreeTieB?.touched &&
                                  formGroup.controls.setThreeTieB?.invalid
                              }"
                            />
                          </div>
                          <div class="set-label">Set Three</div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    class="player"
                    *ngIf="tournamentData.category === 'singles'"
                    [ngClass]="{
                      'add-tie-breaker':
                        isSetOneTie || isSetTwoTie || isSetThreeTie
                    }"
                  >
                    {{ oneOnOnePlayers.teamB.firstName }}
                    {{ oneOnOnePlayers.teamB.lastName }}
                  </div>

                  <div
                    class="player"
                    *ngIf="tournamentData.category === 'doubles'"
                    [ngClass]="{
                      'add-tie-breaker':
                        isSetOneTie || isSetTwoTie || isSetThreeTie
                    }"
                  >
                    {{ oneOnOnePlayers.teamB.name }}
                  </div>

                  <div class="join-buttons">
                    <button
                      type="button"
                      class="btn-cancel"
                      (click)="closeModal()"
                    >
                      Cancel
                    </button>
                    <button type="button" (click)="addOneOnOneScore(formGroup)">
                      Add Score
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </ng-container>
    </div>
    <app-create-challenge></app-create-challenge>
    <div class="clearfix"></div>
  </div>
  <app-newsletter></app-newsletter>
</div>

<ng-template #noFixtures>
  <div class="alert alert-default no-fixtures">
    Currently there are no fixtures. Fixtures will be created once registration
    is closed.
  </div>
</ng-template>
<ng-template #matchCompleted>
  <div class="alert alert-success">This match has been completed</div>
  <div class="show-scores">
    <ng-container *ngIf="tournamentData.category === 'singles'">
      <div class="player">
        {{ oneOnOnePlayers.teamA.firstName }}
        {{ oneOnOnePlayers.teamA.lastName }}
        <span *ngIf="oneOnOnePlayers.winner === 'teamA'"
          ><img src="../../../assets/image/trophy.webp" alt="trophy"
        /></span>
      </div>
    </ng-container>
    <ng-container *ngIf="tournamentData.category === 'doubles'">
      <div class="player">
        {{ oneOnOnePlayers.teamA.name }}
        <span *ngIf="oneOnOnePlayers.winner === 'teamA'"
          ><img src="../../../assets/image/trophy.webp" alt="trophy"
        /></span>
      </div>
    </ng-container>

    <div
      *ngIf="oneOnOneFixtureData.teamA.newScore; else matchScore"
      class="input-fields"
    >
      <div class="setOne">
        <div class="input setOneA">
          {{ oneOnOneFixtureData.teamA.newScore.setOne }}
        </div>
        <div class="input setOneB">
          {{ oneOnOneFixtureData.teamB.newScore.setOne }}
        </div>
      </div>
      <div
        class="tie setOneTie"
        *ngIf="oneOnOneFixtureData.teamA.newScore.setOneTie"
      >
        <label>Tie-Breaker</label>
        <div class="input setOneTieA">
          {{ oneOnOneFixtureData.teamA.newScore.setOneTie }}
        </div>
        <div class="input setOneTieB">
          {{ oneOnOneFixtureData.teamB.newScore.setOneTie }}
        </div>
      </div>
      <div class="setTwo">
        <div class="input setTwoA">
          {{ oneOnOneFixtureData.teamA.newScore.setTwo }}
        </div>
        <div class="input setTwoB">
          {{ oneOnOneFixtureData.teamB.newScore.setTwo }}
        </div>
      </div>
      <div
        class="tie setTwoTie"
        *ngIf="oneOnOneFixtureData.teamA.newScore.setTwoTie"
      >
        <label>Tie-Breaker</label>
        <div class="input setTwoTieA">
          {{ oneOnOneFixtureData.teamA.newScore.setTwoTie }}
        </div>
        <div class="input setTwoTieB">
          {{ oneOnOneFixtureData.teamB.newScore.setTwoTie }}
        </div>
      </div>
      <div class="setThree" *ngIf="oneOnOneFixtureData.teamA.newScore.setThree">
        <div class="input setThreeA">
          {{ oneOnOneFixtureData.teamA.newScore.setThree }}
        </div>
        <div class="input setThreeB">
          {{ oneOnOneFixtureData.teamB.newScore.setThree }}
        </div>
      </div>
      <div
        class="tie setThreeTie"
        *ngIf="oneOnOneFixtureData.teamA.newScore.setThreeTie"
      >
        <label>Tie-Breaker</label>
        <div class="input setThreeTieA">
          {{ oneOnOneFixtureData.teamA.newScore.setThreeTie }}
        </div>
        <div class="input setThreeTieB">
          {{ oneOnOneFixtureData.teamB.newScore.setThreeTie }}
        </div>
      </div>
    </div>
    <ng-template #matchScore>
      <div class="input-fields">
        <div class="setOne">
          <div class="input setOneA">
            {{ oneOnOneFixtureData.teamA.matchScore.setOne }}
          </div>
          <div class="input setOneB">
            {{ oneOnOneFixtureData.teamB.matchScore.setOne }}
          </div>
        </div>
        <div
          class="tie setOneTie"
          *ngIf="oneOnOneFixtureData.teamA.matchScore.setOneTie"
        >
          <label>Tie-Breaker</label>
          <div class="input setOneTieA">
            {{ oneOnOneFixtureData.teamA.matchScore.setOneTie }}
          </div>
          <div class="input setOneTieB">
            {{ oneOnOneFixtureData.teamB.matchScore.setOneTie }}
          </div>
        </div>
        <div class="setTwo">
          <div class="input setTwoA">
            {{ oneOnOneFixtureData.teamA.matchScore.setTwo }}
          </div>
          <div class="input setTwoB">
            {{ oneOnOneFixtureData.teamB.matchScore.setTwo }}
          </div>
        </div>
        <div
          class="tie setTwoTie"
          *ngIf="oneOnOneFixtureData.teamA.matchScore.setTwoTie"
        >
          <label>Tie-Breaker</label>
          <div class="input setTwoTieA">
            {{ oneOnOneFixtureData.teamA.matchScore.setTwoTie }}
          </div>
          <div class="input setTwoTieB">
            {{ oneOnOneFixtureData.teamB.matchScore.setTwoTie }}
          </div>
        </div>
        <div
          class="setThree"
          *ngIf="oneOnOneFixtureData.teamA.matchScore.setThree"
        >
          <div class="input setThreeA">
            {{ oneOnOneFixtureData.teamA.matchScore.setThree }}
          </div>
          <div class="input setThreeB">
            {{ oneOnOneFixtureData.teamB.matchScore.setThree }}
          </div>
        </div>
        <div
          class="tie setThreeTie"
          *ngIf="oneOnOneFixtureData.teamA.matchScore.setThreeTie"
        >
          <label>Tie-Breaker</label>
          <div class="input setThreeTieA">
            {{ oneOnOneFixtureData.teamA.matchScore.setThreeTie }}
          </div>
          <div class="input setThreeTieB">
            {{ oneOnOneFixtureData.teamB.matchScore.setThreeTie }}
          </div>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="tournamentData.category === 'singles'">
      <div class="player">
        {{ oneOnOnePlayers.teamB.firstName }}
        {{ oneOnOnePlayers.teamB.lastName }}
        <span *ngIf="oneOnOnePlayers.winner === 'teamB'"
          ><img src="../../../assets/image/trophy.webp" alt="trophy"
        /></span>
      </div>
    </ng-container>
    <ng-container *ngIf="tournamentData.category === 'doubles'">
      <div class="player">
        {{ oneOnOnePlayers.teamB.name }}
        <span *ngIf="oneOnOnePlayers.winner === 'teamB'"
          ><img src="../../../assets/image/trophy.webp" alt="trophy"
        /></span>
      </div>
    </ng-container>
  </div>
</ng-template>

<section *ngIf="isMapModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body modal-body-registered-players">
      <div class="close-modal" (click)="closeMapModal()">x</div>
      <div class="agm-location">
        <ng-container *ngIf="tournamentData.isLocationFound">
          <h3 class="location">{{ tournamentData.location }}</h3>
          <agm-map
            [latitude]="tournamentData.map.latitude"
            [longitude]="tournamentData.map.longitude"
            [zoom]="12"
          >
            <agm-marker
              [latitude]="tournamentData.map.latitude"
              [longitude]="tournamentData.map.longitude"
            >
            </agm-marker>
          </agm-map>
        </ng-container>
        <ng-container *ngIf="!tournamentData.isLocationFound">
          <p class="alert alert-danger">
            Cannot find {{ tournamentData.location }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<section *ngIf="isPlayerRatingModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="heading">Player Rating</div>
      <p class="text">
        Please rate your opponent in terms of 1) pre match interactions, 2) on
        time or late, 3) respectful, 4) fair line calls, 5) sportsmanship, 6)
        able to play hard but still be fair.
        <br />
        You rating is crucial for us to know how the player performed. And it
        will be beneficial for other players to see the the overall performance
        of the player.
      </p>
      <div class="player-rating">
        <star-rating
          value="{{ playerRating }}"
          totalstars="{{ totalStars }}"
          checkedcolor="#6fbd22"
          uncheckedcolor="#d6d6d6"
          size="35px"
          readonly="false"
          (rate)="onRate($event)"
        >
        </star-rating>

        <p style="text-align: center; font-weight: 500">
          {{
            playerRating === 1
              ? 'Bad'
              : playerRating === 2
              ? 'Not so good'
              : playerRating === 3
              ? 'Okay'
              : playerRating === 4
              ? 'Good'
              : playerRating === 5
              ? 'Excellent'
              : 'Error'
          }}
        </p>
      </div>

      <div class="join-buttons">
        <button type="button" class="btn-cancel" (click)="confirmRating()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</section>

<section *ngIf="isWithdrawModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="close-modal" (click)="closeWithdrawModal()">x</div>
      <p>Would you like to withdraw yourself from the tournament?</p>
      <div class="join-buttons" *ngIf="!isModalLoading">
        <button type="button" class="btn-cancel" (click)="closeWithdrawModal()">
          No
        </button>
        <button type="button" (click)="withdrawFromMatch()">Yes</button>
      </div>
      <div class="loader" *ngIf="isModalLoading">
        <img src="../../../assets/image/ajax-loader.gif" alt="loader-gif" />
      </div>
    </div>
  </div>
</section>
