<div class="news-letter-section" *ngIf="!authService.authenticated()">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="form-container">
          <h2 class="bold-heading3 p-b-20">
            <span>Sign up to our</span><strong>newsletter</strong><br />
            <i>And be always up-to-date!</i>
          </h2>
          <form class="newsletter d-flex align-items-center">
            <input
              class="form-control search-input"
              type="search"
              placeholder="your email here"
            />
            <button>Sign up</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>