import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.scss']
})
export class StandingsComponent implements OnInit {

  @Input() tournament;
  tournamentData;
  isStandings: boolean;
  standings: any[];

  constructor(private firestore: AngularFirestore) { }

  async collectData(standings: any[]) {
    try {
      let tournament = this.tournamentData;
      let standingsData = Promise.all<any[]>(standings.map(async el => {
        let data;
        if(tournament.category === 'singles') {
          data = (await this.firestore.collection('Users').doc(el.id).get().toPromise()).data()
          data.name = data.firstName + ' ' + data.lastName;
        } else data = (await this.firestore.collection('Teams').doc(el.id).get().toPromise()).data()
        let userData = el;
        userData.name = data.name
        userData.victorPoints = data.victorPoints;
        return userData;
      }));
      this.standings = (await standingsData).filter(Boolean)
    } catch(e) { console.log(e) }
  }

  ngOnInit(): void {
    this.tournamentData = this.tournament.data();
    if(this.tournamentData.standings && this.tournamentData.standings.length > 0) {
      let standings = this.tournamentData.standings;
      this.collectData(standings);
      this.isStandings = true;
    } else { this.isStandings = false }
  }

}
