import { HttpClient } from '@angular/common/http';
import { AuthService } from './../../services/auth/auth.service';
import { ProfileService } from '../../services/profile/profile.service';
import { first } from 'rxjs/internal/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { StarRatingComponent } from 'ng-starrating';
import { environment } from 'src/environments/environment';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';

@Component({
  selector: 'app-tournament-page',
  templateUrl: './tournament-page.component.html',
  styleUrls: ['./tournament-page.component.scss'],
})
export class TournamentPageComponent implements OnInit {
  userObj;
  tournamentDoc;
  tournamentData: any;
  registeredPlayers = [];
  registeredTeams = [];

  userId: string;
  tournamentId: string;
  errorMessage: string = '';
  successMessage: string = '';

  isUserRegistered: boolean;
  registrationClosed: boolean;
  isDataLoaded: boolean;
  isButtonActive: boolean;
  isJoinTournamentModalOpen: boolean = false;
  isRegisteredPlayersModalOpen: boolean = false;
  showFixtures: boolean = true;
  showLosersFixtures: boolean;
  showMyMatches: boolean;
  showStandings: boolean;
  isKnockoutMatch: boolean;
  isBoxMatch: boolean;

  isOneOnOneMatch: boolean;
  isOneOnOneMatchLoaded: boolean;
  oneOnOnePlayers;
  isScoreModalOpen: boolean;
  oneOnOneScoreBy: string;
  oneOnOneFixtureData;

  formGroup: FormGroup;
  isSetOneTie: boolean;
  isSetTwoTie: boolean;
  isSetThreeTie: boolean;
  searchUser = new FormControl('');

  isTournamentLoading: boolean;

  isSetThree: boolean;
  isUserExistInMatch: boolean;

  $currUserObj: Subscription;

  userTeams = [];
  isUserTeamsModalOpen: boolean;
  isJoinWithCurrTeam: boolean;
  currJoiningTeam;
  isJoinInviteSent: boolean;
  isUserTeamRegistered: boolean;
  isModalLoading: boolean;

  isMapModalOpen: boolean;
  isRegistrationFull: string;
  isGenderError: string;
  isWheelchairError: string;

  isPlayerRatingModalOpen: boolean;
  totalStars: number = 5;
  playerRating: number = 5;
  currentFirebaseAddress: any;
  invalidUrl: boolean;

  isAgeError: string;

  showOptionDropdown: boolean;
  isWithdrawModalOpen: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private fireFunctions: AngularFireFunctions,
    private http: HttpClient
  ) {}

  //ng-starrating
  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    this.playerRating = $event.newValue;
  }
  //

  isRegistrationClosed(data) {
    if (
      data.frontEndState === 'registration-closed' ||
      data.frontEndState === 'completed'
    ) {
      this.registrationClosed = true;
      this.isButtonActive = false;
      this.tournamentData.prize =
        this.tournamentData.category === 'doubles'
          ? (
              this.tournamentData.entryFee *
              this.tournamentData.players.length *
              0.85 *
              2
            ).toFixed(2)
          : (
              this.tournamentData.entryFee *
              this.tournamentData.players.length *
              0.85
            ).toFixed(2);
    } else {
      this.registrationClosed = false;
      this.canUserJoin();
      this.checkForFilters(data);
    }
  }

  async checkForFilters(data) {
    if (this.authService.authenticated()) {
      try {
        if (
          data.category === 'singles' &&
          (data.matchType === 'knockout' || data.matchType === 'box')
        ) {
          let user = (await this.profileService.getUserProfileData()).data();
          // Gender
          if (data.gender !== 'mixed') {
            if (data.gender !== user.gender) {
              this.isGenderError = `You cannot join this tournament. Only ${data.gender}s can join`;
              this.isButtonActive = false;
            }
          }
          // Wheelchair
          if (data.wheelChair !== user.wheelChair) {
            this.isWheelchairError = `You cannot join this tournament. Only players with${
              data.wheelChair === 'yes' ? '' : 'out'
            } wheelchair can join`;
            this.isButtonActive = false;
          }
        }
        if (data.category === 'doubles') {
          this.fetchUserTeams(data.gender, data.wheelChair);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  async getTournamentData() {
    try {
      let queryParams = await this.activatedRoute.params
        .pipe(first())
        .toPromise();
      let tournament = await this.firestore
        .collection('Matches')
        .doc(queryParams.id)
        .get()
        .toPromise();
      this.tournamentDoc = tournament;
      this.tournamentId = tournament.id;
      this.tournamentData = tournament.data();

      // Check if Tournament Exists
      if (!tournament.exists) {
        this.invalidUrl = true;
        this.isTournamentLoading = false;
        console.log('Match Not Found');
        return;
      } else {
        this.invalidUrl = false;
      }

      // Calculating Tier
      if (this.tournamentData.victorPoints <= 600)
        this.tournamentData.tier = 'Tier 1 Beginners';
      else if (this.tournamentData.victorPoints <= 1200)
        this.tournamentData.tier = 'Tier 2 Intermediate';
      else if (this.tournamentData.victorPoints <= 1800)
        this.tournamentData.tier = 'Tier 3 Advanced';
      else if (this.tournamentData.victorPoints > 1800)
        this.tournamentData.tier = 'Tier 4 Pro level';

      if (tournament.data().players && tournament.data().players.length > 0) {
        if (this.tournamentData.category === 'singles')
          this.getRegisteredPlayersData(tournament.data().players);
        else if (this.tournamentData.category === 'doubles')
          this.getRegisteredTeamsData(tournament.data().players);
      } else {
        this.registeredPlayers.length = 0;
      }

      // Checking for Registration Status
      this.isRegistrationClosed(tournament.data());

      // Checking for Singles or Doubles
      if (this.tournamentData.category === 'singles') {
        this.checkIfUserRegistered(this.tournamentData);
      }
      if (this.tournamentData.category === 'doubles') {
        this.checkIfUserTeamRegistered();
      }

      // For Knockout, Box or One-on-One Match
      if (this.tournamentData.matchType === 'knockout') {
        this.isKnockoutMatch = true;
        this.getLocationMap(this.tournamentData.location);
      } else if (this.tournamentData.matchType === 'box') {
        this.isBoxMatch = true;
        this.getLocationMap(this.tournamentData.location);
        this.checkIfMaxPlayers(this.tournamentData);
      } else if (this.tournamentData.matchType === 'one-on-one') {
        this.isOneOnOneMatch = true;
        this.getTournamentFixtureData(this.tournamentId);
      }

      this.isDataLoaded = true;
      this.isTournamentLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  getRegisteredPlayersData(players) {
    let promises = [];
    players.forEach((el) => {
      let tempUserObj = {};
      tempUserObj = this.gatherRegisteredPlayerObject(el.id);
      promises.push(tempUserObj);
    });

    Promise.all(promises)
      .then((res) => {
        this.registeredPlayers = res.slice();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getRegisteredTeamsData(teams) {
    let promises = [];
    teams.forEach((el) => {
      let tempUserObj = {};
      tempUserObj = this.gatherRegisteredTeamObject(el.id);
      promises.push(tempUserObj);
    });

    try {
      let promiseRes = await Promise.all(promises);
      this.registeredTeams = promiseRes.slice();
    } catch (e) {
      console.log(e);
    }
  }

  /////////////////////

  gatherRegisteredPlayerObject(userId) {
    return this.firestore
      .collection('Users')
      .doc(userId)
      .get()
      .toPromise()
      .then((res) => {
        let userObj: any = {};
        userObj.name = res.data().firstName + ' ' + res.data().lastName;
        userObj.email = res.data().email;
        userObj.id = userId;
        return userObj;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async gatherRegisteredTeamObject(teamId) {
    try {
      let team = await this.firestore
        .collection('Teams')
        .doc(teamId)
        .get()
        .toPromise();
      let playerOne = (
        await this.firestore
          .collection('Users')
          .doc(team.data().members[0].id)
          .get()
          .toPromise()
      ).data();
      let playerTwo = (
        await this.firestore
          .collection('Users')
          .doc(team.data().members[1].id)
          .get()
          .toPromise()
      ).data();

      let teamObj = {
        playerOne: {
          email: playerOne.email,
          name: playerOne.firstName + ' ' + playerOne.lastName,
          id: team.data().members[0].id,
        },
        playerTwo: {
          email: playerTwo.email,
          name: playerTwo.firstName + ' ' + playerTwo.lastName,
          id: team.data().members[1].id,
        },
        teamId: teamId,
        teamName: team.data().name,
      };

      return teamObj;
    } catch (e) {
      console.log(e);
    }
  }

  /////////////////////

  async fetchUserTeams(gender, wheelChair) {
    try {
      let rawTeams = await this.firestore.collection('Teams').get().toPromise();
      let filteredTeams = rawTeams.docs.filter((team) => {
        let teamData = team.data();
        return (
          teamData?.members &&
          (teamData.members[0].id === this.authService.user.uid ||
            teamData.members[1].id === this.authService.user.uid)
        );
      });
      let userTeams: any = filteredTeams.slice();
      if (filteredTeams.length > 0) {
        const getTier = (victorPoints) => {
          return victorPoints > 1800
            ? 3000
            : victorPoints <= 1800 && victorPoints >= 1201
            ? 1800
            : victorPoints <= 1200 && victorPoints >= 601
            ? 1200
            : victorPoints <= 600
            ? 600
            : 0;
        };

        let tournamentTier = getTier(this.tournamentData.victorPoints);
        userTeams = userTeams.map((el) => {
          let teamObj: any = {};
          if (el.data().gender === gender || gender === 'mixed') {
            if (el.data().wheelChair === wheelChair) {
              let teamTier = getTier(el.data().victorPoints);
              if (teamTier <= tournamentTier) {
                teamObj = el.data();
                teamObj.teamId = el.id;
                return teamObj;
              }
            }
          }
        });
        userTeams = userTeams.filter(Boolean);
        this.userTeams = userTeams.slice();
      }
    } catch (e) {
      console.log(e);
    }
  }

  checkIfUserRegistered(tournaments) {
    let players = [];
    if (tournaments.players && tournaments.players.length > 0) {
      players = [...tournaments.players];
    } else {
      players.length = 0;
    }

    if (players.length > 0 && this.authService.authenticated()) {
      players.some((player) => {
        if (player.email === this.authService.user.email) {
          this.isUserRegistered = true;
          return true;
        }
      });
    }
  }

  canUserJoin() {
    if (this.authService.authenticated()) {
      this.profileService.getUserProfileData().then((res) => {
        this.userId = res.id;
        this.userObj = res.data();
        if (this.tournamentData.category === 'singles') {
          // Check if User Already Joined
          if (
            this.isDataLoaded &&
            this.tournamentData.players &&
            this.tournamentData.players.length > 0 &&
            this.tournamentData.players.some((el) => el.id === this.userId)
          ) {
            this.isButtonActive = false;
            this.successMessage = 'You have already joined this tournament';
          } // Check if User Can Join
          else {
            if (
              this.isDataLoaded &&
              this.userObj.victorPoints <= this.tournamentData.victorPoints
            ) {
              let isAgeLimit = this.checkAgeLimit();
              if (isAgeLimit) {
                this.activeJoinButton();
              } else {
                this.isButtonActive = false;
              }
            } else {
              this.isButtonActive = false;
              this.errorMessage =
                "You cannot join this tournament since your tier doesn't match with this tournament";
            }
          }
        } else if (this.tournamentData.category === 'doubles') {
          this.checkIfUserTeamRegistered();
        }
      });
    } else {
      this.activeJoinButton();
      this.errorMessage = 'You need to login to join this tournament';
    }
  }

  checkAgeLimit() {
    let ageError =
      "You cannot join this tournament. Your age doesn't match with the tournament age limit";
    let playerAge =
      new Date().getFullYear() - this.userObj.dateOfBirth.split('-')[0];
    if (
      this.tournamentData.ageLimit &&
      this.tournamentData.ageLimit !== 'open'
    ) {
      let tournamentAge = parseInt(this.tournamentData.ageLimit.slice(1));
      if (this.tournamentData.ageLimit[0] === 'o') {
        if (playerAge >= tournamentAge) return true;
        else {
          this.isAgeError = ageError;
          return false;
        }
      } else {
        if (playerAge <= tournamentAge) return true;
        else {
          this.isAgeError = ageError;
          return false;
        }
      }
    } else {
      return true;
    }
  }

  async checkIfUserTeamRegistered() {
    if (this.authService.authenticated()) {
      try {
        let userTeams = await this.firestore
          .collection('Teams', (ref) =>
            ref.where('members', 'array-contains', {
              email: this.authService.user.email,
              id: this.authService.user.uid,
            })
          )
          .get()
          .toPromise();
        if (this.tournamentData.players) {
          let matchTeams = this.tournamentData.players;
          if (userTeams.docs.length > 0) {
            let isTeamFound: boolean;
            userTeams.docs.some((userTeam, index) => {
              matchTeams.some((matchTeam) => {
                if (userTeam.id === matchTeam.id) {
                  this.isUserTeamRegistered = true;
                  this.isButtonActive = false;
                  isTeamFound = true;
                  return true;
                }
              });
              if (isTeamFound) {
                return true;
              } else {
                if (index === userTeams.docs.length - 1) {
                  this.userTeamNotRegistered();
                }
              }
            });
          } else {
            this.userTeamNotRegistered();
          }
        } else {
          this.userTeamNotRegistered();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  userTeamNotRegistered() {
    this.isUserTeamRegistered = false;
    this.activeJoinButton();
    this.checkTeamInvite();
  }

  async checkTeamInvite() {
    if (this.authService.authenticated()) {
      try {
        let userProfile = await this.profileService.getUserProfileData();
        this.userId = userProfile.id;
        this.userObj = userProfile.data();
        let invites = await this.firestore
          .collection('MatchTeamRequests', (ref) =>
            ref
              .where('matchId', '==', this.tournamentId)
              .where('senderId', '==', userProfile.id)
              .where('state', '==', 'pending')
          )
          .get()
          .toPromise();
        if (invites.docs.length > 0) {
          this.isJoinInviteSent = true;
          this.isButtonActive = false;
        } else {
          this.isJoinInviteSent = false;
          // Check if User can Join
          let isAgeLimit = this.checkAgeLimit();
          if (isAgeLimit) {
            this.activeJoinButton();
          } else {
            this.isButtonActive = false;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  checkIfMaxPlayers(tournament) {
    if (tournament.players) {
      if (tournament.players.length >= 5) {
        this.isButtonActive = false;
        this.isRegistrationFull = `Registration for the tournament has been closed because we have reached the maximum number of registrations for the event. Thank you for your interest!`;
      }
    }
  }

  checkConditionsToJoin() {
    if (this.authService.authenticated()) {
      this.profileService.getUserProfileData().then((res) => {
        this.userId = res.id;
        this.userObj = res.data();
        if (this.isDataLoaded) {
          if (this.tournamentData.category === 'doubles') {
            this.openUserTeamsModal();
          } else if (this.tournamentData.category === 'singles') {
            this.openJoinTournamentModal();
          }
        }
      });
    } else {
      this.router
        .navigate(['tennis/users/login', { redirectTo: this.router.url }])
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async joinSinglesTournament() {
    try {
      this.isModalLoading = true;
      let response = await this.createTransactionforSinglesMatch(
        this.tournamentData
      );
      if (response.success) {
        let players = this.tournamentData.players;
        let userObj = {
          id: this.authService.user.uid,
          email: this.authService.user.email,
        };
        if (players) {
          players.push(userObj);
        } else {
          players = [userObj];
        }

        await this.firestore
          .collection('Matches')
          .doc(this.tournamentId)
          .update({ players });
        this.isModalLoading = false;
        this.successMessage = 'You have successfully joined this tournament';
        this.registeredPlayers.push({
          id: userObj.id,
          name: this.authService.user.displayName,
        });
        this.isButtonActive = false;
        this.closeJoinTournamentModal();
        if (this.tournamentData.matchType === 'box') {
          let tournamentDoc = await this.firestore
            .collection('Matches')
            .doc(this.tournamentId)
            .get()
            .toPromise();
          if (tournamentDoc.data().players.length > 4) {
            await this.firestore
              .collection('Matches')
              .doc(this.tournamentId)
              .update({
                frontEndState: 'registration-closed',
                state: 'generate-fixtures',
              });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async createTransactionforSinglesMatch(tournamentData) {
    try {
      // Calling backend API for transaction
      let transaction = this.fireFunctions.httpsCallable(
        'payments-processPayment'
      );
      let postObj = {
        matchId: this.tournamentId,
        amount: tournamentData.entryFee,
        currency: 'aud',
        uid: this.authService.user.uid,
      };

      let response: {
        success: boolean;
        transactionId: string;
      } = await transaction(postObj).toPromise();
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  getFormattedDate(date) {
    if (date) {
      let months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let fullDate = date.split('-').reverse();
      fullDate[1] = months[parseInt(fullDate[1]) - 1];
      return fullDate.join('/');
    }
    return 'No Date';
  }

  async openJoinTournamentModal() {
    document.body.classList.add('modal-open');
    this.isJoinTournamentModalOpen = true;
  }

  // private initConfig() {
  //   this.payPalConfig = {
  //     currency: "AUD",
  //     clientId: environment.paypalClientId,
  //     createOrderOnClient: (data) => <ICreateOrderRequest>{
  //       intent: 'AUTHORIZE',
  //       purchase_units: [{
  //         amount: {
  //           currency_code: 'AUD',
  //           value: this.tournamentData.entryFee.toString(),
  //         },
  //       }]
  //     },
  //     advanced: {
  //       commit: 'true',
  //       extraQueryParams: [{
  //         name: 'intent',
  //         value: 'authorize',
  //       }]
  //     },
  //     onApprove: async (data, actions) => {
  //       try {
  //         this.isModalLoading = true;
  //         let authorization = await actions.order.authorize();
  //         let authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;

  //         if(this.tournamentData.category === 'singles') {
  //           let order = this.fireFunctions.httpsCallable('payments-processPayment')
  //           let response: any = await order({ authId: authorizationID, matchId: this.tournamentId, amount: this.tournamentData.entryFee, currency: 'aud', uid: this.authService.user.uid  }).toPromise();
  //           if(response.success) {
  //             let tournament = await this.firestore.collection('Matches').doc(this.tournamentId).get().toPromise();
  //             let players = tournament.data().players;
  //             if(players) {
  //               players.push({ id: this.authService.user.uid, email: this.authService.user.email })
  //             } else {
  //               players = [{ id: this.authService.user.uid, email: this.authService.user.email }];
  //             }

  //             await this.firestore.collection('Matches').doc(this.tournamentId).update({ players })
  //             this.isModalLoading = false;
  //             this.successMessage = "You have successfully joined this tournament";
  //             this.registeredPlayers.push({ id: this.authService.user.uid, name: this.authService.user.displayName })
  //             this.isButtonActive = false;
  //             this.closeJoinTournamentModal();
  //             if(this.tournamentData.matchType === 'box') {
  //               let tournamentDoc = await this.firestore.collection('Matches').doc(this.tournamentId).get().toPromise();
  //               if(tournamentDoc.data().players.length > 4) {
  //                 await this.firestore.collection('Matches').doc(this.tournamentId).update({
  //                   frontEndState: 'registration-closed',
  //                   state: 'generate-fixtures'
  //                 });
  //               }
  //             }
  //           }
  //         } else if(this.tournamentData.category === 'doubles') {
  //           let orderId = data.orderID
  //           this.sendJoinInvite(authorizationID, orderId);
  //         }
  //       } catch(e) {
  //         console.log(e)
  //       }
  //     }
  //   };
  // }

  closeJoinTournamentModal() {
    document.body.classList.remove('modal-open');
    this.isJoinTournamentModalOpen = false;
    if (this.$currUserObj) {
      this.$currUserObj.unsubscribe();
    }
  }

  navigateFixturestoMatches(event) {
    if (event.target.innerText === 'MAIN DRAW') {
      this.showFixtures = true;
      this.showLosersFixtures = false;
      this.showMyMatches = false;
      this.showStandings = false;
    } else if (event.target.innerText === 'CONSOLATION') {
      this.showFixtures = false;
      this.showLosersFixtures = true;
      this.showMyMatches = false;
      this.showStandings = false;
    } else if (event.target.innerText === 'MY MATCHES') {
      this.showFixtures = false;
      this.showLosersFixtures = false;
      this.showMyMatches = true;
      this.showStandings = false;
    } else if (event.target.innerText === 'STANDINGS') {
      this.showFixtures = false;
      this.showLosersFixtures = false;
      this.showMyMatches = false;
      this.showStandings = true;
    }
  }

  openRegisteredPlayersModal() {
    document.body.classList.add('modal-open');
    this.isRegisteredPlayersModalOpen = true;
  }

  closeRegisteredPlayersModal() {
    document.body.classList.remove('modal-open');
    this.isRegisteredPlayersModalOpen = false;
  }

  openUserTeamsModal() {
    document.body.classList.add('modal-open');
    this.isUserTeamsModalOpen = true;
  }

  closeUserTeamsModal() {
    document.body.classList.remove('modal-open');
    this.isUserTeamsModalOpen = false;
    this.isJoinWithCurrTeam = false;
  }

  async joinWithCurrTeam(team) {
    this.isModalLoading = true;
    this.currJoiningTeam = team;
    this.isJoinWithCurrTeam = true;

    // Getting Receiver Id
    let receiverId: string = this.currJoiningTeam.members.find(
      (el: { email: string; id: string }) => el.id !== this.authService.user.uid
    ).id;

    // Making Transaction for Creating the Match
    let response = await this.createTransactionforDoublesMatch(receiverId);

    // Notifying User About Successful Match Creation Or About Any Error
    if (response.success) {
      this.isModalLoading = false;
      this.isJoinInviteSent = true;
      this.isButtonActive = false;
    }
  }

  async createTransactionforDoublesMatch(receiverId) {
    try {
      let transaction = this.fireFunctions.httpsCallable(
        'matchTeamRequests-createRequest'
      );
      let postObj = {
        matchId: this.tournamentId,
        amount: this.tournamentData.entryFee,
        currency: 'aud',
        receiverId,
        teamId: this.currJoiningTeam.teamId,
      };

      let response = await transaction(postObj).toPromise();
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  // async sendJoinInvite(authId, orderId) {
  //   let receiverId;
  //   this.currJoiningTeam.members.forEach((el) => {
  //     if (el.id !== this.authService.user.uid) {
  //       receiverId = el.id;
  //     }
  //   });
  //   try {
  //     let sendJoinInvite = await this.firestore
  //       .collection('MatchTeamRequests')
  //       .add({
  //         payments: {
  //           amount: `${this.tournamentData.entryFee}.00`,
  //           playerOneOrderId: orderId,
  //           playerOneAuthId: authId,
  //         },
  //         state: 'pending',
  //         senderId: this.authService.user.uid,
  //         receiverId,
  //         matchId: this.tournamentId,
  //         teamId: this.currJoiningTeam.teamId,
  //       });
  //     this.isJoinInviteSent = true;
  //     this.isButtonActive = false;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  activeJoinButton() {
    if (!this.registrationClosed) {
      this.isButtonActive = true;
    }
  }

  // For One On One
  getTournamentFixtureData(tournamentId) {
    this.firestore
      .collection('Matches')
      .doc(tournamentId)
      .collection('fixtures')
      .doc('fixture1')
      .get()
      .toPromise()
      .then((res) => {
        this.oneOnOneFixtureData = res.data();
        if (this.tournamentData.category === 'singles') {
          this.getUserData(res.data());
        } else if (this.tournamentData.category === 'doubles') {
          this.getOneOnOneTeams(this.tournamentData, res.data());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUserData(fixtureData) {
    let promise = [];
    promise.push(this.gatherData(fixtureData.teamA.id));
    promise.push(this.gatherData(fixtureData.teamB.id));

    Promise.all(promise)
      .then((res) => {
        this.oneOnOnePlayers = {
          home: fixtureData.home,
          winner: fixtureData.winner,
          teamA: res[0],
          teamB: res[1],
        };
        this.isOneOnOneMatchLoaded = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  gatherData(userId) {
    return this.firestore
      .collection('Users')
      .doc(userId)
      .get()
      .toPromise()
      .then((res) => {
        let userObj: any = {};
        userObj.firstName = res.data().firstName;
        userObj.lastName = res.data().lastName;
        userObj.victorPoints = res.data().victorPoints;
        userObj.id = userId;
        return userObj;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getOneOnOneTeams(tournamentData, fixtureData) {
    try {
      let teamFixtures = await Promise.all(
        tournamentData.players.map(async (el) => {
          let teamObj: any = {};
          if (el.id !== '') {
            teamObj.playerOne = {};
            teamObj.playerTwo = {};

            let teamRawData = await this.firestore
              .collection('Teams')
              .doc(el.id)
              .get()
              .toPromise();

            let teamPlayerOne: any = await this.firestore
              .collection('Users')
              .doc(teamRawData.data().members[0].id)
              .get()
              .toPromise();
            teamObj.playerOne.name =
              teamPlayerOne.data().firstName +
              ' ' +
              teamPlayerOne.data().lastName;
            teamObj.playerOne.email = teamPlayerOne.data().email;
            teamObj.playerOne.id = teamPlayerOne.id;

            let teamPlayerTwo = await this.firestore
              .collection('Users')
              .doc(teamRawData.data().members[1].id)
              .get()
              .toPromise();
            teamObj.playerTwo.name =
              teamPlayerTwo.data().firstName +
              ' ' +
              teamPlayerTwo.data().lastName;
            teamObj.playerTwo.email = teamPlayerTwo.data().email;
            teamObj.playerTwo.id = teamPlayerTwo.id;

            teamObj.name = teamRawData.data().name;
            teamObj.teamId = teamRawData.id;
            teamObj.victorPoints = teamRawData.data().victorPoints;
          } else {
            teamObj.name = 'Bye';
          }

          return teamObj;
        })
      );

      this.oneOnOnePlayers = {
        home: fixtureData.home,
        winner: fixtureData.winner,
        teamA: teamFixtures[0],
        teamB: teamFixtures[1],
      };
      this.isOneOnOneMatchLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }
  // End One On One

  initializeScoreForm() {
    this.formGroup = new FormGroup({
      setOneA: new FormControl('', Validators.required),
      setOneB: new FormControl('', Validators.required),
      setTwoA: new FormControl('', Validators.required),
      setTwoB: new FormControl('', Validators.required),
    });

    this.checkFormChanges();
  }

  checkFormChanges() {
    let comparisonValueSetOne: boolean;
    let comparisonValueSetTwo: boolean;
    let comparisonValueSetThree: boolean;

    this.formGroup.valueChanges.subscribe((values) => {
      // Checking If Set Three Exists
      this.isSetThreeExists(values);
      // Checking for Set One
      this.watchChangesSetOne(values, comparisonValueSetOne);
      // Checking for Set Two
      this.watchChangesSetTwo(values, comparisonValueSetTwo);
      // Checking for Set Three
      this.watchChangesSetThree(values, comparisonValueSetThree);
    });
  }

  watchChangesSetOne(values, comparisonValueSetOne) {
    let a = values.setOneA;
    let b = values.setOneB;
    let aMinusB = values.setOneA - values.setOneB;
    let bMinusA = values.setOneB - values.setOneA;
    let comparisonValue: boolean = comparisonValueSetOne;

    if (a == 7 || b == 7) {
      comparisonValue = true;
    } else {
      comparisonValue = false;
    }

    if (a >= 6 || b >= 6) {
      if (comparisonValue) {
        if (a == 7 || b == 7) {
          if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
            this.isSetOneTie = true;
            if (!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl(
                'setOneTieA',
                new FormControl('', Validators.required)
              );
            }
            if (!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl(
                'setOneTieB',
                new FormControl('', Validators.required)
              );
            }
          } else {
            this.isSetOneTie = false;
            if (this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA');
            }
            if (this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB');
            }
          }
        }
      } else {
        if (a == 6 || b == 6) {
          if (a == b) {
            this.isSetOneTie = true;
            if (!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl(
                'setOneTieA',
                new FormControl('', Validators.required)
              );
            }
            if (!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl(
                'setOneTieB',
                new FormControl('', Validators.required)
              );
            }
          } else {
            this.isSetOneTie = false;
            if (this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA');
            }
            if (this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB');
            }
          }
        } else {
          this.isSetOneTie = false;
          if (this.formGroup.controls.setOneTieA) {
            this.formGroup.removeControl('setOneTieA');
          }
          if (this.formGroup.controls.setOneTieB) {
            this.formGroup.removeControl('setOneTieB');
          }
        }
      }
    } else {
      this.isSetOneTie = false;
      if (this.formGroup.controls.setOneTieA) {
        this.formGroup.removeControl('setOneTieA');
      }
      if (this.formGroup.controls.setOneTieB) {
        this.formGroup.removeControl('setOneTieB');
      }
    }

    // Checking for valid scores
    this.isValidScores({ set: 'one', values: values });
  }

  watchChangesSetTwo(values, comparisonValueSetTwo) {
    let a = values.setTwoA;
    let b = values.setTwoB;
    let aMinusB = values.setTwoA - values.setTwoB;
    let bMinusA = values.setTwoB - values.setTwoA;
    let comparisonValue: boolean = comparisonValueSetTwo;

    if (a == 7 || b == 7) {
      comparisonValue = true;
    } else {
      comparisonValue = false;
    }

    if (a >= 6 || b >= 6) {
      if (comparisonValue) {
        if (a == 7 || b == 7) {
          if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
            this.isSetTwoTie = true;
            if (!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl(
                'setTwoTieA',
                new FormControl('', Validators.required)
              );
            }
            if (!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl(
                'setTwoTieB',
                new FormControl('', Validators.required)
              );
            }
          } else {
            this.isSetTwoTie = false;
            if (this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if (this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        }
      } else {
        if (a == 6 || b == 6) {
          if (a == b) {
            this.isSetTwoTie = true;
            if (!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl(
                'setTwoTieA',
                new FormControl('', Validators.required)
              );
            }
            if (!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl(
                'setTwoTieB',
                new FormControl('', Validators.required)
              );
            }
          } else {
            this.isSetTwoTie = false;
            if (this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if (this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        } else {
          this.isSetTwoTie = false;
          if (this.formGroup.controls.setTwoTieA) {
            this.formGroup.removeControl('setTwoTieA');
          }
          if (this.formGroup.controls.setTwoTieB) {
            this.formGroup.removeControl('setTwoTieB');
          }
        }
      }
    } else {
      this.isSetTwoTie = false;
      if (this.formGroup.controls.setTwoTieA) {
        this.formGroup.removeControl('setTwoTieA');
      }
      if (this.formGroup.controls.setTwoTieB) {
        this.formGroup.removeControl('setTwoTieB');
      }
    }

    // Checking for valid scores
    this.isValidScores({ set: 'two', values: values });
  }

  watchChangesSetThree(values, comparisonValueSetThree) {
    if (this.isSetThree) {
      let a = values.setThreeA;
      let b = values.setThreeB;
      let aMinusB = values.setThreeA - values.setThreeB;
      let bMinusA = values.setThreeB - values.setThreeA;
      let comparisonValue: boolean = comparisonValueSetThree;

      if (a == 7 || b == 7) {
        comparisonValue = true;
      } else {
        comparisonValue = false;
      }

      if (a >= 6 || b >= 6) {
        if (comparisonValue) {
          if (a == 7 || b == 7) {
            if (
              (aMinusB < 2 && aMinusB >= 0) ||
              (bMinusA < 2 && bMinusA >= 0)
            ) {
              this.isSetThreeTie = true;
              if (!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl(
                  'setThreeTieA',
                  new FormControl('', Validators.required)
                );
              }
              if (!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl(
                  'setThreeTieB',
                  new FormControl('', Validators.required)
                );
              }
            } else {
              this.isSetThreeTie = false;
              if (this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA');
              }
              if (this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB');
              }
            }
          }
        } else {
          if (a == 6 || b == 6) {
            if (a == b) {
              this.isSetThreeTie = true;
              if (!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl(
                  'setThreeTieA',
                  new FormControl('', Validators.required)
                );
              }
              if (!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl(
                  'setThreeTieB',
                  new FormControl('', Validators.required)
                );
              }
            } else {
              this.isSetThreeTie = false;
              if (this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA');
              }
              if (this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB');
              }
            }
          } else {
            this.isSetThreeTie = false;
            if (this.formGroup.controls.setThreeTieA) {
              this.formGroup.removeControl('setThreeTieA');
            }
            if (this.formGroup.controls.setThreeTieB) {
              this.formGroup.removeControl('setThreeTieB');
            }
          }
        }
      } else {
        this.isSetThreeTie = false;
        if (this.formGroup.controls.setThreeTieA) {
          this.formGroup.removeControl('setThreeTieA');
        }
        if (this.formGroup.controls.setThreeTieB) {
          this.formGroup.removeControl('setThreeTieB');
        }
      }

      // Checking for valid scores
      this.isValidScores({ set: 'three', values: values });
    }
  }

  isValidScores(data) {
    let values = data.values;
    let a, b, isDirtyA, isDirtyB, setA, setB, setATie, setBTie;
    if (data.set === 'one') {
      a = data.values.setOneA;
      b = data.values.setOneB;
      isDirtyA = this.formGroup.controls.setOneA.dirty;
      isDirtyB = this.formGroup.controls.setOneB.dirty;
      setA = 'setOneA';
      setB = 'setOneB';
      setATie = 'setOneTieA';
      setBTie = 'setOneTieB';
    } else if (data.set === 'two') {
      a = data.values.setTwoA;
      b = data.values.setTwoB;
      isDirtyA = this.formGroup.controls.setTwoA.dirty;
      isDirtyB = this.formGroup.controls.setTwoB.dirty;
      setA = 'setTwoA';
      setB = 'setTwoB';
      setATie = 'setTwoTieA';
      setBTie = 'setTwoTieB';
    } else if (data.set === 'three') {
      if (
        this.isSetThree &&
        this.formGroup.controls.setThreeA &&
        this.formGroup.controls.setThreeB
      ) {
        a = data.values.setThreeA;
        b = data.values.setThreeB;
        isDirtyA = this.formGroup.controls.setThreeA.dirty;
        isDirtyB = this.formGroup.controls.setThreeB.dirty;
        setA = 'setThreeA';
        setB = 'setThreeB';
        setATie = 'setThreeTieA';
        setBTie = 'setThreeTieB';
      }
    }

    if (
      ((a == 7 || a == 6) && b < 7 && isDirtyA && isDirtyB) ||
      ((b == 7 || b == 6) && a < 7 && isDirtyA && isDirtyB)
    ) {
      if ((a == 7 && b > 4) || (b == 7 && a > 4)) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else if (
        (a == 6 && (b < 5 || b == 7 || b == 6)) ||
        (b == 6 && (a < 5 || a == 7 || a == 6))
      ) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else {
        if (isDirtyA && isDirtyB) {
          this.formGroup.controls[setA].setErrors({ incorrect: true });
          this.formGroup.controls[setB].setErrors({ incorrect: true });
        }
      }
    } else {
      if (isDirtyA && isDirtyB) {
        this.formGroup.controls[setA].setErrors({ incorrect: true });
        this.formGroup.controls[setB].setErrors({ incorrect: true });
      }
    }

    const addError = () => {
      if (
        this.formGroup.controls[setATie].dirty &&
        this.formGroup.controls[setBTie].dirty
      ) {
        this.formGroup.controls[setATie].setErrors({ incorrect: true });
        this.formGroup.controls[setBTie].setErrors({ incorrect: true });
      }
    };
    const removeError = () => {
      this.formGroup.controls[setATie].setErrors(null);
      this.formGroup.controls[setBTie].setErrors(null);
    };

    // Checking for TIE BREAKER
    if (this.formGroup.controls[setATie]) {
      let setTieA = parseInt(values[setATie]);
      let setTieB = parseInt(values[setBTie]);
      if (setTieA > 7 || setTieB > 7) {
        if (setTieA > setTieB && setTieA - setTieB === 2) removeError();
        else if (setTieB > setTieA && setTieB - setTieA === 2) removeError();
        else addError();
      } else {
        if (setTieA === 7 || setTieB === 7) removeError();
        else addError();
      }
    }
  }

  isSetThreeExists(values) {
    if (this.isFormValid()) {
      let isScoresEqual = this.caclulateScores(values);
      if (isScoresEqual) {
        if (!this.isSetThree) {
          this.isSetThree = true;
          this.formGroup.addControl(
            'setThreeA',
            new FormControl('', Validators.required)
          );
          this.formGroup.addControl(
            'setThreeB',
            new FormControl('', Validators.required)
          );
        }
      } else {
        this.removeSetThree();
      }
    } else {
      this.removeSetThree();
    }
  }

  isFormValid() {
    let isFormValid: boolean =
      this.formGroup.controls.setOneA.valid &&
      this.formGroup.controls.setOneB.valid &&
      this.formGroup.controls.setTwoA.valid &&
      this.formGroup.controls.setTwoB.valid;

    if (this.formGroup.controls.setOneTieA) {
      isFormValid =
        isFormValid &&
        this.formGroup.controls.setOneTieA.valid &&
        this.formGroup.controls.setOneTieB.valid;
    }
    if (this.formGroup.controls.setTwoTieA) {
      isFormValid =
        isFormValid &&
        this.formGroup.controls.setTwoTieA.valid &&
        this.formGroup.controls.setTwoTieB.valid;
    }
    if (
      this.formGroup.controls.setOneTieA &&
      this.formGroup.controls.setTwoTieA
    ) {
      isFormValid =
        isFormValid &&
        this.formGroup.controls.setOneTieA.valid &&
        this.formGroup.controls.setOneTieB.valid &&
        this.formGroup.controls.setTwoTieA.valid &&
        this.formGroup.controls.setTwoTieB.valid;
    }

    return isFormValid;
  }

  caclulateScores(values) {
    let teamAKeys = [];
    let teamBKeys = [];
    let teamAScore = [];
    let teamBScore = [];

    for (let key in values) {
      if (key.includes('A') && !key.includes('Three')) {
        teamAKeys.push(key);
      }
      if (key.includes('B') && !key.includes('Three')) {
        teamBKeys.push(key);
      }
    }

    teamAKeys.forEach((el) => {
      teamAScore.push(parseInt(values[el]));
    });
    teamBKeys.forEach((el) => {
      teamBScore.push(parseInt(values[el]));
    });

    if (teamAScore.length > 2 && teamBScore.length > 2) {
      if (teamAScore.length === 4 && teamBScore.length === 4) {
        if (
          (teamAScore[2] > teamBScore[2] && teamBScore[3] > teamAScore[3]) ||
          (teamBScore[2] > teamAScore[2] && teamAScore[3] > teamBScore[3])
        ) {
          return true;
        }
      }

      if (teamAScore.length === 3 && teamBScore.length === 3) {
        if (this.formGroup.controls.setOneTieA) {
          if (
            (teamAScore[1] > teamBScore[1] && teamBScore[2] > teamAScore[2]) ||
            (teamBScore[1] > teamAScore[1] && teamAScore[2] > teamBScore[2])
          ) {
            return true;
          }
        } else if (this.formGroup.controls.setTwoTieA) {
          if (
            (teamAScore[0] > teamBScore[0] && teamBScore[2] > teamAScore[2]) ||
            (teamBScore[0] > teamAScore[0] && teamAScore[2] > teamBScore[2])
          ) {
            return true;
          }
        }
      }
    } else if (
      (teamAScore[0] > teamBScore[0] && teamBScore[1] > teamAScore[1]) ||
      (teamBScore[0] > teamAScore[0] && teamAScore[1] > teamBScore[1])
    ) {
      return true;
    }

    let teamAFinalScore = teamAScore.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    let teamBFinalScore = teamBScore.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return teamAFinalScore === teamBFinalScore;
  }

  removeSetThree() {
    if (this.isSetThree) {
      this.isSetThree = false;
      this.formGroup.removeControl('setThreeA');
      this.formGroup.removeControl('setThreeB');
      if (this.isSetThreeTie) {
        this.formGroup.removeControl('setThreeTieA');
        this.formGroup.removeControl('setThreeTieB');
      }
    }
  }

  openScoreModal() {
    document.body.classList.add('modal-open');
    this.isScoreModalOpen = true;
    this.findOneOnOneScoreBy(this.tournamentData);
  }

  findOneOnOneScoreBy(tournamentData) {
    if (this.tournamentData.category === 'singles') {
      if (tournamentData.players[0].email === this.authService.user.email) {
        this.oneOnOneScoreBy = 'teamA';
      } else if (
        tournamentData.players[1].email === this.authService.user.email
      ) {
        this.oneOnOneScoreBy = 'teamB';
      }
    } else if (this.tournamentData.category === 'doubles') {
      if (
        this.oneOnOnePlayers.teamA.playerOne.id === this.authService.user.uid ||
        this.oneOnOnePlayers.teamA.playerTwo.id === this.authService.user.uid
      ) {
        this.oneOnOneScoreBy = 'teamA';
      } else if (
        this.oneOnOnePlayers.teamB.playerOne.id === this.authService.user.uid ||
        this.oneOnOnePlayers.teamB.playerTwo.id === this.authService.user.uid
      ) {
        this.oneOnOneScoreBy = 'teamB';
      }
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isScoreModalOpen = false;
    this.formGroup.reset();
  }

  addOneOnOneScore(formGroup) {
    if (formGroup.valid) {
      let formData = formGroup.value;
      let firebaseAddress = this.firestore
        .collection('Matches')
        .doc(this.tournamentId)
        .collection('fixtures')
        .doc('fixture1');
      this.currentFirebaseAddress = firebaseAddress;
      firebaseAddress
        .get()
        .toPromise()
        .then((res) => {
          let data = res.data();

          data.scoreBy = this.oneOnOneScoreBy;
          data.state = 'score-uploaded';
          data.frontEndState = 'score-uploaded';
          let teamAScore = {
            setOne: parseInt(formData.setOneA),
            setTwo: parseInt(formData.setTwoA),
          };
          let teamBScore = {
            setOne: parseInt(formData.setOneB),
            setTwo: parseInt(formData.setTwoB),
          };
          if (formData.setOneTieA) {
            teamAScore['setOneTie'] = parseInt(formData.setOneTieA);
            teamBScore['setOneTie'] = parseInt(formData.setOneTieB);
          }
          if (formData.setTwoTieA) {
            teamAScore['setTwoTie'] = parseInt(formData.setTwoTieA);
            teamBScore['setTwoTie'] = parseInt(formData.setTwoTieB);
          }
          if (formData.setThreeA) {
            teamAScore['setThree'] = parseInt(formData.setThreeA);
            teamBScore['setThree'] = parseInt(formData.setThreeB);
          }
          if (formData.setThreeTieA) {
            teamAScore['setThreeTie'] = parseInt(formData.setThreeTieA);
            teamBScore['setThreeTie'] = parseInt(formData.setThreeTieB);
          }
          data.teamA.matchScore = teamAScore;
          data.teamB.matchScore = teamBScore;

          return firebaseAddress.update(data);
        })
        .then((res) => {
          this.oneOnOneFixtureData.frontEndState = 'score-uploaded';
          this.closeModal();
          this.isPlayerRatingModalOpen = true;
          console.log('Success: Score Uploaded');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  openMapModal() {
    document.body.classList.add('modal-open');
    this.isMapModalOpen = true;
  }

  closeMapModal() {
    document.body.classList.remove('modal-open');
    this.isMapModalOpen = false;
  }

  async confirmRating() {
    try {
      let userId: string;
      let fixtureData = (
        await this.currentFirebaseAddress.get().toPromise()
      ).data();

      if (fixtureData.teamA.id !== this.authService.user.uid) {
        userId = fixtureData.teamA.id;
      } else if (fixtureData.teamB.id !== this.authService.user.uid) {
        userId = fixtureData.teamB.id;
      }

      await this.firestore
        .collection('Users')
        .doc(userId)
        .collection('rating')
        .add({
          rating: this.playerRating,
          uid: this.authService.user.uid,
          timestamp: Date.now(),
        });
      this.isPlayerRatingModalOpen = false;
    } catch (e) {
      console.log(e);
    }
  }

  getLocationMap(location) {
    this.http
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${location}+australia&key=${environment.firebaseConfig.apiKey}`,
        {}
      )
      .toPromise()
      .then((res: any) => {
        this.tournamentData.map = {
          latitude: res.results[0].geometry.location.lat,
          longitude: res.results[0].geometry.location.lng,
          address: res.results[0].formatted_address
            .split(',')
            .slice(0, 3)
            .join(','),
        };
        this.tournamentData.isLocationFound = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  copyUrl(event) {
    let url = window.location.href;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    event.srcElement.innerText = 'Url Copied';
  }

  downloadCSV() {
    let data: any[] = !this.tournamentData.players?.length
      ? []
      : this.registeredPlayers.map((el) => {
          return { name: el.name, email: el.email };
        });

    var options = {
      title: 'User Details',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      headers: ['Name', 'Email'],
    };

    new Angular2Csv(data, 'users_data', options);
  }

  toggleDropdown() {
    this.showOptionDropdown = !this.showOptionDropdown;
  }

  openWithdrawModal() {
    document.body.classList.add('modal-open');
    this.isWithdrawModalOpen = true;
  }

  closeWithdrawModal() {
    document.body.classList.remove('modal-open');
    this.isWithdrawModalOpen = false;
  }

  async withdrawFromMatch() {
    try {
      this.isModalLoading = true;
      let withdraw = this.fireFunctions.httpsCallable('matches-refundMoney');
      let response = await withdraw({
        tournamentId: this.tournamentId,
        uid: this.authService.user.uid,
      }).toPromise();
      console.log(response);
      this.ngOnInit();
      this.closeWithdrawModal();
      this.successMessage = '';
      this.isRegistrationFull = '';
      this.isModalLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  async ngOnInit() {
    this.isTournamentLoading = true;
    let user = await this.authService.authState.pipe(first()).toPromise();
    if (user)
      this.userObj = (await this.profileService.getUserProfileData()).data();
    this.getTournamentData();
    this.initializeScoreForm();
  }

  ngOnDestroy(): void {
    if (this.$currUserObj) {
      this.$currUserObj.unsubscribe();
    }
  }
}
