
<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <div class="wrapper">
        <div class="sidebar">
          <div class="sidebar-inner">
            <ul>
              <li [routerLink]="['/tennis/about-us/how-it-works']" routerLinkActive="active">How It Works</li>
              <li [routerLink]="['/tennis/about-us/match-etiquette']" routerLinkActive="active">Match Etiquette</li>
              <li [routerLink]="['/tennis/about-us/viictor-rankings']" routerLinkActive="active">Viictor Rankings</li>
            </ul>
          </div>
        </div>
        <div class="info-bar">
          <div class="info-bar-inner">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
