import { ProfileService } from './../../services/profile/profile.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
@HostListener('window:resize', ['$event'])
export class NavigationComponent implements OnInit {

  currUser: any;
  isCollapsed: boolean = false;
  showNavbar: boolean = false;
  isShowNotification: boolean = false;
  isNotifications: boolean;
  notifications = [];
  isNotificationsUnRead: Boolean;
  $notifications: Subscription;
  showResponsiveIcons: boolean;

  $chats: Subscription;
  isChatsUnRead: Boolean;

  constructor(public authService: AuthService,
    public profileService: ProfileService,
    public router: Router,
    private firestore: AngularFirestore) { }

  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.showResponsiveIcons = true;
    } else {
      this.showResponsiveIcons = false;
    }
  }

  logout() {
    this.authService.logout()
      .then(() => {
        this.router.navigateByUrl('tennis/home');
      })
      .catch((error) => {
        console.log(error);
      })
  }

  async goToNotificationLink(redirectLink, index) {
    try {
      if (redirectLink && redirectLink !== '') {
        await this.router.navigateByUrl(redirectLink)
      }
      this.isShowNotification = false;
      this.notifications[index].isRead = true;
      await this.firestore.collection('Users').doc(this.authService.user.uid).collection('notifications')
        .doc(this.notifications[index].id).update({ isRead: true })
    } catch (e) {
      console.log(e)
    }
  }

  async initializeUser() {
    try {
      let user = await this.authService.authState.pipe(first()).toPromise();
      if (user) {
        this.currUser = await this.profileService.getUserProfileData();
        let uid = this.authService.user.uid;
        this.getNotifications(uid)
        this.getChats(uid)
      }
    } catch (e) {
      console.log(e)
    }
  }

  getNotifications(id) {
    this.$notifications = this.firestore.collection('Users').doc(id)
      .collection('notifications', ref => ref.orderBy('timestamp', 'desc'))
      .snapshotChanges().subscribe(res => {
        let notifications = []
        notifications = res.map(el => {
          return el.payload.doc
        })
        if (notifications.length > 0) {
          this.isNotifications = true;
          let allNotifications = [];
          allNotifications = notifications.map(el => {
            let notification: any = {}
            notification = el.data()
            notification.id = el.id
            return notification
          })
          this.notifications = allNotifications;
          this.notifications.some((el, index) => {
            if (!el.isRead) {
              this.isNotificationsUnRead = true;
              return true;
            } else {
              if (index === this.notifications.length - 1) {
                this.isNotificationsUnRead = false;
              }
            }
          })
        } else {
          this.isNotifications = false;
        }
      })
  }

  async getChats(id) {
    this.firestore.collection('Chats', ref => ref.where('members', 'array-contains', id)
      .orderBy('updatedAt', 'desc')).snapshotChanges().subscribe(res => {
        let chats = []
        chats = res.map(el => {
          return el.payload.doc
        })
        if (chats.length > 0) {
          chats.some((el, index) => {
            if (!el.data().isRead) {
              this.isChatsUnRead = true;
              return true;
            } else {
              if (index === chats.length - 1) {
                this.isChatsUnRead = false;
              }
            }
          })
        }
      })
  }

  getNotificationTime(timestamp) {
    let monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let notifyTimestamp = timestamp;
    let currTimestamp = Date.now();
    let differenceInMs = currTimestamp - notifyTimestamp;
    let resultDate = Math.ceil(differenceInMs / 86400000); // Milliseconds in a day: 86400000
    let notifyDate: any = new Date(timestamp)
    let currDateTime: any = new Date(Date.now());

    if (resultDate <= 1) {
      if (notifyDate.getDate() === currDateTime.getDate()) {
        let notifyHoursDifference = 24 - notifyDate.getHours();
        let currHoursDifference = 24 - currDateTime.getHours();
        let hoursDifference = Math.abs(notifyHoursDifference - currHoursDifference);

        if (hoursDifference <= 1) {
          if (notifyDate.getHours() === currDateTime.getHours()) {
            return `${Math.abs(notifyDate.getMinutes() - currDateTime.getMinutes())} minute(s) ago`
          } else {
            return `${Math.abs(60 - Math.abs((notifyDate.getMinutes() - currDateTime.getMinutes())))} minute(s) ago`
          }
        } else {
          return `${hoursDifference} hours ago`
        }
      } else {
        return `${notifyDate.getDate()} ${monthList[notifyDate.getMonth()]}`
      }
    } else {
      return `${notifyDate.getDate()} ${monthList[notifyDate.getMonth()]}`
    }
  }

  ngOnInit() {
    this.initializeUser()
    if (window.innerWidth < 768) {
      this.showResponsiveIcons = true;
    }

    this.authService.authState.subscribe(values => {
      if(this.authService?.user?.uid) this.initializeUser()
      else this.currUser = null
    })
  }
}
