<app-registration-template>
  <div class="form">
    <div class="form-header">Forgot Password</div>
    <div class="form-body" *ngIf="!sentSuccessful">
      <form [formGroup]="formGroup">
        <div class="input-fields">
          <input class="form-control" formControlName="email" type="text" placeholder="E-mail" [ngClass]="{'valid': formGroup.controls.email.valid, 'error': formGroup.controls.email.invalid && (formGroup.controls.email.touched || submitAttempt)}">
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.email.invalid && formGroup.controls.email.value === ''">E-mail is required.</p>
          <p class="error-message" *ngIf="submitAttempt && formGroup.controls.email.invalid && formGroup.controls.email.value !== ''">Invalid e-mail format.</p>
        </div>
        <div class="form-check first">
          <label class="form-check-label">
            Enter your email. We'll send you a link to reset your password.
          </label>
        </div>
        <p *ngIf="errorMessage" class="error-message backend">{{errorMessage}}</p>


        <div *ngIf="loading" class="loader-gif">
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
        </div>

        <button *ngIf="!loading" class="btn btn-submit" (click)="sendReset(formGroup)" [disabled]="loading">Reset</button>
        <div class="form-check">
          <label class="form-check-label">
            Don't have an account? <a [routerLink]="['/tennis/users/sign-up']">Join Now</a>
          </label>
        </div>
      </form>
    </div>
    <div *ngIf="sentSuccessful" class="form-body success">
      <p>Please check your email. We have sent you a password reset link.</p>

      <button class="btn btn-submit" (click)="goToHome()">Go TO Home Page</button>
    </div>
  </div>
</app-registration-template>