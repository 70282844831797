import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-player-games-history',
  templateUrl: './player-games-history.component.html',
  styleUrls: ['./player-games-history.component.scss']
})
export class PlayerGamesHistoryComponent implements OnInit {

  node;
  isGamesFound: boolean;
  matches: any[];
  isLoading: boolean = true;
  isError: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private authService: AuthService) { }

  async getGames() {
    try {
      let playerId = this.node.playerId
      let games = await this.firestore.collection('Users').doc(playerId).collection('history').get().toPromise()
      if(games.docs.length > 0) {
        let matches: any[] = await Promise.all(games.docs.map(async el => {
          let matchUrl = el.data().matchRef.split('/')[2]
          let match = await this.firestore.collection('Matches').doc(matchUrl).get().toPromise()
          let matchObj: any = match.data();
          let fixtureData: any = {}
          if(match.exists) {
            if(matchObj.matchType === 'one-on-one' || matchObj.matchType === 'box') {
              let fixture = el.data().matchRef.split('/')[4]
              fixtureData = (await this.firestore.collection('Matches').doc(matchUrl)
                                .collection('fixtures').doc(fixture).get().toPromise()).data()
              if(matchObj.matchType === 'one-on-one') {
                if(!matchObj.location) matchObj.location = 'No Location Found'
                if(matchObj.startDate) {
                  matchObj.startDate = this.getFormattedDate(matchObj.startDate)
                } else {
                  matchObj.startDate = 'No Date Found'
                }
              } else {
                matchObj.startDate = this.getFormattedDate(matchObj.startDate)
              }
            } else if(matchObj.matchType === 'knockout') {
              let roundType = el.data().matchRef.split('/')[3];
              let round = el.data().matchRef.split('/')[4];
              let fixture = el.data().matchRef.split('/')[6];
              fixtureData = (await this.firestore.collection('Matches').doc(matchUrl)
                                .collection(roundType).doc(round).collection('fixtures')
                                .doc(fixture).get().toPromise()).data()

              matchObj.startDate = this.getFormattedDate(matchObj.startDate)
            }

            if(fixtureData) {
              // Get players data
              let playerOne = await this.firestore.collection('Users').doc(fixtureData.teamA.id).get().toPromise()
              let teamA: any = {
                firstName: playerOne.data().firstName,
                lastName:  playerOne.data().lastName,
                profileImg: playerOne.data().profileImg ? playerOne.data().profileImg : '../../assets/image/no-img.webp'
              }
              let playerTwo = await this.firestore.collection('Users').doc(fixtureData.teamB.id).get().toPromise()
              let teamB: any = {
                firstName: playerTwo.data().firstName,
                lastName:  playerTwo.data().lastName,
                profileImg: playerTwo.data().profileImg ? playerTwo.data().profileImg : '../../assets/image/no-img.webp'
              }

              fixtureData.teamA = { ...fixtureData.teamA, ...teamA }
              fixtureData.teamB = { ...fixtureData.teamB, ...teamB }
              matchObj.fixtureData = fixtureData;
              matchObj.matchId = matchUrl
              return matchObj
            }
          }
        }))
        matches = matches.filter(Boolean);
        this.matches = matches;
        this.isGamesFound = true;
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.isGamesFound = false;
      }
    } catch(e) {
      this.isError = true;
      console.log(e)
    }
  }

  getFormattedDate(date) {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let fullDate = date.split('-').reverse()
    fullDate[1] = months[parseInt(fullDate[1]) - 1]
    return fullDate.join('/')
  }

  ngOnInit() {
    this.getGames()
  }

}
