<app-registration-template>
  <div *ngIf="!restrictedAccess && isEmailVerified !== false">
    <div class="form">
      <div class="form-header">Log In</div>
      <div class="form-body">
        <form [formGroup]="formGroup">
          <div class="input-fields">
            <label>Email</label>
            <input class="form-control" formControlName="email" type="email" placeholder="E-mail"
              [ngClass]="{'valid': formGroup.controls.email.valid, 'error': formGroup.controls.email.invalid && (formGroup.controls.email.touched || submitAttempt)}">
            <p class="error-message"
              *ngIf="submitAttempt && formGroup.controls.email.invalid && formGroup.controls.email.value === ''">E-mail
              is required.</p>
            <p class="error-message"
              *ngIf="submitAttempt && formGroup.controls.email.invalid && formGroup.controls.email.value !== ''">Invalid
              e-mail format.</p>

            <label>Password</label>
            <input class="form-control" formControlName="password" type="password" placeholder="Password"
              [ngClass]="{'valid': formGroup.controls.password.valid, 'error': formGroup.controls.password.invalid && (formGroup.controls.password.touched || submitAttempt)}">
            <p class="error-message" *ngIf="submitAttempt && formGroup.controls.password.invalid">Password is required.
            </p>
          </div>
          <div *ngIf="loading" class="loader-gif">
            <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
          </div>
          <p *ngIf="errorMessageBackend !== ''" class="error-message backend">{{errorMessageBackend}}</p>
          <button *ngIf="!loading" type="submit" class="btn btn-submit" (click)="login(formGroup)">Log In</button>
          <div class="form-check">
            <label class="form-check-label">
              Don't have an account? <a [routerLink]="['/tennis/users/sign-up']">Join Now</a>
            </label>
          </div>
        </form>
      </div>
    </div>
    <div class="forgot-pass">
      <a [routerLink]="['/tennis/users/forgot-password']">Forgot password? <strong>>></strong></a>
    </div>
  </div>

  <div *ngIf="isEmailVerified === false && !restrictedAccess">
    <div class="form ">
      <div class="form-header">Email Not Verified</div>
      <div class="form-body email-not-verified">
        <p>Your email is not verified. To verify, please open the link in the email that we sent to you.</p>
        <button type="button" class="btn-submit" (click)="goToHome()">Go to home</button>
      </div>
    </div>
  </div>

  <div *ngIf="restrictedAccess" class="form email-verified">
    <div class="form-header">Account not approved</div>
    <div *ngIf="state === 'pending'" class="form-body success">
      Your parents haven't approved your account on this website yet. Once it is approved your account will be active.
      <button type="button" class="btn-submit" (click)="goToHome()">Go to Home</button> 
    </div>
    <div *ngIf="state === 'not-permitted'" class="form-body success px-20">
      Your parents have rejected your request to join this website. If you want to resend the email to your parents, click the button below.
      <button type="button" class="btn-submit" (click)="sendEmail()">Send Email</button>
    </div>
    <div *ngIf="state === 'not-permitted-pending'" class="form-body success">
      An approval mail has been sent to your parents' email.
      <button type="button" class="btn-submit" (click)="goToHome()">Go to Home</button>
    </div>
  </div>
  
</app-registration-template>
