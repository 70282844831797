<div class="container-fluid">
  <app-page-heading pageHeading="Create Profile"></app-page-heading>
</div>

<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <div class="personal-details">
        <div class="personal-details-inner">
          <div class="details">
            <div class="img-block">
              <div class="img">
                <img *ngIf="currUserObj" [src]="currUserObj.profileImg" alt="proifle-img">
              </div>
              <input type="file" (change)="onFileSelected($event)" accept=".png, .jpg">
            </div>
            <div class="heading">
              Personal Details
            </div>
            <p class="sub-heading">Please complete your remaining profile below.</p>
            <form [formGroup]="formProfile">
              <div class="fields row">
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>First Name</label>
                  <input formControlName="firstName" type="text" value="">
                  <div *ngIf="submitAttempt && formProfile.get('firstName').invalid" class="errorMessage">First Name is
                    required</div>
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Last Name</label>
                  <input formControlName="lastName" type="text" value="">
                  <div *ngIf="submitAttempt && formProfile.get('lastName').invalid" class="errorMessage">Last Name is
                    required</div>
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>E-mail Address</label>
                  <input formControlName="email" type="email" value="">
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Phone no.</label>
                  <input formControlName="phone" type="phone" value="">
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Date of Birth</label>
                  <input formControlName="dateOfBirth" type="text" value="">
                  <div *ngIf="submitAttempt && formProfile.get('dateOfBirth').invalid" class="errorMessage">Date Of Birth is required
                  </div>
                </div>

                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Handness</label>
                  <div class="styled-select">
                    <select class="form-control" formControlName="handness">
                      <option value="Right-Handed">Right-Handed</option>
                      <option value="Left-Handed">Left-Handed</option>
                    </select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('handness').invalid" class="errorMessage">Handness is
                    required</div>
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Gender</label>
                  <div class="styled-select">
                    <select class="form-control" formControlName="gender">
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('gender').invalid" class="errorMessage">Gender is
                    required</div>
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Weight (kg) <i>(Optional)</i></label>
                  <input formControlName="weight" type="number">
                  <div *ngIf="submitAttempt && formProfile.get('weight').invalid" class="errorMessage">Weight is
                    required</div>
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Height (cm)</label>
                  <input formControlName="height" type="number" value="">

                  <div *ngIf="submitAttempt && formProfile.get('height').invalid" class="errorMessage">Height is
                    required</div>
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Do you use wheelchair?</label>
                  <div class="styled-select">
                    <select class="form-control" formControlName="wheelChair">
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('wheelChair').invalid" class="errorMessage">This is
                    required</div>
                </div>
                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Select Country</label>
                  <div class="styled-select">
                    <select class="form-control" formControlName="country">
                      <option value="Australia">Australia</option>
                    </select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('country').invalid" class="errorMessage">Country is
                    required</div>
                </div>

                <div class="col-xs-12 col-md-5 col-lg-4 field">
                  <label>Select City</label>
                  <div class="styled-select">
                    <ng-select
                      [items]="cities"
                      bindLabel="city"
                      bindValue="city"
                      (change)="getLocationMap($event)"
                      formControlName="city"
                      placeholder="Select City">
                    </ng-select>
                  </div>
                  <div *ngIf="submitAttempt && formProfile.get('city').invalid" class="errorMessage">City is
                    required</div>
                </div>
                <div class="col-12 field">
                  <label>Are you?</label>
                  <div class="ng5-slider-cover">
                    <ng5-slider [(value)]="sliderValue" [options]="sliderOptions"></ng5-slider>
                  </div>
                </div>
                <div class="col-12 field">
                  <label>Bio</label>
                  <textarea formControlName="bio" class="form-control field" name="userbio" cols="30"
                    rows="5"></textarea>
                  <div *ngIf="submitAttempt && formProfile.get('bio').invalid" class="errorMessage">Bio is required
                  </div>
                </div>

              </div>

            </form>

            <div class="payment-info">
              <h3>Payment Information</h3>
              <p>Please make sure to create a <a href="https://www.paypal.com/" target="_blank">PayPal</a> account with the same email address that you entered in this website
                so that we can transfer the money to your <a href="https://www.paypal.com/" target="_blank">PayPal</a> account accordingly.</p>
            </div>

            <button type="button" class="btn-submit" (click)="updateProfile(formProfile)">Save</button>

            <ng-container *ngIf="profileUpdated">
              <div class="alert alert-success mt-20">You have Successfully created your Profile</div>
              <div class="alert alert-secondary">Redirecting to your profile in {{ timer }} seconds.</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
