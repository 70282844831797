import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-tennis-challenges-completed',
  templateUrl: './player-tennis-challenges-completed.component.html',
  styleUrls: ['./player-tennis-challenges-completed.component.scss']
})
export class PlayerTennisChallengesCompletedComponent implements OnInit {

  hasMatches: boolean;
  rawMatches = [];
  matches = [];
  isLoading: boolean = true;

  constructor(private firestore: AngularFirestore, private authService: AuthService) { }

  gatherData(matches) {
    let promises = [];
    matches.forEach(el => {
      let tempObjA;
      let tempObjB;
      tempObjA = this.gatherUserData(el.senderId, el.matchId);
      tempObjB = this.gatherUserData(el.receiverId, el.matchId);
      promises.push(tempObjA)
      promises.push(tempObjB)
    })
    
    Promise.all(promises)
    .then((res) => {
      let tempMatchObj = {};
      let matches = [];
      res.forEach((el, index) => {
        if(index === 0) {
          tempMatchObj["teamA"] = el
        } else if(index % 2 !== 0) {
          tempMatchObj["teamB"] = el
          matches.push(tempMatchObj)
          tempMatchObj = {};
        } else {
          tempMatchObj["teamA"] = el
        }
      })

      this.matches = matches.slice();
      this.isLoading = false;
    })
    .catch(error => {
      console.log(error)
    })
  }

  gatherUserData(id, matchId) {
    return this.firestore.collection('Users').doc(id).get().toPromise()
    .then(res => {
      let userObj: any;
      userObj = {...res.data()};
      userObj.id = id;
      userObj.matchId = matchId;
      return userObj;
    })
    .catch(error => {
      console.log(error)        
    })
  }
    
  ngOnInit() {
    this.firestore.collection('ChallengeRequests', ref => ref
    .where('senderId', '==', this.authService.user.uid)
    .where('state', '==', 'accepted')).get().toPromise()
    .then(res => {
      if(res.docs.length > 0) {
        this.rawMatches = [...res.docs];
      }
      return this.firestore.collection('ChallengeRequests', ref => ref
      .where('receiverId', '==', this.authService.user.uid)
      .where('state', '==', 'accepted')).get().toPromise()
    })
    .then(res => {
      if(res.docs.length > 0) {
        this.rawMatches = this.rawMatches.concat(res.docs)
      }

      if(this.rawMatches.length > 0) {
        this.hasMatches = true;

        let matches = [];
        matches = this.rawMatches.map(el => {
          return el.data();
        })
        
        this.gatherData(matches);
      } else {
        this.isLoading = false;
        this.hasMatches = false;
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

}
