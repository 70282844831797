import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-player-tennis-teams-accepted',
  templateUrl: './player-tennis-teams-accepted.component.html',
  styleUrls: ['./player-tennis-teams-accepted.component.scss']
})
export class PlayerTennisTeamsAcceptedComponent implements OnInit {

  isLoading: boolean = true;
  rawTeams = [];
  teams = [];
  hasTeams: boolean;
  currTeam;
  isRejectModalOpen: boolean;
  isModalLoading: boolean;

  constructor(private firestore: AngularFirestore,
              private authService: AuthService) { }

  openModal(team, modalType) {
    this.currTeam = team; 
    if(modalType === 'reject') {
      document.body.classList.add('modal-open');
      this.isRejectModalOpen = true;
    }
  }

  async reject(team) {
    try {
      let deleteTeam = await this.firestore.collection('Teams').doc(team.teamId).delete();
      let deleteTeamRequest = await this.firestore.collection('TeamRequests').doc(team.teamRequestId).delete();
      this.closeModal();
      this.isLoading = true;
      this.ngOnInit();
    } catch(e) {
      console.log(e)
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isRejectModalOpen = false;
  }

  async ngOnInit() {
    try {
      let rawTeams = await this.firestore.collection('Teams').get().toPromise();
      let filteredTeams = rawTeams.docs.filter((team) => {
        let teamData = team.data();
        if(teamData.members) return teamData.members[0].id === this.authService.user.uid || teamData.members[1].id === this.authService.user.uid
      })
      if(filteredTeams.length > 0) {
        this.rawTeams = filteredTeams.slice()
        let teams = [];
        
        teams = this.rawTeams.map(el => {
          let tempObj = el.data();
          tempObj.teamId = el.id; 
          return tempObj;
        })
        this.teams = teams.slice();
        this.isLoading = false;
        this.hasTeams = true;
      } else {
        this.isLoading = false;
        this.hasTeams = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

}
