<div class="loader" *ngIf="isLoading">
  <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
</div>
<ng-container *ngIf="areRoundsCreated else noMatches" >

  <div class="nav-tabs" *ngIf="isNavigationCreated">
    <div class="tab" *ngFor="let nav of navigationArray; let i = index" [ngClass]="{'active': k === i}"
      (click)="navigateDraws(i)">
      {{ nav.round }}
    </div>
  </div>

  <div class="fixtures">
    <div class="col-headings" *ngIf="isNavigationCreated">
      <div class="heading" *ngIf="navigationArray[k+0]">
        <div>{{ navigationArray[k+0].round }}</div>
      </div>
      <div class="heading" *ngIf="navigationArray[k+1]">
        <div>{{ navigationArray[k+1].round }}</div>
      </div>
      <div class="heading" *ngIf="navigationArray[k+2]">
        <div>{{ navigationArray[k+2].round }}</div>
      </div>
    </div>

    <div class="matches">
      <div class="col-33 col-one">
        <div class="player-wrapper" [ngClass]="{'winner': player.winner}" *ngFor="let player of roundsData[k+0]; let i = index">
          <div class="player">
            <img [src]="player.image" alt="no-img">
            <div class="seed" *ngIf="player.seed">({{ player.seed }})</div>
              <div
                class="name"
                [routerLink]="tournamentData.category === 'singles' && player.name !== 'Bye' && player.name !== 'TBA' ? ['/tennis/player/'+player.id] : []">
                  {{ 
                    tournamentData.category === 'singles' ?
                      player.name.split(' ')[0] + ' ' + (player.name !== 'Bye' && player.name !== 'TBA' ? player.name.split(' ')[1][0] + '.' : '' | uppercase)
                    : player.name
                  }}
              </div>
              <div 
                class="outcome"
                [ngClass]="{'walkover': player.outcome === 'walkover', 'retired': player.outcome === 'retired'}"
                *ngIf="player.outcome">
                  {{ player.outcome === 'retired' ? ' (Ret.)' : 'Walkover' }}
              </div>
              <div class="score" *ngIf="player.mark === 'completed' && !player.dontShowScore">
                <div>
                  {{ player.score.setOne }}
                  <sup *ngIf="player.score.setOneTie !== undefined">{{ player.score.setOneTie }}</sup>
                </div>
                <div>
                  {{ player.score.setTwo }}
                  <sup *ngIf="player.score.setTwoTie !== undefined">{{ player.score.setTwoTie }}</sup>
                </div>
                <div *ngIf="player.score.setThree !== undefined">
                  {{ player.score.setThree }}
                  <sup *ngIf="player.score.setThreeTie !== undefined">{{ player.score.setThreeTie }}</sup>
                </div>
              </div>
          </div>

          <div *ngIf="!(i % 2)">
            <div class="space"></div>
          </div>

          <div *ngIf="i % 2">
            <div class="space"></div>
            <div class="space"></div>
          </div>
        </div>
      </div>

      <div class="col-33 col-two" *ngIf="roundsData[k+1]">
        <div class="player-wrapper" [ngClass]="{'winner': player.winner}" *ngFor="let player of roundsData[k+1]; let i = index">
          <div *ngIf="i == 0">
            <div class="space"></div>
          </div>
          <div class="player">
            <img [src]="player.image" alt="no-img">
              <div
                class="name"
                [routerLink]="tournamentData.category === 'singles' && player.name !== 'Bye' && player.name !== 'TBA' ? ['/tennis/player/'+player.id] : []">
                  {{ 
                    tournamentData.category === 'singles' ?
                      player.name.split(' ')[0] + ' ' + (player.name !== 'Bye' && player.name !== 'TBA' ? player.name.split(' ')[1][0] + '.' : '' | uppercase)
                    : player.name
                  }}
              </div>
              <div 
                class="outcome"
                [ngClass]="{'walkover': player.outcome === 'walkover', 'retired': player.outcome === 'retired'}"
                *ngIf="player.outcome">
                  {{ player.outcome === 'retired' ? ' (Ret.)' : 'Walkover' }}
              </div>
              <div class="score" *ngIf="player.mark === 'completed' && !player.dontShowScore">
                <div>
                  {{ player.score.setOne }}
                  <sup *ngIf="player.score.setOneTie !== undefined">{{ player.score.setOneTie }}</sup>
                </div>
                <div>
                  {{ player.score.setTwo }}
                  <sup *ngIf="player.score.setTwoTie !== undefined">{{ player.score.setTwoTie }}</sup>
                </div>
                <div *ngIf="player.score.setThree !== undefined">
                  {{ player.score.setThree }}
                  <sup *ngIf="player.score.setThreeTie !== undefined">{{ player.score.setThreeTie }}</sup>
                </div>
              </div>
          </div>

          <div *ngIf="!(i % 2)">
            <div class="space-combined">
            </div>
          </div>

          <div *ngIf="i % 2">
            <div class="space-combined"></div>
          </div>
        </div>
      </div>

      <div class="col-33 col-three" *ngIf="roundsData[k+2]">
        <div class="player-wrapper" [ngClass]="{'winner': player.winner}" *ngFor="let player of roundsData[k+2]; let i = index">
          <div *ngIf="i == 0">
            <div class="space"></div>
            <div class="space"></div>
            <div class="space"></div>
            <div class="space"></div>
            <div class="space"></div>
          </div>
          <div class="player">
            <img [src]="player.image" alt="no-img">
              <div
                class="name"
                [routerLink]="tournamentData.category === 'singles' && player.name !== 'Bye' && player.name !== 'TBA' ? ['/tennis/player/'+player.id] : []">
                  {{ 
                    tournamentData.category === 'singles' ?
                      player.name.split(' ')[0] + ' ' + (player.name !== 'Bye' && player.name !== 'TBA' ? player.name.split(' ')[1][0] + '.' : '' | uppercase)
                    : player.name
                  }}
              </div>
              <div 
                class="outcome"
                [ngClass]="{'walkover': player.outcome === 'walkover', 'retired': player.outcome === 'retired'}"
                *ngIf="player.outcome">
                  {{ player.outcome === 'retired' ? ' (Ret.)' : 'Walkover' }}
              </div>
              <div class="score" *ngIf="player.mark === 'completed' && !player.dontShowScore">
                <div>
                  {{ player.score.setOne }}
                  <sup *ngIf="player.score.setOneTie !== undefined">{{ player.score.setOneTie }}</sup>
                </div>
                <div>
                  {{ player.score.setTwo }}
                  <sup *ngIf="player.score.setTwoTie !== undefined">{{ player.score.setTwoTie }}</sup>
                </div>
                <div *ngIf="player.score.setThree !== undefined">
                  {{ player.score.setThree }}
                  <sup *ngIf="player.score.setThreeTie !== undefined">{{ player.score.setThreeTie }}</sup>
                </div>
              </div>
          </div>

          <div *ngIf="!(i % 2)">
            <div class="space-combined">
            </div>
          </div>

          <div *ngIf="i % 2">
            <div class="space-combined"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noMatches>
  <div class="no-matches">
    Currenly there is no Cosolation
  </div>
</ng-template>
