import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { first } from 'rxjs/internal/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-single-matches',
  templateUrl: './single-matches.component.html',
  styleUrls: ['./single-matches.component.scss']
})
export class SingleMatchesComponent implements OnInit {
  challenges: any[];
  hasSingleMatches: boolean;
  @Input() userObj;

  constructor(private router: Router,
    private firestore: AngularFirestore) { }

  async getSinglesMatches() {
    try {
      let tournaments = await this.firestore.collection('Users').doc(this.userObj.id).collection('history').get().toPromise()
      if(tournaments.docs.length > 0) {
        let allMatches: any[] = await Promise.all(tournaments.docs.map(async el => {
          // getting match data
          let matchId = el.data().matchRef.split('/')[2];
          let match = await this.firestore.collection('Matches').doc(matchId)
                      .get().toPromise()
          let round;
          if(match.exists && match.data().category === 'singles') {
            let fixtureData;
            // getting fixture data
            if(match.data().matchType === 'one-on-one' || match.data().matchType === 'box') {
              round = 'N/A';
              let fixture = el.data().matchRef.split('/')[4];
              fixtureData = (await this.firestore.collection('Matches').doc(matchId)
                                .collection('fixtures').doc(fixture).get().toPromise()).data()
            } else if(match.data().matchType === 'knockout') {
              let roundType = el.data().matchRef.split('/')[3]
              let roundNo = el.data().matchRef.split('/')[4];
              round = 'Round ' + roundNo.slice(-1);
              let fixture = el.data().matchRef.split('/')[6];
              fixtureData = (await this.firestore.collection('Matches').doc(matchId).collection(roundType)
                                .doc(roundNo).collection('fixtures').doc(fixture).get().toPromise()).data()
            }
            if(fixtureData && fixtureData.teamA.matchScore) {
              // Gathering Scores
              let myScore = fixtureData.teamA.id === this.userObj.id ?
              fixtureData.teamA : fixtureData.teamB
              let opponentScore = fixtureData.teamA.id !== this.userObj.id ?
                                fixtureData.teamA : fixtureData.teamB

              myScore = myScore.newScore ? myScore.newScore : myScore.matchScore;
              opponentScore = opponentScore.newScore ? opponentScore.newScore : opponentScore.matchScore;
              fixtureData.scores = {
              myScore,
              opponentScore
              }

              // getting opponent's data
              let opponentId =  fixtureData.teamA.id !== this.userObj.id
                              ? fixtureData.teamA.id : fixtureData.teamB.id
              let opponentName = (await this.firestore.collection('Users').doc(opponentId).get().toPromise()).data();
              return {
              fixtureData: fixtureData,
              title: match.data().matchType === 'one-on-one' ? 'Head 2 Head Challenge' : match.data().title,
              location: match.data().location ? match.data().location : 'No Location',
              prize: match.data().entryFee ? match.data().entryFee * match.data().players.length * 0.85 : 'No Prize',
              matchId,
              round,
              opponentId,
              opponentName: opponentName.firstName + ' ' + opponentName.lastName
              }
            }
          }
        }))
        allMatches = allMatches.filter(Boolean)
        if(allMatches.length > 0) {
          this.challenges = allMatches;
          this.hasSingleMatches = true;
        } else {
          this.hasSingleMatches = false;
        }
      } else {
        this.hasSingleMatches = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

  ngOnInit(): void {
    this.getSinglesMatches();
  }

}
