import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-player-tennis-teams-sent',
  templateUrl: './player-tennis-teams-sent.component.html',
  styleUrls: ['./player-tennis-teams-sent.component.scss']
})
export class PlayerTennisTeamsSentComponent implements OnInit {

  isLoading: boolean = true;
  invites = [];
  requests = [];
  hasInvites: boolean;
  isRejectModalOpen: boolean;
  currRequest;
  isModalLoading: boolean;

  constructor(private firestore: AngularFirestore,
              private authService: AuthService,
              private router: Router) { }

  async gatherData(invites) {
    try {
      let requests = await Promise.all(invites.map(async el => {
        let tempUserObj = {};
        tempUserObj = await this.gatherUserData(el.receiverId, el.inviteId);
        return tempUserObj
      })) 

      this.requests = requests.slice();
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

  async gatherUserData(userId, inviteId) {
    try { 
      let user = await this.firestore.collection('Users').doc(userId).get().toPromise()
      let userObj: any = {};
      userObj.name = user.data().firstName + " "+ user.data().lastName;
      userObj.email = user.data().email;
      userObj.id = userId;
      userObj.inviteId = inviteId;
      return userObj;
    } catch(e) {
      console.log(e)
    }
  }

  openModal(request, modalType) {
    this.currRequest = request;
    if(modalType === 'reject') {
      document.body.classList.add('modal-open');
      this.isRejectModalOpen = true;
    }
  }

  async reject(request) {
    try {
      let deleteDoc = await this.firestore.collection('TeamRequests').doc(request.inviteId).delete();
      this.closeModal();
      this.isLoading = true;
      this.ngOnInit();
    } catch(e) {
      console.log(e)
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isRejectModalOpen = false;
  }
  
  async ngOnInit() {
    try {
      let requests = await this.firestore.collection('TeamRequests', ref => ref
                    .where('senderId', '==', this.authService.user.uid)
                    .where('state', '==', 'pending')).get().toPromise()
      
      if(requests.docs.length > 0) {
        this.invites = requests.docs.slice()
        let invites = [];
        
        invites = this.invites.map(el => {
          let tempInviteObj = el.data();
          tempInviteObj.inviteId = el.id; 
          return tempInviteObj;
        })
        this.gatherData(invites);
        this.hasInvites = true;
      } else {
        this.isLoading = false;
        this.hasInvites = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

}
