import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-tennis-teams',
  templateUrl: './player-tennis-teams.component.html',
  styleUrls: ['./player-tennis-teams.component.scss']
})
export class PlayerTennisTeamsComponent implements OnInit {

  currUrl;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  watchUrl() {
    this.currUrl = this.router.url;
    this.activatedRoute.url.subscribe(val => {
      this.currUrl = this.router.url;
    })
  }

  ngOnInit(): void {
    this.watchUrl();
  }

}
