import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-player-tennis-challenges-upcoming',
  templateUrl: './player-tennis-challenges-upcoming.component.html',
  styleUrls: ['./player-tennis-challenges-upcoming.component.scss']
})
export class PlayerTennisChallengesUpcomingComponent implements OnInit {
  
  hasMatches: boolean;
  rawMatches = [];
  matches = [];
  isLoading: boolean = true;
  isModalLoading: boolean;
  isAcceptModalOpen: boolean;
  isRejectModalOpen: boolean;
  currMatch;
  isToss: boolean;
  tossTimeout: number = 4;
  coin: number;

  $requestState: Subscription;
  fixtureData: any;

  public payPalConfig ? : IPayPalConfig;

  constructor(private firestore: AngularFirestore,
              private authService: AuthService,
              private router: Router) { }

  gatherData(matches) {
    let promises = [];
    matches.forEach(el => {
      let tempUserObj = {};
      tempUserObj = this.gatherUserData(el.senderId, el.requestId, el.payments.amount);
      promises.push(tempUserObj)
    })
    
    Promise.all(promises)
    .then((res) => {
      this.matches = res.slice();
      this.isLoading = false;
    })
    .catch(error => {
      console.log(error)
    })
  }

  gatherUserData(userId, requestId, betAmount) {
    return this.firestore.collection('Users').doc(userId).get().toPromise()
    .then(res => {
      let userObj: any = {};
      userObj.firstName = res.data().firstName;
      userObj.lastName = res.data().lastName;
      userObj.id = userId;
      userObj.requestId = requestId;
      userObj.betAmount = betAmount;
      return userObj;
    })
    .catch(error => {
      console.log(error)        
    })
  }
    
  async openModal(match, modalType) {
    this.currMatch = match;
    if(modalType === 'accept') {
      document.body.classList.add('modal-open');
      try { await this.initConfig(); } catch(e) { console.log(e) }
      this.isAcceptModalOpen = true;
    }

    if(modalType === 'reject') {
      document.body.classList.add('modal-open');
      this.isRejectModalOpen = true;
    }
  }

  private async initConfig() {
    let request;
    try { request = await this.firestore.collection('ChallengeRequests').doc(this.currMatch.requestId).get().toPromise() }
    catch(e) { console.log(e) }
    this.payPalConfig = {
      currency: "AUD",
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'AUTHORIZE',
        purchase_units: [{
          amount: {
            currency_code: 'AUD',
            value: request.data().payments.amount,
          },
        }]
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [{
          name: 'intent',
          value: 'authorize',
        }]
      },
      onApprove: async (data, actions) => {
        try {
          this.isModalLoading = true;
          let authorization = await actions.order.authorize();
          let authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;
          let orderId = data.orderID
          this.capturePaymentsToCreateMatch(this.currMatch.requestId, authorizationID, orderId)          
        } catch(e) {
          console.log(e)
        }
      },
    };
  }

  async capturePaymentsToCreateMatch(requestId, authId, orderId) {
    try {
      let matchRequest = await this.firestore.collection('ChallengeRequests').doc(requestId).get().toPromise();
      let matchRequestData = matchRequest.data();
      matchRequestData.payments.playerTwoAuthId = authId
      matchRequestData.payments.playerTwoOrderId = orderId
      matchRequestData.state = 'capture-payments'

      await this.firestore.collection('ChallengeRequests').doc(requestId).update(matchRequestData);

      this.$requestState = await this.firestore.collection('ChallengeRequests').doc(requestId).valueChanges().subscribe((res: any) => {
        if(res.state === 'accepted' && res.matchId) {
          this.$requestState.unsubscribe();
          console.log('One On One Match Created')
          this.isModalLoading = false;
          this.isToss = true;
          let tossInterval = setInterval(() => {
            this.tossTimeout--;
            if(this.tossTimeout < 1) {
              clearInterval(tossInterval)
              setTimeout(() => {
                this.coinToss(res)
              }, 900)
            }
          }, 1000)
        }
      })
    } catch(e) {
      this.isModalLoading = false;
      console.log(e)
    }
  }
    
  async coinToss(res) {
    try {
      this.coin = Math.floor(Math.random() * 2);
      let newMatch =  (await this.firestore.collection('Matches').doc(res.matchId).collection('fixtures')
                      .doc('fixture1').get().toPromise()).data()
      newMatch['home'] = this.coin === 0 ? 'teamA' : 'teamB';
      this.fixtureData = newMatch;
      await this.firestore.collection('Matches').doc(res.matchId).collection('fixtures').doc('fixture1').update(newMatch)

      // Update startDate and location
      let rawDate = new Date(Date.now())
      let year = rawDate.getFullYear().toString();
      let month = (rawDate.getMonth()+1).toString();
      month = month.length < 2 ? `0${month}` : month;
      let date = rawDate.getDate().toString();
      let startDate = `${year}-${month}-${date}`
      let user = (await this.firestore.collection('Users').doc(newMatch[newMatch['home']].id).get().toPromise()).data();
      await this.firestore.collection('Matches').doc(res.matchId).update({ location: user.location[0].city, startDate })
    } catch(e) {
      console.log(e)
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');    
    this.isAcceptModalOpen = false;    
    this.isRejectModalOpen = false;
  }
  
  closeTossModal() {
    this.closeModal();
    this.router.navigate(['/tennis/user/challenges/accepted']);
  }

  reject(requestId) {
    this.isModalLoading = true;
    this.firestore.collection('ChallengeRequests').doc(requestId).delete()
    .then(res => {
      this.closeModal();
      this.isModalLoading = false;
      this.isLoading = true;
      this.ngOnInit()
    })
    .catch(error => {
      console.log(error)
    })
  }

  ngOnInit() {
    this.firestore.collection('ChallengeRequests', ref => ref
    .where('receiverId', '==', this.authService.user.uid)
    .where('state', '==', 'pending')).get().toPromise()
    .then(res => {
      if(res.docs.length > 0) {
        this.rawMatches = this.rawMatches.concat(res.docs)
        let matches = [];
        matches = this.rawMatches.map(el => {
          let tempMatchObj = el.data();
          tempMatchObj.requestId = el.id; 
          return tempMatchObj;
        })
        this.gatherData(matches);
        this.hasMatches = true;
      } else {
        this.isLoading = false;
        this.hasMatches = false;
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.$requestState) {
      this.$requestState.unsubscribe();
    }
  }

}
