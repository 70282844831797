<div class="container-fluid">
  <app-shade></app-shade>
  <app-page-heading pageHeading="Faqs"></app-page-heading>
</div>
<div class="container-white">
  <div class="container">
    <div class="clearfix"></div>
    <div class="gray-table-container">

      <div class="panel">
        <div class="panel-inner">
          <div class="content">
            <div class="faqs">
              <h4>Entry Fees</h4>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> How much does it cost to enter a monthly knockout tournament?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Each monthly knockout tournament has an entry fee value clearly marked and
                  allocated
                  to that tournament. The majority of monthly tournaments will incur a $30 entry fee.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> How much does it cost to enter a round robin box league?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> The entry fee will be set by the player that sets up that box league. The minimum
                  entry
                  fee for any box league is $20. Remember, the entry fee will also determine the prize money
                  on offer for the winners. A player has the option to enter into an already created box league
                  or can create his/her own box league.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> Can I stipulate my own entry fee amount in a Head 2 Head match? For example, can I
                  prescribe the entry fee to be $37.45?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Yes, once you are eligible to play Head 2 Head matches after securing your
                  official Viictor
                  Rating and are the first player to throw out a Head 2 Head challenge then you can stipulate
                  the entry fee (which determines the prizemoney). There is a minimum entry fee of $20 for
                  all tournaments including Head 2 Head Matches, Monthly Knockout Tournaments and
                  Round Robin Box Leagues.
                </div>
              </div>
            </div>

            <div class="faqs">
              <h4>Prize Money</h4>
              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> How do I receive my prize money?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Prize money is allocated directly to your player profile wallet. As soon as the
                  tournament
                  is complete, prize money is allocated to winning players accounts instantly. You can withdraw your
                  prizemoney
                  or any money from your account at your convenience.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> How much prizemoney can I win?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Every tournament, box league or Head 2 Head match has a prizemoney box visibly
                  showing the
                  prizemoney for winners/runners up etc.
                </div>
              </div>
            </div>

            <div class="faqs">
              <h4>Match Etiquette</h4>
              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> Who brings the balls and what type should I use?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> If you are the away player you are expected to bring the balls. Players should
                  play all
                  matches with new or near new balls and the balls should be ITF approved. You can find the
                  ITF approved stamp on all good quality tennis ball cans/tins or go to this link if you are
                  unsure: <a href="https://www.itftennis.com/technical/balls/approved-balls.aspx" target="_blank">
                    https://www.itftennis.com/technical/balls/approved-balls.aspx</a>
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> Who books and pays for the court?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> The home player is expected to book and pay for the court at the agreed location
                  and
                  time. Please ensure you have booked enough time to complete your match. (Don’t forget to
                  allow time for warm up for all players….5 minutes)
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> Can we share the cost of both the courts and the balls?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Absolutely, as long as it is agreed upon before the match then you can arrange for
                  this to
                  happen however you like. These arrangements should be finalised before you turn up to the match.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> How long is the warm up period before the match?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> All matches should allow for a 5 min warm up period only. If you need a longer
                  warm up
                  then you should plan to arrive at the courts earlier and do your own physical warm up or
                  book your own court prior. Please do not expect your opponent to pay for court hire for
                  your prolonged warm up routine. It is strongly recommended to discuss this with your
                  opponent before the match and agree upon a time limit otherwise stick to the 5 minute
                  warm up rule.
                </div>
              </div>
            </div>

            <div class="faqs">
              <h4>Coin Toss</h4>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What is the relevance of the coin toss?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> The coin toss determines who the Home and Away players are. This also determines
                  who
                  brings the balls and pays for the court.
                  <a [routerLink]="['/tennis/about-us/match-etiquette']">(See match etiquette)</a>
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> If I’m the home player can I play at a different venue other than my preferred
                  home
                  court?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Yes you can, however, please discuss this with your opponent and try not make them
                  drive further than they would expect to if you were playing at home. It is totally acceptable
                  to meet at tennis courts half way between all players or play at the away players venue if
                  agreed upon.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> Do I need to be present for the coin toss?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Both players/teams should be online to witness the coin toss. This is your
                  opportunity to
                  make arrangements to play your match, discuss the venue, court hire, balls, warm up etc.
                  link to match etiquette <a [routerLink]="['/tennis/about-us/match-etiquette']">(See match etiquette)</a>
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What happens if I didn’t log in and I missed the coin toss?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> If after both players have received notifications to log in and complete the coin
                  toss in the
                  given timeframe and it has not happened, then the software will automatically assign each
                  player with a Home or Away position.
                </div>
              </div>
            </div>

            <div class="faqs">
              <h4>Match Results</h4>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> Who should enter the match results and when?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> The winner of the match should enter the results and the other player/team will
                  need to verify that result. Please enter the result immediately after the match or as soon as
                  practical so the tournament draws can be updated, points can be allocated and prizemoney can be paid.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What happens if I disagree with the result/score?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Both players will need to verify the score before the tournament draws can be
                  updated. If you do not agree with the score that the winner enters, please contact him/her directly to
                  discuss and enter the score you think is correct. When both players agree on the score the result will
                  be finalised and published.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What happens if my opponent does not turn up for our agreed match?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> If your opponent is a NO SHOW and has not made any effort to contact you to
                  re-arrange prior to the match, then you have won the match by NO SHOW. Please enter yourself as the
                  winner by NO SHOW. Your opponent will need to verify this result. If the opponent does not verify the
                  result within the given time frame then you will be verified as the winner.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What happens if my opponent refuses to play at anytime within the given timeframe?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> In this scenario you need to discuss with your opponent that you are going to
                  enter the result as the winner of the match by WALK OVER (W/O) your opponent will either verify this
                  or dispute this and the tournament referee will then decide if you can’t agree on the winner.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What happens if my opponent gets injured, has to leave the match or can’t complete
                  the match that was started?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> The injured player should forfeit the match and the player that was able to
                  complete the match wins by forfeit. This result is irrespective of who was winning at the time of the
                  forfeit. The winner should then enter the score into the system. For example I could have lost the
                  first set 3/6 and was down 1/4 in the second set and my opponent could not finish. I would be the
                  winner and enter the score as follows: 3/6, 1/4 forfeit.
                </div>
              </div>
            </div>

            <div class="faqs">
              <h4>Dispute</h4>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What type of disputes can be raised?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> You can raise a dispute over the score and/or the winner of the match. If these
                  disputes are not verified by both players online then the tournament referee will be notified and will
                  adjudicate. However, this should be a very rare scenario. Tennis matches are always decided as a
                  winner and a loser of the match. Once you shake hands with your opponent then that match and score
                  should be agreed upon at the completion and entered as such.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What happens if my opponent constantly cheated throughout the match, was rude,
                  caused trouble, didn’t turn up, or completely overstepped the line as far as acceptable sportsmanship
                  is involved?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> When you verify the results you have the option to raise a red flag against your
                  opponent. This does not altar the result of the match but it will raise concerns against that player.
                  If a player receives 3 red flags from different opponents then they will receive a ban imposed by the
                  tournament referee. The player will never see if you have raised a red flag against him/her.
                </div>
              </div>
            </div>

            <div class="faqs">
              <h4>5 Star Rating</h4>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> What is the rating based on?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Player ratings are based on your own personal experience of that player. All
                  things to consider are, pre match interactions, on time or late, respectful, fair line calls,
                  sportsmanship, able to play hard but still be fair. Your overall vibe and enjoyment of the match
                  irrespective of how you played and the result.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> Why haven’t I got a 5 star rating?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> Your rating is determined by your opponents. All you can do is play fair, be
                  respectful and try to make your match as enjoyable but competitive as possible for all players. This
                  includes being reasonable with setting up match times/locations and in any discussions you have prior
                  to, during and after your match.
                </div>
              </div>

              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> But we are playing a competitive match, how can I be nice?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> You don’t have to be an angel but just be respectful and mindful of others and
                  treat them as you would wish to be treated yourself. Your rating will sort itself out over time and
                  reflects the personality you show to others in all your dealings surrounding each match.
                </div>
              </div>
            </div>

            <div class="faqs">
              <h4>Red Flags</h4>
              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> I have a red flag in my profile, what does this mean?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> If you have a red flag you would have received a notification by email explaining
                  the circumstances. You can receive a red flag irrespective of the result for
                  <ol>
                    <li>Terrible sportsmanship</li>
                    <li>Terrible behaviour</li>
                    <li>Overly aggressive behaviour</li>
                    <li>Generally making the experience for your opponent horrendous</li>
                  </ol>You need to pull your head in very quickly otherwise you will be banned from playing as nobody
                  wants to play against players like this and the Viictor community will be better off without you. Good
                  news is you get 3 strikes/3 red flags so you can redeem yourself and nobody else knows that you have a
                  red flag.
                </div>
              </div>
              <div class="faq">
                <div class="question">
                  <strong>Q.</strong> How many red flags can I get?
                  <div class="expand-sign">+</div>
                </div>
                <div class="answer">
                  <strong>A.</strong> If you receive your third red flag you will be banned from entering any matches
                  for 12 months or longer depending on the decision of the referee. In fact our preference would be that
                  you withdraw all your money from your account and close your account completely. By this stage you
                  have had fair warning and too many people do not enjoy playing with or against you.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-newsletter></app-newsletter>
</div>