
<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <div class="wrapper">
        <div class="sidebar">
          <div class="sidebar-inner">
            <ul>
              <li [routerLink]="['/admin/users']" routerLinkActive="active">Users</li>
              <li [routerLink]="['/admin/disputes']" routerLinkActive="active">Disputes</li>
              <li [routerLink]="['/admin/queries']" routerLinkActive="active">Queries</li>
              <li [routerLink]="['/admin/transactions']" routerLinkActive="active">Transactions</li>
              <li [routerLink]="['/admin/email-dashboard']" routerLinkActive="active">Email Dashboard</li>
              <li [routerLink]="['/admin/gallery']" routerLinkActive="active">Gallery</li>
            </ul>
          </div>
        </div>
        <div class="info-bar">
          <div class="info-bar-inner">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
