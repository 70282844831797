import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'

@Component({
  selector: 'app-fixtures',
  templateUrl: './fixtures.component.html',
  styleUrls: ['./fixtures.component.scss']
})
export class FixturesComponent implements OnInit {

  k: number = 0;

  @Input() tournament;
  tournamentData;
  tournamentRounds: AngularFirestoreDocument;
  areRoundsLoaded: boolean;
  roundsRawData = [];
  roundsDataLoaded: boolean;
  roundsData;
  numberOfRounds: number;
  areRoundsCreated: boolean;
  isNavigationCreated: boolean;
  navigationArray;

  isBoxMatch: boolean;
  isKnockoutMatch: boolean;

  boxFixtures;
  teams;
  isUserBoxFixtures: boolean;
  isTeamsFixtures: boolean;

  isLoading: boolean;
  registeredPlayers: any[];

  constructor(private firestore: AngularFirestore) { }

  // For Knockout
  navigateDraws(index) {
    this.k = index;
  }

  async getRegisteredPlayers() {
    let players = this.tournamentData.players;
    if(players) {
      try {
        if(this.tournamentData.category === 'singles') {
          this.registeredPlayers = await Promise.all(players.map(async user => await this.gatherData(user.id)));
        } else {
          this.registeredPlayers = await Promise.all(players.map(async team => await this.gatherKnockoutTeamData(team.id)));
        }
      } catch(e) {
        console.log(e)
      }
    }
  }

  getRounds() {
    this.tournament.ref.collection('rounds').get()
    .then(res => {
      this.tournamentRounds = res.docs
      this.areRoundsLoaded = true;
      this.serparateRounds(this.tournamentRounds);
    })
    .catch(error =>{
      console.log(error)
    })
  }

  serparateRounds(rounds) {
    if(this.areRoundsLoaded) {
      let promises = [];

      rounds.forEach((el, index) => {
        let rounds = []
        rounds.length = el.data().fixtureLength
        rounds.fill(undefined)
        promises.push(el.ref.collection('fixtures').get()
        .then(res => {
          res.docs.forEach((el2, index2) => {
            let fixtureId = Number(el2.id.match(/\d+/g)[0]);
            rounds[fixtureId - 1] = el2;
          })
          this.roundsRawData.push(rounds);
        })
        .catch(error => {
          console.log(error)
        }));
      });

      Promise.all(promises)
      .then(() => {
        this.roundsDataLoaded = true;
        this.createRounds(this.roundsRawData);
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  createRounds(rawData) {
    let rawRounds = rawData.slice();
    let rounds = [];
    rawRounds.forEach((el) => {
      let tempArr = el;
      tempArr.forEach((el, index, array) => {
        if(el?.data()) {
          array[index] = el.data(); 
        }
      })
      rounds.push(tempArr);
    });
    this.createSeparateRounds(rounds);
  }

  async createSeparateRounds(rawRounds) {
    let rounds = [];
    rawRounds.forEach(el1 => {
      let round = [];
      el1.forEach((el2) => {
        if(el2) {
          if(el2.frontEndState === 'completed') {
            el2.teamA.mark = 'completed';
            el2.teamB.mark = 'completed';
            if(el2.outcome && el2.outcome.matchOutcome) {
              let outcome  = el2.outcome.newMatchOutcome ? el2.outcome.newMatchOutcome : el2.outcome.matchOutcome
              if(outcome.type === 'walkover') {
                el2.teamA.dontShowScore = true;
                el2.teamB.dontShowScore = true;              
              }
              if(outcome.team === 'teamA') el2.teamA.outcome = outcome.type
              else el2.teamB.outcome = outcome.type
            }
          }
          round.push(el2.teamA)
          round.push(el2.teamB)
        } else round.push(undefined, undefined)
      })
      rounds.push(round)
    })
    this.numberOfRounds = rounds.length;
    await this.gatherUserData(rounds)
    this.createRoundNavigation(rounds)
  }

  async gatherUserData(rounds) {
    try {
      let roundUsers = [];
      for(let i = 0; i < rounds.length; i++) {
        let round = rounds[i];
        let users = [];
        for (let j = 0; j < round.length; j++) {
          if(round[j]) {
            let userData: any = { ...this.registeredPlayers.find(el => el.id === round[j].id) };
            if(!userData || Object.keys(userData).length < 1) {
              if(this.tournamentData.category === 'singles') userData = await this.gatherData('')
              else if(this.tournamentData.category === 'doubles') userData = await this.gatherKnockoutTeamData('') 
            }

            if(round[j].mark === 'completed') {
              userData.mark = 'completed';
              userData.score = round[j].newScore ?? round[j].matchScore
            }
            if(i === 0 && round[j].seed) userData.seed = round[j].seed;
            if(round[j].outcome) userData.outcome = round[j].outcome;
            if(round[j].dontShowScore) userData.dontShowScore = round[j].dontShowScore;

            users.push(userData);
          } else users.push(undefined);
        }
        roundUsers.push(users);
      }
      roundUsers.forEach(el => {
        el.forEach(async (el2, index, array) => {
          if(!el2) array[index] = { name: 'TBA', image: '../../../assets/image/no-img.webp' };
        })
      })
      this.roundsData = roundUsers;
      this.areRoundsCreated = true;
      this.isLoading = false;
    } catch(e) {
      console.log(e);
    }
  }

  async gatherData(userId) {
    let userObj: any = {};
    if(userId !== "") {
      let user = await this.firestore.collection('Users').doc(userId).get().toPromise();
      userObj = {
        id: userId,
        email: user.data().email,
        name: user.data().firstName + " " + user.data().lastName,
        victorPoints: user.data().victorPoints,
        image: user.data().profileImg ? user.data().profileImg : '../../../assets/image/no-img.webp'
      };
    } else {
      userObj = {
        id: "userId",
        email: "email",
        name: "Bye",
        victorPoints: "victorPoints",
        image: '../../../assets/image/no-img.webp'
      };
    }
    return userObj;
  }

  async gatherKnockoutTeamData(teamId) {
    let teamObj: any = {};
    try {
      if(teamId !== '') {
        let team = await this.firestore.collection('Teams').doc(teamId).get().toPromise();
        teamObj = team.data();
        teamObj.id = team.id;
        teamObj.image = '../../../assets/image/no-img.webp'
      } else {
        teamObj.name = 'Bye';
        teamObj.image = '../../../assets/image/no-img.webp'
      }
      return teamObj
    } catch(e) {
      console.log(e)
    }
  }

  async createRoundNavigation(rounds) {
    const addCompulsoryRounds = (roundArr) => {
      roundArr.push({ round: 'Quarterfinals', prize: 0});
      roundArr.push({ round: 'Semifinals', prize: 0});
      roundArr.push({ round: 'Finals', prize: 0});
      roundArr.push({ round: 'Winner', prize: 0});
    }
    let roundsArray = [];
    if(rounds[0].length <= 8) {
      addCompulsoryRounds(roundsArray);
    } else {
      for(let i = 0; i < rounds.length; i++) {
        if(rounds[i].length >= 16) {
          let roundName = 'Round of ' + rounds[i].length;
          roundsArray.push({ round: roundName, prize: 0})
        }
      }
      addCompulsoryRounds(roundsArray);
    }

    const responseArray = await this.assignPrizeMoney(this.tournamentData, rounds, roundsArray)
    this.navigationArray = responseArray;
    this.isNavigationCreated = true;
  }

  async assignPrizeMoney(tournament, rounds, roundsArray) {
    let playersCount = tournament.players.length;
    let prize = tournament.category === 'doubles' ?
                  ((playersCount * tournament.entryFee) * 2) * 0.85
                : (playersCount * tournament.entryFee) * 0.85;
    
    if(playersCount <= 8) {
      roundsArray[roundsArray.length-2].prize = prize * 0.32;
      roundsArray[roundsArray.length-1].prize = prize * 0.68;
    } else if(playersCount <= 23) {
      roundsArray[roundsArray.length-3].prize = prize * 0.11;
      roundsArray[roundsArray.length-2].prize = prize * 0.26;
      roundsArray[roundsArray.length-1].prize = prize * 0.52;      
    } else if(playersCount <= 128) {
      roundsArray[roundsArray.length-4].prize = prize * 0.05;
      roundsArray[roundsArray.length-3].prize = prize * 0.11;
      roundsArray[roundsArray.length-2].prize = prize * 0.20;
      roundsArray[roundsArray.length-1].prize = prize * 0.38;
    }

    if(tournament.frontEndState === 'completed' && tournament.winner) {
      try {
        let user =  tournament.category === 'singles' ?
                      await this.gatherData(tournament.winner.id)
                    : await this.gatherKnockoutTeamData(tournament.winner.id)
        user.winner = true;
        this.roundsData.push([user])
      } catch(e) {
        console.log(e)
      }
    }

    return roundsArray;
  }

  /////////////////////////////////

  // For Box Match
  async getBoxFixtures() {
    try {
      let fixtures = await this.tournament.ref.collection('fixtures').get();
      return this.seperateBoxFixtures(fixtures.docs);
    } catch(e) {
      console.log(e)
    }
  }

  async seperateBoxFixtures(rawFixtures) {
    let fixtures: any[] = rawFixtures.map(el => {
      return el.data()
    })

    if(this.tournamentData.category === 'singles') {
      return this.gatherBoxUserData(fixtures)
    } else if(this.tournamentData.category === 'doubles') {
      return this.gatherBoxTeamData(fixtures)
    }
  }

  async gatherBoxUserData(fixtures) {
    let promises = [];
    fixtures.forEach((el) => {
      let tempObjA;
      let tempObjB;
      tempObjA = this.gatherBoxMatchUserData(el.teamA.id);
      tempObjB = this.gatherBoxMatchUserData(el.teamB.id);
      promises.push(tempObjA)
      promises.push(tempObjB)
    })

    return Promise.all(promises)
    .then((res) => {
      let usersData = []
      let arr = res.slice();
      let length = arr.length/2;
      for(let i = 0; i < length; i++) {
        let tempObj: any = {};
        tempObj = fixtures[i];
        let teamA: any = arr.splice(0,1)[0];
        let teamB: any = arr.splice(0,1)[0];

        if(fixtures[i].teamA.matchScore) {
          teamA.matchScore = fixtures[i].teamA.matchScore
          teamB.matchScore = fixtures[i].teamB.matchScore
        }
        if(fixtures[i].teamA.newScore) {
          teamA.newScore = fixtures[i].teamA.newScore
          teamB.newScore = fixtures[i].teamB.newScore
        }

        tempObj.teamA = teamA;
        tempObj.teamB = teamB;
        usersData.push(tempObj)
      }

      this.boxFixtures = usersData;
      this.areRoundsCreated = true;
      this.isUserBoxFixtures = true;
      this.isLoading = false;
    })
    .catch(error => {
      console.log(error)
    })
  }

  async gatherBoxMatchUserData(id) {
    try {
      let user = await this.firestore.collection('Users').doc(id).get().toPromise()
      let userObj: any = {};
      userObj.name = user.data().firstName + " " + user.data().lastName
      userObj.id = id;
      userObj.email = user.data().email;
      userObj.victorPoints = user.data().victorPoints;
      userObj.image = user.data().profileImg ? user.data().profileImg : '../../../assets/image/no-img.webp';
      return userObj;
    } catch(e) {
      console.log(e)
    }
  }

  async gatherBoxTeamData(fixtures) {
    let teams = await Promise.all(fixtures.map(async el => {
      try {
        let teamA: any = {}
        teamA.playerOne = {}
        teamA.playerTwo = {}

        let teamARawData = await this.firestore.collection('Teams').doc(el.teamA.id).get().toPromise();

        let teamAPlayerOne: any = await this.firestore.collection('Users').doc(teamARawData.data().members[0].id).get().toPromise();
        teamA.playerOne.name = teamAPlayerOne.data().firstName + " " + teamAPlayerOne.data().lastName;
        teamA.playerOne.email = teamAPlayerOne.data().email;
        teamA.playerOne.id = teamAPlayerOne.id;
        teamA.playerOne.image = teamAPlayerOne.data().profileImg ? teamAPlayerOne.data().profileImg : '../../../assets/image/no-img.webp';

        let teamAPlayerTwo = await this.firestore.collection('Users').doc(teamARawData.data().members[1].id).get().toPromise();
        teamA.playerTwo.name = teamAPlayerTwo.data().firstName + " " + teamAPlayerTwo.data().lastName;
        teamA.playerTwo.email = teamAPlayerTwo.data().email;
        teamA.playerTwo.id = teamAPlayerTwo.id;
        teamA.playerTwo.image = teamAPlayerTwo.data().profileImg ? teamAPlayerTwo.data().profileImg : '../../../assets/image/no-img.webp';

        teamA.teamName = teamARawData.data().name;
        teamA.teamId = teamARawData.id;

        let teamB: any = {}
        teamB.playerOne = {}
        teamB.playerTwo = {}
        let teamBRawData = await this.firestore.collection('Teams').doc(el.teamB.id).get().toPromise();

        let teamBPlayerOne = await this.firestore.collection('Users').doc(teamBRawData.data().members[0].id).get().toPromise();
        teamB.playerOne.name = teamBPlayerOne.data().firstName + " " + teamBPlayerOne.data().lastName;
        teamB.playerOne.email = teamBPlayerOne.data().email;
        teamB.playerOne.id = teamBPlayerOne.id;
        teamB.playerOne.image = teamBPlayerOne.data().profileImg ? teamBPlayerOne.data().profileImg : '../../../assets/image/no-img.webp';

        let teamBPlayerTwo = await this.firestore.collection('Users').doc(teamBRawData.data().members[1].id).get().toPromise();
        teamB.playerTwo.name = teamBPlayerTwo.data().firstName + " " + teamBPlayerTwo.data().lastName;
        teamB.playerTwo.email = teamBPlayerTwo.data().email;
        teamB.playerTwo.id = teamBPlayerTwo.id;
        teamB.playerTwo.image = teamBPlayerTwo.data().profileImg ? teamBPlayerTwo.data().profileImg : '../../../assets/image/no-img.webp';

        teamB.teamName = teamBRawData.data().name;
        teamB.teamId = teamBRawData.id;

        let fixtureObj = {
          teamA,
          teamB,
          home: el.home,
          frontEndState: el.frontEndState ? el.frontEndState : '',
          winner: el.winner ? el.winner : '',
        }
        if(el.frontEndState === 'completed') {
          fixtureObj.teamA.matchScore = el.teamA.matchScore
          fixtureObj.teamB.matchScore = el.teamB.matchScore
          if(el.teamA.newScore) {
            fixtureObj.teamA.newScore = el.teamA.newScore
            fixtureObj.teamB.newScore = el.teamB.newScore
          }
        }

        return fixtureObj
      } catch(e) {
        console.log(e)
      }
    }))

    this.teams = teams.slice();
    this.areRoundsCreated = true;
    this.isTeamsFixtures = true;
    this.isLoading = false;
  }

  ngOnInit() {
    this.tournamentData = this.tournament.data();
    this.isLoading = true;
    if(this.tournamentData.matchType === "knockout") {
      this.getRegisteredPlayers();
      this.isKnockoutMatch = true;
      this.getRounds();
    } else if (this.tournamentData.matchType === 'box') {
      this.isBoxMatch = true;
      this.getBoxFixtures();
    }
  }
}
