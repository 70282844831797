<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">

  <app-player-menu></app-player-menu>

  <div class="container">
    <div class="gray-table-container">      
      <div class="statistics-tabs">
        <ul>
          <li [ngClass]="{'tab-active': this.currUrl.includes('user/teams/general-teams')}">
            <a [routerLink]="['/tennis/user/teams/general-teams']">Team Invites</a>
          </li>
          <li [ngClass]="{'tab-active': this.currUrl.includes('user/teams/tournament-teams')}">
            <a [routerLink]="['/tennis/user/teams/tournament-teams']">Match Invites</a>
          </li>
        </ul>
      </div>

      <router-outlet></router-outlet>
      
    </div>

    <div class="clearfix"></div>

  </div>
</div>
