import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/internal/operators';

@Component({
  selector: 'app-player-overview',
  templateUrl: './player-overview.component.html',
  styleUrls: ['./player-overview.component.scss']
})
export class PlayerOverviewComponent implements OnInit {
  currUserRawObj: any;
  currUserObj: any;
  isUserLoggedIn: boolean;
  currUrl: any;
  isUserDataLoaded: boolean;
  isLoading: boolean = true;
  node;
  isError: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private authService: AuthService) { }

  async getPlayerDetails() {
    try {
      let playerId = this.node.playerId;
      let snapshot = await this.firestore.collection('Users').doc(playerId).get().toPromise();
      this.currUserRawObj = snapshot;
      this.currUserObj = snapshot.data();
      if (this.currUserObj.dateOfBirth) {
        let playerAge =  new Date().getFullYear() - this.currUserObj.dateOfBirth.split('-')[0];
        this.currUserObj.age = playerAge;
      }
      if(!this.currUserObj.profileImg) {
        this.currUserObj.profileImg = '../../../assets/image/no-img.webp';
      }
      this.isUserDataLoaded = true;
      this.isLoading = false;
    } catch(error) {
      this.isLoading = false;
      this.isError = true;
      console.log(error)
    }
  }

  ngOnInit() {
    this.getPlayerDetails();
  }

}
