import { first } from 'rxjs/internal/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements  CanActivate {
  constructor(
    private authService: AuthService,
    public router: Router) {}
 
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const user = await this.authService.authState.pipe(first()).toPromise();
      if (user) {
        return true;
      }
      else {
        this.router.navigate(['tennis/users/login', {redirectTo: state.url}]);
        return false;
      }
    }
    catch (error) {
      console.log(error);
      this.router.navigate(['tennis/users/login']);
      return false;
    }
  }
}