<div class="detail-box" (window:resize)="onResize($event)">

  <div class="overview-details">
    <div class="detail">
      <label>Matches Played</label>
      <div class="b-text">16</div>
    </div>
    <div class="detail">
      <label>Money Won</label>
      <div class="b-text">$1.279,00</div>
    </div>
    <div class="detail win-lose">
      <div class="win">
        <label>Wins</label>
        <div class="b-text">102</div>
      </div>
      <div class="lose">
        <label>Loses</label>
        <div class="b-text">3</div>
      </div>
    </div>
    <div class="detail win-ratio">
      <label>Winning Ratio</label>

      <div class="circle-cover">
        <circle-progress
          [percent]="60"
          [radius]="circleRadius"
          [outerStrokeWidth]="circleOuterStroke"
          [innerStrokeWidth]="circleInnerStroke"
          [outerStrokeColor]="'#78C000'"
          [innerStrokeColor]="'#d4d4d4'"
          [space]="circlePadding"
          [animation]="true"
          [animationDuration]="300"
          [titleFontSize] = "circleFontSize"
          [titleFontWeight] = "800"
          [showSubtitle] = "false"
          [subtitleFontSize] = "15"
        ></circle-progress>
        </div>
    </div>
  </div>

  <div class="statistics-details">

    <div class="info-bar">
      <div class="utr">UTR</div>
      <div class="vs">VS</div>
      <div class="games-played">Games Played</div>
    </div>

    <div class="statistics">
      <img src="../../../assets/image/statistics.webp" alt="no-img">
    </div>

  </div>
</div>
