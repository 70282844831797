<div class="standings">
  <div *ngIf="isStandings" class="table-parent">
    <table>
      <thead>
        <tr>
          <td>Name</td>
          <td>Played</td>
          <td>Viictor Points</td>
          <td>Sets</td>
          <td>Games</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let standing of standings; let i = index">
          <td>{{ standing.name }}</td>
          <td>{{ standing.matchesPlayed }}</td>
          <td>{{ standing.victorPoints }}</td>
          <td>{{ standing.setsWinnings }} - {{ standing.setsLosings }}</td>
          <td>{{ standing.gameWinnings }} - {{ standing.gameLosings }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!isStandings">
    Currently there no standings. Please stay tuned.
  </div>
</div>
