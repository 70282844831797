<div class="users">
  <div class="users-inner">
    <h3 class="top-heading">Email Dashboard</h3>
    <div class="email-dashboard-wrapper">
      <form [formGroup]="formGroup">
        <div class="row fields">
          <!-- Multi Select Email -->
          <div class="col-12 field">
            <label>To</label>
            <ng-select
              [items]="users"
              bindLabel="email"
              bindValue="id"
              [multiple]="true"
              placeholder="Add Emails"
              formControlName="players">
            </ng-select>
            <div class="form-check">
                <input class="form-check-input" type="radio" value="allUsers" formControlName="selectType" id="allUsers">
                <label class="form-check-label" for="allUsers">Select all users</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" value="byTier" formControlName="selectType" id="byTier">
              <label class="form-check-label" for="byTier">Select users by tier</label>
            </div>

            <div class="tier-radio" *ngIf="formGroup.controls['selectType'].value === 'byTier'">
              <div class="form-check">
                <fieldset [disabled]="!(tierOneUserIds?.length)">
                  <input class="form-check-input" type="radio" value="tierOne" formControlName="tierType" id="tierOneUsers">
                  <label class="form-check-label" for="tierOneUsers">Select Tier 1 users<code *ngIf="tierOneUserIds.length < 1">: Tier 1 users don't exist</code></label>
                </fieldset>
              </div>
              <div class="form-check">
                <fieldset [disabled]="!(tierTwoUserIds?.length)">
                  <input class="form-check-input" type="radio" value="tierTwo" formControlName="tierType" id="tierTwoUsers">
                  <label class="form-check-label" for="tierTwoUsers">Select Tier 2 users<code *ngIf="tierTwoUserIds.length < 1">: Tier 2 users don't exist</code></label>
                </fieldset>
              </div>
              <div class="form-check">
                <fieldset [disabled]="!(tierThreeUserIds?.length)">
                  <input class="form-check-input" type="radio" value="tierThree" formControlName="tierType" id="tierThreeUsers">
                  <label class="form-check-label" for="tierThreeUsers">Select Tier 3 users<code *ngIf="tierThreeUserIds.length < 1">: Tier 3 users don't exist</code></label>
                </fieldset>
              </div>
              <div class="form-check">
                <fieldset [disabled]="!(tierThreeUserIds?.length)">
                  <input class="form-check-input" type="radio" value="tierFour" formControlName="tierType" id="tierFourUsers">
                  <label class="form-check-label" for="tierFourUsers">Select Tier 4 users<code *ngIf="tierFourUserIds.length < 1">: Tier 4 users don't exist</code></label>
                </fieldset>
              </div>
            </div>
          </div>
          <!-- Other Emails -->
          <div class="col-12 field">
            <label>Other Emails</label>
            <div class="other-emails" *ngIf="formGroup.value.emails.length > 0">
              <div *ngFor="let email of formGroup.value.emails; let i = index">
                <span class="icon" (click)="removeEmail(i)">x</span><span class="value">{{ email }}</span>
              </div>
            </div>
            <div class="add-email">
              <input #otherEmail class="form-control" placeholder="Add Other Emails" (keyup)="inputEmailHandler($event)" (keyup.space)="addEmail($event)" />
              <button (click)="addEmail(otherEmail.value)">Add</button>
            </div>
            <pre><strong>Pro tip:</strong> Press <strong>Space</strong> to add an email</pre>
          </div>
          <!-- Subject -->
          <div class="col-12 field">
            <label>Subject</label>
            <input class="form-control" placeholder="Add Subject" formControlName="subject" />
          </div>
          <!-- Pre Header -->
          <div class="col-12 field">
            <label>Pre Header</label>
            <input class="form-control" placeholder="Add Pre Header" formControlName="preheader" />
          </div>
          <!-- WYSIWYG Email Content Box -->
          <div class="col-12 field">
            <label>Email Content</label>
            <div class="quill-editor">
              <quill-editor
                [styles]="{ minHeight: '150px', maxHeight: '500px', overflowY: 'scroll'}"
                formControlName="content"></quill-editor></div>
          </div>
          <!-- Submit Button -->
          <div class="col-12 field">
            <div *ngIf="isLoading" class="loader">
              <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
            </div>
            <button *ngIf="!isLoading" [disabled]="formGroup.invalid" (click)="sendEmail(formGroup)">Send Email</button>
            <p></p>
            <div class="alert alert-success" *ngIf="success">Your email has been sent successfully</div>
            <div class="alert alert-danger" *ngIf="error.isError">{{ error.message }}</div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
