import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StarRatingComponent } from 'ng-starrating';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-upload-player-rating',
  templateUrl: './upload-player-rating.component.html',
  styleUrls: ['./upload-player-rating.component.scss']
})
export class UploadPlayerRatingComponent implements OnInit {

  @Output() isPlayerRatingModalOpen= new EventEmitter();
  @Input() fixtureData;
  totalStars: number = 5;
  playerRating: number = 5;

  constructor(private authService:  AuthService, private firestore: AngularFirestore) { }

  onRate($event: {oldValue: number, newValue: number, starRating: StarRatingComponent}) {
    this.playerRating = $event.newValue;
  }

  async confirmRating() {
    try {
      let userId: string = this.fixtureData.fixture.opponent.id;

      await this.firestore.collection('Users').doc(userId).collection('rating').add({
        rating: this.playerRating,
        uid: this.authService.user.uid,
        timestamp: Date.now(),
      })
      this.isPlayerRatingModalOpen.emit(false);
    } catch(e) {
      console.log(e)
    }
  }

  ngOnInit(): void {
  }

}
