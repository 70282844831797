import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from './../../services/auth/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { StarRatingComponent, RatingModule } from 'ng-starrating';

@Component({
  selector: 'app-player-matches',
  templateUrl: './player-matches.component.html',
  styleUrls: ['./player-matches.component.scss']
})
export class PlayerMatchesComponent implements OnInit {

  userRegistered: boolean;
  @Input() tournament;
  tournamentData;
  tournamentRounds;
  tournamentLosersRounds;
  areRoundsLoaded: boolean;
  areLosersRoundsLoaded: boolean
  roundsRawData = [];
  losersRoundsRawData = [];
  roundsDataLoaded: boolean;
  losersRoundsDataLoaded: boolean
  roundsData;
  numberOfRounds: number;
  finalRounds = [];
  finalLosersRounds = [];
  areRoundsCreated: boolean;
  userMatchesFound: boolean;
  userLosersMatchesFound: boolean
  userMatches;
  userLoserMatches;
  scoreBy: string;
  isScoreModalOpen: boolean;
  isplayersDataGathered: boolean;
  playersData;
  currentRound: string;
  currentFixture: string;
  errorMessage: string;

  formGroup: FormGroup;
  isSetThree: boolean;
  isSetOneTie: boolean;
  isSetTwoTie: boolean;
  isSetThreeTie: boolean;

  isKnockoutMatch: boolean;
  isBoxMatch: boolean;
  boxFixtures;
  userBoxMatches;
  boxMatchscoreBy: string;
  currentBoxMatchIndex: number;
  currentKnockoutMatchIndex: number;

  finalUserMatches;
  finalLoserUserMatches;
  roundType;
  uploadUserMatchesScore: boolean;
  uploadLoserUserMatchesScore: boolean;

  isUploadScoreLoading: boolean;
  errorUploadScore: boolean;

  myTeamObj;
  boxTeamsFixtures;
  isUserBoxFixtures: boolean;
  isTeamBoxFixtures: boolean;
  isSingleFixtures: boolean;
  isTeamFixtures: boolean;

  isPlayerRatingModalOpen: boolean;
  totalStars: number = 5;
  playerRating: number = 5;
  currentFirebaseAddress: any;

  isIncompleteMatch: boolean = false;
  isFormFilled: boolean;
  matchOutcome: any = { type: "", team: "" };
  @ViewChild("matchOutcome") matchOutcomeElement: ElementRef;

  constructor(private authService:  AuthService, private firestore: AngularFirestore) { }

  //ng-starrating
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    this.playerRating = $event.newValue;
  }
  //

  async isUserRegistered(tournamentData) {
    let players: [any] = tournamentData.players;
    if(players) {
      if(tournamentData.category === 'singles') {
        players.some(player => {
          if(player.id === this.authService.user.uid) {
            this.userRegistered = true;
            if(this.tournamentData.matchType === 'knockout') {
              this.isKnockoutMatch = true;
              this.getRounds();
            } else if(this.tournamentData.matchType === 'box') {
              this.isBoxMatch = true;
              this.getBoxFixtures();
            }
            return true;
          }
        });
      } else if(tournamentData.category === 'doubles') {
        try {
          let teams = [];
          teams = await Promise.all(tournamentData.players.map(async el => {
            let team = await this.firestore.collection('Teams').doc(el.id).get().toPromise()
            let teamObj: any = {}
            teamObj = team.data();
            teamObj.id = team.id;
            return teamObj
          }))

          teams.some(el => {
            let isTeamFound: boolean = false;
            el.members.some(el2 => {
              if(el2.id === this.authService.user.uid) {
                this.myTeamObj = el;
                this.userRegistered = true;
                isTeamFound = true;

                if(this.tournamentData.matchType === 'knockout') {
                  this.isKnockoutMatch = true;
                  this.getRounds();
                } else if(this.tournamentData.matchType === 'box') {
                  this.isBoxMatch = true;
                  this.getBoxTeamsFixtures();
                }
                return true
              }
            })
            if(isTeamFound) { return true; }
          })
        } catch(e) {
          console.log(e);
        }
      }
    }
  }


  // For Knockout Matches
  async getRounds() {
    try {
      if(this.userRegistered) {
        let rounds = await this.tournament.ref.collection('rounds').get()
        let losersRounds = await this.tournament.ref.collection('loserRounds').get()
          this.tournamentRounds = rounds.docs
          this.areRoundsLoaded = true;
          this.serparateRounds(this.tournamentRounds, 'rounds');
          if(losersRounds.docs.length > 0) {
            this.tournamentLosersRounds = losersRounds.docs
            this.areLosersRoundsLoaded = true;
            this.serparateRounds(this.tournamentLosersRounds, 'losersRounds');
          }
      }
    } catch(e) {
      console.log(e)
    }
  }

  async serparateRounds(rounds, roundType) {
    let promises = [];
    rounds.forEach((el: AngularFirestoreDocument, index) => {
      promises.push(el.ref.collection('fixtures').get()
      .then(res => {
        if(roundType === 'rounds') {
          this.roundsRawData.push(res.docs);
        } else if('losersRounds') {
          this.losersRoundsRawData.push(res.docs);
        }
      })
      .catch(error => {
        console.log(error)
      }));
    });

    Promise.all(promises)
    .then(() => {
      if(roundType === 'rounds') {
        this.roundsDataLoaded = true;
        this.createRounds(this.roundsRawData, 'rounds');
      } else if('losersRounds') {
        this.losersRoundsDataLoaded = true;
        this.createRounds(this.losersRoundsRawData, 'losersRounds');
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  createRounds(rawData, roundType) {
    let rawRounds = rawData.slice(0);
    let rounds = [];
    rawRounds.forEach((el) => {
      let tempArr = [];
      el.map((obj, index) => {
        tempArr.push(obj.data())
        if(index === el.length - 1) {
          rounds.push(tempArr);
        }
      });
    });

    if(roundType === 'rounds') {
      this.finalRounds = rounds.slice()
      this.gatherUserKnockoutMatches(rounds, 'rounds');
    } else if(roundType === 'losersRounds') {
      this.finalLosersRounds = rounds.slice();
      this.gatherUserKnockoutMatches(rounds, 'losersRounds');
    }

  }

  async gatherUserKnockoutMatches(rawRounds, roundType) {
    let userMatches = [];
    if(this.tournamentData.category === 'singles') {
      this.isSingleFixtures = true;
      rawRounds.forEach(round => {
        round.forEach((fixture, index) => {
          Object.values(fixture).forEach((player: any) => {
            if(player.id === this.authService.user.uid) {
              let tempObj = fixture;
              tempObj.fixture = index+1;
              userMatches.push(tempObj);
            }
          })
        })
      })

      if(roundType === 'rounds') {
        if(userMatches.length > 0) {
          this.userMatches = userMatches;
          this.userMatchesFound = true;
          this.gatherData(userMatches, 'rounds')
        }
      } else if(roundType === 'losersRounds') {
        if(userMatches.length > 0) {
          this.userLoserMatches = userMatches;
          this.userLosersMatchesFound = true;
          this.gatherData(userMatches, 'losersRounds')
        }
      }

    } else if(this.tournamentData.category === 'doubles') {
      this.isTeamFixtures = true;
      rawRounds.forEach(round => {
        round.forEach((fixture, index) => {
          if((fixture.teamA && this.myTeamObj.id === fixture.teamA.id) || (fixture.teamB && this.myTeamObj.id === fixture.teamB.id)) {
            let fixtureObj: any = {}
            fixtureObj = fixture;
            fixtureObj.fixture = (index + 1)
            userMatches.push(fixtureObj)
          }
        })
      })
      if(roundType === 'rounds') {
        if(userMatches.length > 0) {
          this.userMatches = userMatches;
          this.userMatchesFound = true;
          let teamsFixtures =  [];
          try {
            teamsFixtures = await this.getKnockoutTeamsData(userMatches);
            this.finalUserMatches = teamsFixtures;
          } catch(e) {
            console.log(e)
          }
        }


      } else if(roundType === 'losersRounds') {
        if(userMatches.length > 0) {
          this.userLoserMatches = userMatches;
          this.userLosersMatchesFound = true;
          let teamsFixtures =  [];
          try {
            teamsFixtures = await this.getKnockoutTeamsData(userMatches);
            this.finalLoserUserMatches = teamsFixtures;
          } catch(e) {
            console.log(e)
          }
        }
      }
    }

  }

  gatherData(matches, roundType) {
    let promises = [];
    matches.forEach((el) => {
      let tempObjA;
      let tempObjB;
      tempObjA = this.gatherUserData(el?.teamA?.id, el.fixture);
      tempObjB = this.gatherUserData(el?.teamB?.id, el.fixture);
      promises.push(tempObjA)
      promises.push(tempObjB)
    })

    Promise.all(promises)
    .then((res) => {
      let usersData = []
      let arr = res.slice();
      let length = arr.length/2;
      for(let i = 0; i < length; i++) {
        let tempObj: any = {};
        tempObj.teamA = arr.splice(0,1)[0];
        tempObj.teamB = arr.splice(0,1)[0];
        tempObj.fixture = tempObj.teamA.fixture;
        usersData.push(tempObj)
      }
      matches.forEach((el, index) => {
        if(el.frontEndState) {
          usersData[index].frontEndState = el.frontEndState;
        }
        if(el.winner) {
          usersData[index].winner = el.winner;
        }
        if(el.home) {
          usersData[index].home = el.home;
        }
      })

      if(roundType === 'rounds') {
        this.finalUserMatches = usersData.slice();
      } else if (roundType === 'losersRounds') {
        this.finalLoserUserMatches = usersData.slice();
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  gatherUserData(id, fixture) {
    let userObj: any = {};
    if(id) {
      return this.firestore.collection('Users').doc(id).get().toPromise()
      .then(res => {
        userObj = {
          name: res.data().firstName + " " + res.data().lastName,
          id: id,
          email: res.data().email,
          victorPoints: res.data().victorPoints,
          image: res.data().profileImg ? res.data().profileImg : '../../../assets/image/no-img.webp',
          fixture: fixture
        }
        return userObj;
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      userObj = {
        id: "userId",
        email: "email",
        name: "Bye",
        victorPoints: "victorPoints",
        image: '../../../assets/image/no-img.webp',
        fixture: fixture
      };
      if(id === undefined) userObj.name = 'TBA';
      return userObj;
    }
  }

  async getKnockoutTeamsData(fixtures) {
    try {
      let teamFixtures = await Promise.all(fixtures.map(async el => {
        let fixtureObj: any = {};
        fixtureObj = el;

        let teamA: any = {}
        if (el?.teamA?.id) {
          teamA.playerOne = {}
          teamA.playerTwo = {}

          let teamARawData = await this.firestore.collection('Teams').doc(el.teamA.id).get().toPromise();

          let teamAPlayerOne: any = await this.firestore.collection('Users').doc(teamARawData.data().members[0].id).get().toPromise();
          teamA.playerOne.name = teamAPlayerOne.data().firstName + " " + teamAPlayerOne.data().lastName;
          teamA.playerOne.email = teamAPlayerOne.data().email;
          teamA.playerOne.id = teamAPlayerOne.id;
          teamA.playerOne.image = teamAPlayerOne.data().profileImg ? teamAPlayerOne.data().profileImg : '../../../assets/image/no-img.webp';

          let teamAPlayerTwo = await this.firestore.collection('Users').doc(teamARawData.data().members[1].id).get().toPromise();
          teamA.playerTwo.name = teamAPlayerTwo.data().firstName + " " + teamAPlayerTwo.data().lastName;
          teamA.playerTwo.email = teamAPlayerTwo.data().email;
          teamA.playerTwo.id = teamAPlayerTwo.id;
          teamA.playerTwo.image = teamAPlayerTwo.data().profileImg ? teamAPlayerTwo.data().profileImg : '../../../assets/image/no-img.webp';

          teamA.name = teamARawData.data().name;
          teamA.teamId = teamARawData.id;
        } else {
          teamA.name = 'Bye'
          if(el?.teamA?.id === undefined) teamA.name = 'TBA' 
        }

        let teamB: any = {}
        if (el?.teamB?.id) {
          teamB.playerOne = {}
          teamB.playerTwo = {}
          let teamBRawData = await this.firestore.collection('Teams').doc(el.teamB.id).get().toPromise();

          let teamBPlayerOne = await this.firestore.collection('Users').doc(teamBRawData.data().members[0].id).get().toPromise();
          teamB.playerOne.name = teamBPlayerOne.data().firstName + " " + teamBPlayerOne.data().lastName;
          teamB.playerOne.email = teamBPlayerOne.data().email;
          teamB.playerOne.id = teamBPlayerOne.id;
          teamB.playerOne.image = teamBPlayerOne.data().profileImg ? teamBPlayerOne.data().profileImg : '../../../assets/image/no-img.webp';

          let teamBPlayerTwo = await this.firestore.collection('Users').doc(teamBRawData.data().members[1].id).get().toPromise();
          teamB.playerTwo.name = teamBPlayerTwo.data().firstName + " " + teamBPlayerTwo.data().lastName;
          teamB.playerTwo.email = teamBPlayerTwo.data().email;
          teamB.playerTwo.id = teamBPlayerTwo.id;
          teamB.playerTwo.image = teamBPlayerTwo.data().profileImg ? teamBPlayerTwo.data().profileImg : '../../../assets/image/no-img.webp';

          teamB.name = teamBRawData.data().name;
          teamB.teamId = teamBRawData.id;
        } else {
          teamB.name = 'Bye'
          if(el?.teamB?.id === undefined) teamB.name = 'TBA' 
        }

        fixtureObj.teamA = teamA
        fixtureObj.teamB = teamB
        fixtureObj.fixture = el.fixture
        return fixtureObj
      }))
      return teamFixtures
    } catch (e) {
      console.log(e)
    }
  }

  openScoreModal(playersData, round, fixture, roundType, matchIndex) {
    document.body.classList.add('modal-open');
    this.isScoreModalOpen = true;
    this.playersData = playersData;
    this.currentRound = `round${round}`;
    this.currentFixture = `fixture${fixture}`;
    this.roundType = roundType;

    if(roundType === 'rounds') {
      this.uploadUserMatchesScore = true;
      this.uploadLoserUserMatchesScore = false;
    } else if(roundType === 'loserRounds') {
      this.uploadUserMatchesScore = false;
      this.uploadLoserUserMatchesScore = true;
    }

    this.currentKnockoutMatchIndex = matchIndex;
    this.findScoreBy(playersData)
    this.isplayersDataGathered = true;
    this.checkFormChanges();
  }

  findScoreBy(match) {
    if(this.isSingleFixtures) {
      if(match.teamA.id === this.authService.user.uid) {
        this.scoreBy = "teamA"
      } else if(match.teamB.id === this.authService.user.uid) {
        this.scoreBy = "teamB"
      }
    } else if(this.isTeamFixtures) {
      if(match.teamA.playerOne.id === this.authService.user.uid || match.teamA.playerTwo.id === this.authService.user.uid) {
        this.scoreBy = "teamA"
      } else if(match.teamB.playerOne.id === this.authService.user.uid || match.teamB.playerTwo.id === this.authService.user.uid) {
        this.scoreBy = "teamB"
      }
    }
  }
  // Knockout Matches End

  // For Box Matches
  getBoxFixtures() {
    this.tournament.ref.collection('fixtures').get()
    .then(res => {
      this.orderFetchedDocuments(res.docs)
    })
    .catch(error => {
      console.log(error)
    })
  }

  orderFetchedDocuments(docs) {
    let sortedDocs = docs.slice(0)
    sortedDocs.sort((a, b) => {
      let nameA = Number(a.id.match(/\d+/g)[0]);
      let nameB = Number(b.id.match(/\d+/g)[0]);
      return nameA - nameB
    });
    this.seperateBoxFixtures(sortedDocs);
  }

  seperateBoxFixtures(rawFixtures) {
    let fixtures = [];
    rawFixtures.forEach(el1 => {
      let fixture = {};
      fixture = (el1.data())
      fixtures.push(fixture)
    })
    this.boxFixtures = fixtures;
    this.gatherUserBoxFixtures(fixtures)
    this.areRoundsCreated = true;
    this.isUserBoxFixtures = true;
  }

  gatherUserBoxFixtures(fixtures) {
    let userFixtures = [];
    fixtures.forEach((el, index) => {
      Object.values(el).forEach((el2: any) => {
        if(el2.email === this.authService.user.email) {
          let tempObj = el;
          tempObj.fixture = index+1;
          userFixtures.push(tempObj)
        }
      })
    })
    this.gatherBoxUsersData(userFixtures)
  }

  gatherBoxUsersData(fixtures) {
    let promises = [];
    fixtures.forEach((el) => {
      let tempObjA;
      let tempObjB;
      tempObjA = this.getBoxUsersData(el.teamA.id);
      tempObjB = this.getBoxUsersData(el.teamB.id);
      promises.push(tempObjA)
      promises.push(tempObjB)
    })

    Promise.all(promises)
    .then((res) => {
      let usersData = []
      let arr = res.slice();
      let length = arr.length/2;
      for(let i = 0; i < length; i++) {
        let tempObj: any = {};
        tempObj.teamA = arr.splice(0,1)[0];
        tempObj.teamB = arr.splice(0,1)[0];
        usersData.push(tempObj)
      }
      fixtures.forEach((el, index) => {
        if(el.frontEndState) {
          usersData[index].frontEndState = el.frontEndState;
        }
        if(el.fixture) {
          usersData[index].fixture = el.fixture;
        }
        if(el.winner) {
          usersData[index].winner = el.winner;
        }if(el.home) {
          usersData[index].home = el.home;
        }
      })
      this.userBoxMatches = usersData;
      this.userMatchesFound = true;
    })
    .catch(error => {
      console.log(error)
    })
  }

  getBoxUsersData(id) {
    return this.firestore.collection('Users').doc(id).get().toPromise()
    .then(res => {
      let userObj: any = {};
      userObj.name = res.data().firstName + " " + res.data().lastName
      userObj.id = id;
      userObj.email = res.data().email;
      userObj.victorPoints = res.data().victorPoints;
      userObj.image = res.data().profileImg ? res.data().profileImg : '../../../assets/image/no-img.webp';
      return userObj;
    })
    .catch(error => {
      console.log(error)
    })
  }

  openBoxMatchScoreModal(userMatch, matchIndex) {
    document.body.classList.add('modal-open');
    this.playersData = userMatch
    this.isScoreModalOpen = true;

    this.currentFixture = `fixture${userMatch.fixture}`;
    this.currentBoxMatchIndex = matchIndex;
    this.findBoxMatchScoreBy(userMatch)
    this.isplayersDataGathered = true;
    this.checkFormChanges();
  }

  findBoxMatchScoreBy(match) {
    if(this.isUserBoxFixtures) {
      if(match.teamA.id === this.authService.user.uid) {
        this.boxMatchscoreBy = "teamA"
      } else if(match.teamB.id === this.authService.user.uid) {
        this.boxMatchscoreBy = "teamB"
      }
    } else if(this.isTeamBoxFixtures) {
      if(match.teamA.playerOne.id === this.authService.user.uid || match.teamA.playerTwo.id === this.authService.user.uid) {
        this.boxMatchscoreBy = "teamA"
      } else if(match.teamB.playerOne.id === this.authService.user.uid || match.teamB.playerTwo.id === this.authService.user.uid) {
        this.boxMatchscoreBy = "teamB"
      }
    }
  }

  // For Box Doubles
  async getBoxTeamsFixtures() {
    try {
      let fixtures: any =  await this.tournament.ref.collection('fixtures').get()
      let unsortedFixtures = fixtures.docs.slice();

      // Sorting fixtures by their IDs
      unsortedFixtures.sort((a, b) => {
        let nameA = Number(a.id.match(/\d+/g)[0]);
        let nameB = Number(b.id.match(/\d+/g)[0]);
        return nameA - nameB
      });

      let myFixtures = []

      unsortedFixtures.forEach(el => {
        let fixtureObj: any = {};
        if(el.data().teamA.id === this.myTeamObj.id) {
          fixtureObj = el.data()
          fixtureObj.fixture = Number(el.id.match(/\d+/g)[0])
          myFixtures.push(fixtureObj)
        } else if(el.data().teamB.id === this.myTeamObj.id) {
          fixtureObj = el.data()
          fixtureObj.fixture = Number(el.id.match(/\d+/g)[0])
          myFixtures.push(fixtureObj)
        }
      })
      this.getBoxTeamsData(myFixtures)
    } catch(e) {
      console.log(e)
    }
  }

  async getBoxTeamsData(fixtures) {
    let teamFixtures = await Promise.all(fixtures.map(async el => {
      let fixtureObj: any = {};
      fixtureObj = el;
      try {
        let teamA: any = {}
        teamA.playerOne = {}
        teamA.playerTwo = {}

        let teamARawData = await this.firestore.collection('Teams').doc(el.teamA.id).get().toPromise();

        let teamAPlayerOne: any = await this.firestore.collection('Users').doc(teamARawData.data().members[0].id).get().toPromise();
        teamA.playerOne.name = teamAPlayerOne.data().firstName + " " + teamAPlayerOne.data().lastName;
        teamA.playerOne.email = teamAPlayerOne.data().email;
        teamA.playerOne.id = teamAPlayerOne.id;
        teamA.playerOne.image = teamAPlayerOne.data().profileImg ? teamAPlayerOne.data().profileImg : '../../../assets/image/no-img.webp';

        let teamAPlayerTwo = await this.firestore.collection('Users').doc(teamARawData.data().members[1].id).get().toPromise();
        teamA.playerTwo.name = teamAPlayerTwo.data().firstName + " " + teamAPlayerTwo.data().lastName;
        teamA.playerTwo.email = teamAPlayerTwo.data().email;
        teamA.playerTwo.id = teamAPlayerTwo.id;
        teamA.playerTwo.image = teamAPlayerTwo.data().profileImg ? teamAPlayerTwo.data().profileImg : '../../../assets/image/no-img.webp';

        teamA.name = teamARawData.data().name;
        teamA.teamId = teamARawData.id;

        let teamB: any = {}
        teamB.playerOne = {}
        teamB.playerTwo = {}
        let teamBRawData = await this.firestore.collection('Teams').doc(el.teamB.id).get().toPromise();

        let teamBPlayerOne = await this.firestore.collection('Users').doc(teamBRawData.data().members[0].id).get().toPromise();
        teamB.playerOne.name = teamBPlayerOne.data().firstName + " " + teamBPlayerOne.data().lastName;
        teamB.playerOne.email = teamBPlayerOne.data().email;
        teamB.playerOne.id = teamBPlayerOne.id;
        teamB.playerOne.image = teamBPlayerOne.data().profileImg ? teamBPlayerOne.data().profileImg : '../../../assets/image/no-img.webp';

        let teamBPlayerTwo = await this.firestore.collection('Users').doc(teamBRawData.data().members[1].id).get().toPromise();
        teamB.playerTwo.name = teamBPlayerTwo.data().firstName + " " + teamBPlayerTwo.data().lastName;
        teamB.playerTwo.email = teamBPlayerTwo.data().email;
        teamB.playerTwo.id = teamBPlayerTwo.id;
        teamB.playerTwo.image = teamBPlayerTwo.data().profileImg ? teamBPlayerTwo.data().profileImg : '../../../assets/image/no-img.webp';

        teamB.name = teamBRawData.data().name;
        teamB.teamId = teamBRawData.id;

        fixtureObj.teamA = teamA
        fixtureObj.teamB = teamB
        fixtureObj.fixture = el.fixture
        return fixtureObj
      } catch(e) {
        console.log(e)
      }
    }))

    this.userBoxMatches = teamFixtures.slice();
    this.areRoundsCreated = true;
    this.userMatchesFound = true;
    this.isTeamBoxFixtures = true;
  }
  // Box Matches End

  checkFormChanges() {
    let comparisonValueSetOne: boolean;
    let comparisonValueSetTwo: boolean;
    let comparisonValueSetThree: boolean;

    this.formGroup.valueChanges.subscribe(values => {
      // Checking for Match Outcome
      this.checkMatchOutcome(values);
      // Checking for Set One
      this.watchChangesSetOne(values, comparisonValueSetOne);
      // Checking for Set Two
      this.watchChangesSetTwo(values, comparisonValueSetTwo);
      // Checking for Set Three
      this.watchChangesSetThree(values, comparisonValueSetThree);
      // Checking IF Set Three Exists
      this.isSetThreeExists(values);
    })
  }

  watchChangesSetOne(values, comparisonValueSetOne) {
    let a = values.setOneA;
    let b = values.setOneB;
    let aMinusB = values.setOneA - values.setOneB;
    let bMinusA =  values.setOneB - values.setOneA;
    let comparisonValue: boolean = comparisonValueSetOne;

    if(a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if(a >= 6 || b >= 6) {

      if(comparisonValue) {
        if(a == 7 || b == 7) {
          if( (aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0) ) {
            this.isSetOneTie = true;
            if(!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetOneTie = false;
            if(this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if(this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        }
      } else {
        if(a == 6 || b == 6) {
          if(a == b) {
            this.isSetOneTie = true;
            if(!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetOneTie = false;
            if(this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if(this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        }  else {
          this.isSetOneTie = false;
          if(this.formGroup.controls.setOneTieA) {
            this.formGroup.removeControl('setOneTieA')
          }
          if(this.formGroup.controls.setOneTieB) {
            this.formGroup.removeControl('setOneTieB')
          }
        }
      }
    } else {
      this.isSetOneTie = false;
      if(this.formGroup.controls.setOneTieA) {
        this.formGroup.removeControl('setOneTieA')
      }
      if(this.formGroup.controls.setOneTieB) {
        this.formGroup.removeControl('setOneTieB')
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'one', values: values})
  }

  watchChangesSetTwo(values, comparisonValueSetTwo) {
    let a = values.setTwoA;
    let b = values.setTwoB;
    let aMinusB = values.setTwoA - values.setTwoB;
    let bMinusA =  values.setTwoB - values.setTwoA;
    let comparisonValue: boolean = comparisonValueSetTwo;

    if(a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if(a >= 6 || b >= 6) {

      if(comparisonValue) {
        if(a == 7 || b == 7) {
          if( (aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0) ) {
            this.isSetTwoTie = true;
            if(!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetTwoTie = false;
            if(this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if(this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        }
      } else {
        if(a == 6 || b == 6) {
          if(a == b) {
            this.isSetTwoTie = true;
            if(!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetTwoTie = false;
            if(this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if(this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        }  else {
          this.isSetTwoTie = false;
          if(this.formGroup.controls.setTwoTieA) {
            this.formGroup.removeControl('setTwoTieA');
          }
          if(this.formGroup.controls.setTwoTieB) {
            this.formGroup.removeControl('setTwoTieB');
          }
        }
      }
    } else {
      this.isSetTwoTie = false;
      if(this.formGroup.controls.setTwoTieA) {
        this.formGroup.removeControl('setTwoTieA');
      }
      if(this.formGroup.controls.setTwoTieB) {
        this.formGroup.removeControl('setTwoTieB');
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'two', values: values})
  }

  watchChangesSetThree(values, comparisonValueSetThree) {
    if(this.isSetThree) {
      let a = values.setThreeA;
      let b = values.setThreeB;
      let aMinusB = values.setThreeA - values.setThreeB;
      let bMinusA =  values.setThreeB - values.setThreeA;
      let comparisonValue: boolean = comparisonValueSetThree;

      if(a == 7 || b == 7) {
        comparisonValue = true;
      } else { comparisonValue = false; }

      if(a >= 6 || b >= 6) {

        if(comparisonValue) {
          if(a == 7 || b == 7) {
            if( (aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0) ) {
              this.isSetThreeTie = true;
              if(!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if(!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            }
            else {
              this.isSetThreeTie = false;
              if(this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if(this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          }
        } else {
          if(a == 6 || b == 6) {
            if(a == b) {
              this.isSetThreeTie = true;
              if(!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if(!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            } else {
              this.isSetThreeTie = false;
              if(this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if(this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          }  else {
            this.isSetThreeTie = false;
            if(this.formGroup.controls.setThreeTieA) {
              this.formGroup.removeControl('setThreeTieA')
            }
            if(this.formGroup.controls.setThreeTieB) {
              this.formGroup.removeControl('setThreeTieB')
            }
          }
        }
      } else {
        this.isSetThreeTie = false;
        if(this.formGroup.controls.setThreeTieA) {
          this.formGroup.removeControl('setThreeTieA')
        }
        if(this.formGroup.controls.setThreeTieB) {
          this.formGroup.removeControl('setThreeTieB')
        }
      }

      // Checking for valid scores
      this.isValidScores({set : 'three', values: values})
    }
  }

  checkMatchOutcome(values) {
    // Reset Match Outcome Values
    Object.keys(this.matchOutcome).forEach(el => this.matchOutcome[el] = "");

    // Reset form validation
    // Form validation will auto reset itself

    // Reset Match Outcome Values In UI
    if(this.matchOutcomeElement) {
      this.matchOutcomeElement.nativeElement.options[0].selected = true;
    }

    // Identify Which Match Outcome Is Active
    let isFormFilled = Object.values(values).some(el => {
      if(el !== "") return true
    })
    this.isFormFilled = isFormFilled
  }

  isValidScores(data) {
    let values = data.values;
    let a, b, isDirtyA, isDirtyB, setA, setB, setATie, setBTie;
    if(data.set === 'one') {
      a = data.values.setOneA;
      b = data.values.setOneB;
      isDirtyA = this.formGroup.controls.setOneA.dirty;
      isDirtyB = this.formGroup.controls.setOneB.dirty;
      setA = 'setOneA';
      setB = 'setOneB';
      setATie = 'setOneTieA';
      setBTie = 'setOneTieB';
    } else if(data.set === 'two') {
      a = data.values.setTwoA;
      b = data.values.setTwoB;
      isDirtyA = this.formGroup.controls.setTwoA.dirty;
      isDirtyB = this.formGroup.controls.setTwoB.dirty;
      setA = 'setTwoA';
      setB = 'setTwoB';
      setATie = 'setTwoTieA';
      setBTie = 'setTwoTieB';
    } else if(data.set === 'three') {
      if(this.isSetThree && this.formGroup.controls.setThreeA && this.formGroup.controls.setThreeB) {
        a = data.values.setThreeA;
        b = data.values.setThreeB;
        isDirtyA = this.formGroup.controls.setThreeA.dirty;
        isDirtyB = this.formGroup.controls.setThreeB.dirty;
        setA = 'setThreeA';
        setB = 'setThreeB';
        setATie = 'setThreeTieA';
        setBTie = 'setThreeTieB';
      }
    }

    if( ((a == 7 || a == 6) && (b < 7) && isDirtyA && isDirtyB) ||
        ((b == 7 || b == 6) && (a < 7) && isDirtyA && isDirtyB)) {

      if((a == 7 && b > 4) || (b == 7 && a > 4)) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else if((a == 6 && (b < 5 || b == 7 || b == 6)) || (b == 6 && (a < 5 || a == 7 || a == 6))) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else {
        if(isDirtyA && isDirtyB) {
          this.formGroup.controls[setA].setErrors({incorrect: true});
          this.formGroup.controls[setB].setErrors({incorrect: true});
        }
      }
    } else {
      if(isDirtyA && isDirtyB) {
        this.formGroup.controls[setA].setErrors({incorrect: true});
        this.formGroup.controls[setB].setErrors({incorrect: true});
      }
    }

    const addError = () => {
      if(this.formGroup.controls[setATie].dirty && this.formGroup.controls[setBTie].dirty) {
        this.formGroup.controls[setATie].setErrors({incorrect: true});
        this.formGroup.controls[setBTie].setErrors({incorrect: true});
      }
    }
    const removeError = () => {
      this.formGroup.controls[setATie].setErrors(null);
      this.formGroup.controls[setBTie].setErrors(null);
    }

    // Checking for TIE BREAKER
    if(this.formGroup.controls[setATie]) {
      let setTieA = parseInt(values[setATie])
      let setTieB = parseInt(values[setBTie])
      if(setTieA > 7 || setTieB > 7) {
        if(setTieA > setTieB && setTieA - setTieB === 2)      removeError();
        else if(setTieB > setTieA && setTieB - setTieA === 2) removeError();
        else addError();
      } else {
        if(setTieA === 7 || setTieB === 7) removeError();
        else addError();
      }
    }
  }

  isSetThreeExists(values) {
    if(this.isFormValid()) {
      let isScoresEqual = this.caclulateScores(values)
      if(isScoresEqual) {
        if(!this.isSetThree) {
          this.isSetThree = true;
          this.formGroup.addControl('setThreeA', new FormControl('', Validators.required));
          this.formGroup.addControl('setThreeB', new FormControl('', Validators.required));
        }
      } else {
        this.removeSetThree();
      }
    } else {
      this.removeSetThree();
    }
  }

  isFormValid() {
    let isFormValid: boolean =  this.formGroup.controls.setOneA.valid &&
                                this.formGroup.controls.setOneB.valid &&
                                this.formGroup.controls.setTwoA.valid &&
                                this.formGroup.controls.setTwoB.valid;

    if(this.formGroup.controls.setOneTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid;
    }
    if(this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }
    if(this.formGroup.controls.setOneTieA && this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }

    return isFormValid
  }

  caclulateScores(values) {
    let teamAKeys = [];
    let teamBKeys = [];
    let teamAScore = [];
    let teamBScore = [];

    for(let key in values) {
      if(key.includes('A') && !key.includes('Three')) {
        teamAKeys.push(key)
      }
      if(key.includes('B') && !key.includes('Three')) {
        teamBKeys.push(key)
      }
    }

    teamAKeys.forEach(el => {
      teamAScore.push(parseInt(values[el]))
    })
    teamBKeys.forEach(el => {
      teamBScore.push(parseInt(values[el]))
    })

    if(teamAScore.length > 2 && teamBScore.length > 2) {
      if(teamAScore.length === 4 && teamBScore.length === 4) {
        if((teamAScore[2] > teamBScore[2] && teamBScore[3] > teamAScore[3]) ||
           (teamBScore[2] > teamAScore[2] && teamAScore[3] > teamBScore[3])) {
          return true;
        }
      }

      if(teamAScore.length === 3 && teamBScore.length === 3) {
        if(this.formGroup.controls.setOneTieA) {
          if((teamAScore[1] > teamBScore[1] && teamBScore[2] > teamAScore[2]) ||
             (teamBScore[1] > teamAScore[1] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        } else if(this.formGroup.controls.setTwoTieA) {
          if((teamAScore[0] > teamBScore[0] && teamBScore[2] > teamAScore[2]) ||
             (teamBScore[0] > teamAScore[0] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        }
      }

    } else if((teamAScore[0] > teamBScore[0] && teamBScore[1] > teamAScore[1]) ||
              (teamBScore[0] > teamAScore[0] && teamAScore[1] > teamBScore[1])) {
        return true;
    }

    let teamAFinalScore = teamAScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)

    let teamBFinalScore = teamBScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)

    return teamAFinalScore === teamBFinalScore;
  }

  removeSetThree() {
    if(this.isSetThree) {
      this.isSetThree = false;
      this.formGroup.removeControl('setThreeA')
      this.formGroup.removeControl('setThreeB')
      if(this.isSetThreeTie) {
        this.formGroup.removeControl('setThreeTieA')
        this.formGroup.removeControl('setThreeTieB')
      }
    }
  }

  addScore(formGroup: FormGroup) {
    let tournamentId = this.tournament.id;

    if(this.isplayersDataGathered) {
      if(formGroup.valid) {
        this.isUploadScoreLoading = true;
        let formData = formGroup.value;
        let firebaseAddress: AngularFirestoreDocument<unknown>;
        if(this.isKnockoutMatch) {
            firebaseAddress = this.firestore.collection('Matches').doc(tournamentId)
                              .collection(this.roundType).doc(this.currentRound).collection('fixtures')
                              .doc(this.currentFixture)
            this.currentFirebaseAddress = firebaseAddress;
          this.uploadKnockoutMatchScores(firebaseAddress, formData)
        } else if(this.isBoxMatch) {
          firebaseAddress = this.firestore.collection('Matches').doc(tournamentId)
                            .collection('fixtures').doc(this.currentFixture)
          this.currentFirebaseAddress = firebaseAddress;
          this.uploadBoxMatchScores(firebaseAddress, formData)
        }

        this.isFormFilled = false;
      }
    } else {
      console.log("Data Not Gathered")
    }
  }

  uploadKnockoutMatchScores(firebaseAddress, formData) {
    firebaseAddress.get().toPromise()
    .then(res => {
      let data = res.data()

      data.scoreBy = this.scoreBy;
      data.state = 'score-uploaded';
      data.frontEndState = 'score-uploaded';
      let teamAScore = {
        setOne: parseInt(formData.setOneA),
        setTwo: parseInt(formData.setTwoA),
      }
      let teamBScore = {
        setOne: parseInt(formData.setOneB),
        setTwo: parseInt(formData.setTwoB),
      }
      if (formData.setOneTieA) {
        teamAScore['setOneTie'] = parseInt(formData.setOneTieA);
        teamBScore['setOneTie'] = parseInt(formData.setOneTieB);
      }
      if (formData.setTwoTieA) {
        teamAScore['setTwoTie'] = parseInt(formData.setTwoTieA);
        teamBScore['setTwoTie'] = parseInt(formData.setTwoTieB);
      }
      if(formData.setThreeA) {
        teamAScore["setThree"] = parseInt(formData.setThreeA)
        teamBScore["setThree"] = parseInt(formData.setThreeB)
      }
      if (formData.setThreeTieA) {
        teamAScore['setThreeTie'] = parseInt(formData.setThreeTieA);
        teamBScore['setThreeTie'] = parseInt(formData.setThreeTieB);
      }

      // Convert empty score values to 0
      Object.keys(teamAScore).forEach(el => !teamAScore[el] ? teamAScore[el] = 0 : "");
      Object.keys(teamBScore).forEach(el => !teamBScore[el] ? teamBScore[el] = 0 : "");

      data.teamA.matchScore = teamAScore;
      data.teamB.matchScore = teamBScore;

      if( this.matchOutcome.type !== "" && this.matchOutcome.team !== "") {
        data.outcome = {};
        data.outcome.matchOutcome = this.matchOutcome;
      }

      return firebaseAddress.update(data);
    })
    .then(res => {
      if(this.uploadUserMatchesScore) {
        this.finalUserMatches[this.currentKnockoutMatchIndex].frontEndState = 'score-uploaded'
      } else if(this.uploadLoserUserMatchesScore) {
        this.finalLoserUserMatches[this.currentKnockoutMatchIndex].frontEndState = 'score-uploaded'
      }
      this.isUploadScoreLoading = false;
      this.closeModal();
      if(this.tournamentData.category === 'singles') {
        this.isPlayerRatingModalOpen = true;
      }
      console.log("Success: Knockout Score Uploaded");
    })
    .catch(error => {
      this.isUploadScoreLoading = false;
      this.errorUploadScore = true;
      console.log(error)
    })
  }

  uploadBoxMatchScores(firebaseAddress, formData) {
    firebaseAddress.get().toPromise()
    .then(res => {
      let data = res.data()
      data.scoreBy = this.boxMatchscoreBy;
      data.state = 'score-uploaded';
      data.frontEndState = 'score-uploaded';
      let teamAScore = {
        setOne: parseInt(formData.setOneA),
        setTwo: parseInt(formData.setTwoA),
      }
      let teamBScore = {
        setOne: parseInt(formData.setOneB),
        setTwo: parseInt(formData.setTwoB),
      }

      if (formData.setOneTieA) {
        teamAScore['setOneTie'] = parseInt(formData.setOneTieA);
        teamBScore['setOneTie'] = parseInt(formData.setOneTieB);
      }
      if (formData.setTwoTieA) {
        teamAScore['setTwoTie'] = parseInt(formData.setTwoTieA);
        teamBScore['setTwoTie'] = parseInt(formData.setTwoTieB);
      }

      if(formData.setThreeA) {
        teamAScore["setThree"] = parseInt(formData.setThreeA)
        teamBScore["setThree"] = parseInt(formData.setThreeB)
      }

      if (formData.setThreeTieA) {
        teamAScore['setThreeTie'] = parseInt(formData.setThreeTieA);
        teamBScore['setThreeTie'] = parseInt(formData.setThreeTieB);
      }

      // Convert empty score values to 0
      Object.keys(teamAScore).forEach(el => !teamAScore[el] ? teamAScore[el] = 0 : "");
      Object.keys(teamBScore).forEach(el => !teamBScore[el] ? teamBScore[el] = 0 : "");

      data.teamA.matchScore = teamAScore;
      data.teamB.matchScore = teamBScore;

      if( this.matchOutcome.type !== "" && this.matchOutcome.team !== "") {
        data.outcome = {};
        data.outcome.matchOutcome = this.matchOutcome;
      }

      return firebaseAddress.update(data);
    })
    .then(res => {
      this.userBoxMatches[this.currentBoxMatchIndex].frontEndState = 'score-uploaded';
      this.isUploadScoreLoading = false;
      this.closeModal();
      if(this.tournamentData.category === 'singles') {
        this.isPlayerRatingModalOpen = true;
      }
      console.log("Success: Box Score Uploaded");
    })
    .catch(error => {
      this.isUploadScoreLoading = false;
      this.errorUploadScore = true;
      console.log(error)
    })
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isScoreModalOpen = false;
    this.formGroup.reset();
    // Resetting Match Outcome Values
    this.isFormFilled = false;
    this.isIncompleteMatch = false;
    Object.keys(this.matchOutcome).forEach(el => this.matchOutcome[el] = "");
  }

  async confirmRating() {
    try {
      let userId: string;
      let fixtureData = (await this.currentFirebaseAddress.get().toPromise()).data()

      if(fixtureData.teamA.id !== this.authService.user.uid) {
        userId = fixtureData.teamA.id
      } else if(fixtureData.teamB.id !== this.authService.user.uid) {
        userId = fixtureData.teamB.id
      }

      await this.firestore.collection('Users').doc(userId).collection('rating').add({
        rating: this.playerRating,
        uid: this.authService.user.uid,
        timestamp: Date.now(),
      })
      this.isPlayerRatingModalOpen = false;
    } catch(e) {
      console.log(e)
    }
  }

  initializeScoreForm() {
    this.formGroup = new FormGroup({
      setOneA: new FormControl('', Validators.required),
      setOneB: new FormControl('', Validators.required),
      setTwoA: new FormControl('', Validators.required),
      setTwoB: new FormControl('', Validators.required),
    })
  }

  setMatchOutcome(type, event) {
    Object.keys(this.formGroup.controls).map(key => {
      this.formGroup.controls[key].setErrors(null)
    })
    this.matchOutcome = {
      type,
      team: event.target.value
    };
  }

  checkIncompleteMatch(value) {
    if(!value) {
      this.matchOutcome = { type: "", team: "" };
      Object.keys(this.formGroup.controls).forEach(key => {
        if(!this.formGroup.controls[key].value) {
          this.formGroup.controls[key].setErrors({'incorrect': true});
        }
      })
    };
  }

  ngOnInit() {
    this.authService.authState.subscribe(user => {
      if(user) {
        this.tournamentData = this.tournament.data();
        this.isUserRegistered(this.tournamentData);
        this.initializeScoreForm();
      }
    })
  }
}
