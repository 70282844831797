<div class="matches">
  <div *ngIf="isLoading" class="loader">
    <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
  </div>
  <div *ngIf="!isLoading">
    <div *ngIf="hasInvites else noMatches" class="matches-inner">
      <div class="match" *ngFor="let request of requests; let i = index">
        <div class="block-1">
          <div class="name"><label>Team:</label> 
            <a> {{ request.team.name }} </a>
          </div>
          <div class="name"><label>Match:</label> 
            <a [routerLink]="['/tennis/tournaments/', request.match.id]">
              {{ request.match.name }}
            </a> (Click to view match)
          </div>
          <div class="location"><label>Location:</label> Perth (<span class="view-map">View on map</span>)</div>
        </div>
        <div class="block-2">
          <button type="button" class="button-reject" [routerLink]="['/tennis/tournaments/'+request.match.id]">View Details</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noMatches>
    <div class="alert">
      Currently you don't have any teams yet.
    </div>
  </ng-template>
</div>