import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from './../../services/profile/profile.service';

@Component({
  selector: 'app-player-main-page',
  templateUrl: './player-main-page.component.html',
  styleUrls: ['./player-main-page.component.scss']
})
export class PlayerMainPageComponent implements OnInit {

  currUrl: string;
  playerId;
  currUserObj;
  clicked = '';

  constructor(private router: Router, private route: ActivatedRoute) {}

  getIdParam() {
    this.route.paramMap.subscribe(params => {
      this.playerId = params.get('playerId');
    })
  }

  onOutletLoaded(component) {
    component.node = { playerId: this.playerId};
  }

  ngOnInit() {
    this.getIdParam();
    this.currUrl = this.router.url;
    let pathArray = this.currUrl.split('/');
    let path = pathArray[pathArray.length - 1];
    if (path === 'overview') {
      this.clicked = 'overview';
    } else if (path === 'statistics-history') {
      this.clicked = 'statistics';
    } else {
      this.clicked = 'games';
    }
  }

  onClick(selected) {
    this.clicked = selected;
  }

}
