import { Observable, BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/combineLatest';
import { flatMap } from 'rxjs/internal/operators';
import firebase from 'firebase/app';


@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.scss']
})
export class PlayersComponent implements OnInit {

  players: any[];
  isDataLoaded: boolean = true;
  isLoading: boolean = true;
  isError: boolean;
  filterForm: FormGroup;
  players$: Observable<any[]>;
  genderFilter$: BehaviorSubject<string|null>;
  wheelChairFilter$: BehaviorSubject<string|null>;
  tierFilter$: BehaviorSubject<string|null>;
  isPlayersFound: boolean;

  constructor(private router: Router, private firestore: AngularFirestore) {
    this.genderFilter$ = new BehaviorSubject(null);
    this.wheelChairFilter$ = new BehaviorSubject(null);
    this.tierFilter$ = new BehaviorSubject(null);
    this.players$ = Observable.combineLatest(
      this.genderFilter$,
      this.wheelChairFilter$,
      this.tierFilter$
    ).switchMap(([gender, wheelChair, tier]) =>
      firestore.collection('Users', ref => {
        let query : firebase.firestore.Query = ref;
        if (gender) { query = query.where('gender', '==', gender) };
        if (wheelChair) { query = query.where('wheelChair', '==', wheelChair) };
        if (tier) {
          switch(tier){
            case '600':
              query = query.where('victorPoints', '<=', parseInt(tier));
              break;
            case '1200':
              query = query.where('victorPoints', '<=', parseInt(tier));
              query = query.where('victorPoints', '>', 600);
              break;
            case '1800':
              query = query.where('victorPoints', '<=', parseInt(tier));
              query = query.where('victorPoints', '>', 1200);
              break;
            case '3500':
              query = query.where('victorPoints', '<=', parseInt(tier));
              query = query.where('victorPoints', '>', 1800);
              break;
          }
        }
        query = query.orderBy('victorPoints', 'desc')
        return query;
      }).snapshotChanges().pipe<any[]>(
        flatMap(async players => {
          this.isLoading = true;
          let allPlayers = await this.getPlayers(players)
          this.isPlayersFound = allPlayers.length > 0 ? true : false;
          this.isLoading = false;
          return allPlayers;
        })
      )
    );

  }

  filterByGender(gender: string|null) {
    this.genderFilter$.next(gender);
  }

  filterByWheelChair(wheelChair: string|null) {
    this.wheelChairFilter$.next(wheelChair);
  }

  filterByTier(tier: string|null) {
    this.tierFilter$.next(tier);
  }

  async getPlayers(players) {
    try {
      let allPlayers: any = Promise.all(players.map(async el => {
        el = el.payload.doc;
        if(el.data().state === 'registered' && !el.data().deleted) {
          let wins = [];
          let scores = await el.ref.collection('history').get()
          if(scores.docs.length > 0) {
            wins = await Promise.all(scores.docs.map(async el2 => {
              let matchUrl = el2.data().matchRef.split('/')[2];
              let match = await this.firestore.collection('Matches').doc(matchUrl).get().toPromise()
              if(match.exists) {
                if(match.data().matchType === 'one-on-one' || match.data().matchType === 'box') {
                  let fixture = el2.data().matchRef.split('/')[4];
                  if(!fixture) return false;
                  let fixtureData = (await this.firestore.collection('Matches').doc(matchUrl)
                                    .collection('fixtures').doc(fixture).get().toPromise()).data()
                  if(fixtureData && fixtureData.winner) {
                    if(fixtureData[fixtureData.winner].id && fixtureData[fixtureData.winner].id === el.id){
                      return el2
                    }
                  }
                } else if(match.data().matchType === 'knockout') {
                  let roundType = el2.data().matchRef.split('/')[3];
                  let round = el2.data().matchRef.split('/')[4];
                  let fixture = el2.data().matchRef.split('/')[6];
                  if(!roundType || !round || !fixture) return false;
                  let fixtureData = (await this.firestore.collection('Matches').doc(matchUrl)
                                    .collection(roundType).doc(round).collection('fixtures')
                                    .doc(fixture).get().toPromise()).data()
                  if(fixtureData && fixtureData.winner && fixtureData[fixtureData.winner].id) {
                    if(fixtureData[fixtureData.winner].id === el.id){
                      return el2
                    }
                  }
                }
              }
            }))
          }
          wins = wins.filter(Boolean)
          let victorPoints = parseFloat(el.data().victorPoints).toFixed(2)
          return {
            userId: el.id,
            name: el.data().firstName + " " + el.data().lastName,
            victorPoints,
            earnings: el.data().earnings ? el.data().earnings : 0,
            wins: wins.length,
            availableForChallenge: el.data().openForChallenge
          }
        }
      }))
      this.isDataLoaded = true;
      allPlayers = (await allPlayers).filter(Boolean)
      return allPlayers;
    } catch(e) {
      this.isDataLoaded = false;
      this.isLoading = false;
      this.isError = true;
      console.log(e)
    }
  }

  ngOnInit() {
    // Initialize filter form
    this.filterForm = new FormGroup({
      gender: new FormControl(''),
      wheelChair: new FormControl(''),
      tier: new FormControl('')
    })
  }

}
