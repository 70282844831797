<div class="users">
  <div class="users-inner">
    <h3 class="top-heading">Transactions</h3>
    <div class="transaction-list">

      <div class="filter-wrapper">
        <div class="search">
          <div class="text">Match Id</div>
          <input type="text" [(ngModel)]="matchId"  placeholder="Enter Match Id" />
          <button (click)="searchByMatchId()">Search</button>
        </div>
        <div class="filter">
          <div class="text">
            Filter by
          </div>
          <select #cashflowFilter class="form-control" (change)="applyFilter($event)">
            <option value="">All</option>
            <option value="credit">Credit</option>
            <option value="debit">Debit</option>
          </select>
        </div>
      </div>
      <div class="clear-filters">
        <button type="button" (click)="clearFilters()">Clear Filters</button>
      </div>
      <hr />
      <div class="table-wrapper" *ngIf="hasTransactions && !isLoading">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Activity</th>
              <th>Currency</th>
              <th>Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let transaction of transactions;">
              <td>{{ getFormattedDate(transaction.timestamp) }}</td>
              <td>{{ transaction.user.firstName }} {{ transaction.user.lastName }}</td>
              <td>{{ transaction.detail }}</td>
              <td>{{ transaction.currency ? transaction.currency : '-' }}</td>
              <td>{{ transaction.type }}</td>
              <td>${{ transaction.amount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!hasTransactions && !isLoading">
          No Transactions found
      </div>
      <div class="loader" *ngIf="isLoading">
        <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
      </div>
    </div>

  </div>

</div>
