// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
    // Firebase
    firebaseConfig: {
      apiKey: "AIzaSyBvweMXh4gDzVsN1d59l13zkpYycazrR0k",
      authDomain: "viictor-production.firebaseapp.com",
      projectId: "viictor-production",
      databaseURL: "https://viictor-production.firebaseio.com",
      storageBucket: "viictor-production.appspot.com",
      messagingSenderId: "136634943604",
      appId: "1:136634943604:web:579c43ac9c39e58b2d2921",
      measurementId: "G-15RBKQMJXJ"
    },
    googleMapsKey: 'AIzaSyACh7HGY0-ZkYdgfRLVqfVHrALqtluccPs',
    paypalClientId: 'AcR5HtThKzc4ejaL9fKPasdzllafyS1KEPPj1Pyj4qq8fg3QkoqFsjvwCm3fgGncepMhh0N9iKQZvBdX',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
