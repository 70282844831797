<div class="container-fluid background-dark">
  <div class="shade">
    <div class="bg-layer">
      <div class="container">
        <div class="row">
          <div class="inner-nav">
            <div class="action-links action-links-mobile">
              <ul>
                <li><a [routerLink]="['/tennis/players']">Players</a></li>
                <li><a [routerLink]="['/tennis/tournaments']">Tournaments</a></li>
                <li><a [routerLink]="['/tennis/user/dashboard']">Dashboard</a></li>
              </ul>
            </div>
            <div class="logo-block float-left"><img src="../../assets/image/logo.webp" alt="logo"></div>
            <ul   class="social-links float-right">
              <li class="link">
                <a target="_blank" href="https://www.facebook.com/viictorsports"><i class="icon ico-fb"></i></a>
              </li>
              <li class="link">
                <a target="_blank" href="https://www.instagram.com/viictor_sports/"><i class="icon ico-instagram"></i></a>
              </li>
              <li class="link">
                <a target="_blank" href="https://www.linkedin.com/company/viictor/"><i class="icon ico-linkedin"></i></a>
              </li>
            </ul>
            <div class="action-links float-right">
              <ul>
                <li><a [routerLink]="['/tennis/players']">Players</a></li>
                <li><a [routerLink]="['/tennis/tournaments']">Tournaments</a></li>
                <li><a [routerLink]="['/tennis/user/dashboard']">Dashboard</a></li>
              </ul>
            </div>
            <div class="clearfix spacer"></div>
            <header class="page-title">
              <h1 class="page-heading1">
                <span>START </span><span>PlAYING NOW</span>
              </h1>
              <h2 class="page-heading2">
                <span> <i>accept</i></span><span>challenge</span>
              </h2>
            </header>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-white">
  <div class="container">
    <div class="row">
      <div class="clearfix"></div>
      <div class="gray-table-container">
        <div class="about">
          <h1>What is Viictor?</h1>
          <p>Viictor is an interactive platform allowing players to register & play level based matches and tournaments
             and win $$ or prizes. There are 3 playing options: <strong>Monthly Knockout (with consolation draw)</strong>, <strong>Box Leagues</strong>
             and <strong>Head 2 Head</strong>. After the tournament registration is finalised, the draw will be created and will be published
            on the website. Play & organise your matches and upload the scores. Players have their own admin panel,
            ranking, rating and match history with statistics. Players can send challenge request to other players who
            can accept or reject the request. Start playing now to gain a Viictor ranking and rating and see if you will
            be the Viictor!</p>
        </div>
      </div>
    </div>
  </div>

  <div class="how-it-works">
    <div class="how-it-works-inner">
      <h1>How it works?</h1>
      <div class="row procedure">
        <div class="col-12 col-sm-6 col-md-3">
          <div *ngIf="!authService.authenticated()" [routerLink]="['/tennis/users/sign-up']" style="cursor: pointer;">
            <div class="icon">
              <div [ngStyle]="{'fill': '#8AE134'}" [inlineSVG]="'../../assets/image/note.svg'"></div>
            </div>
            <p >Register with Viictor as a player</p>
          </div>
          <div *ngIf="authService.authenticated()">
            <div class="icon">
              <div [ngStyle]="{'fill': '#8AE134'}" [inlineSVG]="'../../assets/image/note.svg'"></div>
            </div>
            <p>Register with Viictor as a player</p>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <div class="icon">
            <div [ngStyle]="{'fill': '#8AE134'}" [inlineSVG]="'../../assets/image/profile.svg'"></div>
          </div>
          <p>Complete your profile</p>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <div class="icon">
            <div [ngStyle]="{'fill': '#8AE134'}" [inlineSVG]="'../../assets/image/tournament-2.svg'"></div>
          </div>
          <p>Register or create a tournament near you</p>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <div class="icon">
            <div [ngStyle]="{'fill': '#8AE134'}" [inlineSVG]="'../../assets/image/success.svg'"></div>
          </div>
          <p>Start defeating your opponents and become the ultimate Viictor</p>
        </div>
      </div>
    </div>
  </div>

  <div class="top-players" *ngIf="isPlayersLoaded">
    <div class="container">
      <div class="top-players-inner">
        <div class="heading">Top Players</div>
        <div class="players-table">
          <div class="header">
            <div class="s-no">S.No</div>
            <div class="name">Player</div>
            <div class="victor-points">Viictor Points</div>
            <div class="wins">Wins</div>
            <div class="earnings">Earnings</div>
          </div>
          <div class="players">
            <div class="player" (click)="router.navigate(['/tennis/player/', player.userId])" *ngFor="let player of players; let i = index">
              <div class="s-no">{{ i + 1 }}</div>
              <div class="name">{{ player.name }}</div>
              <div class="victor-points">{{ player.victorPoints }}</div>
              <div class="wins">{{ player.wins }}</div>
              <div class="earnings">${{ player.earnings.toFixed(2) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="gallery" *ngIf="isGalleryLoaded">
    <div class="container">
      <!-- <owl-carousel [options]="slideOptions" [items]="gallery" [carouselClasses]="['owl-theme', 'sliding']" >  
        <div class="item" *ngFor="let image of gallery; let i = index">  
          <div class="thumbnail-image">
            <div class="image image-full" *ngIf="image[0]"><img [src]="image[0].url" [alt]="image[0].caption" (click)="openModal($event)" /></div>
            <div class="image image-half">
              <img [src]="image[1].url" [alt]="image[1].caption" (click)="openModal($event)" />
              <img [src]="image[2].url" [alt]="image[2].caption" (click)="openModal($event)" />
              <img [src]="image[3].url" [alt]="image[3].caption" (click)="openModal($event)" />
              <img [src]="image[4].url" [alt]="image[4].caption" (click)="openModal($event)" />
            </div>
          </div>
        </div>
      </owl-carousel> -->
    </div>
  </div>

  <div class="features">
    <div class="features-inner">
        <div class="row">
          <div class="col-md-6">
            <div class="key-features block-one">
              <h2>Key Features</h2>
              <ul>
                <li>Play matches in your own locality or in other locations when you travel</li>
                <li>Play competitive matches against players around your level</li>
                <li>Win fantastic prizes and prize money</li>
                <li>Improve your Viictor ranking and rating</li>
                <li>Play your matches at a time that suits you</li>
                <li>Unlock the Head 2 Head feature when you obtain a verified Viictor rating</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="key-features block-two">
              <h2>Snapshot Of Events</h2>
              <ul>
                <li>Open adult singles, doubles and mixed doubles for Men & Women</li>
                <li>Mixed (guy vs gal) singles, Men’s singles & Women’s singles</li>
                <li>Men’s doubles, Women’s doubles and Mixed doubles (any combo – 2 guys vs 2 gals or 1 guy, 1 gal vs 2 guys etc)</li>
                <li>Wheelchair players welcome in any events or Wheelchair only events</li>
                <li>Senior events – over 40’s, 50’s, 60’s, 70’s & 80’s</li>
                <li>Junior events – 11/u, 13/u, 15/u</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  </div>

  <app-newsletter></app-newsletter>
  <app-sponsor-logo></app-sponsor-logo>
</div>


<section *ngIf="isModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="close-modal" (click)="closeModal()">x</div>
      <img [src]="modalImage.url" alt="modal-image">
      <div *ngIf="modalImage.caption" class="caption">{{ modalImage.caption }}</div>
    </div>
  </div>
</section>