import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeGuard implements CanActivate {
  constructor(private themeService: ThemeService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (next.url[0].path.includes('tennis')) {
      this.themeService.changeThemeGreen();
    } else if (next.url[0].path.includes('boxing')) {
      this.themeService.changeThemeRed();
    } else if (next.url[0].path.includes('ping-pong')) {
      this.themeService.changeThemeBlue();
    }
    return true;
  }

}
