<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">

  <app-player-menu></app-player-menu>

  <div class="container">

    <div class="gray-table-container">
      <div class="statistics">
        <div class="heading">Statistics</div>
        <div class="stats">
          <div class="stat">
            <label>Completed <br>Challenges</label>
            <div class="value">{{ completedMatches }}</div>
          </div>
          <div class="stat">
            <label>Wins</label>
            <div class="value">{{ wins.length }}</div>
          </div>
          <div class="stat">
            <label>Loses</label>
            <div class="value">{{ loses.length }}</div>
          </div>
          <div class="stat">
            <label>Win ratio</label>
            <div class="value">{{ winRatio }}</div>
          </div>
        </div>
      </div>
      <div class="statistics-tabs">
        <div class="container">
          <ul>
            <li [ngClass]="{'tab-active': showSingles}" (click)="showSingles = true; showDoubles = false;">
              <a>Singles</a>
            </li>
            <li [ngClass]="{'tab-active': showDoubles}" (click)="showSingles = false; showDoubles = true;">
              <a>Doubles</a>
            </li>
          </ul>
        </div>
      </div>

      <ng-container *ngIf="showSingles">
        <div class="tournaments singles" *ngIf="showSingles && hasSingleMatches; else noMatches">
          <div class="tournament" *ngFor="let tournament of tournaments">
            <div class="inner">
              <div class="block-1">
                <div class="title"><span>Title: </span> <strong>{{tournament.title}}</strong></div>
                <div class="location"><span>Location: </span> <strong>{{tournament.location}}</strong></div>
              </div>
              <div class="block-2">
                <div class="prize"><span>Prize: </span> <strong>${{tournament.prize}}</strong></div>
                <div class="status"><span>Status: </span> <strong>
                    {{ tournament.fixtureData[tournament.fixtureData.winner].id === authService.user.uid ? "Won" : "Lost" }}
                  </strong></div>
                <div class="btn-join">
                  <button type="button" [routerLink]="['/tennis/tournaments/'+tournament.matchId]">View Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showDoubles">
        <div class="tournaments doubles" *ngIf="showDoubles && hasDoubleMatches; else noMatches">
          <div class="tournament" *ngFor="let tournament of doubleTournaments">
            <div class="inner">
              <div class="block-1">
                <div class="title"><span>Title: </span> <strong>{{tournament.title}}</strong></div>
                <div class="location"><span>Location: </span> <strong>{{tournament.location}}</strong></div>
              </div>
              <div class="block-2">
                <div class="prize"><span>Prize: </span> <strong>${{tournament.prize}}</strong></div>
                <div class="status"><span>Status: </span> <strong>
                    {{ tournament.fixtureData[tournament.fixtureData.winner].id === tournament.teamId ? "Won" : "Lost" }}
                  </strong></div>
                <div class="btn-join">
                  <button type="button" [routerLink]="['/tennis/tournaments/'+tournament.matchId]">View Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="clearfix"></div>
    </div>

    <app-newsletter></app-newsletter>
  </div>

  <ng-template #noMatches>
    <div class="alert">You haven't played any matches yet.</div>
  </ng-template>