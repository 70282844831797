
<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <div class="wrapper">
        <div class="sidebar">
          <div class="sidebar-inner">
            <ul>
              <li [routerLink]="['/tennis/lets-play/box-leagues']" routerLinkActive="active">Box Leagues</li>  
              <li [routerLink]="['/tennis/lets-play/head-to-head-challenges']" routerLinkActive="active">Head 2 Head Challenges</li>
              <li [routerLink]="['/tennis/lets-play/monthly-knockout']" routerLinkActive="active">Monthly Knockout</li>
            </ul>
          </div>
        </div>
        <div class="info-bar">
          <div class="info-bar-inner">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>