import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-victor-rankings',
  templateUrl: './victor-rankings.component.html',
  styleUrls: ['./victor-rankings.component.scss']
})
export class VictorRankingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
