<div class="users">
  <div class="users-inner">
    <h3 class="top-heading">Queries</h3>
    <div class="loader" *ngIf="isLoading">
      <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
    </div>
    <div class="query-list" *ngIf="isQueriesFound; else noQueries">
      <ng-container *ngIf="!currQuery">
        <h4>Unresolved</h4>
        <ul>
          <ng-container *ngIf="queriesUnresolved.length > 0; else noQueries">
            <li *ngFor="let query of queriesUnresolved; let i = index;">
              <div class="s-no">{{ i+1 }}</div>
              <div class="content">
                <div class="details">  
                  <div class="name">Name:<br>{{ query.name }}</div>        
                  <div class="status">Status:<br>{{ query.state }}</div>        
                  <div class="date">Date:<br>{{ getFormattedDate(query.timestamp) }}</div>
                </div>
                <div class="button">
                  <button (click)="viewQuery(query)">View Details</button>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
        <hr>
        <h4>Resolved</h4>
        <ul>
          <ng-container *ngIf="queriesResolved.length > 0; else noQueries">
            <li *ngFor="let query of queriesResolved; let i = index;">
              <div class="s-no">{{ i+1 }}</div>
              <div class="content">
                <div class="details">  
                  <div class="name">Name:<br>{{ query.name }}</div>        
                  <div class="status">Status:<br>{{ query.state }}</div>        
                  <div class="date">Date:<br>{{ getFormattedDate(query.timestamp) }}</div>
                </div>
                <div class="button">
                  <button (click)="viewQuery(query)">View Details</button>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <div *ngIf="currQuery" class="curr-query">
        <div class="go-back">
          <button (click)="currQuery = undefined; ngOnInit()">Go Back</button>
        </div>
        <div class="mark-resolved">
          <button (click)="markResolved(currQuery)">
            {{ currQuery.state === 'unresolved' ? 'Mark As Resolved' : 'Mark As Unresolved' }}
          </button>
        </div>
        <hr>
        <div class="content">
          <div class="name">Name:<br>{{ currQuery.name }}</div>
          <div class="name">Email:<br>{{ currQuery.email }}</div>     
          <div class="status">Status:<br>{{ currQuery.state }}</div>        
          <div class="date">Date:<br>{{ getFormattedDate(currQuery.timestamp) }}</div>
          <div class="message">Message:<br>{{ currQuery.message }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noQueries>
  <ng-container *ngIf="!isLoading">
    <div class="alert">No Queries found</div>
  </ng-container>
</ng-template>
