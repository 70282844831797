<div class="panel">
  <div class="panel-inner">
    <h3 class="top-heading">Monthly Knockout Tournaments</h3>
    <div class="content">
      <p>Play tennis and win money in all open aged tournaments and win prizes in all junior events.</p>
      <p>Monthly Knockout tournaments are best of 3 full set matches (tiebreak at 6 games all) and start
        on the 1st day of each month and will be completed by the last day of that month. Each round
        should be completed before the published deadline.</p>
      <p>New tournament starts for each month of the year!</p>
      <p>Guaranteed 2 matches! Lose first match and go into the Consolation Draw!</p>
      <p>Viictor tournaments are not played at one central tournament location. The draw is
        automatically formulated and players are given a home/away status. This status determines
        where your match will be played. Each player will list his or her possible home court
        locations.</p>
      <p>The tournament is managed online. The draw is formulated for your local region. Players will
        enter their own results directly into the system. Players will have access to and can create
        their own personal player profile page with a summary of all their results.</p>
      <p>Each round of the tournament is allocated a time frame for all matches to be completed and
        results to be entered.</p>
      <p>Viictor tournament concept is designed to be interactive and will therefore be the player’s
        responsibility to arrange a suitable time to play their matches within the given timeframe.</p>
      <p>Viictor tournaments will operate in conjunction with the
        <a [routerLink]="['/tennis/about-us/viictor-rankings']">Viictor ranking system</a>.</p>
      <p>Prizemoney for Open aged tournaments and prizes for junior age groups are awarded to
        player reaching the winning positions within the tournament!</p>
      <p>Viictor automatically deducts 15% from all entry fees. Remaining 85% of entry fees/monies
        goes into the prize money pot for winners of that tournament.</p>
      <p>If only 3 – 5 players register for a tournament then they are automatically allocated into a
        round robin box league.</p>
      <p>All 11 & Under events are green ball events. Please use green dot balls for 11/u matches. If
        you don't have green dot balls please discuss and arrange with your opponent prior to your
        match. (If both players don't have green dot balls and agree to play with regular balls then
        that is ok).</p>
      <p>Will you be the Viictor?</p>
    </div>
  </div>
</div>
