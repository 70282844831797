<div class="pagination-wrapper" *ngIf="pagination">
	<ul>
		<li (click)="goToPage(0)">&laquo;</li>	
		<li 
			*ngFor="let page of pagesArray"
			(click)="goToPage(page.page)"
			[ngClass]="{'active': page.page === currentPage, 'hide': page.class === 'hide'}">{{ page.page + 1 }}</li>
		<li (click)="goToPage(pagesArray.length - 1)">&raquo;</li>
	</ul>
</div>
