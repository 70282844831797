import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-disputes',
  templateUrl: './disputes.component.html',
  styleUrls: ['./disputes.component.scss']
})
export class DisputesComponent implements OnInit {
  isDisputesFound: boolean;
  disputes: any[];
  disputesDisputed: any[] = [];
  disputesResolved: any[] = [];
  currDispute;

  formGroup: FormGroup;
  isSetOneTie: boolean;
  isSetTwoTie: boolean;
  isSetThreeTie: boolean;
  isSetThree: boolean;
  isEditScores: boolean;
  isLoading: boolean;
  
  constructor(private firestore: AngularFirestore) { }

  async collectDisputes(allDisputes) {
    try {
      let disputes = await Promise.all(allDisputes.map(async el => {
        let matchId = el.fixtureRef.split('/')[2];
        let match = await this.firestore.collection('Matches').doc(matchId).get().toPromise()
        let matchData = match.data(); 
        let tempObj: any = {};
        let fixtureData: any = {}
        tempObj = el;
        tempObj.matchId = matchId;
        tempObj.matchType = matchData.matchType
        tempObj.category = matchData.category;
        if(tempObj.matchType === 'one-on-one') tempObj.matchTitle = 'One On One';
        else tempObj.matchTitle = matchData.title;
        if(tempObj.matchType === 'one-on-one' || tempObj.matchType === 'box') {
          let fixtureId = el.fixtureRef.split('/')[4];
          let fixture = await this.firestore.collection('Matches').doc(matchId).collection('fixtures')
                        .doc(fixtureId).get().toPromise()
          fixtureData = fixture.data();
        } else if(tempObj.matchType === 'knockout') {
          let roundType = el.fixtureRef.split('/')[3];
          let roundId = el.fixtureRef.split('/')[4];
          let fixtureId = el.fixtureRef.split('/')[6];
          let fixture = await this.firestore.collection('Matches').doc(matchId).collection(roundType)
                        .doc(roundId).collection('fixtures').doc(fixtureId).get().toPromise()
          fixtureData = fixture.data();
        }
        if(fixtureData) {
          if(tempObj.category === 'singles') {
            let teamA = await this.firestore.collection('Users').doc(fixtureData.teamA.id).get().toPromise();
            let teamB = await this.firestore.collection('Users').doc(fixtureData.teamB.id).get().toPromise();
            fixtureData.teamA.name = teamA.data().firstName + ' ' + teamA.data().lastName;
            fixtureData.teamB.name = teamB.data().firstName + ' ' + teamB.data().lastName;
          } else if(tempObj.category === 'doubles') {
            let teamA = await this.firestore.collection('Teams').doc(fixtureData.teamA.id).get().toPromise();
            let teamB = await this.firestore.collection('Teams').doc(fixtureData.teamB.id).get().toPromise();

            let teamAPlayerOne = await this.firestore.collection('Users').doc(teamA.data().members[0].id).get().toPromise()
            let teamAPlayerTwo = await this.firestore.collection('Users').doc(teamA.data().members[1].id).get().toPromise()

            let teamBPlayerOne = await this.firestore.collection('Users').doc(teamB.data().members[0].id).get().toPromise()
            let teamBPlayerTwo = await this.firestore.collection('Users').doc(teamB.data().members[1].id).get().toPromise()

            fixtureData.teamA.name = teamA.data().name;
            fixtureData.teamB.name = teamB.data().name;
            fixtureData.teamA.playerOne = {
              name: teamAPlayerOne.data().firstName + ' ' + teamAPlayerOne.data().lastName,
              id: teamAPlayerOne.id
            };
            fixtureData.teamA.playerTwo = {
              name: teamAPlayerTwo.data().firstName + ' ' + teamAPlayerTwo.data().lastName,
              id: teamAPlayerTwo.id
            };
            fixtureData.teamB.playerOne = {
              name: teamBPlayerOne.data().firstName + ' ' + teamBPlayerOne.data().lastName,
              id: teamBPlayerOne.id
            };
            fixtureData.teamB.playerTwo = {
              name: teamBPlayerTwo.data().firstName + ' ' + teamBPlayerTwo.data().lastName,
              id: teamBPlayerTwo.id
            };
          }
          tempObj.fixtureData = fixtureData;
          return tempObj;
        }
      }))
      this.disputes = disputes.filter(Boolean);
      this.disputesDisputed = this.disputes.filter((el: any) => el.state === 'disputed')
      this.disputesResolved = this.disputes.filter((el: any) => el.state === 'resolved')
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

  viewDispute(dispute) {
    this.currDispute = dispute;
    this.initializeScoreForm();
  }

  getFormattedDate(timestamp) {
    if(timestamp) {
      let getCurrentDate = new Date(timestamp)
      let getYear = getCurrentDate.getFullYear()
      let getMonth = getCurrentDate.getMonth()
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let getDate = getCurrentDate.getDate()
      return `${getDate}/${months[getMonth]}/${getYear}`
    }
    return `Couldn't find date`
  }

  async confirmScores(dispute) {
    try {
      let matchId = dispute.fixtureRef.split('/')[2];
      if(dispute.matchType === 'one-on-one' || dispute.matchType === 'box') {
        let fixtureId = dispute.fixtureRef.split('/')[4];
        let fixture = await this.firestore.collection('Matches').doc(matchId).collection('fixtures')
                      .doc(fixtureId).update({ state: 'completed', frontEndState: 'completed' })
      } else if(dispute.matchType === 'knockout') {
        let roundType = dispute.fixtureRef.split('/')[3];
        let roundId = dispute.fixtureRef.split('/')[4];
        let fixtureId = dispute.fixtureRef.split('/')[6];
        let fixture = await this.firestore.collection('Matches').doc(matchId).collection(roundType)
                      .doc(roundId).collection('fixtures').doc(fixtureId)
                      .update({ state: 'completed', frontEndState: 'completed' })
      }

      await this.firestore.collection('Disputes').doc(dispute.disputeId).update({ state: 'resolved' })
      console.log("State Updated")
      this.currDispute.state = 'resolved'
    } catch(e) {
      console.log(e)
    }
  }

  editScores() {
    this.formGroup.enable();
    this.checkFormChanges();
    this.isEditScores = true;
  }

  previousScores() {
    this.isEditScores = false;
    this.setScoreValues(this.currDispute.fixtureData);
  }

  async uploadNewScores(formGroup, dispute) {
    if (formGroup.valid) {
      let formData = formGroup.value;
      let firebaseAddress;
      let matchId = dispute.fixtureRef.split('/')[2];
      if(dispute.matchType === 'one-on-one' || dispute.matchType === 'box') {
        let fixtureId = dispute.fixtureRef.split('/')[4];
        firebaseAddress = await this.firestore.collection('Matches').doc(matchId).collection('fixtures')
                          .doc(fixtureId)
      } else if(dispute.matchType === 'knockout') {
        let roundType = dispute.fixtureRef.split('/')[3];
        let roundId = dispute.fixtureRef.split('/')[4];
        let fixtureId = dispute.fixtureRef.split('/')[6];
        firebaseAddress = await this.firestore.collection('Matches').doc(matchId).collection(roundType)
                          .doc(roundId).collection('fixtures').doc(fixtureId)
      }

      firebaseAddress.get().toPromise()
        .then(res => {
          let data = res.data();
          data.state = 'completed';
          data.frontEndState = 'completed';

          let teamAScore = {
            setOne: parseInt(formData.setOneA),
            setTwo: parseInt(formData.setTwoA),
          }
          let teamBScore = {
            setOne: parseInt(formData.setOneB),
            setTwo: parseInt(formData.setTwoB),
          }
          if (formData.setOneTieA) {
            teamAScore['setOneTie'] = parseInt(formData.setOneTieA);
            teamBScore['setOneTie'] = parseInt(formData.setOneTieB);
          }
          if (formData.setTwoTieA) {
            teamAScore['setTwoTie'] = parseInt(formData.setTwoTieA);
            teamBScore['setTwoTie'] = parseInt(formData.setTwoTieB);
          }
          if (formData.setThreeA) {
            teamAScore['setThree'] = parseInt(formData.setThreeA);
            teamBScore['setThree'] = parseInt(formData.setThreeB);
          }
          if (formData.setThreeTieA) {
            teamAScore['setThreeTie'] = parseInt(formData.setThreeTieA);
            teamBScore['setThreeTie'] = parseInt(formData.setThreeTieB);
          }
          data.teamA.newScore = teamAScore;
          data.teamB.newScore = teamBScore;

          return firebaseAddress.update(data);
        })
        .then(res => {
          console.log("Success: New Score Uploaded");
          this.formGroup.disable();
        })
        .catch(error => { console.log(error) })

        try {
          await this.firestore.collection('Disputes').doc(dispute.disputeId).update({ state: 'resolved' })
          console.log("Status Updated")
          this.currDispute.state = 'resolved'
        } catch(e) {
          console.log(e)
        }
    }
  }

  // Show Scores
  initializeScoreForm() {
    this.formGroup = new FormGroup({
      setOneA: new FormControl('', Validators.required),
      setOneB: new FormControl('', Validators.required),
      setTwoA: new FormControl('', Validators.required),
      setTwoB: new FormControl('', Validators.required),
    })

    this.setScoreValues(this.currDispute.fixtureData);
  }
  
  setScoreValues(fixtureData) {
    this.checkTieBreaker(fixtureData);
    let tempObjteamA;
    let tempObjteamB;

    tempObjteamA = Object.assign({}, fixtureData.teamA.newScore);
    tempObjteamB = Object.assign({}, fixtureData.teamB.newScore);  

    // Team A Scores
    tempObjteamA['setOneA'] = tempObjteamA.setOne
    tempObjteamA['setTwoA'] = tempObjteamA.setTwo

    // Team B Scores
    tempObjteamB['setOneB'] = tempObjteamB.setOne
    tempObjteamB['setTwoB'] = tempObjteamB.setTwo

    // Assign values according to conditions
    if (this.isSetThree) {
      tempObjteamA['setThreeA'] = tempObjteamA.setThree
      tempObjteamB['setThreeB'] = tempObjteamB.setThree
    }
    if (tempObjteamA.setOneTie) {
      tempObjteamA['setOneTieA'] = tempObjteamA.setOneTie
      tempObjteamA['setOneTieB'] = tempObjteamB.setOneTie
    }
    if (tempObjteamA.setTwoTie) {
      tempObjteamA['setTwoTieA'] = tempObjteamA.setTwoTie
      tempObjteamA['setTwoTieB'] = tempObjteamB.setTwoTie
    }
    if (tempObjteamA.setThreeTie) {
      tempObjteamA['setThreeTieA'] = tempObjteamA.setThreeTie
      tempObjteamA['setThreeTieB'] = tempObjteamB.setThreeTie
    }
    this.formGroup.patchValue(tempObjteamA);
    this.formGroup.patchValue(tempObjteamB);
    this.formGroup.disable();
  }
  
  checkTieBreaker(fixtureData) {
    if (fixtureData.teamA.newScore.setThree) {
      this.formGroup.addControl('setThreeA', new FormControl('', Validators.required));
      this.formGroup.addControl('setThreeB', new FormControl('', Validators.required));
      this.isSetThree = true;
    } else { this.isSetThree = false; }

    if (fixtureData.teamA.newScore.setOneTie) {
      this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
      this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
      this.isSetOneTie = true;
    } else { this.isSetOneTie = false; }

    if (fixtureData.teamA.newScore.setTwoTie) {
      this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
      this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
      this.isSetTwoTie = true;
    } else { this.isSetTwoTie = false; }

    if (fixtureData.teamA.newScore.setThreeTie) {
      this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
      this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
      this.isSetThreeTie = true;
    } else { this.isSetThreeTie = false; }
  }

  checkFormChanges() {
    let comparisonValueSetOne: boolean;
    let comparisonValueSetTwo: boolean;
    let comparisonValueSetThree: boolean;

    this.formGroup.valueChanges.subscribe(values => {
      // Checking IF Set Three Exists
      this.isSetThreeExists(values);
      // Checking for Set One
      this.watchChangesSetOne(values, comparisonValueSetOne);
      // Checking for Set Two
      this.watchChangesSetTwo(values, comparisonValueSetTwo);
      // Checking for Set Three
      this.watchChangesSetThree(values, comparisonValueSetThree);
    })
  }

  watchChangesSetOne(values, comparisonValueSetOne) {
    let a = values.setOneA;
    let b = values.setOneB;
    let aMinusB = values.setOneA - values.setOneB;
    let bMinusA = values.setOneB - values.setOneA;
    let comparisonValue: boolean = comparisonValueSetOne;

    if (a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if (a >= 6 || b >= 6) {

      if (comparisonValue) {
        if (a == 7 || b == 7) {
          if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
            this.isSetOneTie = true;
            if (!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetOneTie = false;
            if (this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if (this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        }
      } else {
        if (a == 6 || b == 6) {
          if (a == b) {
            this.isSetOneTie = true;
            if (!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetOneTie = false;
            if (this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if (this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        } else {
          this.isSetOneTie = false;
          if (this.formGroup.controls.setOneTieA) {
            this.formGroup.removeControl('setOneTieA')
          }
          if (this.formGroup.controls.setOneTieB) {
            this.formGroup.removeControl('setOneTieB')
          }
        }
      }
    } else {
      this.isSetOneTie = false;
      if (this.formGroup.controls.setOneTieA) {
        this.formGroup.removeControl('setOneTieA')
      }
      if (this.formGroup.controls.setOneTieB) {
        this.formGroup.removeControl('setOneTieB')
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'one', values: values})
  }

  watchChangesSetTwo(values, comparisonValueSetTwo) {
    let a = values.setTwoA;
    let b = values.setTwoB;
    let aMinusB = values.setTwoA - values.setTwoB;
    let bMinusA = values.setTwoB - values.setTwoA;
    let comparisonValue: boolean = comparisonValueSetTwo;

    if (a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if (a >= 6 || b >= 6) {

      if (comparisonValue) {
        if (a == 7 || b == 7) {
          if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
            this.isSetTwoTie = true;
            if (!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetTwoTie = false;
            if (this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if (this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        }
      } else {
        if (a == 6 || b == 6) {
          if (a == b) {
            this.isSetTwoTie = true;
            if (!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetTwoTie = false;
            if (this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if (this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        } else {
          this.isSetTwoTie = false;
          if (this.formGroup.controls.setTwoTieA) {
            this.formGroup.removeControl('setTwoTieA');
          }
          if (this.formGroup.controls.setTwoTieB) {
            this.formGroup.removeControl('setTwoTieB');
          }
        }
      }
    } else {
      this.isSetTwoTie = false;
      if (this.formGroup.controls.setTwoTieA) {
        this.formGroup.removeControl('setTwoTieA');
      }
      if (this.formGroup.controls.setTwoTieB) {
        this.formGroup.removeControl('setTwoTieB');
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'two', values: values})
  }

  watchChangesSetThree(values, comparisonValueSetThree) {
    if(this.isSetThree) {      
      let a = values.setThreeA;
      let b = values.setThreeB;
      let aMinusB = values.setThreeA - values.setThreeB;
      let bMinusA = values.setThreeB - values.setThreeA;
      let comparisonValue: boolean = comparisonValueSetThree;

      if (a == 7 || b == 7) {
        comparisonValue = true;
      } else { comparisonValue = false; }

      if (a >= 6 || b >= 6) {

        if (comparisonValue) {
          if (a == 7 || b == 7) {
            if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
              this.isSetThreeTie = true;
              if (!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if (!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            }
            else {
              this.isSetThreeTie = false;
              if (this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if (this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          }
        } else {
          if (a == 6 || b == 6) {
            if (a == b) {
              this.isSetThreeTie = true;
              if (!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if (!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            } else {
              this.isSetThreeTie = false;
              if (this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if (this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          } else {
            this.isSetThreeTie = false;
            if (this.formGroup.controls.setThreeTieA) {
              this.formGroup.removeControl('setThreeTieA')
            }
            if (this.formGroup.controls.setThreeTieB) {
              this.formGroup.removeControl('setThreeTieB')
            }
          }
        }
      } else {
        this.isSetThreeTie = false;
        if (this.formGroup.controls.setThreeTieA) {
          this.formGroup.removeControl('setThreeTieA')
        }
        if (this.formGroup.controls.setThreeTieB) {
          this.formGroup.removeControl('setThreeTieB')
        }
      }

      // Checking for valid scores
      this.isValidScores({set : 'three', values: values})
    }
  }

  isValidScores(data) {
    let values = data.values;
    let a, b, isDirtyA, isDirtyB, setA, setB, setATie, setBTie;
    if(data.set === 'one') {
      a = data.values.setOneA;
      b = data.values.setOneB;
      isDirtyA = this.formGroup.controls.setOneA.dirty;
      isDirtyB = this.formGroup.controls.setOneB.dirty;
      setA = 'setOneA';
      setB = 'setOneB';
      setATie = 'setOneTieA';
      setBTie = 'setOneTieB';
    } else if(data.set === 'two') {
      a = data.values.setTwoA;
      b = data.values.setTwoB;
      isDirtyA = this.formGroup.controls.setTwoA.dirty;
      isDirtyB = this.formGroup.controls.setTwoB.dirty;
      setA = 'setTwoA';
      setB = 'setTwoB';
      setATie = 'setTwoTieA';
      setBTie = 'setTwoTieB';
    } else if(data.set === 'three') {
      if(this.isSetThree && this.formGroup.controls.setThreeA && this.formGroup.controls.setThreeB) {
        a = data.values.setThreeA;
        b = data.values.setThreeB;
        isDirtyA = this.formGroup.controls.setThreeA.dirty;
        isDirtyB = this.formGroup.controls.setThreeB.dirty;
        setA = 'setThreeA';
        setB = 'setThreeB';
        setATie = 'setThreeTieA';
        setBTie = 'setThreeTieB';
      }
    }

    if( ((a == 7 || a == 6) && (b < 7) && isDirtyA && isDirtyB) ||
        ((b == 7 || b == 6) && (a < 7) && isDirtyA && isDirtyB)) {

      if((a == 7 && b > 4) || (b == 7 && a > 4)) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else if((a == 6 && (b < 5 || b == 7 || b == 6)) || (b == 6 && (a < 5 || a == 7 || a == 6))) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else {
        if(isDirtyA && isDirtyB) {
          this.formGroup.controls[setA].setErrors({incorrect: true});
          this.formGroup.controls[setB].setErrors({incorrect: true});
        }
      }
    } else {
      if(isDirtyA && isDirtyB) {
        this.formGroup.controls[setA].setErrors({incorrect: true});
        this.formGroup.controls[setB].setErrors({incorrect: true});
      }
    }

    const addError = () => {
      if(this.formGroup.controls[setATie].dirty && this.formGroup.controls[setBTie].dirty) {
        this.formGroup.controls[setATie].setErrors({incorrect: true});
        this.formGroup.controls[setBTie].setErrors({incorrect: true});
      }
    }
    const removeError = () => {
      this.formGroup.controls[setATie].setErrors(null);
      this.formGroup.controls[setBTie].setErrors(null);
    }

    // Checking for TIE BREAKER
    if(this.formGroup.controls[setATie]) {
      let setTieA = parseInt(values[setATie])
      let setTieB = parseInt(values[setBTie])
      if(setTieA > 7 || setTieB > 7) {
        if(setTieA > setTieB && setTieA - setTieB === 2)      removeError();
        else if(setTieB > setTieA && setTieB - setTieA === 2) removeError();
        else addError();
      } else {
        if(setTieA === 7 || setTieB === 7) removeError();
        else addError();
      }
    }
  }

  isSetThreeExists(values) {
    if(this.isFormValid()) {
      let isScoresEqual = this.caclulateScores(values)
      if(isScoresEqual) {
        if(!this.isSetThree) {
          this.isSetThree = true;
          this.formGroup.addControl('setThreeA', new FormControl('', Validators.required));
          this.formGroup.addControl('setThreeB', new FormControl('', Validators.required));
        }
      } else {
        this.removeSetThree();
      }
    } else {
      this.removeSetThree();
    } 
  }

  isFormValid() {
    let isFormValid: boolean =  this.formGroup.controls.setOneA.valid &&
                                this.formGroup.controls.setOneB.valid &&
                                this.formGroup.controls.setTwoA.valid &&
                                this.formGroup.controls.setTwoB.valid;

    if(this.formGroup.controls.setOneTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid;
    }
    if(this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }
    if(this.formGroup.controls.setOneTieA && this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }

    return isFormValid
  }

  caclulateScores(values) {
    let teamAKeys = [];
    let teamBKeys = [];
    let teamAScore = [];
    let teamBScore = [];

    for(let key in values) {
      if(key.includes('A') && !key.includes('Three')) {
        teamAKeys.push(key)
      }
      if(key.includes('B') && !key.includes('Three')) {
        teamBKeys.push(key)
      }
    }

    teamAKeys.forEach(el => {
      teamAScore.push(parseInt(values[el]))
    })
    teamBKeys.forEach(el => {
      teamBScore.push(parseInt(values[el])) 
    })

    if(teamAScore.length > 2 && teamBScore.length > 2) {
      if(teamAScore.length === 4 && teamBScore.length === 4) {
        if((teamAScore[2] > teamBScore[2] && teamBScore[3] > teamAScore[3]) || 
           (teamBScore[2] > teamAScore[2] && teamAScore[3] > teamBScore[3])) {
          return true;
        }
      }

      if(teamAScore.length === 3 && teamBScore.length === 3) {
        if(this.formGroup.controls.setOneTieA) {
          if((teamAScore[1] > teamBScore[1] && teamBScore[2] > teamAScore[2]) || 
             (teamBScore[1] > teamAScore[1] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        } else if(this.formGroup.controls.setTwoTieA) {
          if((teamAScore[0] > teamBScore[0] && teamBScore[2] > teamAScore[2]) || 
             (teamBScore[0] > teamAScore[0] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        }
      }

    } else if((teamAScore[0] > teamBScore[0] && teamBScore[1] > teamAScore[1]) || 
              (teamBScore[0] > teamAScore[0] && teamAScore[1] > teamBScore[1])) {
        return true;
    }

    let teamAFinalScore = teamAScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)

    let teamBFinalScore = teamBScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)
    
    return teamAFinalScore === teamBFinalScore;
  }

  removeSetThree() {
    if(this.isSetThree) {
      this.isSetThree = false;
      this.formGroup.removeControl('setThreeA')
      this.formGroup.removeControl('setThreeB')
      if(this.isSetThreeTie) {
        this.formGroup.removeControl('setThreeTieA')
        this.formGroup.removeControl('setThreeTieB')
      }
    } 
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      let allDisputes =  await this.firestore.collection('Disputes', ref => ref 
                              .orderBy('timestamp', 'desc')).get().toPromise()
      if(allDisputes.docs.length > 0) {
        let disputes: any[] = allDisputes.docs.map(el => {
          let tempObj: any = {}
          tempObj = el.data();
          tempObj.disputeId = el.id;
          return tempObj         
        })
        this.collectDisputes(disputes.filter(Boolean));
        this.isDisputesFound = true;
      } else {
        this.isDisputesFound = false;
        this.isLoading = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

}
