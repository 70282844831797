<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">

  <app-player-menu></app-player-menu>

  <div class="container">
    <div class="gray-table-container">
      <div class="statistics">
        <div class="heading">My Balance</div>
        <div class="stats">
          <div class="stat">
            <label>Wallet</label>
            <div class="value">${{ userObj?.wallet ? userObj.wallet : 0 }}</div>
          </div>
          <div class="stat">
            <label>Earnings</label>
            <div class="value">${{ userObj?.earnings ? userObj.earnings : 0 }}</div>
          </div>
        </div>
      </div>
      <div class="detail-box">
        <div class="inner deposit">
          <div class="heading">Deposit</div>
          <div class="deposit-amount">
            <p>Deposit amount into your Viictor Wallet from your Paypal or Credit Card.</p>
            <div class="inputs">
              <input type="number" min="0" placeholder="Enter Amount" [(ngModel)]="depositAmount" />
              <button [disabled]="!depositAmount || depositAmount <= 0" (click)="openModal('deposit')">Deposit</button>
            </div>
          </div>
        </div>
        <div class="inner withdraw">
          <div class="heading">Withdraw</div>
          <div class="withdraw-amount">
            <p>Withdraw money from your Viictor Wallet into your Paypal account.</p>
            <div class="inputs" *ngIf="isUserDataLoaded">
              <input type="number" min="0" [max]="userObj.wallet.amount" placeholder="Enter Amount" [(ngModel)]="withdrawAmount" />
              <button [disabled]="!withdrawAmount || withdrawAmount <= 0 || withdrawAmount > userObj.wallet.amount" (click)="openModal('withdraw')">Withdraw</button>
            </div>
          </div>
        </div>
        <div class="inner transaction">
          <div class="heading">Transactions</div>
          <div class="transaction-table" *ngIf="hasTransactions && !isLoading">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Detail</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of transactions">
                  <td>{{ getFormattedDate(transaction.timestamp) }}</td>
                  <td>{{ transaction.detail }}</td>
                  <td class="currency">{{ transaction.currency }}</td>
                  <td>{{ transaction.type }}</td>
                  <td>${{ transaction.amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="transaction-table" *ngIf="!hasTransactions && !isLoading">
            There are no transactions to show
          </div>
          <div class="loader" *ngIf="isLoading">
            <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
  </div>
</div>

<section *ngIf="isDepositModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="close-modal" (click)="closeModal()">x</div>
      <ng-container *ngIf="!isDeposited">
        <ng-container *ngIf="!showPaypal && !isModalLoading">
          <p>Would you like to deposit <strong>${{depositAmount.toLocaleString()}}</strong> into your <strong>Viictor Wallet</strong>?</p>
        </ng-container>
  
        <div [ngStyle]="{'display': showPaypal ? 'block' : 'none'}">
          <p>Please enter your payment details to deposit the money.</p>
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
  
        <div *ngIf="isModalLoading" class="loader">
          <p>Depositing amount into your <strong>Viictor Wallet</strong>.</p>
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
        </div>
        
        <div *ngIf="!isModalLoading && !showPaypal"class="join-buttons">
          <button type="button" (click)="deposit()">Yes</button>
          <button type="button" class="btn-cancel" (click)="closeModal()">No</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isDeposited">
        <div class="alert alert-success">
          <strong>${{depositAmount.toLocaleString()}}</strong> has been deposited into your <strong>Viictor Wallet</strong>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<section *ngIf="isWithdrawModalOpen" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="close-modal" (click)="closeModal()">x</div>
      <ng-container *ngIf="!isWithdrawn">
        <p>Would you like to withdraw <strong>${{withdrawAmount.toLocaleString()}}</strong> from your <strong>Viictor Wallet</strong>?</p>
  
        <div *ngIf="isModalLoading" class="loader">
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
        </div>
        
        <div *ngIf="!isModalLoading" class="join-buttons">
          <button type="button" (click)="withdraw()">Yes</button>
          <button type="button" class="btn-cancel" (click)="closeModal()">No</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isWithdrawn">
        <div class="alert alert-success">
          <strong>${{withdrawAmount.toLocaleString()}}</strong> has been withdrawn from your <strong>Viictor Wallet</strong>
        </div>
      </ng-container>
    </div>
  </div>
</section>