import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-player-menu',
  templateUrl: './player-menu.component.html',
  styleUrls: ['./player-menu.component.scss']
})
export class PlayerMenuComponent implements OnInit {

  @Input() currUrl: any;

  constructor(public router: Router) {
    this.currUrl = this.router.url;
  }

  ngOnInit() {
  }

}
