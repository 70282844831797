<div class="panel">
  <div class="panel-inner">
    <h3 class="top-heading">Match Etiquette</h3>
    <div class="content">
      <p>Prior to every match opposing players should witness the COIN TOSS. The coin toss
        determines who the Home/Away players are.</p>
      <p>If you are the HOME player</p>
      <ol>
        <li>Discuss with your opponent your chosen home venue and arrange the day/time to
          play.</li>
        <li>The home player is expected to book and pay for the court at the agreed location
          and time. Please ensure you have booked enough time to complete your match.
          (Don’t forget to allow time for warm up for all players….5 minutes)</li>
        <li>You can play at your designated home court, or you can choose to play at any other
          location that is agreed upon by both players. Please try to avoid making the Away
          player travel further than your nominated home court.</li>
      </ol>
      <p>If you are the AWAY player</p>
      <ol>
        <li>Discuss with your opponent the location of their chosen venue and agree upon the
          day/time of your match.</li>
        <li>If you are the away player you are expected to bring the balls. Players should play all
          matches with new or near new balls and the balls should be ITF approved. You can
          find the ITF approved stamp on all good quality tennis ball cans/tins or go to this link
          if you are unsure: <a href="https://www.itftennis.com/technical/balls/approved-balls.aspx">
          https://www.itftennis.com/technical/balls/approved-balls.aspx</a></li>
      </ol>
      <p><strong>Q.</strong> Can we share the cost of both the courts and the balls?</p>
      <p><strong>A.</strong> Absolutely, as long as it is agreed upon before the match then you can arrange for this to
        happen however you like. Have this conversation with your opponent while arranging your
        match.</p>
      <h4>After the match</h4>
      <p>The winning player will enter the result into the system and the losing opponent will need to
        verify the match score and result.</p>
      <p>If the score was entered incorrectly then both players will need to verify the score and result
        before the outcome is published and official.</p>
      <h4>Sportsmanship</h4>
      <p>After playing your match both players will need to enter a rating of their opponent out of 5
        stars. Your rating will be the average across all ratings you have received from all your
        opponents. Ratings are anonymous so please be honest with your rating and factor in all of
        the following:</p>
      <p>Sportsmanship, on court behaviour, fairness, punctuality. In essence your overall
        experience.</p>
      <p>If you had a fantastic experience, whether you won or lost, players are encouraged to send a
        ribbon to their opponent. Ribbons will be allocated to a players profile which in turn allows
        all players to see their accolades and generally shows the good nature of the player.</p>
      <h4>Disputes = $7.50 Administration fee</h4>
      <p>If you need to resolve a dispute that can’t be resolved between the players then you can
        lodge an online dispute. To lodge a dispute your account will be charged a $7.50
        administration fee. This fee is necessary as an administrator will need to follow up with
        detective work, which takes time.</p>
      <p>It’s also designed to be a deterrent so that players can resolve their own disputes or use the
        system in place to rate the opponent or raise a red flag. The results of this sport should be
        plain and simple and we expect the Viictor community to take responsibility for their own
        integrity and to be honest and forthright with scoring and entering results.</p>
    </div>
  </div>
</div>