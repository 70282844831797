import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-match-etiquette',
  templateUrl: './match-etiquette.component.html',
  styleUrls: ['./match-etiquette.component.scss']
})
export class MatchEtiquetteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
