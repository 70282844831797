<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">

  <app-player-menu></app-player-menu>

  <div class="container">
    <div class="gray-table-container">
      <div class="statistics">
        <div class="open-for-challenge">
          <div class="heading">Statistics</div>
          <div *ngIf="totalMatches > 4" class="toggle-challenge">
            <div class="text">Open for Challenge</div>
            <div class="loader" *ngIf="isToggleChallengeLoading else toggleChallengeLoaded">
              <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
            </div>
            <ng-template #toggleChallengeLoaded>
              <div class="on-off-button" [ngClass]="{'on': isOpenForChallenge, 'off': !isOpenForChallenge}" (click)="toggleChallenge()">
                <div> {{ isOpenForChallenge ? "YES" : "NO" }} </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="stats">
          <div class="stat">
            <label>Completed <br>Challenges</label>
            <div class="value">{{ completedChallenges }}</div>
          </div>
          <div class="stat">
            <label>Wins</label>
            <div class="value">{{ wins.length }}</div>
          </div>
          <div class="stat">
            <label>Loses</label>
            <div class="value">{{ loses.length }}</div>
          </div>
          <div class="stat">
            <label>Win ratio</label>
            <div class="value">{{ winRatio }}</div>
          </div>
        </div>
      </div>
      
      <div class="statistics-tabs">
        <div class="container">
          <ul>
            <li [ngClass]="{'tab-active': this.currUrl === '/tennis/user/challenges/accepted'}">
              <a [routerLink]="['/tennis/user/challenges/accepted']">Accepted</a>
            </li>
            <li [ngClass]="{'tab-active': this.currUrl === '/tennis/user/challenges/sent'}">
              <a [routerLink]="['/tennis/user/challenges/sent']">Sent</a>
            </li>
            <li [ngClass]="{'tab-active': this.currUrl === '/tennis/user/challenges/received'}">
              <a [routerLink]="['/tennis/user/challenges/received']">Received</a>
            </li>
          </ul>
        </div>
      </div>

      <router-outlet></router-outlet>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
