<div class="matches">
  <div *ngIf="isLoading" class="loader">
    <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
  </div>
  <div *ngIf="!isLoading">
    <div *ngIf="hasMatches else noMatches" class="matches-inner">
      <div class="match" *ngFor="let match of matches; let i = index">
        <div class="block-1">
          <div class="name"><label>Name:</label> 
            <a [routerLink]="['/tennis/player/', match.id]">
              {{ match.firstName }} {{ match.lastName}}
            </a> (Click to view profile)
          </div>
          <div class="bet"><label>Bet Amount:</label> ${{ match.betAmount }}</div>
          <div class="location"><label>Location:</label> Perth (<span class="view-map">View on map</span>)</div>
        </div>
        <div class="block-2">
          <button type="button" class="button-accept" (click)="openModal(match, 'accept')">Accept</button>
          <button type="button" class="button-reject" (click)="openModal(match, 'reject')">Reject</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noMatches>
    <div class="alert">
      Currently you have no requests.
    </div>
  </ng-template>

  <section [ngStyle]="{'display': isAcceptModalOpen ? 'block' : 'none'}" class="modal">
    <div class="modal-background">
      <div class="modal-body">
        <div [ngStyle]="{'display': !isToss ? 'block' : 'none'}">
          <div [ngStyle]="{'display': isModalLoading ? 'block' : 'none'}" class="loader">
            <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
          </div>
          <div [ngStyle]="{'display': !isModalLoading ? 'block' : 'none'}">
            <p>Please complete the payment process to accept the challenge otherwise you can cancel it</p> 
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            <div class="join-buttons">
              <button type="button" (click)="closeModal()">Cancel</button>
            </div>
          </div>
        </div>
        <div [ngStyle]="{'display': isToss ? 'block' : 'none'}">
          <div class="toss-wrapper" [ngClass]="{'move':  tossTimeout < 1}">
            <img class="coin" [ngClass]="{'spin':  tossTimeout < 1}" src="../../../assets/image/coin-2.webp" alt="coin">
          </div>
          <div *ngIf="tossTimeout > 0" class="alert">Toss is about to begin in <strong>{{ tossTimeout }}</strong></div>
          <div *ngIf="fixtureData">
            <div *ngIf="coin !== undefined" class="alert alert-success">
              You're <strong>
                {{ fixtureData[fixtureData['home']].id === this.authService.user.uid ? 'Home' : 'Away' }}
              </strong>
            </div>
          </div>
          <div *ngIf="coin !== undefined"class="join-buttons">
            <button type="button" (click)="closeTossModal()">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="isRejectModalOpen" class="modal">
    <div class="modal-background">
      <div class="modal-body">
        <p>Would you like to reject this challenge against <strong>{{ currMatch.firstName }} {{ currMatch.lastName }}</strong>?</p> 
        <div *ngIf="isModalLoading" class="loader">
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
        </div>
        <div *ngIf="!isModalLoading"class="join-buttons">
          <button type="button" (click)="reject(currMatch.requestId)">Yes</button>
          <button type="button" (click)="closeModal()">No</button>
        </div>
      </div>
    </div>
  </section>
</div>