import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  faqs: any[];
  faqsEntryFees: any[];
  faqsPrizeMoney: any[];
  faqsMatchEtiquette: any[];
  faqsCoinToss: any[];
  faqsMatchResults: any[];
  faqsDispute: any[];
  faqsRating: any[];
  faqsFlags: any[];

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let accordions = document.getElementsByClassName("faq");
    const addRemoveClass = e => {
      let questionElement: Element = e.currentTarget.children[0]
      let answerElement: Element = e.currentTarget.children[1]
      answerElement.classList.toggle("expand");
      if(answerElement.classList.contains("expand")) 
        questionElement.children[1].innerHTML = "-";
      else 
        questionElement.children[1].innerHTML = "+";
    }      

    for (let i = 0; i < accordions.length; i++) {
      accordions[i].addEventListener('click', addRemoveClass)
    }
  }
}
