import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from './../../services/auth/auth.service';

@Component({
  selector: 'app-upload-scores',
  templateUrl: './upload-scores.component.html',
  styleUrls: ['./upload-scores.component.scss']
})
export class UploadScoresComponent implements OnInit {

  @Input() userObj;

  formGroup: FormGroup;
  isSetThree: boolean;
  isSetOneTie: boolean;
  isSetTwoTie: boolean;
  isSetThreeTie: boolean;
  
  @Input() isScoreModalOpen: boolean;
  @Output() isScoreModalOpenParent = new EventEmitter()
  @Output() scoreUploaded = new EventEmitter()
  @Input() fixtureData;

  isIncompleteMatch: boolean = false;
  isFormFilled: boolean;
  matchOutcome: any = { type: "", team: "" };
  @ViewChild("matchOutcome") matchOutcomeElement: ElementRef;

  isUploadScoreLoading: boolean;
  errorUploadScore: boolean;
  isPlayerRatingModalOpen: boolean

  constructor(private authService:  AuthService, private firestore: AngularFirestore) { }
  
  initializeScoreForm() {
    this.formGroup = new FormGroup({
      setOneA: new FormControl('', Validators.required),
      setOneB: new FormControl('', Validators.required),
      setTwoA: new FormControl('', Validators.required),
      setTwoB: new FormControl('', Validators.required),
    })
    this.checkFormChanges();
  }

  // Calculating Scores
  checkFormChanges() {
    let comparisonValueSetOne: boolean;
    let comparisonValueSetTwo: boolean;
    let comparisonValueSetThree: boolean;

    this.formGroup.valueChanges.subscribe(values => {
      // Checking for Match Outcome
      this.checkMatchOutcome(values);
      // Checking for Set One
      this.watchChangesSetOne(values, comparisonValueSetOne);
      // Checking for Set Two
      this.watchChangesSetTwo(values, comparisonValueSetTwo);
      // Checking for Set Three
      this.watchChangesSetThree(values, comparisonValueSetThree);
      // Checking IF Set Three Exists
      this.isSetThreeExists(values);
    })
  }

  watchChangesSetOne(values, comparisonValueSetOne) {
    let a = values.setOneA;
    let b = values.setOneB;
    let aMinusB = values.setOneA - values.setOneB;
    let bMinusA =  values.setOneB - values.setOneA;
    let comparisonValue: boolean = comparisonValueSetOne;

    if(a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if(a >= 6 || b >= 6) {

      if(comparisonValue) {
        if(a == 7 || b == 7) {
          if( (aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0) ) {
            this.isSetOneTie = true;
            if(!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetOneTie = false;
            if(this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if(this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        }
      } else {
        if(a == 6 || b == 6) {
          if(a == b) {
            this.isSetOneTie = true;
            if(!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetOneTie = false;
            if(this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if(this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        }  else {
          this.isSetOneTie = false;
          if(this.formGroup.controls.setOneTieA) {
            this.formGroup.removeControl('setOneTieA')
          }
          if(this.formGroup.controls.setOneTieB) {
            this.formGroup.removeControl('setOneTieB')
          }
        }
      }
    } else {
      this.isSetOneTie = false;
      if(this.formGroup.controls.setOneTieA) {
        this.formGroup.removeControl('setOneTieA')
      }
      if(this.formGroup.controls.setOneTieB) {
        this.formGroup.removeControl('setOneTieB')
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'one', values: values})
  }

  watchChangesSetTwo(values, comparisonValueSetTwo) {
    let a = values.setTwoA;
    let b = values.setTwoB;
    let aMinusB = values.setTwoA - values.setTwoB;
    let bMinusA =  values.setTwoB - values.setTwoA;
    let comparisonValue: boolean = comparisonValueSetTwo;

    if(a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if(a >= 6 || b >= 6) {

      if(comparisonValue) {
        if(a == 7 || b == 7) {
          if( (aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0) ) {
            this.isSetTwoTie = true;
            if(!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetTwoTie = false;
            if(this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if(this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        }
      } else {
        if(a == 6 || b == 6) {
          if(a == b) {
            this.isSetTwoTie = true;
            if(!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if(!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetTwoTie = false;
            if(this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if(this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        }  else {
          this.isSetTwoTie = false;
          if(this.formGroup.controls.setTwoTieA) {
            this.formGroup.removeControl('setTwoTieA');
          }
          if(this.formGroup.controls.setTwoTieB) {
            this.formGroup.removeControl('setTwoTieB');
          }
        }
      }
    } else {
      this.isSetTwoTie = false;
      if(this.formGroup.controls.setTwoTieA) {
        this.formGroup.removeControl('setTwoTieA');
      }
      if(this.formGroup.controls.setTwoTieB) {
        this.formGroup.removeControl('setTwoTieB');
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'two', values: values})
  }

  watchChangesSetThree(values, comparisonValueSetThree) {
    if(this.isSetThree) {
      let a = values.setThreeA;
      let b = values.setThreeB;
      let aMinusB = values.setThreeA - values.setThreeB;
      let bMinusA =  values.setThreeB - values.setThreeA;
      let comparisonValue: boolean = comparisonValueSetThree;

      if(a == 7 || b == 7) {
        comparisonValue = true;
      } else { comparisonValue = false; }

      if(a >= 6 || b >= 6) {

        if(comparisonValue) {
          if(a == 7 || b == 7) {
            if( (aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0) ) {
              this.isSetThreeTie = true;
              if(!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if(!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            }
            else {
              this.isSetThreeTie = false;
              if(this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if(this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          }
        } else {
          if(a == 6 || b == 6) {
            if(a == b) {
              this.isSetThreeTie = true;
              if(!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if(!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            } else {
              this.isSetThreeTie = false;
              if(this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if(this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          }  else {
            this.isSetThreeTie = false;
            if(this.formGroup.controls.setThreeTieA) {
              this.formGroup.removeControl('setThreeTieA')
            }
            if(this.formGroup.controls.setThreeTieB) {
              this.formGroup.removeControl('setThreeTieB')
            }
          }
        }
      } else {
        this.isSetThreeTie = false;
        if(this.formGroup.controls.setThreeTieA) {
          this.formGroup.removeControl('setThreeTieA')
        }
        if(this.formGroup.controls.setThreeTieB) {
          this.formGroup.removeControl('setThreeTieB')
        }
      }

      // Checking for valid scores
      this.isValidScores({set : 'three', values: values})
    }
  }

  checkMatchOutcome(values) {
    // Reset Match Outcome Values
    Object.keys(this.matchOutcome).forEach(el => this.matchOutcome[el] = "");

    // Reset form validation
    // Form validation will auto reset itself

    // Reset Match Outcome Values In UI
    if(this.matchOutcomeElement) {
      this.matchOutcomeElement.nativeElement.options[0].selected = true;
    }

    // Identify Which Match Outcome Is Active
    let isFormFilled = Object.values(values).some(el => {
      if(el !== "") return true
    })
    this.isFormFilled = isFormFilled
  }

  isValidScores(data) {
    let values = data.values;
    let a, b, isDirtyA, isDirtyB, setA, setB, setATie, setBTie;
    if(data.set === 'one') {
      a = data.values.setOneA;
      b = data.values.setOneB;
      isDirtyA = this.formGroup.controls.setOneA.dirty;
      isDirtyB = this.formGroup.controls.setOneB.dirty;
      setA = 'setOneA';
      setB = 'setOneB';
      setATie = 'setOneTieA';
      setBTie = 'setOneTieB';
    } else if(data.set === 'two') {
      a = data.values.setTwoA;
      b = data.values.setTwoB;
      isDirtyA = this.formGroup.controls.setTwoA.dirty;
      isDirtyB = this.formGroup.controls.setTwoB.dirty;
      setA = 'setTwoA';
      setB = 'setTwoB';
      setATie = 'setTwoTieA';
      setBTie = 'setTwoTieB';
    } else if(data.set === 'three') {
      if(this.isSetThree && this.formGroup.controls.setThreeA && this.formGroup.controls.setThreeB) {
        a = data.values.setThreeA;
        b = data.values.setThreeB;
        isDirtyA = this.formGroup.controls.setThreeA.dirty;
        isDirtyB = this.formGroup.controls.setThreeB.dirty;
        setA = 'setThreeA';
        setB = 'setThreeB';
        setATie = 'setThreeTieA';
        setBTie = 'setThreeTieB';
      }
    }

    if( ((a == 7 || a == 6) && (b < 7) && isDirtyA && isDirtyB) ||
        ((b == 7 || b == 6) && (a < 7) && isDirtyA && isDirtyB)) {

      if((a == 7 && b > 4) || (b == 7 && a > 4)) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else if((a == 6 && (b < 5 || b == 7 || b == 6)) || (b == 6 && (a < 5 || a == 7 || a == 6))) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else {
        if(isDirtyA && isDirtyB) {
          this.formGroup.controls[setA].setErrors({incorrect: true});
          this.formGroup.controls[setB].setErrors({incorrect: true});
        }
      }
    } else {
      if(isDirtyA && isDirtyB) {
        this.formGroup.controls[setA].setErrors({incorrect: true});
        this.formGroup.controls[setB].setErrors({incorrect: true});
      }
    }

    const addError = () => {
      if(this.formGroup.controls[setATie].dirty && this.formGroup.controls[setBTie].dirty) {
        this.formGroup.controls[setATie].setErrors({incorrect: true});
        this.formGroup.controls[setBTie].setErrors({incorrect: true});
      }
    }
    const removeError = () => {
      this.formGroup.controls[setATie].setErrors(null);
      this.formGroup.controls[setBTie].setErrors(null);
    }

    // Checking for TIE BREAKER
    if(this.formGroup.controls[setATie]) {
      let setTieA = parseInt(values[setATie])
      let setTieB = parseInt(values[setBTie])
      if(setTieA > 7 || setTieB > 7) {
        if(setTieA > setTieB && (setTieA - setTieB) === 2)      removeError();
        else if(setTieB > setTieA && (setTieB - setTieA) === 2) removeError();
        else addError();
      } else {
        if(setTieA === 7 || setTieB === 7) {
          if(setTieA === 7 && setTieB < 7) removeError();
          else if(setTieB === 7 && setTieA < 7) removeError();
          else addError();
        } else addError();
      }
    }
  }

  isSetThreeExists(values) {
    if(this.isFormValid()) {
      let isScoresEqual = this.caclulateScores(values)
      if(isScoresEqual) {
        if(!this.isSetThree) {
          this.isSetThree = true;
          this.formGroup.addControl('setThreeA', new FormControl('', Validators.required));
          this.formGroup.addControl('setThreeB', new FormControl('', Validators.required));
        }
      } else {
        this.removeSetThree();
      }
    } else {
      this.removeSetThree();
    }
  }

  isFormValid() {
    let isFormValid: boolean =  this.formGroup.controls.setOneA.valid &&
                                this.formGroup.controls.setOneB.valid &&
                                this.formGroup.controls.setTwoA.valid &&
                                this.formGroup.controls.setTwoB.valid;

    if(this.formGroup.controls.setOneTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid;
    }
    if(this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }
    if(this.formGroup.controls.setOneTieA && this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }

    return isFormValid
  }

  caclulateScores(values) {
    let teamAKeys = [];
    let teamBKeys = [];
    let teamAScore = [];
    let teamBScore = [];

    for(let key in values) {
      if(key.includes('A') && !key.includes('Three')) {
        teamAKeys.push(key)
      }
      if(key.includes('B') && !key.includes('Three')) {
        teamBKeys.push(key)
      }
    }

    teamAKeys.forEach(el => {
      teamAScore.push(parseInt(values[el]))
    })
    teamBKeys.forEach(el => {
      teamBScore.push(parseInt(values[el]))
    })

    if(teamAScore.length > 2 && teamBScore.length > 2) {
      if(teamAScore.length === 4 && teamBScore.length === 4) {
        if((teamAScore[2] > teamBScore[2] && teamBScore[3] > teamAScore[3]) ||
           (teamBScore[2] > teamAScore[2] && teamAScore[3] > teamBScore[3])) {
          return true;
        }
      }

      if(teamAScore.length === 3 && teamBScore.length === 3) {
        if(this.formGroup.controls.setOneTieA) {
          if((teamAScore[1] > teamBScore[1] && teamBScore[2] > teamAScore[2]) ||
             (teamBScore[1] > teamAScore[1] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        } else if(this.formGroup.controls.setTwoTieA) {
          if((teamAScore[0] > teamBScore[0] && teamBScore[2] > teamAScore[2]) ||
             (teamBScore[0] > teamAScore[0] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        }
      }

    } else if((teamAScore[0] > teamBScore[0] && teamBScore[1] > teamAScore[1]) ||
              (teamBScore[0] > teamAScore[0] && teamAScore[1] > teamBScore[1])) {
        return true;
    }

    let teamAFinalScore = teamAScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)

    let teamBFinalScore = teamBScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)

    return teamAFinalScore === teamBFinalScore;
  }

  removeSetThree() {
    if(this.isSetThree) {
      this.isSetThree = false;
      this.formGroup.removeControl('setThreeA')
      this.formGroup.removeControl('setThreeB')
      if(this.isSetThreeTie) {
        this.formGroup.removeControl('setThreeTieA')
        this.formGroup.removeControl('setThreeTieB')
      }
    }
  }
  // Calculating Scores End

  // Adding Scores
  async addScore(formGroup: FormGroup) {
    if(formGroup.valid) {
      this.isUploadScoreLoading = true;
      let formData = formGroup.value;
      let firebaseAddress = this.firestore.collection('Matches').doc(this.fixtureData.matchId);
      // Knockout match
      if(this.fixtureData.roundId) {
        firebaseAddress = firebaseAddress.collection(this.fixtureData.roundType)
                          .doc(this.fixtureData.roundId).collection('fixtures')
                          .doc(this.fixtureData.fixtureId)
      } else {
        firebaseAddress = firebaseAddress.collection('fixtures').doc(this.fixtureData.fixtureId)
      }
      try {
        await this.uploadScore(firebaseAddress, formData, this.fixtureData)
        this.scoreUploaded.emit(true)
        this.isUploadScoreLoading = false;
        this.errorUploadScore = false;
        this.closeModal();
        if(this.fixtureData.category === 'singles') {
          this.isPlayerRatingModalOpen = true;
        }
        console.log("Success: Score Uploaded");
      } catch(e) {
        this.isUploadScoreLoading = false;
        this.errorUploadScore = true;
        console.log(e)
      }
    }
  }

  async uploadScore (firebaseAddress, formData, fixtureData) {
    try {
      let getFixtureData = await firebaseAddress.get().toPromise();
      let data = getFixtureData.data();
      data.scoreBy = await this.getScoreBy(fixtureData);
      data.state = 'score-uploaded';
      data.frontEndState = 'score-uploaded';

      let teamAScore = {
        setOne: parseInt(formData.setOneA),
        setTwo: parseInt(formData.setTwoA),
      }
      let teamBScore = {
        setOne: parseInt(formData.setOneB),
        setTwo: parseInt(formData.setTwoB),
      }
      if (formData.setOneTieA) {
        teamAScore['setOneTie'] = parseInt(formData.setOneTieA);
        teamBScore['setOneTie'] = parseInt(formData.setOneTieB);
      }
      if (formData.setTwoTieA) {
        teamAScore['setTwoTie'] = parseInt(formData.setTwoTieA);
        teamBScore['setTwoTie'] = parseInt(formData.setTwoTieB);
      }
      if(formData.setThreeA) {
        teamAScore["setThree"] = parseInt(formData.setThreeA)
        teamBScore["setThree"] = parseInt(formData.setThreeB)
      }
      if (formData.setThreeTieA) {
        teamAScore['setThreeTie'] = parseInt(formData.setThreeTieA);
        teamBScore['setThreeTie'] = parseInt(formData.setThreeTieB);
      }

      // Convert empty score values to 0
      Object.keys(teamAScore).forEach(el => !teamAScore[el] ? teamAScore[el] = 0 : "");
      Object.keys(teamBScore).forEach(el => !teamBScore[el] ? teamBScore[el] = 0 : "");

      data.teamA.matchScore = teamAScore;
      data.teamB.matchScore = teamBScore;

      if(this.matchOutcome.type !== "" && this.matchOutcome.team !== "") {
        data.outcome = {};
        data.outcome.matchOutcome = this.matchOutcome;
      }

      return firebaseAddress.update(data);
    } catch(e) {
      this.isUploadScoreLoading = false;
      this.errorUploadScore = true;
      console.log(e)
    }
  }

  async getScoreBy(fixtureData) {
    if(fixtureData.category === 'singles') {
      if(fixtureData.fixture.teamA.id === this.userObj.id) {
        return 'teamA';
      } else return 'teamB'
    } else {
      const getTeams = async () => {
        return  (await this.firestore.collection('Teams', ref => ref
                .where('members', 'array-contains', { id: this.userObj.id, email: this.userObj.email }))
                .get().toPromise()).docs;
      }
      let userTeams = await getTeams();
      const matchTeamIds = (teamId) => {
        return userTeams.some(team => team.id === teamId)
      }
      if(matchTeamIds(fixtureData.fixture.teamA.id)) {
        return 'teamA';
      } else return 'teamB'
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isScoreModalOpen = false;
    this.isScoreModalOpenParent.emit(false)
    this.formGroup.reset();
    // Resetting Match Outcome Values
    this.isFormFilled = false;
    this.isIncompleteMatch = false;
    Object.keys(this.matchOutcome).forEach(el => this.matchOutcome[el] = "");
  }

  setMatchOutcome(type, event) {
    Object.keys(this.formGroup.controls).map(key => {
      this.formGroup.controls[key].setErrors(null)
    })
    this.matchOutcome = {
      type,
      team: event.target.value
    };
  }

  checkIncompleteMatch(value) {
    if(!value) {
      this.matchOutcome = { type: "", team: "" };
      Object.keys(this.formGroup.controls).forEach(key => {
        if(!this.formGroup.controls[key].value) {
          this.formGroup.controls[key].setErrors({'incorrect': true});
        }
      })
    };
  }

  ngOnInit(): void {
    this.initializeScoreForm();
  }

}
