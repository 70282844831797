<div class="nav-main container-fluid" *ngIf="!router.url.includes('create-profile')" (window:resize)="onResize($event)">
  <div class="container">
    <nav class="navbar navbar-expand-md">
      <div class="responsive-msg-notify-icon" *ngIf="authService.authenticated() && showResponsiveIcons">
        <ul>          
          <ng-container *ngTemplateOutlet="messagesElement"></ng-container>
          <ng-container *ngTemplateOutlet="notificationsElement"></ng-container>
        </ul>
      </div>
      <button class="navbar-toggler" type="button" (click)="showNavbar = !showNavbar" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </button>
      <div class="navbar-collapse" [ngClass]="showNavbar ? 'show-nav' : 'hide-nav'" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto" [ngClass]="authService.authenticated() ? 'logged-in' : 'not-logged-in'">
          <li class="nav-item underline active">
            <a class="nav-link" [routerLink]="['/tennis']">
              <i class="icon ico-tennis-home"></i></a>
          </li>
          <li class="nav-item underline">
            <a class="nav-link" [routerLink]="['/tennis/about-us']">About Us</a>
          </li>
          <li class="nav-item underline">
            <a class="nav-link" [routerLink]="['/tennis/lets-play']">Let's Play</a>
          </li>
          <li class="nav-item underline">
            <a class="nav-link" [routerLink]="['/tennis/faqs']">FAQs</a>
          </li>
          <li class="nav-item underline">
            <a class="nav-link" [routerLink]="['/contact-us']">Contact Us</a>
          </li>
          <li *ngIf="currUser && currUser.data().isAdmin" class="nav-item underline">
            <a class="nav-link" [routerLink]="['/admin']">Admin Panel</a>
          </li>
          <ng-container *ngIf="authService.authenticated() && !showResponsiveIcons">
            <ng-container *ngTemplateOutlet="messagesElement"></ng-container>
            <ng-container *ngTemplateOutlet="notificationsElement"></ng-container>
          </ng-container>
          <li class="nav-item underline">
            <a  
              class="nav-link" *ngIf="authService.authenticated(); else showLogin"
              [routerLink]="['/tennis/user/account']">
                {{authService?.user?.displayName}}
                <i class="icon ico-user header-menu"></i></a>
            <ng-template #showLogin>
              <a
                class="nav-link login"
                id="login"
                (click)="router.navigate(['tennis/users/login', {redirectTo: this.router.url}])"
              >Log In <i class="icon ico-user header-menu"></i></a>
            </ng-template>
          </li>
          <li class="nav-item underline" (click)="logout()" *ngIf="authService.authenticated()">
            <a class="nav-link">Logout</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div *ngIf="isCollapsed" class="dropdown-menu">
    <div class="container menu">
      <a class="dropdown-item" [routerLink]="['/tennis']"><i class="icon ico-tennis"></i> Tennis</a>
      <a class="dropdown-item" [routerLink]="['/boxing']"><i class="icon ico-tennis"> </i> Boxing</a>
      <a class="dropdown-item" [routerLink]="['/ping-pong']"><i class="icon ico-tennis"></i> Ping-pong</a>
      <a class="dropdown-item"><i class="icon ico-tennis"></i> Another</a>
    </div>
  </div>
</div>

<ng-template #messagesElement>
  <li class="nav-item notify-img messages" [routerLink]="['/tennis/user/messages']">
    <a class="nav-link"><img [src]="isChatsUnRead ? '../../../assets/image/messages-unread.webp' : '../../../assets/image/messages.webp'" alt="message"></a>
  </li>
</ng-template>
<ng-template #notificationsElement>
  <li appClickOutside (clickOutside)="isShowNotification = false" (click)="isShowNotification = !isShowNotification" class="nav-item notify-img notifications">
    <a #notificationAnchor class="nav-link">
      <img #notificationImg  [src]="isNotificationsUnRead ? '../../../assets/image/bell-unread.webp' : '../../../assets/image/bell.webp'" alt="bell">
    </a>
    <div class="notification-box"  [ngStyle]="{'display': isShowNotification ? 'block' : 'none'}">
      <div class="notification-box-inner" *ngIf="isNotifications else noNotifications">
        <div  class="notification"
              *ngFor="let notification of notifications; let i = index;"
              (click)="goToNotificationLink(notification.redirectLink, i)"
              [ngClass]="notification.isRead === true ? 'read' : 'unread'">
          <div class="title" [ngStyle]="{'font-weight': !notification.isRead ? '500' : 'normal'}">{{ notification.title }}</div>
          <div class="date-time">{{ getNotificationTime(notification.timestamp) }}</div>
        </div>
      </div>
      <ng-template #noNotifications>
        <div class="alert mb-0">You have no notifications.</div>
      </ng-template>   
    </div>
  </li>
</ng-template>
