import { ProfileService } from './../../services/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/internal/operators';

@Component({
  selector: 'app-player-tennis-challenges',
  templateUrl: './player-tennis-challenges.component.html',
  styleUrls: ['./player-tennis-challenges.component.scss']
})
export class PlayerTennisChallengesComponent implements OnInit {

  currUrl: string;
  hasNoUpcomingMatches: boolean;
  isDataLoaded: boolean;
  allMatches;

  areMatchesLoaded: boolean;
  upcomingMatches;
  ongoingMatches;

  isOpenForChallenge: boolean;
  isToggleChallengeLoading: boolean = true;
  wins: any[] = [];
  loses: any[] = [];
  winRatio: string;
  challenges: any[];
  completedChallenges: number;
  totalMatches: number = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private profileService: ProfileService) { }

  watchUrl() {
    this.currUrl = this.router.url;
    this.activatedRoute.url.subscribe(val => {
      this.currUrl = this.router.url;
    })
  }

  toggleChallenge() {
    this.isOpenForChallenge = !this.isOpenForChallenge
    this.updateChallengeStatus();
  }

  updateChallengeStatus() {
    this.firestore.collection('Users').doc(this.authService.user.uid).update({
      openForChallenge: this.isOpenForChallenge
    })
      .then(res => {
        console.log("Status Updated")
      })
      .catch(error => {
        this.isOpenForChallenge = !this.isOpenForChallenge
        console.log(error)
      });
  }

  async ngOnInit() {
    this.watchUrl();
    let user = await this.authService.authState.pipe(first()).toPromise();
    if (user) {
      let tournaments = await this.firestore.collection('Users').doc(this.authService.user.uid).collection('history').get().toPromise()
      if(tournaments.docs.length > 0) {
        let allMatches: any[] = await Promise.all(tournaments.docs.map(async el => {
          this.totalMatches += 1;
          let matchId = el.data().matchRef.split('/')[2];
          let match = await this.firestore.collection('Matches').doc(el.data().match)
                    .get().toPromise()
          if(match.exists && match.data().category === 'singles' && match.data().matchType === 'one-on-one') {
            let fixtureData = await this.firestore.collection('Matches').doc(matchId)
            .collection('fixtures').doc('fixture1').get().toPromise()
            return {
              fixtureData: fixtureData.data(),
              ...match.data(),
              title: 'One On One',
              location: 'No Location',
              prize: 'No Prize',
              matchUrl: `/tennis/tournaments/${el.data().match}`
            }
          }
        }))
        allMatches = allMatches.filter(Boolean)
        if(allMatches.length > 0) {
          this.challenges = allMatches;
          this.completedChallenges = this.challenges.length;
          this.wins = allMatches.filter((el: any) => el.fixtureData[el.fixtureData.winner].id === this.authService.user.uid);
          this.loses = allMatches.filter((el: any) => el.fixtureData[el.fixtureData.winner].id !== this.authService.user.uid);
          this.winRatio = `${ Math.trunc((this.wins.length / this.completedChallenges) * 100) }%`;
        } else {
          this.completedChallenges = 0
          this.wins = [];
          this.loses = [];
          this.winRatio = `0%`;
        }
      } else {
        this.completedChallenges = 0
        this.wins = [];
        this.loses = [];
        this.winRatio = `0%`;
      }
    }

    this.profileService.getUserProfileData()
    .then(res => {
      this.isToggleChallengeLoading = false;
      if(typeof res.data().openForChallenge !== 'undefined') {
        if(res.data().openForChallenge) {
          this.isOpenForChallenge = true;
        } else {
          this.isOpenForChallenge = false;
        }
      } else {
        this.isOpenForChallenge = false;
      }
    })
    .catch(error => {
      this.isToggleChallengeLoading = false;
      console.log(error)
    })
  }
}