import { AuthService } from 'src/app/services/auth/auth.service';
import { first } from 'rxjs/internal/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-tennis-tournaments',
  templateUrl: './player-tennis-tournaments.component.html',
  styleUrls: ['./player-tennis-tournaments.component.scss']
})
export class PlayerTennisTournamentsComponent implements OnInit {

  hasSingleMatches: boolean;
  tournaments: any[];
  doubleTournaments: any[];
  completedMatches: number;
  wins: any[] = [];
  loses: any[] = [];
  winRatio: string;
  showSingles: boolean = true;
  showDoubles: boolean;
  hasDoubleMatches: boolean;

  constructor(private router: Router,
              private firestore: AngularFirestore,
              private authService: AuthService) { }

  async findSinglesMatches() {
    try {
      let tournaments = await this.firestore.collection('Users').doc(this.authService.user.uid).collection('history').get().toPromise()
      if(tournaments.docs.length > 0) {
        let allMatches: any[] = await Promise.all(tournaments.docs.map(async el => {
          let matchId = el.data().matchRef.split('/')[2];
          let match = await this.firestore.collection('Matches').doc(matchId)
                      .get().toPromise()
          if(match.exists && match.data().category === 'singles') {
            let fixtureData;
            if(match.data().matchType === 'one-on-one' || match.data().matchType === 'box') {
              let fixture = el.data().matchRef.split('/')[4];
              fixtureData = await this.firestore.collection('Matches').doc(matchId)
                                .collection('fixtures').doc(fixture).get().toPromise()
            } else if(match.data().matchType === 'knockout') {
              let roundType = el.data().matchRef.split('/')[3]
              let round = el.data().matchRef.split('/')[4]
              let fixture = el.data().matchRef.split('/')[6];
              fixtureData = await this.firestore.collection('Matches').doc(matchId).collection(roundType)
                                .doc(round).collection('fixtures').doc(fixture).get().toPromise()
            }
            return {
              fixtureData: fixtureData.data(),
              title: match.data().matchType === 'one-on-one' ? 'One On One' : match.data().title,
              location: match.data().location ? match.data().location : 'No Location',
              prize: match.data().entryFee ? match.data().entryFee * match.data().players.length * 0.85 : 'No Prize',
              matchId,
            }
          }
        }))
        allMatches = allMatches.filter(el => el?.fixtureData)
        if(allMatches.length > 0) {
          this.tournaments = allMatches;
          this.completedMatches = this.tournaments.length;
          this.wins = allMatches.filter((el: any) => el.fixtureData[el.fixtureData.winner].id === this.authService.user.uid);
          this.loses = allMatches.filter((el: any) => el.fixtureData[el.fixtureData.winner].id !== this.authService.user.uid);
          this.winRatio = `${ Math.trunc((this.wins.length / this.completedMatches) * 100) }%`;
          this.hasSingleMatches = true;
        } else {
          this.completedMatches = 0
          this.wins = [];
          this.loses = [];
          this.winRatio = `0%`;
          this.hasSingleMatches = false;
        }
      } else {
        this.completedMatches = 0
        this.wins = [];
        this.loses = [];
        this.winRatio = `0%`;
        this.hasSingleMatches = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

  async findDoublesMatches() {
    try {
      let teams = await this.firestore.collection('Teams', ref => ref.
                  where('members', 'array-contains', { email: this.authService.user.email, id: this.authService.user.uid }))
                  .get().toPromise()
      if(teams.docs.length > 0) {
        let teamsData = await Promise.all(teams.docs.map(async el => {
          let teamHistory = await el.ref.collection('history').get()
          if(teamHistory.docs.length > 0) {
            let allMatches: any[] = await Promise.all(teamHistory.docs.map(async el2 => {
              let matchId = el2.data().matchRef.split('/')[2];
              let match = await this.firestore.collection('Matches').doc(matchId)
                          .get().toPromise()
              if(match.data().category === 'doubles') {
                let fixtureData;
                if(match.data().matchType === 'one-on-one' || match.data().matchType === 'box') {
                  let fixture = el2.data().matchRef.split('/')[4];
                  fixtureData = await this.firestore.collection('Matches').doc(matchId)
                                    .collection('fixtures').doc(fixture).get().toPromise()
                } else if(match.data().matchType === 'knockout') {
                  let roundType = el2.data().matchRef.split('/')[3]
                  let round = el2.data().matchRef.split('/')[4]
                  let fixture = el2.data().matchRef.split('/')[6];
                  fixtureData = await this.firestore.collection('Matches').doc(matchId).collection(roundType)
                                    .doc(round).collection('fixtures').doc(fixture).get().toPromise()
                }
                return {
                  teamId: el.id,
                  fixtureData: fixtureData.data(),
                  title: match.data().matchType === 'one-on-one' ? 'One On One' : match.data().title,
                  location: match.data().location ? match.data().location : 'No Location',
                  prize: match.data().entryFee ? match.data().entryFee * match.data().players.length * 0.85 : 'No Prize',
                  matchId: matchId
                }
              }
            }))
            return allMatches.filter(Boolean)[0];
          }
        }))
        if(teamsData.filter(Boolean).length > 0) {
          this.doubleTournaments = teamsData.filter(Boolean)
          this.hasDoubleMatches = true;
        } else {
          this.hasDoubleMatches = false;
        } 
      } else {
        this.hasDoubleMatches = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

  async ngOnInit() {
    let user = await this.authService.authState.pipe(first()).toPromise();
    if(user) {
      this.findSinglesMatches();
      this.findDoublesMatches();
    }
  }
}
