import { AngularFireFunctions } from '@angular/fire/functions';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Options } from 'ng5-slider';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})

export class CreateProfileComponent implements OnInit {

  formProfile: FormGroup;
  submitAttempt: boolean = false;
  currUserObj;
  loading = false;
  profileUpdated = false;

  sliderValue: number = 1200;
  sliderOptions: Options;

  latitude: number;
  longitude: number;
  address: string;
  zoom;
  search: string = '';

  timer: number = 5;
  imgRef: AngularFireStorageReference;
  imgUploadTask;
  imgUrl;
  img;
  cities;

  constructor(
    public authService: AuthService,
    public profileService: ProfileService,
    public firestore: AngularFirestore,
    public fireStorage: AngularFireStorage,
    public fireFunctions: AngularFireFunctions, 
    private router: Router,
    private http: HttpClient) {}

  initializeForm() {
    this.formProfile = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl(''),
      phone: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(''),
      handness: new FormControl('', Validators.required),
      weight: new FormControl(''),
      height: new FormControl('', Validators.required),
      bio: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      wheelChair: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
    })

    this.formProfile.disable();
  }

  async onFileSelected(event) {
    this.img = event.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.img)
    reader.onload = (_event) => {
      this.currUserObj.profileImg = reader.result;
    }
  }

  initializeExperienceSlider() {
    this.sliderOptions = {
      floor: 200,
      ceil: 2000,
      step: 100,
      showTicks: true,
      showTicksValues: false,
      showSelectionBar: true,
      vertical: window.innerWidth < 768 ? true : false,
      ticksTooltip: (v: number) => {
        if(v === 10) return 'Very strong junior'
        if(v === 15) return 'Adult tournament player'
        if(v === 20) return 'ATP World ranked no. 1'
        return '';
      },
      stepsArray: [
        {value: 200, legend: 'Beginner'},
        {value: 300},
        {value: 400},
        {value: 500},
        {value: 600},
        {value: 700, legend: 'Intermediate'},
        {value: 800},
        {value: 900},
        {value: 1000},
        {value: 1100},
        {value: 1200, legend: 'Advanced'},
        {value: 1300},
        {value: 1400},
        {value: 1500},
        {value: 1600},
        {value: 1700, legend: 'Strong'},
        {value: 1800},
        {value: 1900},
        {value: 2000},
        {value: 2100},
        {value: 2200, legend: 'Best'},
      ]

    };
  }

  async updateProfile(formProfile) {
    this.submitAttempt = true;

    if(formProfile.valid) {
      let form = this.formProfile.value;
        if(this.authService.authenticated()) {
          
        // Uploading Img
        if(this.img) {
          const id = Math.random().toString(36).substring(2);
          this.imgRef = this.fireStorage.ref('profile-pictures/' + id);
          try {
            let uploadImg = await this.imgRef.put(this.img)
            let downloadURL = await uploadImg.ref.getDownloadURL();
            this.currUserObj.profileImg = downloadURL;
            await this.firestore.collection('Users').doc(this.authService.user.uid).update({
              profileImg: downloadURL
            })
          } catch(e) {
            console.log(e)
          }
        }
        // Uploading User Data
        try {
          await this.firestore.collection('Users').doc(this.authService.user.uid).update({
            firstName: form.firstName,
            lastName: form.lastName,
            phone: form.phone,
            handness: form.handness,
            weight: form.weight ? form.weight : null,
            height: form.height,
            gender: form.gender,
            wheelChair: form.wheelChair,
            location: [{
              isPrimaryLocation: true,
              latitude: this.latitude,
              longitude: this.longitude,
              address: this.address,
              city: form.city,
              country: form.country,
            }],
            bio: form.bio,
            firstTimeLogin: false,
            victorPoints: this.sliderValue,
            wallet: 0,
            earnings: 0,
            openForChallenge: false,
          })
    
          this.profileUpdated = true;
          setTimeout(() => {
            this.router.navigate([`/tennis/user/account`])
          }, parseInt(""+this.timer + "000"))
          setInterval(() => {
            this.timer--;
          }, 1000)
        } catch(e) {
          console.log(e)
        }
      }
    } 
  }

  getProfileData() {
    this.profileService.getUserProfileData()
    .then(res => {
      this.currUserObj = res.data();
      this.currUserObj.profileImg = '../../assets/image/no-img.webp'
      this.setFormValues();
    })
    .catch(error => {
      console.log(error)
    })
  }

  setFormValues() {
    this.formProfile.patchValue({
      email: this.currUserObj.email,
      dateOfBirth: this.currUserObj.dateOfBirth,  
      firstName: this.currUserObj.firstName,
      lastName: this.currUserObj.lastName,
      phone: this.currUserObj.phone
    })
    this.formProfile.enable()
    this.formProfile.controls.email.disable();
    this.formProfile.controls.dateOfBirth.disable();
  }

  
  getLocationMap(cityObj) {
    let city = cityObj.city
    this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${city}+australia&key=${environment.firebaseConfig.apiKey}`, {}).toPromise()
    .then((res: any) => {
      this.latitude = res.results[0].geometry.location.lat;
      this.longitude = res.results[0].geometry.location.lng;
      this.address = res.results[0].formatted_address.split(',').slice(0, 3).join(',');
    })
    .catch(error => {
      console.log(error)
    })
  }

  async ngOnInit() {
    this.getProfileData();
    this.initializeForm();
    this.initializeExperienceSlider();
    try {
      let citiesFunction = this.fireFunctions.httpsCallable('cities-getCities')
      this.cities = await citiesFunction({}).toPromise()
    } catch(e) {
      console.log(e)
    }
  }

}
