<div class="detail-box">
  <ng-container *ngIf="isLoading">
    <div class="loader">
      <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <div class="games-history-details" *ngIf="isGamesFound else noHistory">
      <div class="game" *ngFor="let match of matches; let i = index">
        <div class="match-details">
          <div class="match-type" [routerLink]="['/tennis/tournaments/'+match.matchId]">
           {{ match.title }}
          </div>
          <div class="date">{{ match.startDate }}</div>
          <div class="location">{{ match.location }}</div>
        </div>

        <div class="players">
          <div class="player">
            <div class="img-block"><img [src]="match.fixtureData.teamA.profileImg" alt="no-img"></div>
            <div class="text-block">
              <div class="name">{{ match.fixtureData.teamA.firstName }} <span class="last-name">{{ match.fixtureData.teamA.lastName }}</span></div>
              <div class="points">
                <div *ngIf="match.fixtureData.winner === 'teamA'" class="trophy-icon"><img src="../../../assets/image/trophy.webp" alt="trophy"></div>
                <ng-container *ngIf="match.fixtureData.teamA.newScore">
                  <div class="point">{{ match.fixtureData.teamA.newScore.setOne }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.newScore.setOneTie">{{ match.fixtureData.teamA.newScore.setOneTie }}</sup>
                  <div class="point">{{ match.fixtureData.teamA.newScore.setTwo }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.newScore.setTwoTie">{{ match.fixtureData.teamA.newScore.setTwoTie }}</sup>
                  <div class="point" *ngIf="match.fixtureData.teamA.newScore.setThree">{{ match.fixtureData.teamA.newScore.setThree }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.newScore.setThreeTie">{{ match.fixtureData.teamA.newScore.setThreeTie }}</sup>
                </ng-container>
                <ng-container *ngIf="!match.fixtureData.teamA.newScore">
                  <div class="point">{{ match.fixtureData.teamA.matchScore.setOne }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.matchScore.setOneTie">{{ match.fixtureData.teamA.matchScore.setOneTie }}</sup>
                  <div class="point">{{ match.fixtureData.teamA.matchScore.setTwo }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.matchScore.setTwoTie">{{ match.fixtureData.teamA.matchScore.setTwoTie }}</sup>
                  <div class="point" *ngIf="match.fixtureData.teamA.matchScore.setThree">{{ match.fixtureData.teamA.matchScore.setThree }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.matchScore.setThreeTie">{{ match.fixtureData.teamA.matchScore.setThreeTie }}</sup>
                </ng-container>
                <div class="point" *ngIf="match.fixtureData.teamA.setThree">{{ match.fixtureData.teamA.setThree }}</div>
              </div>
            </div>
          </div>
          <div class="player">
            <div class="img-block"><img [src]="match.fixtureData.teamB.profileImg" alt="no-img"></div>
            <div class="text-block">
              <div class="name">{{ match.fixtureData.teamB.firstName }} <span class="last-name">{{ match.fixtureData.teamB.lastName }}</span></div>
              <div class="points">
                <div *ngIf="match.fixtureData.winner === 'teamB'" class="trophy-icon"><img src="../../../assets/image/trophy.webp" alt="trophy"></div>
                <ng-container *ngIf="match.fixtureData.teamA.newScore">
                  <div class="point">{{ match.fixtureData.teamB.newScore.setOne }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.newScore.setOneTie">{{ match.fixtureData.teamB.newScore.setOneTie }}</sup>
                  <div class="point">{{ match.fixtureData.teamB.newScore.setTwo }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.newScore.setTwoTie">{{ match.fixtureData.teamB.newScore.setTwoTie }}</sup>
                  <div class="point" *ngIf="match.fixtureData.teamA.newScore.setThree">{{ match.fixtureData.teamB.newScore.setThree }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.newScore.setThreeTie">{{ match.fixtureData.teamB.newScore.setThreeTie }}</sup>
                </ng-container>
                <ng-container *ngIf="!match.fixtureData.teamA.newScore">
                  <div class="point">{{ match.fixtureData.teamB.matchScore.setOne }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.matchScore.setOneTie">{{ match.fixtureData.teamB.matchScore.setOneTie }}</sup>
                  <div class="point">{{ match.fixtureData.teamB.matchScore.setTwo }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.matchScore.setTwoTie">{{ match.fixtureData.teamB.matchScore.setTwoTie }}</sup>
                  <div class="point" *ngIf="match.fixtureData.teamA.matchScore.setThree">{{ match.fixtureData.teamB.matchScore.setThree }}</div>
                  <sup class="point tie-breaker" *ngIf="match.fixtureData.teamA.matchScore.setThreeTie">{{ match.fixtureData.teamB.matchScore.setThreeTie }}</sup>
                </ng-container>
                <div class="point" *ngIf="match.fixtureData.teamB.setThree">{{ match.fixtureData.teamB.setThree }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="isError" class="alert alert-danger">Could not load. Please refresh the page</div>
</div>

<ng-template #noHistory>
  <div class="alert">You haven't completed any matches yet</div>
</ng-template>
