<div class="shade">
  <div class="bg-layer inner-page-background">
    <div class="container">
      <div class="row">
        <div class="inner-nav">
          <div class="action-links action-links-mobile">
            <ul>
              <li><a [routerLink]="['/tennis/players']">Players</a></li>
              <li><a [routerLink]="['/tennis/tournaments']">Tournaments</a></li>
              <li><a [routerLink]="['/tennis/user/dashboard']">Dashboard</a></li>
            </ul>
          </div>
          <div class="logo-block float-left"><img src="../../assets/image/logo.webp" alt="logo"></div>
          <ul class="social-links float-right">
            <li class="link">
              <a target="_blank" href="https://www.facebook.com/viictorsports"><i class="icon ico-fb"></i></a>
            </li>
            <li class="link">
              <a target="_blank" href="https://www.instagram.com/viictor_sports/"><i class="icon ico-instagram"></i></a>
            </li>
            <li class="link">
              <a target="_blank" href="https://www.linkedin.com/company/viictor/"><i class="icon ico-linkedin"></i></a>
            </li>
          </ul>
          <div class="action-links float-right">
            <ul>
              <li><a [routerLink]="['/tennis/players']">Players</a></li>
              <li><a [routerLink]="['/tennis/tournaments']">Tournaments</a></li>
              <li><a [routerLink]="['/tennis/user/dashboard']">Dashboard</a></li>
            </ul>
          </div>
          <div class="clearfix spacer"></div>
          <header class="page-title ommited-spacing">
            <h1 class="page-heading3"><span>Start Playing Now</span></h1>
            <h2 class="page-heading2">
              <span> <i>accept</i></span
              ><span>challenge</span>
            </h2>
          </header>
        </div>
      </div>
    </div>
  </div>
</div>
