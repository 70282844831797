import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { first } from 'rxjs/internal/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-player-tennis-dashboard',
  templateUrl: './player-tennis-dashboard.component.html',
  styleUrls: ['./player-tennis-dashboard.component.scss']
})
export class PlayerTennisDashboardComponent implements OnInit {

  userObj: any;

  constructor(private firestore: AngularFirestore,
              private authService: AuthService) { }

  async ngOnInit() {
    let user = await this.authService.authState.pipe(first()).toPromise();
    if(user) {
      this.userObj = (await this.firestore.collection('Users').doc(user.uid).get().toPromise()).data();
      this.userObj.id = user.uid;
    }
  }

}
