import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  submitAttempt = false;
  loading: boolean = false;
  errorMessageBackend = '';
  currUserInfoObj;
  currUserObj;
  isEmailVerified: boolean;
  state;
  restrictedAccess: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private firestore: AngularFirestore) {
    this.formGroup = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])],
    });
  }

  login(formGroup) {
    this.errorMessageBackend = '';
    let form = formGroup.value;
    this.submitAttempt = true;
    if(formGroup.valid) {
      this.loading = true;
      this.authService.login(form.email, form.password)
      .then((userObj) => {
        if(userObj.user.emailVerified) {
          this.isEmailVerified = true;
          this.getUserData(userObj)
        } else {
          this.isEmailVerified = false;
          this.authService.logout();
        }
      })
      .catch(error => {
        this.loading = false;
        console.log(error)
        if (error.code === 'auth/wrong-password') {
          this.errorMessageBackend = 'Invalid email or password';
        } else if (error.code === 'auth/user-not-found') {
          this.errorMessageBackend = 'User not found';
        } else if (error.code === 'auth/too-many-requests') {
          this.errorMessageBackend = 'Too many unsuccessful login attempts. Please try again later';
        }
      })
    }
  } 


  async getUserData(userObj) {
    try {
      let uid = userObj.user.uid;
      let user = await this.firestore.collection('Users').doc(uid).get().toPromise();
      this.currUserInfoObj = user;
      this.currUserObj = user.data();
      if(this.currUserObj.state === 'not-permitted' ) {
        this.restrictedAccess = true;
        this.state = 'not-permitted';
        this.authService.logout()
      } else if(this.currUserObj.state === 'pending' ) {
        this.restrictedAccess = true;
        this.state = 'pending';
        this.authService.logout();
      } else {
        if(this.currUserObj.firstTimeLogin === true) {
          this.router.navigateByUrl('/tennis/create-profile');  
        } else {
          let redirectUrl = this.activatedRoute.snapshot.url[0].parameters.redirectTo;
          if(redirectUrl) {
            let url = redirectUrl.includes('/tennis/home') ? '/tennis/user/dashboard' : redirectUrl
            this.router.navigateByUrl(url)
          } else {
            this.router.navigate(['/tennis/home'])
          }
        }
      }
    } catch(e) {
      console.log(e)
    }
  }

  sendEmail() {
    this.loading = true;
    this.firestore.collection('Users').doc(this.currUserInfoObj.id).update({
      state: 'pending',
      isTokenValid: true
    })
    .then(() => {
      this.loading = false;
      this.state = 'not-permitted-pending'
    })
  }

  goToHome() {
    this.router.navigate(['tennis/home'])
  }

  ngOnInit() {}

}
