<div class="nav-tabs">
  <div class="container">
    <ul>
      <li [ngClass]="{'tab-active': this.currUrl.includes('/tennis/user/dashboard')}">
        <a [routerLink]="['/tennis/user/dashboard']"><span>Dashboard</span>
          <div class="responsive-icon" [ngStyle]="{'fill': this.currUrl.includes('/tennis/user/dashboard') ? '#8AE134' : '#d4d4d4'}" [inlineSVG]="'../../assets/image/dashboard.svg'"></div>
        </a></li>
      
      <li [ngClass]="{'tab-active': this.currUrl.includes('/tennis/user/account')}">
        <a [routerLink]="['/tennis/user/account']"><span>Profile</span>
          <div class="responsive-icon" [ngStyle]="{'fill': this.currUrl.includes('/tennis/user/account') ? '#8AE134' : '#d4d4d4'}" [inlineSVG]="'../../assets/image/profile.svg'"></div>
        </a></li>
      
      <li [ngClass]="{'tab-active': this.currUrl.includes('/tennis/user/wallet')}">
        <a [routerLink]="['/tennis/user/wallet']"><span>Wallet</span>
          <div class="responsive-icon" [ngStyle]="{'fill': this.currUrl.includes('/tennis/user/wallet') ? '#8AE134' : '#d4d4d4'}" [inlineSVG]="'../../assets/image/money.svg'"></div>
        </a></li>
      
      <li [ngClass]="{'tab-active': this.currUrl.includes('/tennis/user/challenges')}">
        <a [routerLink]="['/tennis/user/challenges']"><span>Challenges</span>
          <div class="responsive-icon" [ngStyle]="{'fill': this.currUrl.includes('/tennis/user/challenges') ? '#8AE134' : '#d4d4d4'}" [inlineSVG]="'../../assets/image/fight.svg'"></div>
        </a></li>
      
      <li [ngClass]="{'tab-active': this.currUrl.includes('/tennis/user/teams')}">
        <a [routerLink]="['/tennis/user/teams']"><span>Teams</span>
          <div class="responsive-icon" [ngStyle]="{'fill': this.currUrl.includes('/tennis/user/teams') ? '#8AE134' : '#d4d4d4'}" [inlineSVG]="'../../assets/image/team.svg'"></div>
        </a></li>
      
      <li [ngClass]="{'tab-active': this.currUrl.includes('/tennis/user/tournaments')}">
        <a [routerLink]="['/tennis/user/tournaments']"><span>Tournaments</span>
          <div class="responsive-icon" [ngStyle]="{'fill': this.currUrl.includes('/tennis/user/tournaments') ? '#8AE134' : '#d4d4d4'}" [inlineSVG]="'../../assets/image/tournament.svg'"></div>
        </a></li>
    </ul>
  </div>
</div>
