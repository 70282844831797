import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users = [];
  isUsersFound: boolean;
  isLoading: boolean;

  constructor(private firestore: AngularFirestore) { }

  async collectUsers() {
    try {
      let usersSnapshot = await this.firestore.collection('Users', ref => ref
                          .orderBy('firstName', 'asc')).get().toPromise();
      if(usersSnapshot.docs.length > 0) {
        let usersDocs = usersSnapshot.docs;
        let users = usersDocs.map(user => {
          let userData: any = {};
          userData = user.data();
          userData.id = user.id;
          return userData
        })
        this.users = users;
        this.isUsersFound = true;
      }
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

  async toggleBan(userId, index) {
    this.users[index].isProfileActive = !this.users[index].isProfileActive;
    try {
      let ban = await this.firestore.collection('Users').doc(userId).update({
        isProfileActive: this.users[index].isProfileActive
      })
    } catch(e) {
      console.log(e)
      this.users[index].isProfileActive = !this.users[index].isProfileActive;      
    }
  }

  async addRemoveFlag(mode, userId, index) {
    let previousFlagState = this.users[index].flagCount ? this.users[index].flagCount : 0;
    let user = this.users[index];
    if(mode === 'add') {
      if(user.flagCount) {
        user.flagCount++;
      } else {
        user.flagCount = 1;
      }
    } else if(mode === 'remove') {
      if(user.flagCount) {
        if(user.flagCount > 0) {
          user.flagCount--;
        }
      }
    }

    try {
      await this.firestore.collection('Users').doc(userId).update({
        flagCount: user.flagCount
      })
    } catch(e) {
      console.log(e)
      this.users[index].flagCount = previousFlagState;
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.collectUsers();
  }

}
