<div class="panel">
  <div class="panel-inner">
    <h3 class="top-heading">Box Leagues</h3>
    <div class="content">
      <h4>All box league matches are best of 3 full sets (tiebreak at 6 games all) in a round robin format
        with winners determined by total wins in the group.</h4>
      <p>Box leagues can be created by a player to be played within a specific locality, specific Viictor
        rating range and with a specific entry fee which determines the prizemoney/prize pool.</p>
        <p>Players can join a box league or create their own box league.</p>
        <p>Box leagues will have a maximum of 5 players and will commence immediately when the 5
          positions are filled or at the entry deadline, whichever is sooner. In some instances, box
          leagues may contain 3 or 4 players.</p>
        <p>If the entry deadline is reached and only 2 players have entered then this will become a
          Head 2 Head Challenge.</p>
        <p>Each round of the tournament is allocated and players will endeavour to play the matches at
          their earliest convenience.</p>
        <p>The box league is managed online. The draw is formulated for your local region. Players will
          enter their own results directly into the system.</p>
        <p>Viictor box leagues are not played at one central tournament location. The draw is
          automatically formulated and players are given a home/away status. This status determines
          where your match will be played. Each player will list his or her possible home court
          locations.</p>
        <p>Viictor box leagues will operate in conjunction with the Viictor rating system and
          <a [routerLink]="['/tennis/about-us/viictor-rankings']">Viictor ranking system</a>.</p>
        <p>Prizemoney for Open aged box leagues and prizes for junior age groups will be awarded to
          player reaching the winning positions within the tournament!</p>
        <p>Viictor automatically deducts 15% from all entry fees. Remaining 85% of entry fees/monies
          goes into the prize money pot for winners of that tournament.</p>
        <p>All 11 & Under events are green ball events. Please use green dot balls for 11/u matches. If
          you don't have green dot balls please discuss and arrange with your opponent prior to your
          match. (If both players don't have green dot balls and agree to play with regular balls then
          that is ok).</p>
        <p>Will you be the Viictor?</p>
    </div>
  </div>
</div>
