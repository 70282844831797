<section *ngIf="isScoreModalOpen && fixtureData" class="modal">
  <div class="modal-background">
    <div class="modal-body">
      <div class="heading">Add Score</div>

      <div class="player" [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">
        <span>Player One</span>
        {{ fixtureData.fixture.teamA.name }}
        <ng-container *ngIf="matchOutcome && matchOutcome.type !== '' && matchOutcome.team !== ''">
          <span class="winner" *ngIf="matchOutcome.team === 'teamB'">
            <strong>- Winner</strong><img src="../../../assets/image/trophy.webp" alt="trophy">
          </span>
        </ng-container>
      </div>

      <div class="input-fields">
        <div class="fields-inner">
          <form [formGroup]="formGroup">
            <div class="set set-one">
              <div class="set-not-tie">
                <input type="text" formControlName="setOneA" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneA.valid, 'error': formGroup.controls.setOneA.touched && formGroup.controls.setOneA.invalid}">
                <input type="text" formControlName="setOneB" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneB.valid, 'error': formGroup.controls.setOneB.touched && formGroup.controls.setOneB.invalid}">
              </div>
              <div class="set-tie" *ngIf="isSetOneTie">
                <div class="tie-breaker">Tie Breaker</div>
                <input type="text" formControlName="setOneTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneTieA.valid, 'error': formGroup.controls.setOneTieA.touched && formGroup.controls.setOneTieA.invalid}">
                <input type="text" formControlName="setOneTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setOneTieB.valid, 'error': formGroup.controls.setOneTieB.touched && formGroup.controls.setOneTieB.invalid}">
              </div>
              <div class="set-label">Set One</div>
            </div>
            <div class="set set-two">
              <div class="set-not-tie">
                <input type="text" formControlName="setTwoA" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoA.valid, 'error': formGroup.controls.setTwoA.touched && formGroup.controls.setTwoA.invalid}">
                <input type="text" formControlName="setTwoB" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoB.valid, 'error': formGroup.controls.setTwoB.touched && formGroup.controls.setTwoB.invalid}">
              </div>
              <div class="set-tie" *ngIf="isSetTwoTie">
                <div class="tie-breaker">Tie Breaker</div>
                <input type="text" formControlName="setTwoTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoTieA.valid, 'error': formGroup.controls.setTwoTieA.touched && formGroup.controls.setTwoTieA.invalid}">
                <input type="text" formControlName="setTwoTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setTwoTieB.valid, 'error': formGroup.controls.setTwoTieB.touched && formGroup.controls.setTwoTieB.invalid}">
              </div>
              <div class="set-label">Set Two</div>
            </div>
            <div *ngIf="isSetThree" class="set set-three">
              <div class="set-not-tie">
                <input type="text" formControlName="setThreeA" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeA?.valid, 'error': formGroup.controls.setThreeA?.touched && formGroup.controls.setThreeA?.invalid}">
                <input type="text" formControlName="setThreeB" pattern="[0-7]" maxlength="1" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeB?.valid, 'error': formGroup.controls.setThreeB?.touched && formGroup.controls.setThreeB?.invalid}">
              </div>
              <div class="set-tie" *ngIf="isSetThreeTie">
                <div class="tie-breaker">Tie Breaker</div>
                <input type="text" formControlName="setThreeTieA" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeTieA?.valid, 'error': formGroup.controls.setThreeTieA?.touched && formGroup.controls.setThreeTieA?.invalid}">
                <input type="text" formControlName="setThreeTieB" pattern="[0-99]{1,2}" maxlength="2" placeholder="0"
                  [ngClass]="{'valid': formGroup.controls.setThreeTieB?.valid, 'error': formGroup.controls.setThreeTieB?.touched && formGroup.controls.setThreeTieB?.invalid}">
              </div>
              <div class="set-label">Set Three</div>
            </div>
          </form>
        </div>
      </div>

      <div class="player" [ngClass]="{'add-tie-breaker': isSetOneTie || isSetTwoTie || isSetThreeTie }">
        <span>Player Two</span>
        {{ fixtureData.fixture.teamB.name }}
        <ng-container *ngIf="matchOutcome && matchOutcome.type !== '' && matchOutcome.team !== ''">
          <span class="winner" *ngIf="matchOutcome.team === 'teamA'">
            <strong>- Winner</strong><img src="../../../assets/image/trophy.webp" alt="trophy">
          </span>
        </ng-container>
      </div>

      <hr />
      <div class="incomplete-match-check">
        <input
          type="checkbox"
          [(ngModel)]="isIncompleteMatch"
          (change)="checkIncompleteMatch(isIncompleteMatch)"
          id="incompleteMatchCheck"
        />
        <label for="incompleteMatchCheck">This match was incomplete</label>
      </div> 
      <div class="match-outcome-wrapper" *ngIf="isIncompleteMatch">
        <div class="match-outcome">
          <p>Match Outcome</p>
          <div *ngIf="!isFormFilled" class="outcome-wrapper walkover">
            <p>Walkover</p>
            <select #matchOutcome class="form-control" (change)="setMatchOutcome('walkover', $event)">
              <option value="">Select</option>
              <option [value]="'teamA'">{{ fixtureData.fixture.teamA.name }}</option>
              <option [value]="'teamB'">{{ fixtureData.fixture.teamB.name }}</option>
            </select>
          </div>
          <div *ngIf="isFormFilled" class="outcome-wrapper retired">
            <p>Retired</p>
            <select #matchOutcome class="form-control" (change)="setMatchOutcome('retired', $event)">
              <option value="">Select</option>
              <option [value]="'teamA'">{{ fixtureData.fixture.teamA.name }}</option>
              <option [value]="'teamB'">{{ fixtureData.fixture.teamB.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="loader-and-error" *ngIf="isUploadScoreLoading || errorUploadScore">
        <div class="loader"  *ngIf="isUploadScoreLoading && !errorUploadScore">
          <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
        </div>
        <div *ngIf="!isUploadScoreLoading && errorUploadScore">
          <div class="alert alert-danger">Could not upload the scores. Please refresh the page and try again.</div>
          <button type="button" class="btn-cancel" (click)="closeModal()">Close Modal</button>
        </div>
      </div>
      <div class="join-buttons" *ngIf="!isUploadScoreLoading && !errorUploadScore">
        <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
        <button type="button" (click)="addScore(formGroup)">Add Score</button>
      </div>
    </div>
  </div>
</section>

<app-upload-player-rating
  *ngIf="isPlayerRatingModalOpen"
  [fixtureData]="fixtureData"
  (isPlayerRatingModalOpen)="isPlayerRatingModalOpen = false"
></app-upload-player-rating>