import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { first } from 'rxjs/internal/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  formGroup: FormGroup;
  isLoading: boolean = true;
  loading = false;
  errorMessage = '';

  private userEmail : string;
  private userPassword : string;
  private actionCode: string;

  isUrlValid: boolean;
  passwordUpdated: boolean;


  isResetPassword = false;
  isVerifyEmail = false;
  isParentPermission = false;

  permissionOobCode: string;
  permissionGranted: boolean;

  userUid = '0000';
  userOobCode
  userObj;
  getUserName;
  getUserEmail;

  constructor(private authService: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient,
              private firestore: AngularFirestore) {
    this.formGroup = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    })
  }

  resetPassword(form) {
    this.loading = true;
    this.userPassword = form.value.newPassword;
    if(this.userEmail && this.userPassword) {
      if(this.userPassword.length > 7) {
        this.authService.getAuth().confirmPasswordReset(this.actionCode, this.userPassword)
        .then(res => {
          this.passwordUpdated = true;
        })
        .catch(error => { console.log(error) })
      } else {
        this.errorMessage = 'The length of the password must be 8 characters'
      }
    } else {
      this.errorMessage = 'Password field is required'
    }
  }

  goToHome() {
    this.router.navigateByUrl('/tennis/home');
  }

  isPermissionGranted(permission) {
    this.loading = true;
    if(this.userObj.parentApprovalToken === this.userOobCode && this.userObj.isTokenValid) {
      this.isUrlValid = true;
      if(permission === 'allow') {
        this.permissionGranted = true;
        this.firestore.collection('Users').doc(this.userUid).update({
          isTokenValid: false,
          state: 'registered'
        })
      } else {
        this.permissionGranted = false;
        this.firestore.collection('Users').doc(this.userUid).update({
          isTokenValid: false,
          state: 'not-permitted'
        })
      }
    } else {
      this.isUrlValid = false;
    }
  }

  ngOnInit() {
    if(this.activatedRoute.snapshot.queryParams['mode'] === 'parentPermission') {
      this.isParentPermission = true;
      this.userUid = this.activatedRoute.snapshot.queryParams['uId']
      this.userOobCode = this.activatedRoute.snapshot.queryParams['oobCode']

      this.firestore.collection('Users').doc(this.userUid).get().pipe(first()).toPromise()
      .then(user => {
        this.userObj = user.data();
        this.getUserName = this.userObj.firstName + " " + this.userObj.lastName
        this.getUserEmail = this.userObj.email
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      })
    }
    
    if(this.activatedRoute.snapshot.queryParams['mode'] === 'verifyEmail') {
      this.isVerifyEmail = true;
      let oobCode = this.activatedRoute.snapshot.queryParams['oobCode'];
      return this.authService.getAuth().applyActionCode(oobCode)
      .then(userEmail => {
        this.isUrlValid = true
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error)
        this.isUrlValid = false;
        this.isLoading = false;
      })  
    } 
    
    if(this.activatedRoute.snapshot.queryParams['mode'] === 'resetPassword') {
      this.isResetPassword = true;
      this.activatedRoute.queryParams.pipe(first()).toPromise()
      .then(params => {
        let mode = params['mode'];
        this.actionCode = params['oobCode']
        return this.authService.getAuth().verifyPasswordResetCode(this.actionCode); 
      })
      .then(email => { 
        this.userEmail = email;
        this.isUrlValid = true;
        this.isLoading = false;
      })
      .catch(error => { 
        this.isUrlValid = false;
        this.isLoading = false;
      })
    }
  }
}