<div class="panel">
  <div class="panel-inner">
    <h3 class="top-heading">How It Works</h3>
    <div class="content">
      <h4>ENTER, PLAY and WIN PRIZEMONEY!</h4>
      <ol>
        <li>Monthly Knockout Tournaments</li>
        <li>Box Leagues – Round Robin format with up to 5 players</li>
        <li>Head 2 Head Challenges</li>
        <li>Best of 3 sets</li>
      </ol>
      <hr>
      <ol>
        <li>First, set up your player profile and deposit a minimum of $20 into your account to
          get started. $20 is the minimum entry fee and there is no maximum entry fee (entry
          fees determine the prizemoney/prize pool) when you create your own box league or
          Head 2 Head Challenge.</li>
        <li>Choose a monthly tournament in your region/locality and enter to win prizemoney or
          prizes (juniors). <br>OR<br>
          Create or enter a box league round robin tournament within your locality, set the entry
          fee (which determines your prizemoney/prize pool), when the tournament is full, start
          playing and win prizemoney or prizes (juniors).
        </li>
      </ol>
      <p>Once you select your presumed Viictor rating it is highly recommended you first enter a
        Monthly Knockout Tournament to quickly obtain a verified Viictor Rating. You can not play
        Head 2 Head Challenges until you have a verified Viictor Rating.</p>
      <ol start="3">
        <li>You can enter more than 1 tournament at a time. Be mindful of your time restraints.</li>
        <li>Win through to the prizemoney rounds to earn money! Prize money will be
          automatically paid into your online wallet.</li>
        <li>Unlock Head 2 Head challenges - You can not play Head 2 Head Challenges until you
          have a verified Viictor Rating.</li>
      </ol>
      <h4>Where do we play?</h4>
      <p>Tournaments are not played at one central tournament location. Viictor software formulates
        the draw and players are given a home/away status at the coin toss. This status determines
        where your match will be played. Each player will list his or her home court location. If you
        are the HOME player, you can choose your home court venue to play the match. If you are the
        AWAY player then you will need to travel to the home players’ court.</p>
      <p>Matches can be played at any suitable venue upon agreement by both players.</p>
      <p>The tournament is managed online. The draw is formulated for your local region so you will
        not have to travel too far. You can set up your travel limits in your profile.</p>
      <h4>Enter the score</h4>
      <p>On completion of each match, the winner will enter the score and result of the match.</p>
      <p>The opponent must verify the score and result before the match outcome is finalised and
        published.</p>
      <h4>Progression in the tournament</h4>
      <p>Each round of the Monthly knock out tournament is allocated a time frame for all matches to
        be completed and results to be entered and verified.</p>
      <p>The tournament concept is interactive and will therefore be the player’s responsibility to
        arrange a suitable time to play their matches/round. Both players should utilise the chat
        option to connect with each other and arrange:</p>
      <ol>
        <li>The court/venue to play the match (booked and paid for by the home player. Make
          sure you book enough time to complete a best of 3 set match)</li>
        <li>The tennis balls (responsibility of the away player)</li>
        <li>The day and time to be played. Be respectful of each others commitments but be
          mindful that you need to complete the match in the given timeframe.</li>
      </ol>
      <p>If you lose your first match in a monthly knockout tournament, you will go into the
        consolation knockout draw. All players are guaranteed a minimum of 2 matches in monthly
        knockout tournaments.</p>
      <p>Keep winning and you can earn some prizemoney!</p>
      <h4>Prizemoney and Points</h4>
      <p>85% of entry fees collected for each event will go directly into the prize pool for that
        Tournament/Box League or Head 2 Head Challenge.</p>
      <p>15% of every entry fee is automatically deducted for Viictor administration to keep the
        system to the highest standard.</p>
      <p>Total prizemoney and prizemoney rounds will be clearly indicated for each tournament.
        There is no prizemoney on offer for any consolation events, just a great chance to play more
        matches and improve your Viictor rating and Viictor ranking.</p>
      <p>All monthly tournaments/box leagues and Head 2 Head challenge matches will operate in
        conjunction with the Viictor rating system and all matches played will affect the players
        Viictor rating.</p>
      <p></p>
      <p>Viictor has its own point scoring and ranking system. Monthly tournaments and Box League
        tournaments will count towards Viictor ratings &amp; rankings. Head 2 Head Challenges will
        only affect Viictor ratings.</p>
      <p>Prizes and vouchers will be awarded for winners of junior age group tournaments.</p>
      <h4>Will you be the Viictor?</h4>
    </div>
  </div>
</div>