import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor() { }

  changeThemeRed() {
    document.documentElement.style.setProperty('--base-text', '#ba2f2f');
    document.documentElement.style.setProperty('--base-text2', '#e55454');
    document.documentElement.style.setProperty('--grad-start', '#e55454');
    document.documentElement.style.setProperty('--grad-end', '#9a0c0c');
    document.documentElement.style.setProperty('--theme-text', '#e55454');
    document.documentElement.style.setProperty('--base-dark', '#3d2121');
    document.documentElement.style.setProperty('--nav-shadow', '#ff4141');
    document.documentElement.style.setProperty('--background', 'radial-gradient(circle, rgba(186, 47, 47, 0.412) 0%, rgba(0,0,0,0) 60% )');
  }

  changeThemeGreen() {
    document.documentElement.style.setProperty('--base-text', '#8ae134');
    document.documentElement.style.setProperty('--base-text2', '#a6ff47');
    document.documentElement.style.setProperty('--grad-start', '#83e720');
    document.documentElement.style.setProperty('--grad-end', '#366b02');
    document.documentElement.style.setProperty('--theme-text', '#60a31e');
    document.documentElement.style.setProperty('--base-dark', 'green');
    document.documentElement.style.setProperty('--nav-shadow', '#52ff00');
    document.documentElement.style.setProperty('--background', 'radial-gradient(circle,rgba(172, 212, 130, 0.411922268907563) 0%,rgba(0, 0, 0, 0) 60%)');
  }

  changeThemeBlue() {
    document.documentElement.style.setProperty('--base-text', '#89adcb');
    document.documentElement.style.setProperty('--base-text2', '#89adcb');
    document.documentElement.style.setProperty('--grad-start', '#93bbdb');
    document.documentElement.style.setProperty('--grad-end', '#3a6080');
    document.documentElement.style.setProperty('--theme-text', '#89adcb');
    document.documentElement.style.setProperty('--base-dark', '232b31');
    document.documentElement.style.setProperty('--nav-shadow', '#6abcff');
    document.documentElement.style.setProperty('--background', 'radial-gradient(circle, rgba(99, 163, 247, 0.412) 0%, rgba(0,0,0,0) 60% )');
  }
}
