import { AngularFirestore } from '@angular/fire/firestore';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { first } from 'rxjs/internal/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  formGroup: FormGroup;
  submitAttempt: boolean;
  isMessageSent: boolean;
  isError: boolean;
  isLoading: boolean;
  isLoggedIn: boolean;

  constructor(private authService: AuthService,
              private profileService: ProfileService,
              private firestore: AngularFirestore) { }

  async sendMessage(form) {
    this.submitAttempt = true;
    if(form.valid) {
      this.isLoading = true;
      this.formGroup.enable();
      let query: any = {}
      query = form.value;
      query.timestamp = Date.now()
      query.state = 'unresolved'
      try {
        await this.firestore.collection('Queries').add(query)
        if(this.isLoggedIn) {
          this.formGroup.controls.email.disable();
          this.formGroup.controls.name.disable();
        }
        this.isLoading = false;
        this.isMessageSent = true;
        this.isError = false;
      } catch(e) {
        this.isLoading = false;
        this.isMessageSent = false;
        this.isError = true;
        console.log(e)
      }
    }
  }

  initializeForm() {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      message: new FormControl('', Validators.required)
    })
  }

  async ngOnInit() {
    this.initializeForm();
    let user = await this.authService.authState.pipe(first()).toPromise()
    if(user) {
      this.isLoggedIn = true
      let data = (await this.profileService.getUserProfileData()).data()
      this.formGroup.patchValue({ name: data.firstName + ' ' + data.lastName, email: user.email })
      this.formGroup.controls.email.disable()
      this.formGroup.controls.name.disable()
      
    }
  }

}
