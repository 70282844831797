import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: FormGroup;
  submitAttempt = false;
  loading = false;
  sentSuccessful = false;
  errorMessage = '';

  constructor(public formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
    this.formGroup = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {
  }

  sendReset(formGroup) {
    this.loading = true;
    this.errorMessage = '';
    this.submitAttempt = true;
    let form = formGroup.value;
    if (formGroup.valid) {
      this.authService.sendPasswordResetEmail(form.email)
      .then((value) => {
        this.sentSuccessful = true;
        this.loading = false;
      })
      .catch((error) => {
        console.error(error)
        this.loading = false;
        if (error.code === 'auth/user-not-found') {
          this.errorMessage = 'There is no user registered with the above email.';
        } else {
          this.errorMessage = 'There was some error while sending password reset link. Please try again.';
        }
      });
    } else {
      this.loading = false;
    }
  }


  goToHome() {
    this.router.navigateByUrl('/tennis/home');
  }

}
