import { first } from 'rxjs/internal/operators';
import { AuthService } from './../../services/auth/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StarRatingComponent, RatingModule } from 'ng-starrating';

@Component({
  selector: 'app-verify-score',
  templateUrl: './verify-score.component.html',
  styleUrls: ['./verify-score.component.scss']
})
export class VerifyScoreComponent implements OnInit {

  isVerifyScore: boolean;
  verifyScoreObj: any
  matchType: string;
  isTokenVerified: boolean;
  tournamentRawData;
  roundRawData;
  fixtureRawData;
  tournamentData;
  isTournamentDataLoaded: boolean;
  roundData;
  isRoundDataLoaded: boolean;
  fixtureData;
  usersData;
  isDataLoaded: boolean;

  isAskUser: boolean = true;
  isUserAgreed: boolean;
  deniedScores: boolean;
  isNewScoreUploaded: boolean;
  isScoreUploadLoading: boolean;

  fixtureState: string;
  isLoading: boolean = true;

  formGroup: FormGroup;
  isSetOneTie: boolean;
  isSetTwoTie: boolean;
  isSetThreeTie: boolean;
  isSetThree: boolean;
  isLoggedIn: boolean;

  isPlayerRating: boolean;
  totalStars: number = 5;
  playerRating: number = 5;
  currentFirebaseAddress: any;
  
  isIncompleteMatch: boolean = false;
  isFormFilled: boolean;
  matchOutcome: any = { type: "", team: "" };
  @ViewChild("matchOutcome") matchOutcomeElement: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private firestore: AngularFirestore,
    private router: Router,
    public authService: AuthService) { }

  //ng-starrating
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    this.playerRating = $event.newValue;
  }
  //

  async getTournamentData(matchType) {
    try {
      let match = await this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId).get().toPromise()
      this.tournamentRawData = match;
      this.tournamentData = match.data();
      this.isTournamentDataLoaded = true;

      if(matchType === 'knockout') {
        this.getKnockoutRoundData();
      } else if(matchType === 'box') {
        this.isRoundDataLoaded = true;
        this.getFixtureData('box')
      } else if(matchType === 'one-on-one') {
        this.isRoundDataLoaded = true;
        this.getFixtureData('one-on-one')
      }
    } catch(e) {
        console.log(e)
        this.isTokenVerified = false;
        this.isLoading = false;
    }
  }

  getKnockoutRoundData() {
    let firebaseRoundAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection(this.verifyScoreObj.roundType).doc(this.verifyScoreObj.round)

    firebaseRoundAddress.get().toPromise()
      .then(res => {
        this.roundRawData = res;
        this.roundData = res.data();
        this.isRoundDataLoaded = true;
        this.getFixtureData('knockout');
      })
      .catch(error => { console.log(error) })
  }

  async getFixtureData(matchType) {
    try {
      if (this.isRoundDataLoaded) {
        let fixture;
        if(matchType === 'knockout') {
          fixture = await this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
          .collection(this.verifyScoreObj.roundType).doc(this.verifyScoreObj.round).collection('fixtures')
          .doc(this.verifyScoreObj.fixture).get().toPromise()
        } else if(matchType === 'box' || matchType === 'one-on-one') {
          fixture = await this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
          .collection('fixtures').doc(this.verifyScoreObj.fixture).get().toPromise()
        }

        this.fixtureRawData = fixture;
        this.fixtureData = fixture.data();

        if(await this.validateUser()) {
          this.fixtureState = fixture.data().frontEndState;
          if (this.fixtureData.approvalToken === this.verifyScoreObj.approvalToken) {
            this.isTokenVerified = true;
            if(this.fixtureState !== 'completed' && this.fixtureState !== 'disputed') {
              this.getUserData(this.fixtureData)
            } else { this.isLoading = false; }
          } else {
            this.isTokenVerified = false;
            this.isLoading = false;
          }
        } else {
          this.isTokenVerified = false;
          this.isLoading = false;
        }
      }
    } catch(e) {
        this.isTokenVerified = false;
        this.isLoading = false;
        console.log(e);
      }
  }

  async validateUser() {
    try {
      let scoreBy = this.fixtureData.scoreBy;
      if(this.tournamentData.category === 'singles') {
        if(this.fixtureData[scoreBy].id === this.authService.user.uid) return false
        else return true;
      } else if(this.tournamentData.category === 'doubles') {
        let userteam: any = (await this.firestore.collection('Teams').doc(this.fixtureData[scoreBy].id).get().toPromise()).data()
        if(userteam.members[0].id === this.authService.user.uid || userteam.members[1].id === this.authService.user.uid) return false
        else return true;
      }
      return false;
    } catch(e) {
      console.log(e)
      return false;
    }
  }

  async getUserData(fixtureData){
    if(this.tournamentData.category === 'singles') {
      let promise = [];
      promise.push(this.gatherData(fixtureData.teamA.id))
      promise.push(this.gatherData(fixtureData.teamB.id))

      Promise.all(promise)
      .then(res => {
        this.usersData = {teamA: res[0], teamB: res[1]}
        this.isDataLoaded = true;
        this.isLoading = false;
        this.initializeScoreForm();
      })
      .catch(error => {
        console.log(error)
      })
    } else if(this.tournamentData.category === 'doubles') {
      try {
        let teamARawData = await this.firestore.collection('Teams').doc(fixtureData.teamA.id).get().toPromise();
        let teamA: any = {}
        teamA.firstName = teamARawData.data().name
        teamA.lastName = '';
        teamA.teamId = teamARawData.id;

        let teamAPlayerOne = await this.firestore.collection('Users').doc(teamARawData.data().members[0].id).get().toPromise();
        teamA.playerOne = {}
        teamA.playerOne.userId = teamAPlayerOne.id
        teamA.playerOne.firstName = teamAPlayerOne.data().firstName
        teamA.playerOne.lastName = teamAPlayerOne.data().lastName

        let teamAPlayerTwo = await this.firestore.collection('Users').doc(teamARawData.data().members[1].id).get().toPromise();
        teamA.playerTwo = {}
        teamA.playerTwo.userId = teamAPlayerTwo.id
        teamA.playerTwo.firstName = teamAPlayerTwo.data().firstName
        teamA.playerTwo.lastName = teamAPlayerTwo.data().lastName

        let teamBRawData = await this.firestore.collection('Teams').doc(fixtureData.teamB.id).get().toPromise();
        let teamB: any = {}
        teamB.firstName = teamBRawData.data().name
        teamB.lastName = '';
        teamB.teamId = teamBRawData.id;

        let teamBPlayerOne = await this.firestore.collection('Users').doc(teamBRawData.data().members[0].id).get().toPromise();
        teamB.playerOne = {}
        teamB.playerOne.userId = teamBPlayerOne.id
        teamB.playerOne.firstName = teamBPlayerOne.data().firstName
        teamB.playerOne.lastName = teamBPlayerOne.data().lastName

        let teamBPlayerTwo = await this.firestore.collection('Users').doc(teamBRawData.data().members[1].id).get().toPromise();
        teamB.playerTwo = {}
        teamB.playerTwo.userId = teamBPlayerTwo.id
        teamB.playerTwo.firstName = teamBPlayerTwo.data().firstName
        teamB.playerTwo.lastName = teamBPlayerTwo.data().lastName

        this.usersData = {teamA, teamB}
        this.isDataLoaded = true;
        this.isLoading = false;
        this.initializeScoreForm();
      } catch(e) {
        console.log(e)
      }
    }
  }

  gatherData(userId) {
    return this.firestore.collection('Users').doc(userId).get().toPromise()
    .then(res => {
      let userObj: any;
      userObj = {...res.data()};
      userObj.id = userId;
      return userObj;
    })
    .catch(error => {
      console.log(error)
    })
  }

  initializeScoreForm() {
    this.formGroup = new FormGroup({
      setOneA: new FormControl('', Validators.required),
      setOneB: new FormControl('', Validators.required),
      setTwoA: new FormControl('', Validators.required),
      setTwoB: new FormControl('', Validators.required),
    })

    this.setScoreValues(this.fixtureData);
  }

  setScoreValues(fixtureData) {
    this.checkTieBreaker(fixtureData);
    let tempObjteamA;
    let tempObjteamB;
    if(this.fixtureData.frontEndState === 'score-uploaded') {
      tempObjteamA = Object.assign({}, fixtureData.teamA.matchScore);
      tempObjteamB = Object.assign({}, fixtureData.teamB.matchScore);
    } else if(this.fixtureData.frontEndState === 'disputed-pending') {
      tempObjteamA = Object.assign({}, fixtureData.teamA.newScore);
      tempObjteamB = Object.assign({}, fixtureData.teamB.newScore);
    } else if(this.fixtureData.frontEndState === 'completed') {
      if(fixtureData.teamA.newScore) {
        tempObjteamA = Object.assign({}, fixtureData.teamA.newScore);
        tempObjteamB = Object.assign({}, fixtureData.teamB.newScore);
      } else {
        tempObjteamA = Object.assign({}, fixtureData.teamA.matchScore);
        tempObjteamB = Object.assign({}, fixtureData.teamB.matchScore);
      }
    }

    // Team A Scores
    tempObjteamA['setOneA'] = tempObjteamA.setOne
    tempObjteamA['setTwoA'] = tempObjteamA.setTwo

    // Team B Scores
    tempObjteamB['setOneB'] = tempObjteamB.setOne
    tempObjteamB['setTwoB'] = tempObjteamB.setTwo

    // Assign values according to conditions
    if (this.isSetThree) {
      tempObjteamA['setThreeA'] = tempObjteamA.setThree
      tempObjteamB['setThreeB'] = tempObjteamB.setThree
    }
    if (tempObjteamA.setOneTie) {
      tempObjteamA['setOneTieA'] = tempObjteamA.setOneTie
      tempObjteamA['setOneTieB'] = tempObjteamB.setOneTie
    }
    if (tempObjteamA.setTwoTie) {
      tempObjteamA['setTwoTieA'] = tempObjteamA.setTwoTie
      tempObjteamA['setTwoTieB'] = tempObjteamB.setTwoTie
    }
    if (tempObjteamA.setThreeTie) {
      tempObjteamA['setThreeTieA'] = tempObjteamA.setThreeTie
      tempObjteamA['setThreeTieB'] = tempObjteamB.setThreeTie
    }
    this.formGroup.patchValue(tempObjteamA);
    this.formGroup.patchValue(tempObjteamB);
    this.formGroup.disable();
  }

  checkTieBreaker(fixtureData) {
    let scoreType: string;
    if(this.fixtureState === 'score-uploaded') {
      scoreType = "matchScore";
    } else if(this.fixtureState === 'disputed-pending') {
      if(fixtureData.teamA.newScore) {
        scoreType = "newScore"
      } else {
        scoreType = "matchScore"
      }
    } else {
      scoreType = "matchScore"
    }

    if (fixtureData.teamA[scoreType].setThree) {
      this.formGroup.addControl('setThreeA', new FormControl('', Validators.required));
      this.formGroup.addControl('setThreeB', new FormControl('', Validators.required));
      this.isSetThree = true;
    } else { this.isSetThree = false; }

    if (fixtureData.teamA[scoreType].setOneTie) {
      this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
      this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
      this.isSetOneTie = true;
    } else { this.isSetOneTie = false; }

    if (fixtureData.teamA[scoreType].setTwoTie) {
      this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
      this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
      this.isSetTwoTie = true;
    } else { this.isSetTwoTie = false; }

    if (fixtureData.teamA[scoreType].setThreeTie) {
      this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
      this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
      this.isSetThreeTie = true;
    } else { this.isSetThreeTie = false; }
  }

  checkFormChanges() {
    let comparisonValueSetOne: boolean;
    let comparisonValueSetTwo: boolean;
    let comparisonValueSetThree: boolean;

    this.formGroup.valueChanges.subscribe(values => {
      // Checking for Set One
      this.watchChangesSetOne(values, comparisonValueSetOne);
      // Checking for Set Two
      this.watchChangesSetTwo(values, comparisonValueSetTwo);
      // Checking for Set Three
      this.watchChangesSetThree(values, comparisonValueSetThree);
      // Checking IF Set Three Exists
      this.isSetThreeExists(values);
    })
  }

  watchChangesSetOne(values, comparisonValueSetOne) {
    let a = values.setOneA;
    let b = values.setOneB;
    let aMinusB = values.setOneA - values.setOneB;
    let bMinusA = values.setOneB - values.setOneA;
    let comparisonValue: boolean = comparisonValueSetOne;

    if (a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if (a >= 6 || b >= 6) {

      if (comparisonValue) {
        if (a == 7 || b == 7) {
          if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
            this.isSetOneTie = true;
            if (!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetOneTie = false;
            if (this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if (this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        }
      } else {
        if (a == 6 || b == 6) {
          if (a == b) {
            this.isSetOneTie = true;
            if (!this.formGroup.controls.setOneTieA) {
              this.formGroup.addControl('setOneTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setOneTieB) {
              this.formGroup.addControl('setOneTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetOneTie = false;
            if (this.formGroup.controls.setOneTieA) {
              this.formGroup.removeControl('setOneTieA')
            }
            if (this.formGroup.controls.setOneTieB) {
              this.formGroup.removeControl('setOneTieB')
            }
          }
        } else {
          this.isSetOneTie = false;
          if (this.formGroup.controls.setOneTieA) {
            this.formGroup.removeControl('setOneTieA')
          }
          if (this.formGroup.controls.setOneTieB) {
            this.formGroup.removeControl('setOneTieB')
          }
        }
      }
    } else {
      this.isSetOneTie = false;
      if (this.formGroup.controls.setOneTieA) {
        this.formGroup.removeControl('setOneTieA')
      }
      if (this.formGroup.controls.setOneTieB) {
        this.formGroup.removeControl('setOneTieB')
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'one', values: values})
  }

  watchChangesSetTwo(values, comparisonValueSetTwo) {
    let a = values.setTwoA;
    let b = values.setTwoB;
    let aMinusB = values.setTwoA - values.setTwoB;
    let bMinusA = values.setTwoB - values.setTwoA;
    let comparisonValue: boolean = comparisonValueSetTwo;

    if (a == 7 || b == 7) {
      comparisonValue = true;
    } else { comparisonValue = false; }

    if (a >= 6 || b >= 6) {

      if (comparisonValue) {
        if (a == 7 || b == 7) {
          if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
            this.isSetTwoTie = true;
            if (!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          }
          else {
            this.isSetTwoTie = false;
            if (this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if (this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        }
      } else {
        if (a == 6 || b == 6) {
          if (a == b) {
            this.isSetTwoTie = true;
            if (!this.formGroup.controls.setTwoTieA) {
              this.formGroup.addControl('setTwoTieA', new FormControl('', Validators.required));
            }
            if (!this.formGroup.controls.setTwoTieB) {
              this.formGroup.addControl('setTwoTieB', new FormControl('', Validators.required));
            }
          } else {
            this.isSetTwoTie = false;
            if (this.formGroup.controls.setTwoTieA) {
              this.formGroup.removeControl('setTwoTieA');
            }
            if (this.formGroup.controls.setTwoTieB) {
              this.formGroup.removeControl('setTwoTieB');
            }
          }
        } else {
          this.isSetTwoTie = false;
          if (this.formGroup.controls.setTwoTieA) {
            this.formGroup.removeControl('setTwoTieA');
          }
          if (this.formGroup.controls.setTwoTieB) {
            this.formGroup.removeControl('setTwoTieB');
          }
        }
      }
    } else {
      this.isSetTwoTie = false;
      if (this.formGroup.controls.setTwoTieA) {
        this.formGroup.removeControl('setTwoTieA');
      }
      if (this.formGroup.controls.setTwoTieB) {
        this.formGroup.removeControl('setTwoTieB');
      }
    }

    // Checking for valid scores
    this.isValidScores({set : 'two', values: values})
  }

  watchChangesSetThree(values, comparisonValueSetThree) {
    if(this.isSetThree) {      let a = values.setThreeA;
      let b = values.setThreeB;
      let aMinusB = values.setThreeA - values.setThreeB;
      let bMinusA = values.setThreeB - values.setThreeA;
      let comparisonValue: boolean = comparisonValueSetThree;

      if (a == 7 || b == 7) {
        comparisonValue = true;
      } else { comparisonValue = false; }

      if (a >= 6 || b >= 6) {

        if (comparisonValue) {
          if (a == 7 || b == 7) {
            if ((aMinusB < 2 && aMinusB >= 0) || (bMinusA < 2 && bMinusA >= 0)) {
              this.isSetThreeTie = true;
              if (!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if (!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            }
            else {
              this.isSetThreeTie = false;
              if (this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if (this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          }
        } else {
          if (a == 6 || b == 6) {
            if (a == b) {
              this.isSetThreeTie = true;
              if (!this.formGroup.controls.setThreeTieA) {
                this.formGroup.addControl('setThreeTieA', new FormControl('', Validators.required));
              }
              if (!this.formGroup.controls.setThreeTieB) {
                this.formGroup.addControl('setThreeTieB', new FormControl('', Validators.required));
              }
            } else {
              this.isSetThreeTie = false;
              if (this.formGroup.controls.setThreeTieA) {
                this.formGroup.removeControl('setThreeTieA')
              }
              if (this.formGroup.controls.setThreeTieB) {
                this.formGroup.removeControl('setThreeTieB')
              }
            }
          } else {
            this.isSetThreeTie = false;
            if (this.formGroup.controls.setThreeTieA) {
              this.formGroup.removeControl('setThreeTieA')
            }
            if (this.formGroup.controls.setThreeTieB) {
              this.formGroup.removeControl('setThreeTieB')
            }
          }
        }
      } else {
        this.isSetThreeTie = false;
        if (this.formGroup.controls.setThreeTieA) {
          this.formGroup.removeControl('setThreeTieA')
        }
        if (this.formGroup.controls.setThreeTieB) {
          this.formGroup.removeControl('setThreeTieB')
        }
      }

      // Checking for valid scores
      this.isValidScores({set : 'three', values: values})
    }
  }

  isValidScores(data) {
    let values = data.values;
    let a, b, isDirtyA, isDirtyB, setA, setB, setATie, setBTie;
    if(data.set === 'one') {
      a = data.values.setOneA;
      b = data.values.setOneB;
      isDirtyA = this.formGroup.controls.setOneA.dirty;
      isDirtyB = this.formGroup.controls.setOneB.dirty;
      setA = 'setOneA';
      setB = 'setOneB';
      setATie = 'setOneTieA';
      setBTie = 'setOneTieB';
    } else if(data.set === 'two') {
      a = data.values.setTwoA;
      b = data.values.setTwoB;
      isDirtyA = this.formGroup.controls.setTwoA.dirty;
      isDirtyB = this.formGroup.controls.setTwoB.dirty;
      setA = 'setTwoA';
      setB = 'setTwoB';
      setATie = 'setTwoTieA';
      setBTie = 'setTwoTieB';
    } else if(data.set === 'three') {
      if(this.isSetThree && this.formGroup.controls.setThreeA && this.formGroup.controls.setThreeB) {
        a = data.values.setThreeA;
        b = data.values.setThreeB;
        isDirtyA = this.formGroup.controls.setThreeA.dirty;
        isDirtyB = this.formGroup.controls.setThreeB.dirty;
        setA = 'setThreeA';
        setB = 'setThreeB';
        setATie = 'setThreeTieA';
        setBTie = 'setThreeTieB';
      }
    }

    if( ((a == 7 || a == 6) && (b < 7) && isDirtyA && isDirtyB) ||
        ((b == 7 || b == 6) && (a < 7) && isDirtyA && isDirtyB)) {

      if((a == 7 && b > 4) || (b == 7 && a > 4)) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else if((a == 6 && (b < 5 || b == 7 || b == 6)) || (b == 6 && (a < 5 || a == 7 || a == 6))) {
        this.formGroup.controls[setA].setErrors(null);
        this.formGroup.controls[setB].setErrors(null);
      } else {
        if(isDirtyA && isDirtyB) {
          this.formGroup.controls[setA].setErrors({incorrect: true});
          this.formGroup.controls[setB].setErrors({incorrect: true});
        }
      }
    } else {
      if(isDirtyA && isDirtyB) {
        this.formGroup.controls[setA].setErrors({incorrect: true});
        this.formGroup.controls[setB].setErrors({incorrect: true});
      }
    }

    const addError = () => {
      if(this.formGroup.controls[setATie].dirty && this.formGroup.controls[setBTie].dirty) {
        this.formGroup.controls[setATie].setErrors({incorrect: true});
        this.formGroup.controls[setBTie].setErrors({incorrect: true});
      }
    }
    const removeError = () => {
      this.formGroup.controls[setATie].setErrors(null);
      this.formGroup.controls[setBTie].setErrors(null);
    }

    // Checking for TIE BREAKER
    if(this.formGroup.controls[setATie]) {
      let setTieA = parseInt(values[setATie])
      let setTieB = parseInt(values[setBTie])
      if(setTieA > 7 || setTieB > 7) {
        if(setTieA > setTieB && setTieA - setTieB === 2)      removeError();
        else if(setTieB > setTieA && setTieB - setTieA === 2) removeError();
        else addError();
      } else {
        if(setTieA === 7 || setTieB === 7) removeError();
        else addError();
      }
    }
  }

  isSetThreeExists(values) {
    if(this.isFormValid()) {
      let isScoresEqual = this.caclulateScores(values)
      if(isScoresEqual) {
        if(!this.isSetThree) {
          this.isSetThree = true;
          this.formGroup.addControl('setThreeA', new FormControl('', Validators.required));
          this.formGroup.addControl('setThreeB', new FormControl('', Validators.required));
        }
      } else {
        this.removeSetThree();
      }
    } else {
      this.removeSetThree();
    }
  }

  isFormValid() {
    let isFormValid: boolean =  this.formGroup.controls.setOneA.valid &&
                                this.formGroup.controls.setOneB.valid &&
                                this.formGroup.controls.setTwoA.valid &&
                                this.formGroup.controls.setTwoB.valid;

    if(this.formGroup.controls.setOneTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid;
    }
    if(this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }
    if(this.formGroup.controls.setOneTieA && this.formGroup.controls.setTwoTieA) {
      isFormValid = isFormValid &&
                    this.formGroup.controls.setOneTieA.valid &&
                    this.formGroup.controls.setOneTieB.valid &&
                    this.formGroup.controls.setTwoTieA.valid &&
                    this.formGroup.controls.setTwoTieB.valid;
    }

    return isFormValid
  }

  caclulateScores(values) {
    let teamAKeys = [];
    let teamBKeys = [];
    let teamAScore = [];
    let teamBScore = [];

    for(let key in values) {
      if(key.includes('A') && !key.includes('Three')) {
        teamAKeys.push(key)
      }
      if(key.includes('B') && !key.includes('Three')) {
        teamBKeys.push(key)
      }
    }

    teamAKeys.forEach(el => {
      teamAScore.push(parseInt(values[el]))
    })
    teamBKeys.forEach(el => {
      teamBScore.push(parseInt(values[el]))
    })

    if(teamAScore.length > 2 && teamBScore.length > 2) {
      if(teamAScore.length === 4 && teamBScore.length === 4) {
        if((teamAScore[2] > teamBScore[2] && teamBScore[3] > teamAScore[3]) ||
           (teamBScore[2] > teamAScore[2] && teamAScore[3] > teamBScore[3])) {
          return true;
        }
      }

      if(teamAScore.length === 3 && teamBScore.length === 3) {
        if(this.formGroup.controls.setOneTieA) {
          if((teamAScore[1] > teamBScore[1] && teamBScore[2] > teamAScore[2]) ||
             (teamBScore[1] > teamAScore[1] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        } else if(this.formGroup.controls.setTwoTieA) {
          if((teamAScore[0] > teamBScore[0] && teamBScore[2] > teamAScore[2]) ||
             (teamBScore[0] > teamAScore[0] && teamAScore[2] > teamBScore[2])) {
            return true;
          }
        }
      }

    } else if((teamAScore[0] > teamBScore[0] && teamBScore[1] > teamAScore[1]) ||
              (teamBScore[0] > teamAScore[0] && teamAScore[1] > teamBScore[1])) {
        return true;
    }

    let teamAFinalScore = teamAScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)

    let teamBFinalScore = teamBScore.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0)

    return teamAFinalScore === teamBFinalScore;
  }

  removeSetThree() {
    if(this.isSetThree) {
      this.isSetThree = false;
      this.formGroup.removeControl('setThreeA')
      this.formGroup.removeControl('setThreeB')
      if(this.isSetThreeTie) {
        this.formGroup.removeControl('setThreeTieA')
        this.formGroup.removeControl('setThreeTieB')
      }
    }
  }


  confirmScores() {
    this.isScoreUploadLoading = true;
    let matchType = this.matchType;
    let firebaseFixtureAddress;
    if(matchType === 'knockout') {
      firebaseFixtureAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection(this.verifyScoreObj.roundType).doc(this.verifyScoreObj.round).collection('fixtures').doc(this.fixtureRawData.id)
    } else if(matchType === 'box' || matchType === 'one-on-one') {
      firebaseFixtureAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection('fixtures').doc(this.verifyScoreObj.fixture)
    }
    this.currentFirebaseAddress = firebaseFixtureAddress;

    firebaseFixtureAddress.update({
      frontEndState: 'completed',
      state: 'completed'
    })
    .then(res => {
      console.log("SCORES CONFIRMED")
      this.isUserAgreed = true;
      this.isAskUser = false;
      this.isScoreUploadLoading = false;
      if(this.tournamentData.category === 'singles') {
        this.isPlayerRating = true;
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  denyScores() {
    this.isAskUser = false;
    this.deniedScores = true;
    this.formGroup.enable();
    this.checkFormChanges();
  }

  goToPreviousScores() {
    this.isUserAgreed = false;
    this.isAskUser = true;
    this.deniedScores = false;
    this.formGroup.disable();
    this.initializeScoreForm();
  }

  addScore(formGroup) {
    if (formGroup.valid) {
      this.isScoreUploadLoading = true;
      let formData = formGroup.value;
      let matchType = this.matchType;
      let firebaseAddress;
      if(matchType === 'knockout') {
        firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
        .collection(this.verifyScoreObj.roundType).doc(this.verifyScoreObj.round).collection('fixtures').doc(this.fixtureRawData.id)
      } else if(matchType === 'box' || matchType === 'one-on-one') {
        firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
        .collection('fixtures').doc(this.verifyScoreObj.fixture)
      }
      this.currentFirebaseAddress = firebaseAddress

      firebaseAddress.get().toPromise()
        .then(res => {
          let data = res.data();

          data.state = 'disputed-pending';
          data.frontEndState = 'disputed-pending';
          data.scoreBy = this.scoreBy();

          let teamAScore = {
            setOne: parseInt(formData.setOneA),
            setTwo: parseInt(formData.setTwoA),
          }
          let teamBScore = {
            setOne: parseInt(formData.setOneB),
            setTwo: parseInt(formData.setTwoB),
          }
          if (formData.setOneTieA) {
            teamAScore['setOneTie'] = parseInt(formData.setOneTieA);
            teamBScore['setOneTie'] = parseInt(formData.setOneTieB);
          }
          if (formData.setTwoTieA) {
            teamAScore['setTwoTie'] = parseInt(formData.setTwoTieA);
            teamBScore['setTwoTie'] = parseInt(formData.setTwoTieB);
          }
          if (formData.setThreeA) {
            teamAScore['setThree'] = parseInt(formData.setThreeA);
            teamBScore['setThree'] = parseInt(formData.setThreeB);
          }
          if (formData.setThreeTieA) {
            teamAScore['setThreeTie'] = parseInt(formData.setThreeTieA);
            teamBScore['setThreeTie'] = parseInt(formData.setThreeTieB);
          }

          // Convert empty score values to 0
          Object.keys(teamAScore).forEach(el => !teamAScore[el] ? teamAScore[el] = 0 : "");
          Object.keys(teamBScore).forEach(el => !teamBScore[el] ? teamBScore[el] = 0 : "");

          data.teamA.newScore = teamAScore;
          data.teamB.newScore = teamBScore;

          if( this.matchOutcome.type !== "" && this.matchOutcome.team !== "") {
            data.newMatchOutcome = {};
            data.outcome.newMatchOutcome = this.matchOutcome;
          }

          return firebaseAddress.update(data);
        })
        .then(res => {
          console.log("Success: New Score Uploaded");
          this.formGroup.disable();
          this.isScoreUploadLoading = false;
          this.isNewScoreUploaded = true;
          this.deniedScores = false;
          if(this.tournamentData.category === 'singles') {
            this.isPlayerRating = true;
          }
        })
        .catch(error => { console.log(error) })
    }
  }

  scoreBy() {
    if(this.tournamentData.category === 'singles') {
      if(this.authService.user.uid === this.usersData.teamA.id) {
        return "teamA"
      } else if(this.authService.user.uid === this.usersData.teamB.id) {
        return "teamB"
      }
    } else if(this.tournamentData.category === 'doubles') {
      if( this.authService.user.uid === this.usersData.teamA.playerOne.userId ||
          this.authService.user.uid === this.usersData.teamA.playerTwo.userId) {
        return "teamA"
      } else if(this.authService.user.uid === this.usersData.teamB.playerOne.userId ||
                this.authService.user.uid === this.usersData.teamB.playerTwo.userId) {
        return "teamB"
      }
    }
  }

  agree() {
    this.isScoreUploadLoading = true;
    let matchType = this.matchType;
    let firebaseAddress;
    if(matchType === 'knockout') {
      firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection(this.verifyScoreObj.roundType).doc(this.verifyScoreObj.round).collection('fixtures').doc(this.fixtureRawData.id)
    } else if(matchType === 'box') {
      firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection('fixtures').doc(this.verifyScoreObj.fixture)
    } else if(matchType === 'one-on-one') {
      firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection('fixtures').doc(this.verifyScoreObj.fixture)
    }

    firebaseAddress.update({
      frontEndState: 'completed',
      state: 'completed'
    })
    .then(res => {
      console.log("Success")
      this.isAskUser = false;
      this.isUserAgreed = true;
      this.isScoreUploadLoading = false;
    })
    .catch(error => {
      console.log(error)
    })
  }

  notAgree() {
    this.isScoreUploadLoading = true;
    let matchType = this.matchType;
    let firebaseAddress;
    if(matchType === 'knockout') {
      firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection(this.verifyScoreObj.roundType).doc(this.verifyScoreObj.round).collection('fixtures').doc(this.fixtureRawData.id)
    } else if(matchType === 'box') {
      firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection('fixtures').doc(this.verifyScoreObj.fixture)
    } else if(matchType === 'one-on-one') {
      firebaseAddress = this.firestore.collection('Matches').doc(this.verifyScoreObj.tournamentId)
      .collection('fixtures').doc(this.verifyScoreObj.fixture)
    }

    firebaseAddress.update({
      state: 'disputed',
      frontEndState: 'disputed'
    })
    .then(res => {
      console.log("Success")
      this.isAskUser = false;
      this.deniedScores = true;
      this.isScoreUploadLoading = false;
    })
    .catch(error => {
      console.log(error)
    })
  }

  goToTournament() {
    this.router.navigate([`/tennis/tournaments/${this.tournamentRawData.id}`])
  }

  async confirmRating() {
    try {
      let userId: string;
      let fixtureData = (await this.currentFirebaseAddress.get().toPromise()).data()

      if(fixtureData.teamA.id !== this.authService.user.uid) {
        userId = fixtureData.teamA.id
      } else if(fixtureData.teamB.id !== this.authService.user.uid) {
        userId = fixtureData.teamB.id
      }

      await this.firestore.collection('Users').doc(userId).collection('rating').add({
        rating: this.playerRating,
        uid: this.authService.user.uid,
        timestamp: Date.now(),
      })
      this.isPlayerRating = false;
    } catch(e) {
      console.log(e)
    }
  }

  setMatchOutcome(type, event) {
    Object.keys(this.formGroup.controls).map(key => {
      this.formGroup.controls[key].setErrors(null)
    })
    this.matchOutcome = {
      type,
      team: event.target.value
    };
  }

  checkIncompleteMatch(value) {
    if(!value) {
      this.matchOutcome = { type: "", team: "" };
      Object.keys(this.formGroup.controls).forEach(key => {
        if(!this.formGroup.controls[key].value) {
          this.formGroup.controls[key].setErrors({'incorrect': true});
        }
      })
    };
  }

  async ngOnInit() {
    try {
      let user = await this.authService.authState.pipe(first()).toPromise();
      if(user) {
        this.isLoggedIn = true;
        let params = this.activatedRoute.snapshot.queryParams;
        if (params.mode === 'verify-score') {
          this.isVerifyScore = true;
          let match = await this.firestore.collection('Matches').doc(params["t-Id"]).get().toPromise()
          if(match.data().matchType === 'knockout') {
            this.matchType = 'knockout';
            this.verifyScoreObj = {
              tournamentId: params["t-Id"],
              roundType: params["round-type"],
              round: params["round"],
              fixture: params["fixture"],
              approvalToken: params["approval-token"]
            }
            this.getTournamentData('knockout');
          } else if(match.data().matchType === 'box') {
            this.matchType = 'box';
            this.verifyScoreObj = {
              tournamentId: params["t-Id"],
              fixture: params["fixture"],
              approvalToken: params["approval-token"]
            }
            this.getTournamentData('box');

          } else if(match.data().matchType === 'one-on-one') {
            this.matchType = 'one-on-one';
            this.verifyScoreObj = {
              tournamentId: params["t-Id"],
              fixture: params["fixture"],
              approvalToken: params["approval-token"]
            }
            this.getTournamentData('one-on-one');
          } else {
            this.isTokenVerified = false;
            this.isLoading = false;
          }
        }
      } else { this.isLoggedIn = false; }
    }
    catch(e) {
      console.log(e)
    }
  }
}
