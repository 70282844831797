import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-current-fixtures',
  templateUrl: './current-fixtures.component.html',
  styleUrls: ['./current-fixtures.component.scss']
})
export class CurrentFixturesComponent implements OnInit {

  @Input() userObj;
  currentFixtures: any = [];
  singleFixtures: any[];
  doubleFixtures: any[];
  isScoreModalOpen: boolean;

  selectedFixture;

  constructor(private router: Router,
    private firestore: AngularFirestore) { }

  async getCurrentFixtures() {
    try {
      let singleFixtures: any = await this.getSingleFixtures();
      this.singleFixtures = singleFixtures.flat();
      this.currentFixtures = this.singleFixtures
      
      let doubleFixtures: any = await this.getDoubleFixtures();
      this.doubleFixtures = doubleFixtures.flat();
      
      this.currentFixtures = [...this.singleFixtures, ...this.doubleFixtures];
      this.currentFixtures.forEach((match, index) => match.id = index);

    } catch(e) {
      console.log(e)
    }
  }

  async getSingleFixtures() {
    try {
      let knockoutArray = [];
      let boxArray = [];

      let matches = await this.firestore.collection('Matches', ref => ref
                    .where('players', 'array-contains', { id: this.userObj.id, email: this.userObj.email })
                    .where('frontEndState', '==', 'registration-closed'))
                    .get().toPromise()
      let knockoutMatches = matches.docs.filter(match => match.data().matchType === 'knockout')
       // Box also contains one on one matches
      let boxMatches = matches.docs.filter(match => match.data().matchType === 'box' || match.data().matchType === 'one-on-one')
      
      if(knockoutMatches.length > 0) {
        let rounds = await this.getKnockoutRounds(knockoutMatches);
        if(rounds.length > 0) {
          let fixtures = await this.getAllFixtures(rounds);
          if(fixtures.length > 0) {
            let userFixtures = this.getUserFixtures(fixtures, 'singles')
            if(userFixtures.length > 0) {
              let opponentData = await this.getOpponentData(userFixtures, 'singles');
              knockoutArray = opponentData
            }
          }
        }
      }

      if(boxMatches.length > 0) {
        let allFixtures = await Promise.all(boxMatches.map(async match => {
          let fixtures = await match.ref.collection('fixtures').get()
          if(fixtures.docs.length > 0) {
            return {
              matchId: match.id,
              fixtures: fixtures.docs
            }
          }
        }))
        allFixtures = allFixtures.filter(Boolean)
        if(allFixtures.length > 0) {
          let userFixtures = this.getUserFixtures(allFixtures, 'singles')
          if(userFixtures.length > 0) {
            let opponentData = await this.getOpponentData(userFixtures, 'singles');
            boxArray = opponentData;
          }
        }
      }
      
      return [knockoutArray, boxArray]
    } catch(e) {
      console.log(e)
    }
  }

  async getDoubleFixtures() {
    try {
      let knockoutArray = [];
      let boxArray = [];

      let userTeams = await this.firestore.collection('Teams', ref => ref
                      .where('members', 'array-contains', { id: this.userObj.id, email: this.userObj.email }))
                      .get().toPromise();
      if(userTeams.docs.length > 0) {
        let teams = userTeams.docs.map(team => {
          return { id: team.id, ...team.data() }
        })
        let matches: any = await Promise.all(teams.map(async team => {
          let match = await this.firestore.collection('Matches', ref => ref
                      .where('players', 'array-contains', { id: team.id })
                      .where('frontEndState', '==', 'registration-closed'))
                      .get().toPromise();
          if(match.docs.length > 0) {
            return match.docs
          }
        }))
        matches = matches.filter(Boolean).flat();
        if(matches.length > 0) {
          let knockoutMatches = matches.filter(match => match.data().matchType === 'knockout')
          // Box also contains one on one matches
          let boxMatches = matches.filter(match => match.data().matchType === 'box' || match.data().matchType === 'one-on-one')
          if(knockoutMatches.length > 0) {
            let rounds = await this.getKnockoutRounds(knockoutMatches)
            if(rounds.length > 0) {
              let fixtures = await this.getAllFixtures(rounds);
              if(fixtures.length > 0) {
                let userFixtures = this.getUserFixtures(fixtures, 'doubles', teams)
                if(userFixtures.length > 0) {
                  let opponentData = await this.getOpponentData(userFixtures, 'doubles', teams);
                  knockoutArray = opponentData
                }
              }
            }
          }

          if(boxMatches.length > 0) {
            let allFixtures: any = await Promise.all(boxMatches.map(async match => {
              let fixtures = await match.ref.collection('fixtures').get()
              if(fixtures.docs.length > 0) {
                return {
                  matchId: match.id,
                  fixtures: fixtures.docs
                }
              }
            }))
            allFixtures = allFixtures.filter(Boolean)
            if(allFixtures.length > 0) {
              let userFixtures = this.getUserFixtures(allFixtures, 'doubles')
              if(userFixtures.length > 0) {
                let opponentData = await this.getOpponentData(userFixtures, 'doubles');
                boxArray = opponentData;
              }
            }

          }

          return [knockoutArray, boxArray]
        }
      }
      return [knockoutArray, boxArray]
    } catch(e) {
      console.log(e)
    }
  }

  async getKnockoutRounds(matches) {
    try {
      let rounds: any = await Promise.all(matches.map(async (match, index) => {
        let roundsData = await match.ref.collection('rounds').get()
        let loserRoundsData = await match.ref.collection('loserRounds').get()
        let matchObj = {
          matchId: match.id,
          matchTitle: match.data().title, 
          matchType: match.data().matchType,
          category: match.data().category,
        }
        if(roundsData.docs.length > 0 && loserRoundsData.docs.length > 0) {
          return [
            {
              ...matchObj,   
              roundType: 'rounds',
              rounds: roundsData.docs
            },
            {
              ...matchObj,   
              roundType: 'loserRounds',
              rounds: loserRoundsData.docs
            }
          ]
        } else {
          if(roundsData.docs.length > 0) {
            return {
              ...matchObj,
              roundType: 'rounds',
              rounds: roundsData.docs
            }
          }
          if(loserRoundsData.docs.length > 0) {
            return {
              ...matchObj,
              roundType: 'loserRounds',
              rounds: loserRoundsData.docs
            }
          }
        }
      }))
      rounds = rounds.filter(Boolean).flat();
      return rounds.length > 0 ? rounds : []
    } catch(e) {
      console.log(e)
    }
  }

  async getAllFixtures(matches) {
    try {
      let fixtures: any = await Promise.all(matches.map(async (match, index) => {
        let getAllRoundFixtures = await Promise.all(match[match['roundType']].map(async round => {
          let roundFixtures = await round.ref.collection('fixtures').get()
          delete match.rounds
          let currentRound = round.id.split('d')[0].split('')
          currentRound.push('d')
          currentRound = `${currentRound.join('')} ${round.id.split('d')[1]}`
          return {
            ...match,
            roundId: round.id,
            currentRound,
            fixtures: roundFixtures.docs
          }
        }))
        return getAllRoundFixtures.filter(Boolean)
      }))
      fixtures = fixtures.filter(Boolean).flat()
      return fixtures.length > 0 ? fixtures : []
    } catch(e) {
      console.log(e)
    }
  }

  getUserFixtures(fixtures, category, teams?) {
    const singles = (match, fixtureData) => {
      if(fixtureData.teamA.id && fixtureData.teamB.id) {
        if(fixtureData.teamA.id === this.userObj.id || fixtureData.teamB.id === this.userObj.id) {
          if(!fixtureData.frontEndState) {
            delete match.fixtures
            return  {
              ...match,
              fixtureId: fixtureData.id,
              fixture: fixtureData
            }
          }
        }
      }
    }

    const matchTeamIds = (teamId) => {
      return teams.some(team => team.id === teamId)
    }

    const doubles = (match, fixtureData) => {
      if(fixtureData.teamA.id && fixtureData.teamB.id) {
        if(matchTeamIds(fixtureData.teamA.id) || matchTeamIds(fixtureData.teamB.id)) {
          if(!fixtureData.frontEndState) {
            delete match.fixtures
            return  {
              ...match,
              fixtureId: fixtureData.id,
              fixture: fixtureData
            }
          }
        }
      }
    }

    let allFixtures = fixtures.map(match => {
      let userFixtures = match.fixtures.map(fixture => {
        let fixtureData = fixture.data();
        fixtureData.id = fixture.id;
        if(category === 'singles') return singles(match, fixtureData);
        else return doubles(match, fixtureData);       
      })
      return userFixtures.filter(Boolean)
    })
    allFixtures = allFixtures.filter(Boolean).flat();
    return allFixtures.length > 0 ? allFixtures : []
  }

  async getOpponentData(fixtures, category, teams?) {
    const singles = async (fixtureData) => {
      if(fixtureData.fixture.teamA.id === this.userObj.id) {
        fixtureData.fixture.opponent = fixtureData.fixture.teamB.id
      }
      else fixtureData.fixture.opponent = fixtureData.fixture.teamA.id
      let opponent =  (await this.firestore.collection('Users').doc(fixtureData.fixture.opponent)
                      .get().toPromise()).data()
      fixtureData.fixture.opponent = {
        id: fixtureData.fixture.opponent,
        name: `${opponent.firstName} ${opponent.lastName}`
      }

      if(fixtureData.fixture.opponent.id === fixtureData.fixture.teamA.id) {
        fixtureData.fixture.teamA.name = `${opponent.firstName} ${opponent.lastName}`;
        fixtureData.fixture.teamB.name = `${this.userObj.firstName} ${this.userObj.lastName}`;
      } else {
        fixtureData.fixture.teamB.name = `${opponent.firstName} ${opponent.lastName}`;
        fixtureData.fixture.teamA.name = `${this.userObj.firstName} ${this.userObj.lastName}`;
      }

      return fixtureData
    }

    const matchTeamIds = (teamId) => {
      return teams.some(team => team.id === teamId)
    }

    const doubles = async (fixtureData) => {
      if(matchTeamIds(fixtureData.fixture.teamA.id)) {
        fixtureData.fixture.opponent = fixtureData.fixture.teamB.id
      }
      else fixtureData.fixture.opponent = fixtureData.fixture.teamA.id
      let opponent =  (await this.firestore.collection('Teams').doc(fixtureData.fixture.opponent)
                      .get().toPromise()).data()
      fixtureData.fixture.opponent = {
        id: fixtureData.fixture.opponent,
        name: opponent.name
      }

      const getMyTeamData = async (teamId) => {
        let team = (await this.firestore.collection('Teams').doc(teamId).get().toPromise()).data()
        return team.name
      }

      if(fixtureData.fixture.opponent.id === fixtureData.fixture.teamA.id) {
        fixtureData.fixture.teamA.name = `${opponent.name}`;
        fixtureData.fixture.teamB.name = await getMyTeamData(fixtureData.fixture.teamB.id);
      } else {
        fixtureData.fixture.teamB.name = `${opponent.name}`;
        fixtureData.fixture.teamA.name = await getMyTeamData(fixtureData.fixture.teamA.id);
      }

      return fixtureData
    }
    
    let separateOpponent =  await Promise.all(fixtures.map(async fixture => {
      let fixtureData = fixture
      if(category === 'singles') return await singles(fixtureData)
      else return await doubles(fixtureData)
    }))
    return separateOpponent
  }

  openScoreModal(fixture) {
    this.selectedFixture = fixture;
    this.isScoreModalOpen = true;
  }

  ngOnInit(): void {
    this.getCurrentFixtures();
  }

}
