import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/internal/operators';
import firebase from 'firebase/app';

@Component({
  selector: 'app-tournaments-list',
  templateUrl: './tournaments-list.component.html',
  styleUrls: ['./tournaments-list.component.scss']
})
export class TournamentsListComponent implements OnInit {

  isTournaments: boolean;
  tournaments;
  tournamentCount: number;
  showUserMatches: boolean = true;
  showSystemMatches: boolean;
  page: number;

  @ViewChild('matchTypeFilter') matchTypeFilterElement: ElementRef
  @ViewChild('genderFilter') genderFilterElement: ElementRef
  @ViewChild('wheelChairFilter') wheelChairFilterElement: ElementRef
  @ViewChild('tierFilter') tierFilterElement: ElementRef
  @ViewChild('stateFilter') stateFilterElement: ElementRef
  @ViewChild('ageFilter') ageFilterElement: ElementRef
  @ViewChild('categoryFilter') categoryFilterElement: ElementRef

  isLoading: boolean = true;
  cities: any[];
  filterMatchType: string = "";
  filterCity: string = "";
  filterGender: string = "";
  filterWheelChair: string = "";
  filterTier: string = "";
  filterState: string = "";
  filterAge: string = "";
  filterCategory: string = "";
  filterQuery: firebase.firestore.Query;
  citySelect;

  constructor(
    private firestore: AngularFirestore,
    public router: Router,
    private activatedRoute: ActivatedRoute) { }

  clearFilters() {
    this.isLoading = true;
    let matchTypeFilter = this.matchTypeFilterElement.nativeElement.options
    let genderFilter = this.genderFilterElement.nativeElement.options
    let wheelChairFilter = this.wheelChairFilterElement.nativeElement.options
    let tierFilter = this.tierFilterElement.nativeElement.options
    let stateFilter = this.stateFilterElement.nativeElement.options
    let ageFilter = this.ageFilterElement.nativeElement.options
    let categoryFilter = this.categoryFilterElement.nativeElement.options
    matchTypeFilter[0].selected = true;
    genderFilter[0].selected = true;
    wheelChairFilter[0].selected = true;
    tierFilter[0].selected = true;
    stateFilter[0].selected = true;
    ageFilter[0].selected = true;
    categoryFilter[0].selected = true;

    // Remove query params from the url
    this.router.navigate([]);
    this.filterQuery = null;
    this.citySelect = null;
    let type = this.showUserMatches ? 'user' : 'system'
    this.getTournaments(type)
  }

  async getTournaments(type) {
    try {
      let tournaments = await this.firestore.collection('Matches', ref => ref.
                        where('createdBy', '==', type)).get().toPromise();

      if(tournaments.docs.length > 0) {
        this.tournaments = tournaments.docs.filter(el => el.exists && !el.data().deleted)
        this.orderMatches(this.tournaments);
        this.isTournaments = true;
      } else {
        this.tournamentCount = 0;
        this.isTournaments = false;
      }
      this.isLoading = false;
    } catch(error) {
      console.log(error);
    }
  }

  orderMatches(tournaments: any[]) {
    let matches = tournaments.slice();
    let newMatchesArr = [];
    let openMatches = [];
    let completed = [];
    let inProgress = [];
    openMatches = matches.filter((el) => el.data().frontEndState === 'registration-open')
    inProgress = matches.filter((el) => el.data().frontEndState === 'registration-closed')
    completed = matches.filter((el) => el.data().frontEndState === 'completed')

    if(openMatches.length > 0)  newMatchesArr = newMatchesArr.concat(openMatches);
    if(inProgress.length > 0)   newMatchesArr = newMatchesArr.concat(inProgress);
    if(completed.length > 0)    newMatchesArr = newMatchesArr.concat(completed)
    
    this.tournamentCount = newMatchesArr.length;
    let paginatedMatches = this.createPagination(newMatchesArr);
    this.tournaments = paginatedMatches;
  }

  createPagination(allMatches) {
    let paginationLength = allMatches.length / 15;
    let paginatedMatches = []
    let count = 0;
    for(let i = 0; i<paginationLength; i++) {
      let batch = allMatches.slice(count, count + 15)
      paginatedMatches.push(batch);
      count += 15;
    }
    return paginatedMatches;    
  }

  async applyFilter(event, filterType) {
    this.isLoading = true;
    let value = filterType !== 'filterCity' ? event.target.value : event?.name ?? '';

    const mergeInUrl = async (filterKey, filterValue) => {
      await this.router.navigate([], { queryParams: { [filterKey]: filterValue ? filterValue : null }, queryParamsHandling: 'merge' });
    }

    if (filterType === 'filterMatchType')       await mergeInUrl('matchType', value)
    else if (filterType === 'filterCity')       await mergeInUrl('location', value)
    else if (filterType === 'filterGender')     await mergeInUrl('gender', value)
    else if (filterType === 'filterWheelChair') await mergeInUrl('wheelChair', value)
    else if (filterType === 'filterTier')       await mergeInUrl('victorPoints', value)
    else if (filterType === 'filterState')      await mergeInUrl('frontEndState', value)
    else if (filterType === 'filterAge')        await mergeInUrl('ageLimit', value)
    else if (filterType === 'filterCategory')   await mergeInUrl('category', value)

    let params = await this.activatedRoute.queryParams.pipe(first()).toPromise();
    if(Object.keys(params).length > 1) {
      this.applyFiltersOnLoad(params, true);
    } else {  
      let type = this.showUserMatches ? 'user' : 'system'
      this.getTournaments(type)
    }
  }

  async getAllCities() {
    try {
      let cities = await this.firestore.collection('Cities').get().toPromise()
      if(cities.docs.length > 0) {
        this.cities = cities.docs.map(el => {
          return { name: el.data().name, timezone: el.data().timezone }
        })
      }
    } catch(e) {
      console.log(e)
    }
  }

  onOutletLoaded(component) {
    component.tournaments = this.tournaments;
    component.cities = this.cities;
    this.activatedRoute.queryParams.subscribe(params => {
      component.page = this.page
    })
  }
  watchUrl(type?: string) {
    if(type) {
      this.router.navigate(['/tennis/tournaments/category', type])
      .then(res => {
        this.isLoading = true;
        let createdBy = type === 'user-created' ? 'user' : 'system';
        this.getTournaments(createdBy);
        this.showUserMatches = createdBy === 'user' ? true : false;
        this.showSystemMatches = createdBy === 'system' ? true : false;
        this.clearFilters();
      })
    } else {
      this.isLoading = true;
      if(this.router.url.includes('category/user-created')) {
        this.getTournaments('user');
        this.showUserMatches = true;
        this.showSystemMatches = false;
      } else {
        this.getTournaments('system');
        this.showUserMatches = false;
        this.showSystemMatches = true;
      }
    }
  }

  async applyFiltersOnLoad(queryParams, pageZero?) {
    let type;
    if(this.router.url.includes('category/user-created')) {
      type = 'user'
      this.showUserMatches = true;
      this.showSystemMatches = false;
    } else {
      type = 'system'
      this.showUserMatches = false;
      this.showSystemMatches = true;
    }
    try {
      let params = Object.keys(queryParams);
      let result = await this.firestore.collection('Matches', ref => {
        this.filterQuery = ref;
        if(params.includes('matchType')) {
          this.filterQuery = this.filterQuery.where('matchType', '==', queryParams['matchType'])
          this.matchTypeFilterElement.nativeElement.value = queryParams['matchType'];
        } if(params.includes('location')) {
          this.filterQuery = this.filterQuery.where('location', '==', queryParams['location'])
          this.citySelect = queryParams['location'];
        } if(params.includes('gender')) {
          this.filterQuery = this.filterQuery.where('gender', '==', queryParams['gender'])
          this.genderFilterElement.nativeElement.value = queryParams['gender'];
        } if(params.includes('wheelChair')) {
          this.filterQuery = this.filterQuery.where('wheelChair', '==',  queryParams['wheelChair'])
          this.wheelChairFilterElement.nativeElement.value = queryParams['wheelChair'];
        } if(params.includes('frontEndState')) {
          this.filterQuery = this.filterQuery.where('frontEndState', '==', queryParams['frontEndState'])
          this.stateFilterElement.nativeElement.value = queryParams['frontEndState'];
        } if(params.includes('ageLimit')) {
          this.filterQuery = this.filterQuery.where('ageLimit', '==', queryParams['ageLimit'])
          this.ageFilterElement.nativeElement.value = queryParams['ageLimit'];
        } if(params.includes('category')) {
          this.filterQuery = this.filterQuery.where('category', '==', queryParams['category'])
          this.categoryFilterElement.nativeElement.value = queryParams['category'];
        } if(params.includes('victorPoints')) {
          switch (queryParams['victorPoints']) {
            case '600':
              this.filterQuery = this.filterQuery.where('victorPoints', '<=', parseInt(queryParams['victorPoints']));
              break;
            case '1200':
              this.filterQuery = this.filterQuery.where('victorPoints', '<=', parseInt(queryParams['victorPoints']));
              this.filterQuery = this.filterQuery.where('victorPoints', '>', 600);
              break;
            case '1800':
              this.filterQuery = this.filterQuery.where('victorPoints', '<=', parseInt(queryParams['victorPoints']));
              this.filterQuery = this.filterQuery.where('victorPoints', '>', 1200);
              break;
            case '3500':
              this.filterQuery = this.filterQuery.where('victorPoints', '<=',  parseInt(queryParams['victorPoints']));
              this.filterQuery = this.filterQuery.where('victorPoints', '>', 1800);
              break;
          }
          this.tierFilterElement.nativeElement.value = queryParams['victorPoints'];
        } 

        this.filterQuery = this.filterQuery.where('createdBy', '==', type)
        return this.filterQuery;
      }).get().toPromise();
      if (result.docs.length > 0) {
        this.tournaments = result.docs.filter(el => el.exists && !el.data().deleted)
        this.orderMatches(this.tournaments);
        if(!this.tournaments[this.page]) {
          this.page = this.tournaments.length - 1;
          this.router.navigate([], { queryParams: { page: this.page }, queryParamsHandling: 'merge' });
        }
        this.isTournaments = true;
      } else {
        this.tournamentCount = 0;
        this.router.navigate([], { queryParams: { page: 0 }, queryParamsHandling: 'merge' });
        this.isTournaments = false;
      }
      if(pageZero) this.router.navigate([], { queryParams: { page: 0 }, queryParamsHandling: 'merge' });
      this.isLoading = false;
    } catch (e) {
      console.log(e)
    }
  }

  queryParamsForPagination() {
    this.activatedRoute.queryParams.subscribe(params => {
      const page = params['page'];
      if(page) this.page = parseInt(page);
      else {
        this.page = 0;
        this.router.navigate([], {
          queryParams: { page: 0 },
          queryParamsHandling: 'merge',
        });
      } 
    });
  }

  async ngOnInit() {
    await this.getAllCities();
    let params = await this.activatedRoute.queryParams.pipe(first()).toPromise();
    if(Object.keys(params).length > 0) {
      this.applyFiltersOnLoad(params);
    } else {
      this.activatedRoute.url.subscribe(url => {
        this.watchUrl();
      })
    }
    this.queryParamsForPagination();
  }

}
