<div class="container-fluid">
  <app-page-heading pageHeading="Contact Us"></app-page-heading>
</div>
<div class="container-white">
  <div class="container">
    <div class="clearfix"></div>
    <div class="gray-table-container">
      <div class="note">Please feel free to contact us if you have any query, request or feedback.</div>
      <hr>
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="contact-us">
            <form [formGroup]="formGroup">
              <div class="field">
                <label>Full Name</label>
                <input type="text" class="form-control" formControlName="name">
                <div class="errorMessage" *ngIf="submitAttempt && formGroup.get('name').invalid">Full name is required</div>
              </div>
              <div class="field">
                <label>Email</label>
                <input type="email" class="form-control" formControlName="email">
                <div class="errorMessage" *ngIf="submitAttempt && formGroup.get('email').value === ''">Email is required</div>
                <div class="errorMessage" *ngIf="submitAttempt && formGroup.get('email').invalid && formGroup.get('email').value !== ''">Email is invalid</div>
              </div>
              <div class="field">
                <label>Message</label>
                <textarea class="form-control" formControlName="message" rows="6"></textarea>
                <div class="errorMessage" *ngIf="submitAttempt && formGroup.get('message').invalid">Message is required</div>
              </div>
              <div *ngIf="!isLoading" class="submit-button">
                <button (click)="sendMessage(formGroup)">Submit</button>
              </div>
            </form>
            <div *ngIf="isLoading" class="loader">
              <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
            </div>
            <div class="msgs">
              <div *ngIf="isMessageSent" class="alert alert-success">You message has been successfully sent</div>
              <div *ngIf="isError" class="alert alert-danger">Could not send message. Please refresh the page and try again</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-newsletter></app-newsletter>
</div>