<div class="matches">
  <div *ngIf="isLoading" class="loader">
    <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
  </div>
  <div *ngIf="!isLoading">
    <div *ngIf="hasInvites; else noMatches" class="matches-inner">
      <div class="match" *ngFor="let request of requests; let i = index">
        <div class="block-1">
          <div class="name">
            <label>Team:</label>
            <a> {{ request.team.name }} </a>
          </div>
          <div class="name">
            <label>Match:</label>
            <a [routerLink]="['/tennis/tournaments/', request.match.id]">
              {{ request.match.name }}
            </a>
            (Click to view match)
          </div>
          <div class="location">
            <label>Location:</label> Perth (<span class="view-map"
              >View on map</span
            >)
          </div>
        </div>
        <div class="block-2">
          <button
            type="button"
            class="button-accept"
            (click)="openModal(request, 'accept')"
          >
            Accept
          </button>
          <button
            type="button"
            class="button-reject"
            (click)="openModal(request, 'reject')"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noMatches>
    <div class="alert">Currently you have no match invites.</div>
  </ng-template>

  <section *ngIf="isAcceptModalOpen" class="modal">
    <div class="modal-background">
      <div class="modal-body">
        <ng-container *ngIf="userObj.wallet < currRequest.amount">
          <div class="alert alert-danger">
            You have insufficient balance in your wallet. This match request
            requires you to pay
            <strong>${{ currRequest.amount }}</strong
            >. Please recharge account and try again.
          </div>
          <div *ngIf="!isModalLoading" class="join-buttons">
            <button
              type="button"
              (click)="closeModal(); router.navigate(['/tennis/user/wallet'])"
            >
              Okay
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="userObj.wallet >= currRequest.amount">
          <p>
            This match request requires you to pay
            <strong>${{ currRequest.amount }}</strong
            >. Would you like to proceed?
          </p>

          <div *ngIf="isModalLoading" class="loader">
            <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
          </div>

          <div *ngIf="!isModalLoading" class="join-buttons">
            <button type="button" (click)="acceptRequest(currRequest)">
              Yes
            </button>
            <button type="button" (click)="closeModal()">No</button>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <section *ngIf="isRejectModalOpen" class="modal">
    <div class="modal-background">
      <div class="modal-body">
        <p>
          Would you like to reject the team invite for
          <strong>{{ currRequest.match.name }}</strong
          >?
        </p>
        <div *ngIf="isModalLoading" class="loader">
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
        </div>
        <div *ngIf="!isModalLoading" class="join-buttons">
          <button type="button" (click)="reject(currRequest)">Yes</button>
          <button type="button" (click)="closeModal()">No</button>
        </div>
      </div>
    </div>
  </section>
</div>
