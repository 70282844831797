<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <app-player-details></app-player-details>
        <div class="nav-tabs" *ngIf="playerId">
          <ul>
            <li [ngClass]="{'tab-active': clicked === 'overview'}" (click)="onClick('overview')">
              <a [routerLink]="['/tennis/player/' + playerId + '/overview']">Overview</a></li>
            <li [ngClass]="{'tab-active': clicked === 'games'}" (click)="onClick('games')">
              <a [routerLink]="['/tennis/player/' + playerId + '/games-history']">Games History</a></li>
          </ul>
        </div>
        <router-outlet *ngIf="playerId" (activate)="onOutletLoaded($event)"></router-outlet>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
