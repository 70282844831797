<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">

  <app-player-menu></app-player-menu>

  <div class="container">
    <div class="gray-table-container">
      <ng-container *ngIf="userObj">
        <div class="statistics">
          <div class="heading">Statistics</div>
          <div class="stats">
            <div class="stat">
              <label>Viictor Points</label>
              <div class="value">{{ userObj.victorPoints.toFixed(2) }}</div>
            </div>
            <div class="stat">
              <label>Tier</label>
              <div class="value">
                {{  userObj.victorPoints > 1800 ? 'Tier 4 Pro level' :
                    userObj.victorPoints > 1200 ? 'Tier 3 Advanced' :
                    userObj.victorPoints > 600 ? 'Tier 2 Intermediate' :
                    userObj.victorPoints < 601 ? 'Tier 1 Beginners' : 'Error' }}
              </div>
            </div>
            <div class="stat">
              <label>Plays</label>
              <div class="value">{{ userObj.handness }}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6"><app-current-fixtures [userObj]="userObj"></app-current-fixtures></div>
          <div class="col-md-6"><app-current-matches [userObj]="userObj"></app-current-matches></div>
        </div>
        <app-single-matches [userObj]="userObj"></app-single-matches>
        <app-double-matches [userObj]="userObj"></app-double-matches>
        <app-player-notifications [userObj]="userObj"></app-player-notifications>
      </ng-container>
    </div>
  </div>
</div>

