<ng-container *ngIf="isLoading">
  <div class="loader">
    <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
  </div>
</ng-container>
<ng-container *ngIf="!isLoading">
  <div class="detail-box" *ngIf="isUserDataLoaded">
    <div class="overview-details">
      <div class="detail">
        <label>Viictor Points</label>
        <div class="b-text">{{ currUserObj.victorPoints.toFixed(2) }}</div>
      </div>
      <div class="detail">
        <label>Tier</label>
        <div class="b-text">
          {{  currUserObj.victorPoints > 1800 ? 'Tier 4 Pro level' :
          currUserObj.victorPoints > 1200 ? 'Tier 3 Advanced' :
          currUserObj.victorPoints > 600 ? 'Tier 2 Intermediate' :
          currUserObj.victorPoints < 601 ? 'Tier 1 Beginners' : 'Error' }}
        </div>
      </div>
      <div class="detail">
        <label>Plays</label>
        <div class="text">{{ currUserObj.handness }}</div>
      </div>
    </div>

    <div class="bio-details">
      <label>Bio</label>
      <div class="bio">{{ currUserObj.bio }}</div>
    </div>
  </div>
</ng-container>

<div *ngIf="isError" class="alert alert-danger">Could not load. Please refresh the page</div>
