<div class="container-fluid">
  <app-shade></app-shade>
  <app-page-heading pageHeading="All Tournaments"></app-page-heading>
</div>
<div class="container-white">
  <div class="container">
    <app-create-challenge></app-create-challenge>
    <div class="clearfix"></div>
    <div class="navigate-components">
      <div class="inner">
        <div
          [ngClass]="{'active': router.url.includes('tennis/tournaments/category/user-created')}"
          (click)="watchUrl('user-created')">
          User Created Tournaments</div>
        <div
          [ngClass]="{'active': router.url.includes('tennis/tournaments/category/monthly')}"
          (click)="watchUrl('monthly')">
          Monthly Tournaments</div>
      </div>
    </div>
    <div class="total-tournaments">
      <strong>Total Tournaments: {{tournamentCount}}</strong>
      <hr>
      <div class="filters">
        <h3>Filters</h3>
        <div class="row">
          <div class="col-6 col-sm-4 col-md-3">
            <label>Tournament Type</label>
            <select class="form-control" #matchTypeFilter (change)="applyFilter($event, 'filterMatchType')">
              <option value="">All Tournaments</option>
              <option value="knockout">Knockout Tournaments</option>
              <option value="box">Box-Match Tournaments</option>
              <option value="one-on-one">Head 2 Head Challenges</option>
            </select>
          </div>

          <div class="col-6 col-sm-4 col-md-2">
            <label>City</label>
            <ng-select
              [(ngModel)]="citySelect"
              [items]="cities"
              bindLabel="name"
              bindValue="name"
              (change)="applyFilter($event, 'filterCity')"
              placeholder="Select City">
            </ng-select>
          </div>
          <div class="col-6 col-sm-4 col-md-2">
            <label>Gender</label>
            <select class="form-control" #genderFilter (change)="applyFilter($event, 'filterGender')">
              <option value="">All</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="mixed">Mixed</option>
            </select>
          </div>
          <div class="col-6 col-sm-4 col-md-2">
            <label>Wheelchair</label>
            <select class="form-control" #wheelChairFilter (change)="applyFilter($event, 'filterWheelChair')">
              <option value="">All</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="col-6 col-sm-4 col-md-2">
            <label>Tier</label>
            <select class="form-control" #tierFilter (change)="applyFilter($event, 'filterTier')">
              <option value="">All Tiers</option>
              <option value="600">Tier 1 Beginners</option>
              <option value="1200">Tier 2 Intermediate</option>
              <option value="1800">Tier 3 Advanced</option>
              <option value="3500">Tier 4 Pro level</option>
            </select>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <label>Tournament State</label>
            <select class="form-control" #stateFilter (change)="applyFilter($event, 'filterState')">
              <option value="">All</option>
              <option value="registration-open">Open</option>
              <option value="registration-closed">In Progress</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <label>Age</label>
            <select class="form-control" #ageFilter (change)="applyFilter($event, 'filterAge')">
              <option value="">All Ages</option>
              <option value="open">Open</option>
              <option value="o40">Over 40</option>
              <option value="o50">Over 50</option>
              <option value="o60">Over 60</option>
              <option value="o70">Over 70</option>
              <option value="o80">Over 80</option>
              <option value="u11">11 and under green ball</option>
              <option value="u13">13 and under</option>
              <option value="u15">15 and under</option>
            </select>
          </div>
          <div class="col-6 col-sm-4 col-md-2">
            <label>Category</label>
            <select class="form-control" #categoryFilter (change)="applyFilter($event, 'filterCategory')">
              <option value="">Both</option>
              <option value="singles">Singles</option>
              <option value="doubles">Doubles</option>
            </select>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <button (click)="clearFilters()">Clear Filters</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isLoading" class="loader">
      <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
    </div>
    <div *ngIf="!isTournaments && !isLoading">
      <div class="gray-table-container">
        <div class="no-tournaments">There are no tournaments</div>
      </div>
    </div>
    <ng-container *ngIf="isTournaments && !isLoading && page !== undefined">
      <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
      <app-tournaments-pagination [currentPage]="page" [pagesLength]="tournaments.length"></app-tournaments-pagination>
    </ng-container>

  </div>
  <app-newsletter></app-newsletter>
</div>
