<div class="container-fluid">
  <app-shade></app-shade>
</div>

<div class="container-white">
  <div class="container">
    <div class="gray-table-container">

      <div class="loader" *ngIf="isLoading">
        <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
      </div>

      <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="isChatFound else noChats">
          <div class="users-tab">
            <div class="users-tab-inner">
              <div class="user" *ngFor="let user of users" [ngClass]="{'active': user.chatId === currChatId}" (click)="openChat(user.chatId)">
                <div class="img-block">
                  <img [src]="user.userTwo.image" alt="player-image">
                </div>
                <div class="player-name" [ngStyle]="{'font-weight': !user.isRead ? '500' : 'normal'}">
                  {{ user.userOne.id !== authService.user.uid ? user.userOne.name : user.userTwo.name }}
                </div>
              </div>
            </div>
          </div>
  
          <div class="messages-tab">
            <div class="loader-message loader" *ngIf="isChatLoading">
              <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
            </div>
            <div class="messages-tab-inner" *ngIf="!isChatLoading">
              
              <div class="no-chat" *ngIf="!isChatLoaded">
                <h3>Select a chat from the <span></span> panel</h3>
              </div>
              <div class="message-panel-wrapper" *ngIf="isChatLoaded">
                <div class="messages-wrapper">
                  <div class="messages">
                    <div class="message" 
                        [ngClass]="authService.user.uid === message.id ? 'self-user' : 'other-user'"
                        *ngFor="let message of chat">{{ message.content }}
                    </div>
                  </div>
                </div>
                <div class="input-wrapper">
                  <div *ngIf="!isSendMessageLoading" class="message-input">
                    <form [formGroup]="chatForm">
                      <input type="text" class="form-control" formControlName="message">
                      <button type="submit" (click)="sendMessage(chatForm)" [disabled]="!isSendMessageActive">Send</button>
                    </form>
                  </div>
                  <div *ngIf="isSendMessageLoading" class="loader-message">
                    <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
                  </div>
                  <div class="alert alert-danger message-sent" *ngIf="isMessageError">Could not send message. Please refresh the page and try again.</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container> 
      </ng-container>
      <div class="clearfix"></div>
  </div>
</div>

<ng-template #noChats>
  <div class="alert">Currently you have no new chats. You can start a chat by going to a player profile and sending a message.</div>
</ng-template>
