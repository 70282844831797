<app-registration-template>
  <div class="form">

    <div *ngIf="isLoading" class="loader-gif">
      <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
    </div>

    <ng-container *ngIf="!isLoading">

      <ng-container *ngIf="isResetPassword">
        <div class="form-header">Reset Password</div>
        <div *ngIf="isUrlValid && !passwordUpdated" class="form-body">
          <form [formGroup]="formGroup">
            <div class="input-fields">
              <input class="form-control" formControlName="newPassword" type="password" placeholder="New Password"
                [ngClass]="{'valid': formGroup.controls.newPassword.valid, 'error': formGroup.controls.newPassword.invalid && (formGroup.controls.newPassword.touched || submitAttempt)}">
              <p class="error-message"
                *ngIf="formGroup.controls.newPassword.invalid && formGroup.controls.newPassword.value === ''">Password is
                required.</p>
              <p class="error-message" *ngIf="error-message"> {{ errorMessage }} </p>
            </div>
            <div class="form-check first">
              <label class="form-check-label">
                Enter your new password. The length of the password must be 6 characters
              </label>
            </div>
            <p *ngIf="errorMessage" class="error-message backend">{{errorMessage}}</p>

            <div *ngIf="loading" class="loader-gif">
              <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
            </div>

            <button *ngIf="!loading" class="btn btn-submit" (click)="resetPassword(formGroup)"
              [disabled]="formGroup.controls.newPassword.invalid || loading">Reset</button>

          </form>
        </div>
        <div *ngIf="passwordUpdated" class="form-body success">
          <p>Your password is has been changed. Please login with your new password.</p>

          <button class="btn btn-submit" (click)="goToHome()">Go TO Home Page</button>
        </div>
        <div *ngIf="!isUrlValid" class="form-body success">
          <p>Your url is invalid. Please try again.</p>

          <button class="btn btn-submit" (click)="goToHome()">Go TO Home Page</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isVerifyEmail">
        <div *ngIf="!isUrlValid" class="form-body success">
          <p>Your url is invalid. Please try again.</p>
          <button class="btn btn-submit" (click)="goToHome()">Go TO Home Page</button>
        </div>
        <div *ngIf="isUrlValid === true" class="form-body success">
          <p> Your Email is verified</p>
          <button class="btn btn-submit" [routerLink]="['/tennis/users/login']">Login</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isParentPermission">
        <div class="form-header">Account Permission</div>
        <div *ngIf="isUrlValid == undefined" class="form-body success">
          <p>Hello, your child <strong>{{ getUserName }}</strong> has requested to join this website with his email
            <strong>{{ getUserEmail }}</strong>. Do you allow him/her to join?</p>
          <div *ngIf="!loading" class="allow-buttons">
            <button class="btn-submit" type="button" (click)="isPermissionGranted('allow')">Yes</button>
            <button class="btn-submit" type="button" (click)="isPermissionGranted('disllow')">No</button>
          </div>

          <div *ngIf="loading" class="loader-gif">
            <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
          </div>
        </div>

        <div *ngIf="permissionGranted" class="form-body success">
          Permission Granted. Thank you for your response
        </div>

        <div *ngIf="permissionGranted === false" class="form-body success">
          Permission Denied. Thank you for your response
        </div>

        <div *ngIf="isUrlValid === false" class="form-body success">
          <p>Your url is invalid. Please try again.</p>
          <button class="btn btn-submit" (click)="goToHome()">Go TO Home Page</button>
        </div>
      </ng-container>

    </ng-container>

  </div>


</app-registration-template>