
<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <div class="panel">
        <div class="panel-inner">
          <h3 class="top-heading">Website Updates</h3>
            <div *ngIf="isLoading" class="loader">
              <img src="../../../assets/image/ajax-loader.gif" alt="no-img">
            </div>
            <ng-container *ngIf="!isLoading">
              <div *ngIf="hasUpdates" class="content">
                <div class="update" *ngFor="let update of updates">
                  <div class="timestamp">{{ getFormattedDate(update.timestamp) }} <div class="line"></div></div>
                  <div class="fixes">
                    <h4>Fixes</h4>
                    <ul>
                      <li *ngFor="let fix of update.fixes">{{ fix }}</li>
                    </ul>
                  </div>
                  <div class="fixes">
                    <h4>Improvements</h4>
                    <ul>
                      <li *ngFor="let improvement of update.improvements">{{ improvement }}</li>
                    </ul>
                  </div>
                  <div class="fixes">
                    <h4>New Features</h4>
                    <ul>
                      <li *ngFor="let feature of update.newFeatures">{{ feature }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div *ngIf="!hasUpdates" class="content">
                <p>Currently there no updates listed.</p>
              </div>
            </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
