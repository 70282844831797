import { TransactionsComponent } from './admin-panel/transactions/transactions.component';
import { WebsiteUpdatesComponent } from './misc/website-updates/website-updates.component';
import { MonthlyTournamentsComponent } from './tournaments/monthly-tournaments/monthly-tournaments.component';
import { UserCreatedTournamentsComponent } from './tournaments/user-created-tournaments/user-created-tournaments.component';
import { QueriesComponent } from './admin-panel/queries/queries.component';
import { UsersComponent } from './admin-panel/users/users.component';
import { DisputesComponent } from './admin-panel/disputes/disputes.component';
import { ContactUsComponent } from './misc/contact-us/contact-us.component';
import { AboutUsComponent } from './misc/about-us/about-us.component';
import { PrivacyPolicyComponent } from './misc/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './misc/terms-of-use/terms-of-use.component';
import { RegistrationTermsComponent } from './registration/registration-terms/registration-terms.component';
import { MatchEtiquetteComponent } from './misc/about-us/match-etiquette/match-etiquette.component';
import { RulesComponent } from './misc/rules/rules.component';
import { VictorRankingsComponent } from './misc/about-us/victor-rankings/victor-rankings.component';
import { MonthlyKnockoutComponent } from './misc/rules/monthly-knockout/monthly-knockout.component';
import { HowItWorksComponent } from './misc/about-us/how-it-works/how-it-works.component';
import { HeadToHeadChallengesComponent } from './misc/rules/head-to-head-challenges/head-to-head-challenges.component';
import { FaqsComponent } from './misc/rules/faqs/faqs.component';
import { BoxLeaguesComponent } from './misc/rules/box-leagues/box-leagues.component';
import { PlayerTennisTournamentTeamsReceivedComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams-received/player-tennis-tournament-teams-received.component';
import { PlayerTennisTournamentTeamsSentComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams-sent/player-tennis-tournament-teams-sent.component';
import { PlayerTennisTournamentTeamsAcceptedComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams-accepted/player-tennis-tournament-teams-accepted.component';
import { PlayerTennisGeneralTeamsComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-general-teams.component';
import { PlayerTennisTournamentTeamsComponent } from './profile/player-tennis-teams/player-tennis-tournament-teams/player-tennis-tournament-teams.component';
import { PlayerTennisTeamsComponent } from './profile/player-tennis-teams/player-tennis-teams.component';
import { PlayerTennisTeamsSentComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-teams-sent/player-tennis-teams-sent.component';
import { PlayerTennisTeamsAcceptedComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-teams-accepted/player-tennis-teams-accepted.component';
import { PlayerTennisTeamsInvitesComponent } from './profile/player-tennis-teams/player-tennis-general-teams/player-tennis-teams-invites/player-tennis-teams-invites.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ChatsComponent } from './profile/chats/chats.component';
import { TournamentPageComponent } from './tournaments/tournament-page/tournament-page.component';
import { TournamentsListComponent } from './tournaments/tournaments-list/tournaments-list.component';
import { HomeComponent } from './home/home.component';
import { PlayersComponent } from './players/players.component';
import { PlayerTennisChallengesComponent } from './profile/player-tennis-challenges/player-tennis-challenges.component';
import { PlayerTennisTournamentsComponent } from './profile/player-tennis-tournaments/player-tennis-tournaments.component';
import { PlayerTennisDashboardComponent } from './profile/player-tennis-dashboard/player-tennis-dashboard.component';
import { PlayerTennisAccountComponent } from './profile/player-tennis-account/player-tennis-account.component';
import { PlayerTennisWalletComponent } from './profile/player-tennis-wallet/player-tennis-wallet.component';
import { PlayerMainPageComponent } from './public-profile/player-main-page/player-main-page.component';
import { PlayerOverviewComponent } from './public-profile/player-overview/player-overview.component';
import { PlayerGamesHistoryComponent } from './public-profile/player-games-history/player-games-history.component';
import { PlayerStatisticsHistoryComponent } from './public-profile/player-statistics-history/player-statistics-history.component';
import { CreateProfileComponent } from './profile/create-profile/create-profile.component';
import { CreateTournamentComponent } from './profile/create-tournament/create-tournament.component';
import { VerifyScoreComponent } from './tournaments/verify-score/verify-score.component';
import { PlayerTennisChallengesCompletedComponent } from './profile/player-tennis-challenges/player-tennis-challenges-completed/player-tennis-challenges-completed.component';
import { PlayerTennisChallengesUpcomingComponent } from './profile/player-tennis-challenges/player-tennis-challenges-upcoming/player-tennis-challenges-upcoming.component';
import { PlayerTennisChallengesOngoingComponent } from './profile/player-tennis-challenges/player-tennis-challenges-ongoing/player-tennis-challenges-ongoing.component';
import { EmailDashboardComponent } from './admin-panel/email-dashboard/email-dashboard.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThemeGuard } from './guards/theme/theme.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { AnonymousGuard } from './guards/anonymous/anonymous.guard';
import { NewprofileGuard } from './guards/newprofile/newprofile.guard';
import { AdminGuard } from './guards/admin/admin.guard';
import { UploadGalleryComponent } from './admin-panel/upload-gallery/upload-gallery.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tennis/home',
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    component: AdminPanelComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'users'
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'disputes',
        component: DisputesComponent
      },
      {
        path: 'queries',
        component: QueriesComponent
      },
      {
        path: 'transactions',
        component: TransactionsComponent
      },
      {
        path: 'email-dashboard',
        component: EmailDashboardComponent
      },
      {
        path: 'gallery',
        component: UploadGalleryComponent
      },
      {
        path: '**',
        redirectTo: 'admin'
      }
    ]
  },
  {
    path: 'tennis',
    canActivate: [ThemeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'players',
        component: PlayersComponent
      },
      {
        path: 'create-profile',
        canActivate: [AuthGuard, NewprofileGuard],
        component: CreateProfileComponent
      },
      {
        path: 'verify-score',
        component: VerifyScoreComponent
      },
      {
        path: 'user',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'account',
            component: PlayerTennisAccountComponent
          },
          {
            path: 'challenges',
            component: PlayerTennisChallengesComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'accepted'
              },
              {
                path: 'accepted',
                component: PlayerTennisChallengesCompletedComponent,
              },
              {
                path: 'sent',
                component: PlayerTennisChallengesOngoingComponent,
              },
              {
                path: 'received',
                component: PlayerTennisChallengesUpcomingComponent,
              },
            ]
          },
          {
            path: 'tournaments',
            component: PlayerTennisTournamentsComponent
          },
          {
            path: 'wallet',
            component: PlayerTennisWalletComponent
          },
          {
            path: 'dashboard',
            component: PlayerTennisDashboardComponent
          },
          {
            path: 'teams',
            component: PlayerTennisTeamsComponent,
            children: [
              {
                path: '',
                redirectTo: 'general-teams',
                pathMatch: 'full'
              },
              {
                path: 'general-teams',
                component: PlayerTennisGeneralTeamsComponent,
                children: [
                  {
                    path: '',
                    redirectTo: 'accepted',
                    pathMatch: 'full'
                  },
                  {
                    path: 'accepted',
                    component: PlayerTennisTeamsAcceptedComponent
                  },
                  {
                    path: 'sent',
                    component: PlayerTennisTeamsSentComponent
                  },
                  {
                    path: 'received',
                    component: PlayerTennisTeamsInvitesComponent
                  },
                ]
              },
              {
                path: 'tournament-teams',
                component: PlayerTennisTournamentTeamsComponent,
                children: [
                  {
                    path: '',
                    redirectTo: 'accepted',
                    pathMatch: 'full'
                  },
                  {
                    path: 'accepted',
                    component: PlayerTennisTournamentTeamsAcceptedComponent
                  },
                  {
                    path: 'sent',
                    component: PlayerTennisTournamentTeamsSentComponent
                  },
                  {
                    path: 'received',
                    component: PlayerTennisTournamentTeamsReceivedComponent
                  },
                ]
              },
            ]
          },
          {
            path: 'create-tournament',
            component: CreateTournamentComponent
          },
          {
            path: 'messages',
            component: ChatsComponent
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: 'account'
          },
        ]
      },
      {
        path: 'player/:playerId',
        component: PlayerMainPageComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'overview'
          },
          {
            path: 'overview',
            component: PlayerOverviewComponent
          },
          {
            path: 'games-history',
            component: PlayerGamesHistoryComponent,
          },
          {
            path: 'statistics-history',
            component: PlayerStatisticsHistoryComponent,
          },
        ]

      },
      {
        path: 'users',
        canActivate: [AnonymousGuard],
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'tournaments',
        component: TournamentsListComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'category/user-created'
          },
          {
            path: 'category',
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'user-created'
              },
              {
                path: 'user-created',
                component: UserCreatedTournamentsComponent
              },
              {
                path: 'monthly',
                component: MonthlyTournamentsComponent
              },
            ]
          },
        ]
      },
      {
        path: 'tournaments/:id',
        component: TournamentPageComponent,
      },
      {
        path: 'lets-play',
        component: RulesComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'box-leagues'
          },
          {
            path: 'box-leagues',
            component: BoxLeaguesComponent
          },
          {
            path: 'head-to-head-challenges',
            component: HeadToHeadChallengesComponent,
          },
          {
            path: 'monthly-knockout',
            component: MonthlyKnockoutComponent,
          },
          {
            path: '**',
            redirectTo: 'tennis/lets-play',
          },
        ]

      },
      {
        path: 'about-us',
        component: AboutUsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'how-it-works'
          },
          {
            path: 'how-it-works',
            component: HowItWorksComponent,
          },
          {
            path: 'match-etiquette',
            component: MatchEtiquetteComponent,
          },
          {
            path: 'viictor-rankings',
            component: VictorRankingsComponent,
          },
          {
            path: '**',
            redirectTo: 'tennis/about-us',
          },
        ]

      },
      {
        path: 'faqs',
        component: FaqsComponent,
      },
      {
        path: 'website-updates',
        component: WebsiteUpdatesComponent,
      },
    ]
  },
  {
    path: 'ping-pong',
    canActivate: [ThemeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeComponent
      }
    ]
  },
  {
    path: 'boxing',
    canActivate: [ThemeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeComponent
      }
    ]
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'registration-terms',
    component: RegistrationTermsComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: '**',
    redirectTo: 'tennis/home'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
