export const tierArray = [
  {
    name: 'Tier 1 Beginners',
    value: 1,
  },
  {
    name: 'Tier 2 Intermediate',
    value: 2,
  },
  {
    name: 'Tier 3 Advanced',
    value: 3,
  },
  {
    name: 'Tier 4 Pro Level',
    value: 4,
  },
];

export const overAgeArray = [
  {
    name: 'Over 40',
    value: 40,
  },
  {
    name: 'Over 50',
    value: 50,
  },
  {
    name: 'Over 60',
    value: 60,
  },
  {
    name: 'Over 70',
    value: 70,
  },
  {
    name: 'Over 80',
    value: 80,
  },
];

export const underAgeArray = [
  {
    name: 'Under 11',
    value: 11,
  },
  {
    name: 'Under 13',
    value: 13,
  },
  {
    name: 'Under 15',
    value: 15,
  },
];
