import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-template',
  templateUrl: './registration-template.component.html',
  styleUrls: ['./registration-template.component.scss']
})
export class RegistrationTemplateComponent implements OnInit {
  currUrl: any;
  isLogin = false;
  isSignUp = false;
  isForgotPass = false;

  constructor(public router: Router) { 
    this.currUrl = router.url;
  }

  ngOnInit() {
    if(this.currUrl == '/login') {
      this.isLogin = !this.isLogin;
    }
    if(this.currUrl == '/sign-up') {
      this.isSignUp = !this.isSignUp;
    }
    if(this.currUrl == '/forget-password') {
      this.isForgotPass = !this.isForgotPass;
    }
  }

}
