<div class="users">
  <div class="users-inner">
    <h3 class="top-heading">Users</h3>
    <div class="loader" *ngIf="isLoading">
      <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
    </div>
    <div class="users-list" *ngIf="isUsersFound">
      <ul>
        <li>
          <div class="s-no">S.no</div>
          <div class="content">
            <div class="upper-block">
              <div class="name">Name</div>
              <div class="id">ID</div>            
              <div class="status">Profile Status</div>
            </div>
          </div>
        </li>
        <li *ngFor="let user of users; let i = index;">
          <div class="s-no">{{ i+1 }}</div>
          <div class="content">
            <div class="upper-block">
              <div class="name">{{ user.firstName + " " + user.lastName }}</div>
              <div class="id">{{ user.id }}</div>            
              <div class="status">{{ user.isProfileActive ? 'Active' : 'Inactive' }}</div>
            </div>
            <div class="lower-block">
              <div class="ban">
                <label>Banned</label>
                <div  class="on-off-button" 
                      [ngClass]="{'on': !user.isProfileActive, 'off': user.isProfileActive}" 
                      (click)="toggleBan(user.id, i)">
                  <div>{{ user.isProfileActive ? "NO" : "YES" }}</div>
                </div>
              </div>
              <div class="flag">
                <label>Flag</label>
                <div class="flag-data">
                  <ng-container *ngIf="user.flagCount else noFlags">
                    <div *ngIf="user.flagCount > 0 else noFlags">
                      <img  src="../../../assets/image/flag.webp" alt="flag">
                      <span>x{{ user.flagCount }}</span>
                    </div>
                    <div class="add-subtact-buttons">
                      <div class="add" (click)="addRemoveFlag('add', user.id, i)">+</div>
                      <div class="subtract" (click)="addRemoveFlag('remove', user.id, i)">-</div>
                    </div>
                  </ng-container>
                  <ng-template #noFlags>
                    <div>
                      <img src="../../../assets/image/flag-inactive.webp" alt="no-flag">
                    </div>
                    <div class="add-subtact-buttons">
                      <div class="add" (click)="addRemoveFlag('add', user.id, i)">+</div>
                      <div class="subtract" (click)="addRemoveFlag('remove', user.id, i)">-</div>
                    </div>
                  </ng-template>
                  
                </div>                
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
