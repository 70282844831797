import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss']
})
export class QueriesComponent implements OnInit {
  isQueriesFound: boolean;
  queries: any;
  queriesUnresolved: any[] = [];
  queriesResolved: any[] = [];
  currQuery: any;
  isLoading: boolean;

  constructor(private firestore: AngularFirestore) { }

  getFormattedDate(timestamp) {
    if(timestamp) {
      let getCurrentDate = new Date(timestamp)
      let getYear = getCurrentDate.getFullYear()
      let getMonth = getCurrentDate.getMonth()
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let getDate = getCurrentDate.getDate()
      return `${getDate}/${months[getMonth]}/${getYear}`
    }
    return `Couldn't find date`
  }

  viewQuery(query) {
    this.currQuery = query;
  }

  async markResolved(query) {
    try {
      let state = query.state === 'unresolved' ? 'resolved' : 'unresolved';
      await this.firestore.collection('Queries').doc(query.queryId).update({ state })
      query.state = state;
    } catch(e) {
      console.log(e)
    }
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      let allQueries =  await this.firestore.collection('Queries', ref => ref 
                        .orderBy('timestamp', 'desc')).get().toPromise()
      if(allQueries.docs.length > 0) {
        let queries: any[] = allQueries.docs.map(el => {
          let tempObj: any = {}
          tempObj = el.data();
          tempObj.queryId = el.id;
          return tempObj         
        })
        this.queriesUnresolved = queries.filter((el: any) => el.state === 'unresolved');
        this.queriesResolved = queries.filter((el: any) => el.state === 'resolved');
        this.isQueriesFound = true;
      } else {
        this.isQueriesFound = false;
      }
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

}
