
<div class="notifications">
  <h2 class="table-header">My Notifications</h2>
  <div class="player-notifications">
    <div class="notifications" *ngIf="isNotifications else noNotifications">
      <div class="notification"
            *ngFor="let notification of notifications; let i = index;"
            (click)="goToNotificationLink(notification.redirectLink, i)">
        <div class="img-block">
          <img src="./../../../assets/image/notification-bar.webp" alt="no-img">
        </div>
        <div class="text-block">
          <div class="date">{{ getNotificationTime(notification.timestamp) }}</div>
          <div class="text">{{ notification.title }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noNotifications>
  <div class="alert mb-0">You have no notifications.</div>
</ng-template> 