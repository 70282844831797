<div class="container-fluid">
  <app-shade></app-shade>
  <app-page-heading pageHeading="Registration Terms"></app-page-heading>
</div>
<div class="container-white">
  <div class="container">
    <div class="gray-table-container">
      <p>Thank you for creating an account (Account) via the Viictor website (the Website).</p>
      <p>It is important to us that you enjoy your time using the Website. Therefore, we
        recommend that you read the following terms that relate to the services provided by
        Viictor Pty Ltd A.C.N.638 794 760 (we, us, our, or Viictor), via the Website.</p>
      <p>Your decision to create an Account with us means that you acknowledge that you
        have read, understood and agree to be bound by the terms contained in this
        agreement (these Terms). These Terms govern your use of our services (the
        Services). Your access to the Services is conditional upon your acceptance and
        ongoing compliance with these Terms.</p>
      <p>We may at any time and at our sole discretion, amend, revise or modify these Terms
        and/or the scope of the Services. This may result in additional services being
        provided or some of the existing services being terminated.</p>
      <h2>How to access the Services</h2>
      <p>Viictor provides you, the registrant (Registrant), with access to the Website for the
        purpose of participating in sporting events with other Registrants.</p>
      <p>You must be a current and valid Registrant of Viictor, to access the Services and the
        associated benefits.</p>
      <p>Once you become a Registrant, registration is ongoing unless you terminate your
        agreement with us, in accordance with these Terms.</p>
      <p>Viictor reserves the sole right to make changes to our Services from time to time.</p>
      <h2>Registrtion</h2>
      <p>Registration entitles you to a non-exclusive, non-transferable right to access and use
        the Services, subject to the terms and conditions contained in these Terms.
        Registration occurs when you create an Account via the Website (the Registration
        Date).</p>
      <p>When creating an Account, you warrant and represent that:</p>
      <ul>
        <li>If you are aged 17 years or younger, you have obtained consent to register for
          an Account, from your parent or legal guardian;</li>
        <li>all information provided is true, accurate and complete to the best of your
          knowledge;</li>
        <li>you have authority to enter in to these Terms;</li>
        <li>you have read and understand the terms and conditions contained within these
          Terms and agree to be bound by them;</li>
        <li>you will only use the Services for lawful purposes;</li>
        <li>you are not a convicted sex offender;</li>
        <li>we have not previously disabled your Account for violations of these Terms or
          any of our policies;</li>
        <li>you will not use the Service in connection with money laundering;</li>
        <li>you will not use the Services and/or the Website to harass, offend, threaten,
          harm (mentally or physically), or otherwise menace any other person; and</li>
        <li>you are not prohibited from receiving the Services, under applicable laws.</li>
      </ul>
      <p>You further agree and acknowledge that you are solely responsible for keeping all
        relevant and personal information, including but not limited to your Account’s
        username, passwords and other security features, confidential and secure to prevent
        unauthorised use of your Account. If you reasonably suspect that the security of your
        Account has been compromised, you must notify Viictor immediately. You agree to
        be solely responsible for any conduct associated with, or originating from your
        Account.</p>
      <p>We are not liable or responsible in any way for any damages, liabilities, costs and
        expenses (including any direct, indirect, incidental, special or consequential damages
        and reasonable legal fees and expenses) arising out of, or related to, any breaches
        of the Account’s security resulting from your act or omission to act.</p>
      <p>From time to time, you may change information relating to your Account. You
        undertake to update your Account details with true and accurate information at all
        times. We undertake to take all due care with any information you provide to us when
        creating, updating and accessing your Account, and we will comply with our 
        <a [routerLink]="['/privacy-policy']">Privacy Policy</a> at all times.</p>
      <h2>Term of agreement</h2>
      <h3>Commencement</h3>
      <p>These Terms commence on the Registration Date and will continue until such time
        that they are terminated under these Terms.</p>
      <h3>Termination</h3>
      <p>Viictor may terminate these Terms if you have committed any material breach of
        these Terms and, in the case of a breach capable of being remedied, you have failed
        to remedy the breach within five (5) days after the receipt of a written request from
        Viictor.</p>
      <p>You may terminate these Terms by providing written notice to us via email.</p>
      <p>Upon termination, Viictor will immediately disable your user name and password and
        consequently, deny you access to the Website and/or the Services and your
        Account.</p>
      <h2>Representations and warranties</h2>
      <p>Subject to all applicable laws, the Services are provided to you on an as is basis and
        we make no representation or warranty, express or implied, including any implied
        warranty of merchantability or fitness for a particular purpose.</p>
      <h2>Exclusions and Limitations of Liability</h2>
      <p>To the extent permitted by law, Viictor will not be liable for any loss or damages,
        whether direct, indirect, incidental or consequential, arising from:</p>
      <ol>
        <li>suspension or cancellation of an Account;</li>
        <li>your use of the Services provided via the Website;</li>
        <li>any interruptions or delays in accessing the Website, the Services or any third
          party websites; or</li>
        <li>circumstances beyond the reasonable control of Viictor,</li>
      </ol>
      <p>whether in contract, tort (including negligence) or otherwise.</p>
      <p>All conditions and warranties, which may be implied by law into these Terms, are
        excluded except to the extent that it would be unlawful to do so.</p>
      <p>To the extent permitted by law, our liability for breach of any implied warranty or
        condition, which cannot be excluded by these Terms, is limited, at our option, to one
        or more of the following:</p>
      <ul>
        <li>the resupply of the Services; or</li>
        <li>the payment of the cost of having the Services resupplied.</li>
      </ul>
      <h2>Indemnity</h2>
      <p>To the fullest extent permitted by law, you agree to indemnify Viictor from any liability
        for any loss, damage, costs or expense whether direct, indirect, incidental, special
        and/or consequential, including loss of profits, suffered by you or claims made
        against you which result from your use of the Services.</p>
      <h2>Notices</h2>
      <p>Any notice to be given by Viictor under these Terms or in relation to our Services will
        be in writing, and sent to the email address provided by you in respect of the relevant
        Account, or by posting the relevant information on the Website. Such notice shall be
        deemed to have been given at the time the email was sent by Viictor or at the time
        the relevant information was posted on the Website.</p>
      <h2>Assignment</h2>
      <p>You shall not assign, transfer or sub-licence any of your rights or obligations under
        these Terms, except with the prior written consent of Viictor.</p>
      <h2>Conduct and Rules</h2>
      <p>By agreeing to and accepting these Terms, you also agree to any rules, regulations,
        code of conduct or reasonable direction that may be provided or distributed by Victor,
        from time to time, including on the Website.</p>
      <h2>Privacy</h2>
      <p>By agreeing to and accepting the Terms, you also agree to our
        <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</p>
      <h2>General</h2>
      <p>If any provision of these Terms is found to be invalid or unenforceable by a Court of
        Law, such invalidity or unenforceability will not affect the remainder of the document,
        which will continue in full force and effect.</p>
      <p>All rights not expressly granted in these Terms are reserved.</p>
      <p>If we do not act in relation to a breach of these Terms by you, this does not waive our
        rights to act with respect to subsequent or similar breaches of these Terms by you.</p>
      <h2>Jurisdiction and applicable Law</h2>
      <p>The Terms of Use are governed by and construed in accordance with the laws of the
        State of Western Australia, without regard to conflict of law provisions. You
        irrevocably and unconditionally submit to the exclusive jurisdiction of the Courts of
        Western Australia and Courts of appeal from them for determining any dispute
        concerning the Terms of Use. This clause will survive a termination of these Terms.</p>
        <p><i>These Registration Terms were last updated on 26th April 2020.</i></p>
    </div>
  </div>
</div>