import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-player-tennis-general-teams',
  templateUrl: './player-tennis-general-teams.component.html',
  styleUrls: ['./player-tennis-general-teams.component.scss']
})
export class PlayerTennisGeneralTeamsComponent implements OnInit {

  currUrl;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  watchUrl() {
    this.currUrl = this.router.url;
    this.activatedRoute.url.subscribe(val => {
      this.currUrl = this.router.url;
    })
  }

  ngOnInit(): void {
    this.watchUrl();
  }

}
