import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/internal/operators';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-player-details',
  templateUrl: './player-details.component.html',
  styleUrls: ['./player-details.component.scss']
})
export class PlayerDetailsComponent implements OnInit {

  currUrl;
  playerId;
  currUserObj: any;
  currUserRawObj: any;
  isUserDataLoaded: boolean;
  isChallengeModalOpen: boolean;
  success: boolean;
  isChallengeButtonActive: boolean;
  isChallengeAlreadySent: boolean;
  isUserLoggedIn: boolean;
  isLoading: boolean = true;

  isOpenForChallenge: boolean;

  isChatModalOpen: boolean;
  chatForm: FormGroup;
  chatDoc;
  chatDocData;
  isChatFound;
  isSendMessageLoading: boolean;
  isMessageSent: boolean;
  isMessageError: boolean;

  isInviteModalOpen: boolean;
  isInviteSent: boolean;
  isInviteButtonActive: boolean
  isTeamInviteAlreadySent: boolean;
  isUserInTeam: boolean;
  isUserBetBox: boolean = true;
  betAmount: number = 20;
  isModalLoading: boolean;

  public payPalConfig ? : IPayPalConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private formBuilder: FormBuilder) { }

  async getPlayerDetails() {
    try {
      let params = await this.route.paramMap.pipe(first()).toPromise();
      let playerId = params.get('playerId');
      let snapshot = await this.firestore.collection('Users').doc(playerId).get().toPromise();
      this.currUserRawObj = snapshot;
      this.currUserObj = snapshot.data();
      if (this.currUserObj.dateOfBirth) {
        let playerAge =  new Date().getFullYear() - this.currUserObj.dateOfBirth.split('-')[0];
        this.currUserObj.age = playerAge;
      }
      if(!this.currUserObj.profileImg) {
        this.currUserObj.profileImg = '../../../assets/image/no-img.webp';
      }

      if(this.authService.authenticated()) {
        this.isUserLoggedIn = true;
        if(this.currUrl.includes(this.authService.user.uid)) {
          this.isChallengeButtonActive = false;
        } else {
          this.checkChallengeRequest();
          this.checkIfAlreadyJoinedATeam();
          this.findPreviousChat();
        }
      } else {
        this.isUserLoggedIn = false;
        this.isChallengeButtonActive = false;
      }
      this.isUserDataLoaded = true;
      this.isLoading = false;
    } catch(error) {
      console.log(error)
    }
  }

  openChallengeModal() {
    document.body.classList.add('modal-open');
    this.isChallengeModalOpen = true;
    this.initConfig();
  }

  placeBet() {
    if(this.betAmount > 19) { this.isUserBetBox = false; }
  }

  private initConfig() {
    this.payPalConfig = {
      currency: "AUD",
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'AUTHORIZE',
        purchase_units: [{
          amount: {
            currency_code: 'AUD',
            value: this.betAmount.toString(),
          },
        }]
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [{
          name: 'intent',
          value: 'authorize',
        }]
      },
      onApprove: async (data, actions) => {
        try {
          this.isModalLoading = true;
          let authorization = await actions.order.authorize();
          let authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;
          let orderId = data.orderID
          this.challenge(authorizationID, orderId);
        } catch(e) {
          this.isModalLoading = false;
          console.log(e)
        }
      },
    };
  }

  async challenge(authId, orderId) {
    try {
      await this.firestore.collection('ChallengeRequests').add({
        payments: {
          amount: this.betAmount.toString(),
          playerOneOrderId: orderId,
          playerOneAuthId: authId,
        },
        state: 'pending',
        senderId: this.authService.user.uid,
        receiverId: this.currUserRawObj.id,
      })
      this.success = true;
      this.isModalLoading = false;
      this.closeModal();
      this.isChallengeButtonActive = false;
    } catch(e) {
      this.isModalLoading = false;
      console.log(e)
    }
  }

  async isUserOpenForChallenge() {
    if(typeof this.currUserObj.openForChallenge !== 'undefined') {
      if(!this.currUserObj.openForChallenge) {
        this.isChallengeButtonActive = false
        this.isOpenForChallenge = false;
      } else {
        this.isChallengeButtonActive = true
        this.isOpenForChallenge = true;
      }
    }
  }

  async checkChallengeRequest() {
    try {
      let user = await this.firestore.collection('ChallengeRequests', ref => ref
                .where('senderId', '==', this.authService.user.uid)
                .where('receiverId', '==', this.currUserRawObj.id))
                .get().toPromise();
      if(user.docs.length > 0) {
        this.isChallengeAlreadySent = true;
        this.isChallengeButtonActive = false;
      } else {
        this.isChallengeButtonActive = true;
        this.isUserOpenForChallenge();
      }
    } catch(error) {
      console.log(error)
    }
  }

  async checkTeamRequest() {
    try {
      let user = await this.firestore.collection('TeamRequests', ref => ref
                .where('senderId', '==', this.authService.user.uid)
                .where('receiverId', '==', this.currUserRawObj.id))
                .get().toPromise();
      if(user.docs.length > 0) {
        this.isTeamInviteAlreadySent = true;
        this.isInviteButtonActive = false;
      } else {
        this.isInviteButtonActive = true;
      }
    } catch(error) {
      console.log(error)
    }
  }

  async checkIfAlreadyJoinedATeam(){
    try {
      let user = await this.firestore.collection('Teams', ref => ref
                .where('members', 'array-contains', { email: this.authService.user.email, id: this.authService.user.uid }))
                .get().toPromise();
                
      if(user.docs.length > 0) {
        user.docs.some((el: any, index) => {
          el.data().members.some((el2, index2) => {
            if(el2.id === this.currUserRawObj.id) {
              this.isUserInTeam = true;
              this.isInviteButtonActive = false;
              return true;
            } else {
              if(index === (user.docs.length - 1) && index2 === (el.data().members.length - 1)) {
                this.isInviteButtonActive = true;
                this.checkTeamRequest();
              }
            }
          })
          if(this.isUserInTeam) { return true; }
        })
      } else {
        this.isInviteButtonActive = true;
        this.checkTeamRequest();
      }
    } catch(error) {
      console.log(error)
    }
  }

  openChatModal() {
    document.body.classList.add('modal-open');
    this.isChatModalOpen = true;
  }

  async findPreviousChat() {
    try {
      if(this.authService.authenticated) {
        let previousChat: any =  await this.getChats();
        previousChat.docs.some((el: any) => {
          let data = el.data()
          if(data.members.includes(this.authService.user.uid)) {
            if(data.members.includes(this.currUserRawObj.id)) {
              this.chatDoc = el;
              this.chatDocData = data;
              this.isChatFound = true;
              return true;
            }
          }
        })
      }
    } catch(e) {
      console.log(e)
    }
  }

  async getChats() {
    try {
      let chats = this.firestore.collection('Chats', ref => ref
                  .where('members', 'array-contains', this.authService.user.uid))
                  .get().toPromise();
      return chats;
    } catch(e) {
      console.log(e)
    }
  }

  initializeMessageForm() {
    this.chatForm = this.formBuilder.group({
      message: new FormControl('', Validators.required)
    })
  }

  async sendMessage(form) {
    try {
      if(form.valid) {
        this.isSendMessageLoading = true;
        this.isMessageSent = false;
        this.isMessageError = false;
        let messageObj = {
          content: form.value.message,
          timestamp: Date.now(),
          id: this.authService.user.uid
        }
        if(this.isChatFound) {
          try {
            let addChat =     await this.firestore.collection('Chats').doc(this.chatDoc.id)
                              .collection('messages').add(messageObj);
            let updateTime =  await this.firestore.collection('Chats').doc(this.chatDoc.id)
                              .update({ updatedAt: Date.now(), isRead: false });
            this.isMessageSent = true;
            this.isSendMessageLoading = false;
            this.chatForm.reset();
          } catch(e) {
            this.isMessageError = true;
            this.isSendMessageLoading = false;
            console.log(e)
          }
        } else {
          let createNewChat: any = await this.firestore.collection('Chats').add({
            members: [this.authService.user.uid, this.currUserRawObj.id],
            createdAt: Date.now(),
            updatedAt: Date.now(),
            isRead: false
          })
          let addChat = await this.firestore.collection('Chats').doc(createNewChat.id).collection('messages').add(messageObj)
          this.chatDoc = await this.firestore.collection('Chats').doc(createNewChat.id).get().toPromise();
          this.chatDocData = this.chatDoc.data();
          this.isChatFound = true;
          this.isMessageSent = true;
          this.isSendMessageLoading = false;
          this.chatForm.reset();          
        }
      }
    } catch(e) {
      console.log(e)
      this.isMessageError = true;
      this.isSendMessageLoading = false;
    }
  }

  openInviteModal() {
    document.body.classList.add('modal-open');
    this.isInviteModalOpen = true;    
  }

  async sendInvite() {
    await this.firestore.collection('TeamRequests').add({
      state: 'pending',
      senderId: this.authService.user.uid,
      receiverId: this.currUserRawObj.id,
    })

    this.isInviteSent = true;
    this.isInviteButtonActive = false;
    this.closeModal();
  }
  
  closeModal() {
    document.body.classList.remove('modal-open');
    this.isChallengeModalOpen = false;    
    this.isChatModalOpen = false;
    this.isInviteModalOpen = false;
    this.isUserBetBox = true;
  }

  ngOnInit() {
    this.currUrl = this.router.url;
    this.getPlayerDetails();
    this.initializeMessageForm();
  }
}
