import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { first } from 'rxjs/internal/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-current-matches',
  templateUrl: './current-matches.component.html',
  styleUrls: ['./current-matches.component.scss']
})
export class CurrentMatchesComponent implements OnInit {
  currentMatches: any;
  @Input() userObj;
  hasCurrentMatches: boolean;

  constructor(private router: Router,
    private firestore: AngularFirestore) { }

  async getCurrentMatches() {
    try {
      let userMatches: any = await this.firestore.collection('Matches', ref => ref
                        .where('players', 'array-contains', { id: this.userObj.id, email: this.userObj.email })
                        .where('frontEndState', '==', 'registration-closed'))
                        .get().toPromise()
      userMatches = userMatches.docs;
      if(userMatches.length > 0) {
        this.currentMatches = userMatches.map(el => {
          let match = el.data()
          let currentRound = match.matchType === 'knockout' ?
                              match.currentRound ?
                                `${match.currentRound.match(/[a-zA-Z]+/g)[0]} ${match.currentRound.match(/\d+/g)[0]}`
                              : '-'
                            : '-'
          let matchType = match.matchType !== 'one-on-one' ? match.matchType : 'One On One' 
          return {
            matchId: el.id,
            title: match.title,
            location: match.location ? match.location : 'No Location',
            matchType,
            currentRound,
          }
        })
        
        this.hasCurrentMatches = true;
      }
    } catch(e) {
      console.log(e)
    }
  }

  ngOnInit(): void {
    this.getCurrentMatches();
  }

}
