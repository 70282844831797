import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-tennis-teams-invites',
  templateUrl: './player-tennis-teams-invites.component.html',
  styleUrls: ['./player-tennis-teams-invites.component.scss']
})
export class PlayerTennisTeamsInvitesComponent implements OnInit {

  isLoading: boolean = true;
  invites = [];
  requests = [];
  hasInvites: boolean;
  isAcceptModalOpen: boolean;
  isRejectModalOpen: boolean;
  currRequest;
  isModalLoading: boolean;


  constructor(private firestore: AngularFirestore,
              private authService: AuthService,
              private router: Router) { }

  async gatherData(invites) {
    try {
      let requests = await Promise.all(invites.map(async el => {
        let tempUserObj = {};
        tempUserObj = await this.gatherUserData(el.senderId, el.inviteId);
        return tempUserObj
      })) 

      this.requests = requests.slice();
      this.isLoading = false;
    } catch(e) {
      console.log(e)
    }
  }

  async gatherUserData(userId, inviteId) {
    try { 
      let user = await this.firestore.collection('Users').doc(userId).get().toPromise()
      let userObj: any = {};
      userObj.name = user.data().firstName + " "+ user.data().lastName;
      userObj.email = user.data().email;
      userObj.id = userId;
      userObj.inviteId = inviteId;
      return userObj;
    } catch(e) {
      console.log(e)
    }
  }

  openModal(request, modalType) {
    this.currRequest = request;
    if(modalType === 'accept') {
      document.body.classList.add('modal-open');
      this.isAcceptModalOpen = true;
    }
    if(modalType === 'reject') {
      document.body.classList.add('modal-open');
      this.isRejectModalOpen = true;
    }
  }

   async accept(request) {
    this.isModalLoading = true;
    let firebaseUserCollection = this.firestore.collection('Users');
    try {
      let memberOne = (await firebaseUserCollection.doc(request.id).get().toPromise()).data();
      let memberTwo = (await firebaseUserCollection.doc(this.authService.user.uid).get().toPromise()).data();
      let gender: string = memberOne.gender === memberTwo.gender ? memberTwo.gender : 'mixed';
      let wheelChair: string = memberOne.wheelChair === memberTwo.wheelChair ? memberTwo.wheelChair : 'mixed';

      let newTeam = await this.firestore.collection('Teams').add({
        teamRequestId: request.inviteId,
        members: [
          { id: request.id, email: memberOne.email },
          { id: this.authService.user.uid, email: memberTwo.email }
        ],
        name: memberOne.firstName + " & " + memberTwo.firstName,
        victorPoints: 1000,
        gender,
        wheelChair,
      })
      await this.firestore.collection('TeamRequests').doc(request.inviteId).update({ state: 'accepted', teamId: newTeam.id })
      this.isModalLoading = false;
      this.closeModal();
      this.router.navigate(['/tennis/user/teams/general-teams/accepted'])
    } catch(e) {
      console.log(e)
      this.isModalLoading = false;
    }
  }

  async reject(request) {
    try {
      let deleteDoc = await this.firestore.collection('TeamRequests').doc(request.inviteId).delete();
      this.closeModal();
      this.isLoading = true;
      this.ngOnInit();
    } catch(e) {
      console.log(e)
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');    
    this.isAcceptModalOpen = false;    
    this.isRejectModalOpen = false;
  }

  async ngOnInit() {
    try {
      let requests = await this.firestore.collection('TeamRequests', ref => ref
                    .where('receiverId', '==', this.authService.user.uid)
                    .where('state', '==', 'pending')).get().toPromise()
      
      if(requests.docs.length > 0) {
        this.invites = requests.docs.slice()
        let invites = [];
        
        invites = this.invites.map(el => {
          let tempInviteObj = el.data();
          tempInviteObj.inviteId = el.id; 
          return tempInviteObj;
        })
        this.gatherData(invites);
        this.hasInvites = true;
      } else {
        this.isLoading = false;
        this.hasInvites = false;
      }
    } catch(e) {
      console.log(e)
    }
  }

}
