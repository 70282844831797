<div class="panel">
  <div class="panel-inner">
    <h3 class="top-heading">VIICTOR RANKING POINT SYSTEM &amp; RATING SYSTEM</h3>
    <div class="content">
      <ul>
        <li>All players who have participated in Viictor knockout or box league tournaments are eligible
          to receive ranking points for a Viictor ranking and a constantly updated Viictor rating.</li>
        <li>Viictor ratings are different to Viictor rankings.</li>
        <li>Viictor rankings are solely rankings based on players results within the Viictor
          system, which has a 4 tiered system.</li>
        <li>Viictor ratings are based solely on on your last 30 played within Viictor.</li>
        <li>Viictor rankings and points system are used solely for Viictor. Viictor point
          system will be used to determine the Viictor ranking, which in turn may
          determine the winners of major prizes allocated. (For example end of year race)</li>
        <li>Viictor conducts both a singles ranking and rating &amp; a doubles ranking and rating.</li>
        <li>Viictor rankings are calculated using a players best ten (10) singles results for
          singles and best ten (10) doubles results over a 12 month period.</li>
        <li>Viictor conducts a points race system, January – December, with major prizes
          being allocated at the end of December.</li>
        <li>Viictor also uses a rolling points system whereby points are dropped off at the end
          of each players 12 month period. Each player will therefore defend or accumulate
          more points in each given months tournament at the commencement of that
          players 2 nd year of Viictor tournaments.</li>
        <li>There are no Viictor ranking points awarded to Head 2 Head Challenges however,
          those challenges will affect your Viictor rating.</li>
      </ul>
      <h4 class="main-heading">VIICTOR RANKING POINTS SYSTEM</h4>
      <h4>Tier 4 Pro level: </h4>
      <h4>Monthly knockout tournament</h4>
      <h4>Open Viictor Rating 1800 –2200 (players with a Viictor rating of 0 –1799 can also play these tournaments)</h4>
      <h4>Singles, Doubles, Mixed</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Rnd 64</td>
              <td>Rnd 32</td>
              <td>Rnd 16</td>
              <td>Quarter Finals</td>
              <td>Semi Finals</td>
              <td>Finalist</td>
              <td>Winner</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2 Points</td>
              <td>5 Points</td>
              <td>15 Points</td>
              <td>30 Points</td>
              <td>45 Points</td>
              <td>60 Points</td>
              <td>90 Points</td>
            </tr>
            <tr>
              <td>Consolation Draw</td>
              <td></td>
              <td></td>
              <td></td>
              <td>5 Points</td>
              <td>10 Points</td>
              <td>20 Points</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul>
        <li>If a draw has 15 players or less the total points awarded will be as above divided
          by 2. Ie Winner of a 15 player draw will earn 45 points, finalist 30 points etc.
          Rule also applies to consolation draw where main draw has 15 players or less.</li>
      </ul>

      <h4 class="main-heading"></h4>
      <h4>Tier 4 Pro level: </h4>
      <h4>Box Leagues</h4>
      <h4>Open Viictor Rating 1800 –2200 (players with a Viictor rating of 0 –1799 can also play these tournaments)</h4>
      <h4>Singles, Doubles, Mixed</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Win 1 Match</td>
              <td>Win 2 Matches</td>
              <td>Win 3 Matches</td>
              <td>Win 4 Matches</td>
              <td>Win 5 Matches</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5 Points</td>
              <td>15 Points</td>
              <td>35 Points</td>
              <td>45 Points</td>
              <td>60 Points</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 class="main-heading"></h4>
      <h4>Tier 3 Advanced: </h4>
      <h4>Monthly knockout tournaments</h4>
      <h4>Viictor Rating 1201-1799 (players with a Viictor rating of 0 –1200 can also play these tournaments)</h4>
      <h4>15/u tournaments</h4>
      <h4>Open mixed combined doubles Viictor Rating 2402 – 3599</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Rnd 64</td>
              <td>Rnd 32</td>
              <td>Rnd 16</td>
              <td>Quarter Finals</td>
              <td>Semi Finals</td>
              <td>Finalist</td>
              <td>Winner</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 Point</td>
              <td>3 Points</td>
              <td>7 Points</td>
              <td>15 Points</td>
              <td>22 Points</td>
              <td>30 Points</td>
              <td>45 Points</td>
            </tr>
            <tr>
              <td>Consolation Draw</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>5 Points</td>
              <td>10 Points</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul>
        <li>If a draw has 15 players or less the total points awarded will be as above divided
          by 2. Ie Winner of a 15 player Tier 2 Intermediate draw will earn 22.5 points, finalist 15 points
          etc. Rule also applies to consolation draw where main draw has 15 players or less.</li>
      </ul>
      <h4>Tier 3 Advanced: </h4>
      <h4>Box Leagues</h4>
      <h4>Open Viictor Rating 1201 –1799 (players with a Viictor rating of 0 –1799 can also play these tournaments)</h4>
      <h4>15/u tournaments</h4>
      <h4>Open mixed combined doubles Viictor Rating 2402 – 3599</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Win 1 Match</td>
              <td>Win 2 Matches</td>
              <td>Win 3 Matches</td>
              <td>Win 4 Matches</td>
              <td>Win 5 Matches</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5 Points</td>
              <td>15 Points</td>
              <td>30 Points</td>
              <td>45 Points</td>
              <td>60 Points</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 class="main-heading"></h4>
      <h4>Tier 2 Intermediate: </h4>
      <h4>Monthly knockout tournaments</h4>
      <h4>Viictor Rating 601 -1200 (players with a Viictor rating of 0 –600 can also play these tournaments)</h4>
      <h4>13/u</h4>
      <h4>Over 40s</h4>
      <h4>Over 50s</h4>
      <h4>Wheelchair events</h4>
      <h4>Open mixed combined doubles Viictor Rating 1202 – 2401</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Rnd 64</td>
              <td>Rnd 32</td>
              <td>Rnd 16</td>
              <td>Quarter Finals</td>
              <td>Semi Finals</td>
              <td>Finalist</td>
              <td>Winner</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 Point</td>
              <td>1.5 Points</td>
              <td>4.5 Points</td>
              <td>9.5 Points</td>
              <td>14 Points</td>
              <td>20 Points</td>
              <td>32 Points</td>
            </tr>
            <tr>
              <td>Consolation Draw</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>3.5 Points</td>
              <td>7.5 Points</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul>
        <li>If a draw has 15 players or less the total points awarded will be as above divided
          by 2. Ie Winner of a 15 player draw will earn 16 points, finalist 10 points etc.
          Rule also applies to consolation draw where main draw has 15 players or less.</li>
      </ul>
      <h4>Tier 2 Intermediate: </h4>
      <h4>Box Leagues</h4>
      <h4>Viictor Rating 601 – 1200 (players with a Viictor rating of 0 –600 can also play these tournaments)</h4>
      <h4>Over 40s</h4>
      <h4>Over 50s</h4>
      <h4>Wheelchair events</h4>
      <h4>Open mixed combined doubles Viictor Rating 1202 – 2401</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Win 1 Match</td>
              <td>Win 2 Matches</td>
              <td>Win 3 Matches</td>
              <td>Win 4 Matches</td>
              <td>Win 5 Matches</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.5 Points</td>
              <td>4.5 Points</td>
              <td>9.5 Points</td>
              <td>14 Points</td>
              <td>20 Points</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 class="main-heading"></h4>
      <h4>Tier 1 Beginners: </h4>
      <h4>Monthly knockout tournaments</h4>
      <h4>Viictor Rating 0 - 600</h4>
      <h4>11/u</h4>
      <h4>Over 60s</h4>
      <h4>Over 70s</h4>
      <h4>Over 80s</h4>
      <h4>Open mixed combined doubles Viictor Rating 0 – 1200</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Rnd 64</td>
              <td>Rnd 32</td>
              <td>Rnd 16</td>
              <td>Quarter Finals</td>
              <td>Semi Finals</td>
              <td>Finalist</td>
              <td>Winner</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 Point</td>
              <td>1 Points</td>
              <td>3 Points</td>
              <td>7 Points</td>
              <td>11 Points</td>
              <td>15 Points</td>
              <td>22 Points</td>
            </tr>
            <tr>
              <td>Consolation Draw</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>2.5 Points</td>
              <td>5 Points</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul>
        <li>If a draw has 16 players or less the total points awarded will be as above divided
          by 2. Ie Winner of a 15 player Tier 3 Advanced draw will earn 11 points, finalist 7.5 points
          etc.</li>
      </ul>
      <h4>Tier 1 Beginners: </h4>
      <h4>Box Leagues</h4>
      <h4>Viictor Rating 0 - 600</h4>
      <h4>11/u</h4>
      <h4>Over 60s</h4>
      <h4>Over 70s</h4>
      <h4>Over 80s</h4>
      <h4>Open mixed combined doubles Viictor Rating 0 – 1200</h4>
      <div class="table">
        <table class="tier-table">
          <thead>
            <tr>
              <td>Win 1 Match</td>
              <td>Win 2 Matches</td>
              <td>Win 3 Matches</td>
              <td>Win 4 Matches</td>
              <td>Win 5 Matches</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 Points</td>
              <td>3 Points</td>
              <td>7 Points</td>
              <td>11 Points</td>
              <td>15 Points</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>Points allocation</h4>
      <ul>
        <li>Tier 4 Pro level Tournaments are open level tournaments to all standards with any Viictor
          Rating &amp; have maximum points value. These are the strongest tournaments.</li>
        <li>Tier 3 Advanced Tournaments are closed level tournaments only accepting players with a
          Viictor Rating of 0 – 1799 &amp;
          15/u age group tournaments &amp;
          Combined doubles rating tournaments of 0 – 3599</li>
        <li>Tier 2 Intermediate Tournaments are closed level tournaments only accepting players with a
          Viictor Rating of 0 – 1200 &amp;
          13/u, Over 40’s &amp; Over 50’s &amp; Wheelchair events
          Combined doubles rating tournaments of 0 – 2401</li>
        <li>Tier 1 Tournaments are closed level tournaments only accepting players with a
          Viictor Rating of 0 – 600 &amp;
          11/u, Over 60’s &amp; over 70’s &amp;
          Combined doubles rating tournaments of 0 – 1200</li>
      </ul>
    </div>
  </div>
</div>
