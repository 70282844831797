<div class="footer1">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <ul class="social-links-footer">
          <li class="link">
            <a target="_blank" href="https://www.facebook.com/viictorsports"><i class="icon ico-fb"></i></a>
          </li>
          <li class="link">
            <a target="_blank" href="https://www.instagram.com/viictor_sports/"><i class="icon ico-instagram"></i></a>
          </li>
          <li class="link">
            <a target="_blank" href="https://www.linkedin.com/company/viictor/"><i class="icon ico-linkedin"></i></a>
          </li>
        </ul>
      </div>
      <div class="col-md-6 d-flex align-items-center">
        <div class="contact">
          <div class="float-right">
            <i class="icon ico-email"></i>
            <a>info@viictor.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer2">
  <div class="container">
    <div class="row">
      <div class="col-md-4">Viictor {{ fullYear }}</div>
      <div class="col-md-4"><a target="_blank" [routerLink]="['/privacy-policy']">Privacy Policy</a></div>
      <div class="col-md-4"><a target="_blank" [routerLink]="['/terms-of-use']">Terms & Conditions</a></div>
    </div>
  </div>
</footer>
