<div class="personal-details">
  <div class="loader" *ngIf="isLoading">
    <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
  </div>
  <div *ngIf="isUserDataLoaded && !isLoading" class="personal-details-inner">
    <div class="img-block">
      <img *ngIf="currUserObj" [src]="currUserObj.profileImg" alt="proifle-img">
    </div>
    <div class="details">
      <div class="heading">
        {{ currUserObj.firstName }} {{ currUserObj.lastName }}
        <div class="button-wrapper">
          <button *ngIf="authService.authenticated() && isInviteButtonActive" type="button" class="btn-desktop" (click)="openInviteModal()">Invite To Team</button>
          <button *ngIf="authService.authenticated() && authService.user.uid !== currUserRawObj.id" type="button" class="btn-desktop" (click)="openChatModal()">Send Message</button>
          <button *ngIf="isChallengeButtonActive" type="button" class="btn-desktop" (click)="openChallengeModal()">Challenge</button>
        </div>
      </div>
      <div class="fields row">
        <div class="col-4 col-md-4 col-lg-2 field">
          <label>Age</label>
          <div class="value">{{ currUserObj.age }}</div>
        </div>
        <div class="col-4 col-md-4 col-lg-2 field">
          <label>Gender</label>
          <div class="value">{{ currUserObj.gender }}</div>
        </div>
        <div class="col-4 col-md-4 col-lg-2 field" *ngIf="currUserObj.weight">
          <label>Weight</label>
          <div class="value">{{ currUserObj.weight }}<small>kg</small> </div>
        </div>
        <div class="col-4 col-md-4 col-lg-2 field">
          <label>Height</label>
          <div class="value">{{ currUserObj.height }}<small>cm</small></div>
        </div>
        <div class="col-4 col-md-4 col-lg-2 field">
          <label>Wheelchair</label>
          <div class="value">{{ currUserObj.wheelChair }}</div>
        </div>
      </div>

      <div class="button-wrapper-mobile">
        <button  *ngIf="authService.authenticated() && isInviteButtonActive" type="button" class="btn-mobile" (click)="openInviteModal()">Invite To Team</button>
        <button  *ngIf="authService.authenticated()" type="button" class="btn-mobile" (click)="openChatModal()">Send Message</button>
        <button *ngIf="isChallengeButtonActive" type="button" class="btn-mobile" (click)="openChallengeModal()">Challenge</button>
      </div>
    </div>
  </div>
  <div class="alert-msgs">
    <div *ngIf="success" class="alert alert-success">
      You have successfully sent the challenge request to <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong>
    </div>
    <div *ngIf="isChallengeAlreadySent" class="alert alert-success">
      You have already sent the challenge request to <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong>
    </div>
    <div *ngIf="isUserLoggedIn === false" class="alert alert-danger">
      You need to login before sending the challenge request to <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong>
    </div>
    <div *ngIf="isInviteSent" class="alert alert-success">
      You have successfully sent the invite to <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong>
    </div>
    <div *ngIf="isTeamInviteAlreadySent" class="alert alert-success">
      You have already sent the team invite to <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong>
    </div>
    <div *ngIf="isUserInTeam" class="alert alert-success">
      You are in a team with <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong>
    </div>
  </div>

  <section [ngStyle]="{'display': isChallengeModalOpen ? 'block' : 'none'}" class="modal">
    <div class="modal-background">
      <div class="modal-body">

        <div [ngStyle]="{'display': isUserBetBox ? 'block' : 'none'}">
          <div class="bet-box">
            <p>Enter an amount to place a bet</p>
            <input class="form-control" [(ngModel)]="betAmount" type="number">
            <div class="note">The minimum amount for bet is $20</div>
            <div class="join-buttons">
              <button type="button" (click)="placeBet()">Pay Fees</button>
            </div>
          </div>
        </div>
        <div [ngStyle]="{'display': !isUserBetBox ? 'block' : 'none'}">
          <p>Please complete the payment process to send a challenge request</p>
          <div [ngStyle]="{'display': !isModalLoading ? 'block' : 'none'}">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          </div>
          <div [ngStyle]="{'display': isModalLoading ? 'block' : 'none'}">
            <div class="loader"><img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader"></div>
          </div>
        </div>

        <div class="join-buttons">
          <button type="button" (click)="closeModal()">Cancel</button>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="isChatModalOpen" class="modal">
    <div class="modal-background chat-modal">
      <div class="modal-body">
        <p>Send a message to <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong></p>
        <div class="close-modal" (click)="closeModal()">x</div>
        <div class="message">
          <form [formGroup]="chatForm">
            <textarea class="form-control input" rows="5" formControlName="message"></textarea>
            <button *ngIf="!isSendMessageLoading"  type="button" (click)="sendMessage(chatForm)">Send</button>
            <div *ngIf="isSendMessageLoading" class="loader-message">
              <img src="../../../assets/image/ajax-loader.gif" alt="ajax-loader">
            </div>
            <div class="alert alert-success message-sent" *ngIf="isMessageSent">Message Sent</div>
            <div class="alert alert-danger message-sent" *ngIf="isMessageError">Could not send message. Please try again.</div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="isInviteModalOpen" class="modal">
    <div class="modal-background">
      <div class="modal-body">
        <p>Would you like to add <strong>{{ currUserObj.firstName }} {{ currUserObj.lastName }}</strong> in your team?</p>
        <div class="join-buttons">
          <button type="button" (click)="sendInvite()">Yes</button>
          <button type="button" (click)="closeModal()">No</button>
        </div>
      </div>
    </div>
  </section>
</div>
