import { ProfileService } from './../../../../services/profile/profile.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-player-tennis-tournament-teams-received',
  templateUrl: './player-tennis-tournament-teams-received.component.html',
  styleUrls: ['./player-tennis-tournament-teams-received.component.scss'],
})
export class PlayerTennisTournamentTeamsReceivedComponent implements OnInit {
  isLoading: boolean = true;
  invites = [];
  requests = [];
  hasInvites: boolean;
  isAcceptModalOpen: boolean;
  isRejectModalOpen: boolean;
  currRequest;
  isModalLoading: boolean;
  userObj;
  $requestState: Subscription;

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router
  ) {}

  async gatherData(invites) {
    try {
      let requests = await Promise.all(
        invites.map(async (el) => {
          let team = await this.gatherTeamData(el.teamId);
          let match = await this.gatherMatchData(el.matchId);
          let requestObj: any = {
            team,
            match,
            amount: el.payments.amount,
            inviteId: el.inviteId,
          };

          return requestObj;
        })
      );
      this.requests = requests.slice();
      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  async gatherTeamData(teamId) {
    try {
      let team = await this.firestore
        .collection('Teams')
        .doc(teamId)
        .get()
        .toPromise();
      let teamObj: any = {};
      teamObj.name = team.data().name;
      teamObj.id = team.id;
      return teamObj;
    } catch (e) {
      console.log(e);
    }
  }

  async gatherMatchData(matchId) {
    try {
      let match = await this.firestore
        .collection('Matches')
        .doc(matchId)
        .get()
        .toPromise();
      let matchObj: any = {};
      matchObj.name = match.data().title;
      matchObj.id = match.id;
      return matchObj;
    } catch (e) {
      console.log(e);
    }
  }

  async openModal(request, modalType) {
    this.currRequest = request;
    if (modalType === 'accept') {
      document.body.classList.add('modal-open');
      this.isAcceptModalOpen = true;
    }
    if (modalType === 'reject') {
      document.body.classList.add('modal-open');
      this.isRejectModalOpen = true;
    }
  }

  async acceptRequest(currRequest: {
    inviteId: string;
    match: { id: string };
  }) {
    try {
      this.isModalLoading = true;
      // Updating request object
      await this.firestore
        .collection('MatchTeamRequests')
        .doc(currRequest.inviteId)
        .update({ state: 'capture-payments' });

      this.$requestState = this.firestore
        .collection('MatchTeamRequests')
        .doc(currRequest.inviteId)
        .valueChanges()
        .subscribe(async (res: any) => {
          if (res.state === 'accepted') {
            if (this.$requestState) {
              this.$requestState.unsubscribe();
            }

            // Getting match data
            let tournamentDoc = this.firestore
              .collection('Matches')
              .doc(currRequest.match.id);

            // Update match status to visible
            await tournamentDoc.update({ deleted: false });
            let tournamentData = await tournamentDoc.get().toPromise();

            // Closing Registration if match_type is 'box and Players Array length is 5
            if (tournamentData.data().matchType === 'box') {
              if (
                tournamentData.data().players &&
                tournamentData.data().players.length > 4
              ) {
                await this.firestore
                  .collection('Matches')
                  .doc(currRequest.match.id)
                  .update({ frontEndState: 'registration-closed' });
              }
            }

            this.isModalLoading = false;
            this.isAcceptModalOpen = false;
            this.router.navigate([
              '/tennis/user/teams/tournament-teams/accepted',
            ]);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  async reject(request) {
    try {
      let deleteDoc = await this.firestore
        .collection('MatchTeamRequests')
        .doc(request.inviteId)
        .delete();
      this.closeModal();
      this.isLoading = true;
      this.ngOnInit();
    } catch (e) {
      console.log(e);
    }
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.isAcceptModalOpen = false;
    this.isRejectModalOpen = false;
  }

  async ngOnInit() {
    this.userObj = (await this.profileService.getUserProfileData()).data();
    try {
      let requests = await this.firestore
        .collection('MatchTeamRequests', (ref) =>
          ref
            .where('receiverId', '==', this.authService.user.uid)
            .where('state', '==', 'pending')
        )
        .get()
        .toPromise();

      if (requests.docs.length > 0) {
        this.invites = requests.docs.slice();
        let invites = [];

        invites = this.invites.map((el) => {
          let tempInviteObj = el.data();
          tempInviteObj.inviteId = el.id;
          return tempInviteObj;
        });
        this.gatherData(invites);
        this.hasInvites = true;
      } else {
        this.isLoading = false;
        this.hasInvites = false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.$requestState) {
      this.$requestState.unsubscribe();
    }
  }
}
