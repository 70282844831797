import { ProfileService } from './../../services/profile/profile.service';
import { AuthService } from './../../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    try {
      let userObj = await this.profileService.getUserProfileData()
      if(userObj.data().isAdmin){
        return true
      }
      else {
        this.router.navigate(['tennis/home']);
        return false;
      }
    } catch(e) {
      console.log(e)
    }
  }
  
}
