<div class="singles-matches">
  <h2 class="table-header">My Single Matches</h2>
  <div class="challenges-table" *ngIf="hasSingleMatches; else noMatches">
    <div class="header">
      <div class="title">Title</div>
      <div class="location">Location</div>
      <div class="opponent">Opponent</div>
      <div class="score">Score</div>
      <div class="round">Round</div>
    </div>
    <div class="challenges">
      <div class="challenge" [routerLink]="['/tennis/tournaments/' + challenge.matchId]"
        *ngFor="let challenge of challenges; let i = index">
        <div class="title">{{ challenge.title }}</div>
        <div class="location">{{ challenge.location }}</div>
        <div class="opponent" [routerLink]="['/tennis/player/' + challenge.opponentId]">{{ challenge.opponentName }}</div>

        <div class="score">
          <span class="set-one">
            <span>{{ challenge.fixtureData.scores.myScore.setOne }}
              <sup *ngIf="challenge.fixtureData.scores.myScore.setOneTie">{{ challenge.fixtureData.scores.myScore.setOneTie }}</sup>
            </span>/<span>{{ challenge.fixtureData.scores.opponentScore.setOne }}
              <sup *ngIf="challenge.fixtureData.scores.opponentScore.setOneTie">{{ challenge.fixtureData.scores.opponentScore.setOneTie }}</sup>
            </span>
          </span>
          <span class="set-two">
            <span>{{ challenge.fixtureData.scores.myScore.setTwo }}
              <sup *ngIf="challenge.fixtureData.scores.myScore.setTwoTie">{{ challenge.fixtureData.scores.myScore.setTwoTie }}</sup>
            </span>/<span>{{ challenge.fixtureData.scores.opponentScore.setTwo }}
              <sup *ngIf="challenge.fixtureData.scores.opponentScore.setTwoTie">{{ challenge.fixtureData.scores.opponentScore.setTwoTie }}</sup>
            </span>
          </span>
          <span *ngIf="challenge.fixtureData.scores.myScore.setThree" class="set-three">
            <span>{{ challenge.fixtureData.scores.myScore.setThree }}
              <sup *ngIf="challenge.fixtureData.scores.myScore.setThreeTie">{{ challenge.fixtureData.scores.myScore.setThreeTie }}</sup>
            </span>/<span>{{ challenge.fixtureData.scores.opponentScore.setThree }}
              <sup *ngIf="challenge.fixtureData.scores.opponentScore.setThreeTie">{{ challenge.fixtureData.scores.opponentScore.setThreeTie }}</sup></span>
          </span>
        </div>
        <div class="round">{{ challenge.round }}</div>
      </div>
    </div>
  </div>
</div>


<ng-template #noMatches>
  <div class="alert">You haven't played any matches yet.</div>
</ng-template>