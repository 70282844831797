<div class="panel">
  <div class="panel-inner">
    <h3 class="top-heading">Head 2 Head Challenge</h3>
    <div class="content">
      <p>Only players that have a verified Viictor rating can play or create a Head 2 Head Challenge.</p>
      <p>All Head 2 Head challenges are best of 3 full sets with a tiebreak at 6 games all.</p>
      <p>Players can join a H2H or create their own H2H.</p>
      <p>OR</p>
      <p>Accept or enter into an already created Head 2 Head Challenge</p>
      <p>OR</p>
      <p>Decline to accept the nominated challenger until he or she is comfortable with the
        opponent.</p>
      <p>Viictor automatically deducts 15% from all entry fees. Remaining 85% of entry fees/monies
        goes into the prize money pot for the winner of the Head 2 Head Challenge. Prizemoney is
        automatically paid into the winners E – Wallet.</p>
      <p>A coin toss will be initiated to determine the Home/Away player and players will then
        arrange to play their match, enter and verify the result to determine the winner.</p>
      <p>Viictor Head 2 Head Challenges are designed to be interactive and will therefore be the
        player’s responsibility to arrange a suitable time and place to play their matches within the
        given timeframe.</p>
      <p>Viictor Head 2 Head Challenges will operate in conjunction with the Viictor rating system. All
        challenges will count towards both players Viictor rating.</p>
    </div>
  </div>
</div>