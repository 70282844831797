<div class="users">
  <div class="users-inner">
    <h3 class="top-heading">Upload Image</h3>
    <div class="gallery">
      <div class="upload-image">
        <div class="upload">
          <input #uploadInput type="file" accept="image/png,image/jpeg" (change)="imgHandler($event)" />
          <label>Caption</label>
          <textarea rows="6" [(ngModel)]="caption"></textarea>
          <button type="button" [disabled]="!isValid" (click)="uploadImage()">Upload Image</button>
        </div>
        <div class="preview">
          <label>Preview</label>
          <img #previewImage src="../../../assets/image/no-img.webp" alt="gallery-image" />
        </div>
      </div>
      <div class="alert-msgs">
        <div *ngIf="success" class="alert alert-success">Image uploaded successfully</div>
        <div *ngIf="isError.error" class="alert alert-danger">{{ isError.msg }}</div>
      </div>
      <div class="image-gallery">
        <h3 class="top-heading">Gallery</h3>
        <div class="loader" *ngIf="isLoading">
          <img src="../../../assets/image/ajax-loader.gif" alt="no-img" />
        </div>
        <div class="row" *ngIf="hasGallery && !isLoading">
          <div class="col-sm-6 col-md-4" *ngFor="let image of gallery; let i = index">
            <div class="img-block">
              <img [src]="image.url" alt="gallery-image" />
            </div>
            <div class="caption" *ngIf="image.caption">
              <div>{{ image.caption }}</div>
            </div> 
          </div>
        </div>
        <div style="padding: 20px" *ngIf="!hasGallery && !isLoading">
          There are no images in the gallery.
        </div>
      </div>
    </div>
  </div>
</div>